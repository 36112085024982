
import  { BodyContentFormWithCard ,EnablesRadiosBtn,TitleSmall} from "../../../../../../index";



const Invite = () => {
  return (
    <BodyContentFormWithCard id="setting-invite">
      <TitleSmall title="Invitation" className=" pb-5 pb-lg-10" />
      <EnablesRadiosBtn typeForms="invite" />
    </BodyContentFormWithCard>
  );
};

export default Invite;
