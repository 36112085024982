import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BodyContentFormWithCard,
  CheckBox,
  ColTextAreaWithLabel,
  ColTextFiledWithLabel,
  CountrySelect,
  DateComp,
  GroupCheckBox,
  GroupRadioBtn,
  MyLabel,
  MySelect,
  StateSelect,
  TimeComp,
  TitleSmall,
  UploadFile,
} from "../../../../../index";


const Onborad = (props) => {
 if(props.tabs){ return (
    <div id="kt_app_content" className="app-content px-0">
      {/*begin::Navbar*/}
      <div className="tab-content ">
        {props.tabs?.map((element, key) => {
          return (
            <ContentOnborading
              key={`onborading-data-cleanse-${element.id}-${key}`}
              id={`onborading-data-cleanse-${element.id}-${key}`}
              element={element}
              isActive={key == 0}
            />
          );
        })}
      </div>
    </div>
  );}else return <div>tabs empty</div>
};

export default Onborad;

const ContentOnborading = (props) => {
  const element = props.element;
  const [visiable, setVisiable] = useState(element.do_have != 1);

  const widget = ListField(element);

  const rowsField = widget.map((element, index) => {
    const component = <Row key={index} key1={index} element={element} />;

    return component;
  });

  return (
    <BodyContentFormWithCard id={props.id} isActive={props.isActive}>
      <TitleSmall title={element.form_name} className=" pb-5 pb-lg-10" />

      {visiable ? (
        rowsField
      ) : (
        <div className="fv-row">
          {/*begin::Label*/}
          <label className="form-label">Do You Have</label>
          {/*end::Label*/}
          {/*begin::Input*/}
          <div className="form-check form-check-custom form-check-solid mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              onClick={() => {
                setVisiable(!visiable);
              }}
            />
            <label className="form-check-label">Yes</label>
          </div>
          {/*end::Input*/}
          {/*begin::Description*/}
          <div className="text-muted fs-7">Allow Show Form .</div>
          {/*end::Description*/}
        </div>
      )}
    </BodyContentFormWithCard>
  );
};

const Row = (props) => {
  return (
    <>
      {/*begin::Input group*/}
      <div className="row mb-5">
        {props.element.map((item, key) => (
          <Col key={item.id + "_" + props.key1} {...item} />
        ))}
      </div>
      {/*end::Input group*/}
    </>
  );
};

const Col = (props) => {
  const col = props.col ?? 12;
  let field;
  switch (props.type) {
    case "file":
      field = (
        <UploadFile
          label={props.name}
          name={props.slug}
          isRequired={props.required == 1}
        />
      );
      break;
    case "select":
      field = (
        <MySelect
          label={props.name}
          name={props.slug}
          isRequired={props.required == 1}
        />
      );
      break;
    case "multi_select":
      field = (
        <>
          <MyLabel label={props.name} />
          <GroupCheckBox options={props.options} />
        </>
      );
      break;
    case "country":
      field = (
        <CountrySelect
          label={props.name}
          name={props.slug}
          isRequired={props.required == 1}
        />
      );
      break;
    case "state":
      field = (
        <StateSelect
          label={props.name}
          name={props.slug}
          isRequired={props.required == 1}
        />
      );
      break;
    case "radio":
      field = <GroupRadioBtn />;
      break;
    case "date":
      field = <DateComp />;
      break;
    case "time":
      field = <TimeComp />;
      break;
    case "textarea":
      field = (
        <ColTextAreaWithLabel
          label={props.name}
          name={props.slug}
          isRequired={props.required == 1}
        />
      );
      break;
    case "checkbox":
      field = (
        <CheckBox
          title={props.name}
          name={props.slug}
          isRequired={props.required == 1}
        />
      );
      break;
    case "time":
      field = (
        <CheckBox
          title={props.name}
          name={props.slug}
          isRequired={props.required == 1}
        />
      );
      break;

    case "link":
      field = (
        <ColTextFiledWithLabel
          label={props.name}
          name={props.slug}
          type="url"
          isRequired={props.required == 1}
        />
      );
      break;
    default: //input ,number,email
      field = (
        <ColTextFiledWithLabel
          label={props.name}
          name={props.slug}
          type={props.type}
          isRequired={props.required == 1}
        />
      );
  }
  return (
    <div
      className={`col-${col}  col-md-${col > 6 ? col : col * 2}  col-fv-row`}
    >
      {field}
    </div>
  );
};

const ListField = (props) => {
  let col = 0;
  let arrRows = [];
  let arrCols = [];
  const fields = props.with;
  const [listField, setListField] = useState([]);

  useEffect(() => {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      col = col + Number(element.bs_column);
      arrCols.push(element);

      if (col >= 12) {
        col = 0;
        arrRows.push(arrCols);

        arrCols = [];
      }
    }
    setListField(arrRows);
  }, []);

  return listField;
};
