import { useDispatch, useSelector } from "react-redux";
import {
  TableAdmin,
  CardsSummary,
  BtnPrimary,
  EmptyTableTD,
  LayoutAdmin,
  OriginalVendorMasterAction,
  TDCheck,
  TDNameAndEmail,
  TDTax,
  TDAvgSpend,
  TDAvgCount,
  TDPriority,
  TDAddress1,
  TDStatus,
  TDContact,
  TDPhon,
  TDCountry,
  TDVerificationStatus,
  TDVerificationCount,
  TDId,
  LayoutCleanse,
} from "../../../../../index";
import {
  address1Txt,
  avgCountTxt,
  avgSpendTxt,
  contactTxt,
  countryTxt,
  emailTxt,
  phonTxt,
  priorityTxt,
  statusTxt,
  taxTxt,
  vendorIdTxt,
  verificationCountTxt,
  verificationStatusTxt,
} from "./constTitleFiled";
import { useEffect } from "react";
import {
  fetchRecord,
  updateCurrentPage,
} from "../../../../../../rtk/Slices/Records-Slices";

const OriginalVendorMaster = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCurrentPage(1));
    dispatch(fetchRecord({ category: "original_supplier_master" }));
  }, []);
  return (
    <LayoutCleanse
      title="Original Supplier Master"
      pages={["Original Supplier Master"]}
    >
      <CardsSummary
        filters={dataCleans["record_status"]}
        total_vendors={dataCleans.total_vendors}
      />
      <TableAdmin
      
        isFilter={true}
        isExport={true}
        isSelest={true}
        isAction={true}
        titles={[
          vendorIdTxt,
          emailTxt, ///name and email
          taxTxt,
          avgSpendTxt,
          avgCountTxt,
          priorityTxt,
          address1Txt,
          statusTxt,
          contactTxt,
          phonTxt,
          countryTxt,
          verificationStatusTxt,
          verificationCountTxt,
        ]}
        bulks={{
          name: "original_vendor_master_bulk",
          list: [
          
            {
              id: "kt_modal_original_vendor_master_bulk_option_0",

              title: "To Verify",
              desc: " Best for business owners and company administrators",
            },
         
            {
              defaultValue: 1,
              id: "kt_modal_original_vendor_master_bulk_option_3",

              title: "Archive",
              desc: "Best for employees who regularly refund payments and respond to disputes",
            },
            {
              defaultValue: 3,
              id: "kt_modal_original_vendor_master_bulk_option_4",

              title: "To  Verify",
              desc: "Best for employees who regularly refund payments and respond to disputes",
            },
          ],
        }}
        pagenation={{
          total: dataCleans["num_page"] ?? 1,
          currentPage: dataCleans.current_page,
          updateCurrentPage: ({ page, count }) => {
            dispatch(
              fetchRecord({
                category: "original_supplier_master",
                page: page,
                count: count,
              })
            );
          },
        }}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default OriginalVendorMaster;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={record.id + key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={11} />;
  }
};

const Record = (props) => {
  const record = props.record;
  const id = record.vendor_id;

  return (
    <tr key={id}>
      <TDCheck />
      <TDId {...record} />
      <TDNameAndEmail {...record} />
      <TDTax {...record} />
      <TDAvgSpend {...record} />
      <TDAvgCount {...record} />
      <TDPriority {...record} />
      <TDAddress1 {...record} />
      <TDStatus {...record} />
      <TDContact {...record} />
      <TDPhon {...record} />
      <TDCountry {...record} />
      <TDVerificationStatus {...record} />
      <TDVerificationCount {...record} />
      <td className="text-end">
        <OriginalVendorMasterAction
          record={record}
          extraAction={<BtnPrimary text="Save To Data Cleans" />}
        />
      </td>
    </tr>
  );
};
