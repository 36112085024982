import React from "react";
import {
  BtnPrimary,
  BtnSecundary,
  CardsSummary,
  EmptyTableTD,
  TableAdmin,
  TDCheck,
} from "../../../../../../index";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ClaimsStatementTabs = () => {
  const navigate = useNavigate();
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <>
      <CardsSummary WithoutMT={true} filters={[]} total_vendors={0} />
      <TableAdmin
        isTableTabs={true}
        isSelest={true}
        isAction={true}
        ActionExtra={
          <>
            <BtnSecundary
              text="Download"
              attributes={{
                onClick: () => {},
              }}
            />
            <BtnPrimary
              text="Create Claim"
              attributes={{
                onClick: () => {
                  navigate("/new_claims");
                },
              }}
            />
          </>
        }
        titles={["Vendor ID", "Company Name", "Contact ", "Attachment"]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </>
  );
};

export default ClaimsStatementTabs;
const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={4} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <td></td>
      <td></td>
      <td></td>
      <td className="text-end"></td>
    </tr>
  );
};
