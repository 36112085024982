import { useEffect } from "react";
import {
  SideBarMneuOnboradingDataCleanse,
  Onborad,
  LayoutVendor,
} from "../../../../../index";
import { useDispatch, useSelector } from "react-redux";
import { fetchOnborading } from "../../../../../../rtk/Slices/Onborading-Data-Cleanse-Slices";

import { useParams } from "react-router-dom";

const OnboradingPortal = (props) => {
  const tabs = useSelector((state) => state.onbording.result);

  const dispatch = useDispatch();
  let { oboradingId } = useParams();
  useEffect(() => {
    dispatch(fetchOnborading({ vendor: oboradingId, cleanse: false }));
  }, []);

  return (
    <LayoutVendor
      title="Onborad"
      pages={["Portal", "Onborading"]}
      sidebar={<SideBarMneuOnboradingDataCleanse isnav={true} tabs={tabs} />}
    >
      <Onborad tabs={tabs} />
    </LayoutVendor>
  );
};

export default OnboradingPortal;
