export function encode(params) {
  var encodeData = JSON.stringify(params);
  if (!encodeData) {
    console.log("params encode:", undefined);
  }
  return encodeData;
}
export function decode(params) {
  var encodeData = JSON.parse(params);
  if (!encodeData) {
    console.log("params decode:", undefined);
  }
  return encodeData;
}
