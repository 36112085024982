import MySelect from "../MySelect";
///props
////label    String ex 'lable' (option);
////name     String ex 'name';
////isHeadenSearch bool option by default false;
const StateSelect = (props) => {

  return (
    <MySelect
  
    {...props}
      options={[
        { value: "IL", selected: true, country: "IL" },
        { value: "AF", country: "kkAfghanistan" },
        { value: "AX", country: "Aland Islands" },
        { value: "AL", country: "Albania" },
        { value: "DZ", country: "Algeria" },
        { value: "AS", country: "American Samoa" },
        { value: "AD", country: "Andorra" },
        { value: "AO", country: "Angola" },
        { value: "AI", country: "Anguilla" },
        { value: "AG", country: "Antigua and Barbuda" },
        { value: "AR", country: "Argentina" },
        { value: "AM", country: "Armenia" },

        { "data-state": "Alabama", value: "Alabama", country: "Alabama" },
        { "data-state": "Alaska", value: "Alaska" },
        { "data-state": "Alaska", value: "Alaska" },
        { "data-state": "Arkansas", value: "Arkansas" },
        { "data-state": "Alabama", value: "Alabama" },

        { "data-state": "Alaska", value: "Alaska" },

        { "data-state": "Arizona", value: "Arizona" },

        { "data-state": "Arkansas", value: "Arkansas" },

        { "data-state": "Byram", value: "Byram" },

        { "data-state": "California", value: "California" },
        { "data-state": "Cokato", value: "Cokato" },

        { "data-state": "Colorado", value: "Colorado" },

        { "data-state": "Connecticut", value: "Connecticu" },
        { "data-state": "Delaware", value: "Delaware" },

        { "data-state": "District of Columbia", value: "District of Columbia" },
        { "data-state": "Florida", value: "Florida" },

        { "data-state": "Georgia", value: "Georgia" },

        { "data-state": "Hawaii", value: "Hawaii" },

        { "data-state": "Idaho", value: "Idaho" },

        { "data-state": "Illinois", value: "Illinois" },

        { "data-state": "Indiana", value: "Indiana" },

        { "data-state": "Iowa", value: "Iowa" },

        { "data-state": "Kansas", value: "Kansas" },

        { "data-state": "Kentucky", value: "Kentucky" },

        { "data-state": "Louisiana", value: "Louisiana" },

        { "data-state": "Lowa", value: "Lowa" },

        { "data-state": "Maine", value: "Maine" },

        { "data-state": "Maryland", value: "Maryland" },

        { "data-state": "Massachusetts", value: "Massachusetts" },
        { "data-state": "Medfield", value: "Medfield" },

        { "data-state": "Michigan", value: "Michigan" },

        { "data-state": "Minnesota", value: "Minnesota" },

        { "data-state": "Mississippi", value: "Mississippi" },
        { "data-state": "Missouri", value: "Missouri" },

        { "data-state": "Montana", value: "Montana" },

        { "data-state": "Nebraska", value: "Nebraska" },

        { "data-state": "Nevada", value: "Nevada" },

        { "data-state": "New Hampshire", value: "New Hampshire" },
        { "data-state": "New Jersey", value: "New Jersey" },
        { "data-state": "New Jersy", value: "New Jersy" },

        { "data-state": "New Mexico", value: "New Mexico" },
        { "data-state": "New York", value: "New York" },

        { "data-state": "North Carolina", value: "North Carolina" },
        { "data-state": "North Dakota", value: "North Dakota" },
        { "data-state": "Ohio", value: "Ohio" },

        { "data-state": "Oklahoma", value: "Oklahoma" },

        { "data-state": "Ontario", value: "Ontario" },

        { "data-state": "Oregon", value: "Oregon" },

        { "data-state": "Pennsylvania", value: "Pennsylva" },
        { "data-state": "Ramey", value: "Ramey" },

        { "data-state": "Rhode Island", value: "Rhode Isl" },
        { "data-state": "South Carolina", value: "South C" },
        { "data-state": "South Dakota", value: "South Dakota" },
        { "data-state": "Sublimity", value: "Sublimity" },

        { "data-state": "Tennessee", value: "Tennessee" },

        { "data-state": "Texas", value: "Texas" },

        { "data-state": "Trimble", value: "Trimble" },

        { "data-state": "Utah", value: "Utah" },

        { "data-state": "Vermont", value: "Vermont" },

        { "data-state": "Virginia", value: "Virginia" },

        { "data-state": "Washington", value: "Washington" },

        { "data-state": "Wisconsin", value: "Wisconsin" },

        { "data-state": "Wyoming", value: "Wyoming" },

        { "data-state": "Puerto Rico ", value: "Puerto Rico" },
      ]}
    />
  );
};

export default StateSelect;
