import { NavitemSideBarMneu, SideBarMneu } from "../../../../../index";
////props
//tabs array
const SideBarMneuOnboradingDataCleanse = (props) => {
  
  return (
    <>
      {/*begin::Sidebar*/}
      <SideBarMneu>
        {props.tabs?.map((element, key) => (
          <NavitemSideBarMneu
          key={`onborading-data-cleanse-${element.id}-${key}`}
            id={`onborading-data-cleanse-${element.id}-${key}`}
            title={element.form_name}
            isActive={key == 0}
          />
        ))}
      </SideBarMneu>
      {/*end::Sidebar*/}
    </>
  );
};

export default SideBarMneuOnboradingDataCleanse;
