import React from 'react'
import { BtnPrimary, CardsSummary, EmptyTableTD, TableAdmin } from '../../core'
import { useSelector } from 'react-redux';

const VendorHome = () => {
    const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (<>
    <CardsSummary filters={dataCleans["record_status"]} />
    <TableAdmin
 
 ActionExtra={<BtnPrimary text='New Statement'/>}

    
      titles={["Statement Name", "Create date", "Status"]}
    >
      <TableBody records={dataCleans.records ?? []} />
    </TableAdmin></>
  )
}

export default VendorHome

const TableBody = (props) => {
    const recordes = props.records;
    let recordList;
  
    if (recordes && recordes.length >= 1) {
      recordList = recordes.map((record, key) => {
        return <Record key={key} record={record} />;
      });
  
      return <>{recordList}</>;
    } else {
      return <EmptyTableTD colSpan={3} />;
    }
  };
  
  const Record = (props) => {
    const record = props.record;
    return (
      <tr key={record.id}>
    
        <td></td>
        <td></td>
        <td className="text-end">view</td>
      </tr>
    );
  };
  