import React from 'react'
import { ColTextFiledWithLabel, InputGroupRow, MySelect } from '../../../../../index'

const GlobalVerificationForm = () => {
  return (
    <>      <InputGroupRow
    col1={<ColTextFiledWithLabel label="Supplier Number" name="vendor_number" />}
    col2={
      <ColTextFiledWithLabel
        label="Company Name / Full Name"
        name="company"
        isRequired={true}
      />
    }
  />
  <InputGroupRow
    col1={
      <ColTextFiledWithLabel label="EIN/SSN" name="tin" isRequired={true} />
    }
    col2={
      <ColTextFiledWithLabel
        label="US Postal Code"
        name="zip"
        isRequired={true}
      />
    }
  />
  <InputGroupRow
    col1={<ColTextFiledWithLabel label="City" name="city" isRequired={true} />}
    col2={<MySelect label="state" name="state" isRequired={true} />}
  />
  <InputGroupRow
    col1={
      <ColTextFiledWithLabel
        label="Address1 (Street Address)"
        name="address1"
        isRequired={true}
      />
    }
    col2={
      <ColTextFiledWithLabel
        label="Address2 (Suite/Apt Number)"
        name="address2"
        isRequired={true}
      />
    }
  />
  <InputGroupRow
    col1={<ColTextFiledWithLabel label="Address3" name="address3" />}
    col2={<ColTextFiledWithLabel label="Address4" name="address4" />}
  /> </>
  )
}

export default GlobalVerificationForm