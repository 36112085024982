import {
  BtnPrimary,
  GroupCheckBox,
  PopupDialog,
  TableAdmin,
} from "../../../../../index";

const EmailQueue = () => (
  <TableAdmin
    ActionExtra={
      <div>
        <BtnPrimary
          attributes={{
            "data-kt-menu-trigger": "click",
            "data-kt-menu-placement": "bottom-end",
          }}
          text="Setting Email Queue"
        />
        <PopupDialog title="Setting" width={400}>
          <GroupCheckBox
            options={[
               { name:"Enable Email Queue",checked:'checked'},
               { name:"Don't  add emails with attachments in the queue",checked:''},
            ]}
          />
        </PopupDialog>
      </div>
    }
    titles={["Subject", "To", "Status"]}
  >
    <TableBody records={[]} />
  </TableAdmin>
);

const TableBody = (props) => {
  const recordes = props.records;

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={key} />;
    });

    return <>{recordList}</>;
  } else {
    return (
      <tr>
        <td colSpan={3}>
          <center>Empty data</center>
        </td>
      </tr>
    );
  }
};

const Record = (props) => {
  const record = props.record;

  return (
    <tr key={props.id}>
      <td></td>
      <td></td>

      <td className="text-end"></td>
    </tr>
  );
};

export default EmailQueue;
