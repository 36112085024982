
import {
  CheckBox,
  InputGroupRow,
  MarginBottomFiled,
  ColTextFiledWithLabel,
  MySelect,
  EnablesRadiosBtn,BodyContentFormWithCard, TitleSmall
} from "../../../../../../index";


const BedrockVerificationForm = () => {
  return (
    <BodyContentFormWithCard id="setting-bedrock-verification" isActive={true}>
      
      
    <EnablesRadiosBtn typeForms='tin_check'/>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Username"
          value="engineering@sphereaccess.com"
          name="settings[tincheck_username]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Password"
          type="password"
          name="settings[tincheck_password]"
        />
        <CheckBox title="Show Password" isHint={true}/>
      </MarginBottomFiled>

      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="tincheck max calls"
              type="number"
              name="settings[tincheck_max_calls]"
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="tincheck remaining calls"
              type="number"
              name="settings[tincheck_remaining_calls]"
            />
          }
        />
      </MarginBottomFiled>

      <TitleSmall
        title="Tincheck Filter Status"
        className="pt-10 pt-lg-15 pb-5 pb-lg-10"
      />
      <MarginBottomFiled>
        <MySelect label="pass" name="pass" />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <MySelect label="Fail" name="Fail" />
      </MarginBottomFiled>

      <MySelect label="Low Pass" name="Low Pass" />
    </BodyContentFormWithCard>
  );
};

export default BedrockVerificationForm;
