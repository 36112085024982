import React from "react";

import { useNavigate } from "react-router-dom";
import { ItemMenuUserLink, HeaderAdmin, Layout } from "../../../index";
///props
//sidebar widget(option)
//children widget
///extraAction btn (option)
//title Strig
//pages array ex:[ "Bedrock Verifiction", "Bedrock Verifiction"]
//menuNavigationHeader Widget

const LayoutAdmin = (props) => {
  const navigate = useNavigate();
  return (
    <Layout
      title={props.title}
      pages={props.pages}
      extraAction={props.extraAction}
      sidebar={props.sidebar}
      menuNavigationHeader={props.menuNavigationHeader ?? <HeaderAdmin />}
      menuUserHeader={
        <>
          <ItemMenuUserLink
            text="My Profile"
            onClick={() => {
              navigate("/profile");
            }}
          />
        </>
      }
    >
      {props.children}
    </Layout>
  );
};

export default LayoutAdmin;
