import '../style.css';

const Logo = () => {
  return (
    <>
        {/*begin::Logo*/}
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
                <a href="#">
                  <img
                    alt="Logo"
                    src="assets/media/logos/demo48-small.svg"
                    className="h-25px d-sm-none"
                  />
                  <img
                    alt="Logo"
                    src="assets/media/logos/official-logosSVGlogo-bedrock-blue-black.svg"
                    className="h-35px d-none d-sm-inline app-sidebar-logo-default theme-light-show"
                  />
                  <img
                    alt="Logo"
                    src="official-logosSVGlogo-bedrock-black-white.svg"
                    className="h-35px d-none d-sm-inline app-sidebar-logo-default theme-dark-show"
                  />
                </a>
              </div>
              {/*end::Logo*/}
    </>
  )
}

export default Logo
