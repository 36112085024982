

const TDTaxType = (props) => {
    const tax=props.tax_1_type;
  return (
    <td>{tax}</td>
  )
}

export default TDTaxType
