import React from 'react'

const TDAvgCount = (props) => {
    
  const avgCount = props.avg_spend;
  return (
 <td>{avgCount}</td>
  )
}

export default TDAvgCount
