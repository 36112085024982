import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TableAdmin,
  Badge,
  CardsSummary,
  BtnPrimary,
  EmptyTableTD,
  TDId,
  TDNameAndEmail,
  TDCheck,
  TDStatus,
  TDContact,
  TDVerificationStatus,
  TDVerificationCount,
  TDCountry,
  VendorInvitationAction,LayoutAdmin, LayoutCleanse
} from "../../../../../index";

import {
  contactTxt,
  countryTxt,
  emailTxt,
  statusTxt,
  vendorIdTxt,
  verificationCountTxt,
  verificationStatusTxt,
} from "./constTitleFiled";
import {
  fetchRecord,
  updateCurrentPage,
} from "../../../../../../rtk/Slices/Records-Slices";
const VendorInvitation = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCurrentPage(1));
    dispatch(fetchRecord({ category: "supplier_invitation" }));
  }, []);
  return (
    <LayoutCleanse
      title="Supplier Invitation"
      pages={["Data Cleans", "Supplier Invitation"]}
    >
      <CardsSummary
        filters={dataCleans["record_status"]}
        total_vendors={dataCleans.total_vendors}
      />
      <TableAdmin
        isExport={true}
        isAction={true}
        isSelest={true}
        titles={[
          vendorIdTxt,
          emailTxt,
          contactTxt,
          verificationStatusTxt,
          verificationCountTxt,
          statusTxt,
          countryTxt,
        ]}
        bulks={{
          name: "supplier_invitaion_bulk",
          list: [
            {
              defaultValue: 1,
              id: "kt_modal_supplier_invitaion_bulk_option_1",

              title: "Archive",
              desc: "Best for developers or people primarily using the API",
            },
            {
              id: "kt_modal_supplier_invitaion_bulk_option_0",

              title: "To Verify",
              desc: " Best for business owners and company administrators",
            },
            {
              defaultValue: 2,
              id: "kt_modal_supplier_invitaion_bulk_option_2",

              title: "To Final",
              desc: "Best for people who need full access to analytics data, but don't need to update business settings",
            },
         
         
          ],
        }}
        pagenation={{
          total: dataCleans["num_page"] ?? 1,
          currentPage: dataCleans.current_page,
          updateCurrentPage: ({ page, count }) => {
            dispatch(
              fetchRecord({
                category: "supplier_invitation",
                page: page,
                count: count,
              })
            );
          },
        }}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default VendorInvitation;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record) => {
      return <Record id={record.vendor_id} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={8} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <TDId {...record} />
      <TDNameAndEmail {...record} />
      <TDContact {...record} />
      <TDVerificationStatus {...record} />
      <TDVerificationCount {...record} />
      <TDStatus {...record} />
      <TDCountry {...record} />

      <td className="text-end">
        <VendorInvitationAction
          record={record}
          extraAction={<BtnPrimary text="Save To Data Cleans" />}
        />
      </td>
    </tr>
  );
};
