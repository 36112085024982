
///props
//desc String
const Notice = (props) => {
  return (
    <>
    {/*begin::Notice*/}
    <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
      {/*begin::Icon*/}
      {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
      <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x={2}
            y={2}
            width={20}
            height={20}
            rx={10}
            fill="currentColor"
          />
          <rect
            x={11}
            y={14}
            width={7}
            height={2}
            rx={1}
            transform="rotate(-90 11 14)"
            fill="currentColor"
          />
          <rect
            x={11}
            y={17}
            width={2}
            height={2}
            rx={1}
            transform="rotate(-90 11 17)"
            fill="currentColor"
          />
        </svg>
      </span>
      {/*end::Svg Icon*/}
      {/*end::Icon*/}
      {/*begin::Wrapper*/}
      <div className="d-flex flex-stack flex-grow-1">
        {/*begin::Content*/}
        <div className="fw-semibold">
          <h4 className="text-gray-900 fw-bold">We need your attention!</h4>
          <div className="fs-6 text-gray-700">
           {props.desc}
            .
          </div>
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*end::Notice*/}
  </>
  
  )
}

export default Notice
