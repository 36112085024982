import { BtnSecundary, Dialog } from "../../../index";
import PermissionForm from "./PermissionForm";




const PermissionDialog = () => {
  return (
    <>
      <BtnSecundary
        text="Permision"
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_permission_role",
        }}
      />
      <Dialog
        id="kt_modal_permission_role"
        closeAttributes={{ "data-kt-roles-modal-action": "close" }}
      >
      <PermissionForm/>
      </Dialog>
    </>
  );
};

export default PermissionDialog;
