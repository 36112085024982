
import { SearchIcon } from '../icon/index';

const SearchFeild = () => {
  return (
    <>
           {/*begin::Search*/}
           <div className="d-flex align-items-center position-relative my-1">
       <SearchIcon/>
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-250px ps-14"
            placeholder="Search user"
          />
        </div>
        {/*end::Search*/}
    </>
  )
}

export default SearchFeild
