import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LayoutAdmin,
  TableAdmin,
  CardsSummary,
  TDCheck,
  TDId,
  TDNameAndEmail,
  TDStatus,
  TDCountry,
  VendorInvitationAction,
  BtnPrimary,
  EmptyTableTD,
  TDPriority,
  TDZipCode,
  TDBy,
  TDState,
  TDAddress1,
  LayoutCleanse,
} from "../../../../../index";

import {
  addressTxt,
  countryTxt,
  emailTxt,
  byTxt,
  priorityTxt,
  stateTxt,
  statusTxt,
  vendorIdTxt,
  zipCodeTxt,
} from "./constTitleFiled";
import { useEffect } from "react";
import {
  fetchRecord,
  updateCurrentPage,
} from "../../../../../../rtk/Slices/Records-Slices";
const DataFinal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCurrentPage(1));
    dispatch(fetchRecord({ category: "data_final" }));
  }, []);
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <LayoutCleanse title="Data Final" pages={["Data Final"]}>
      <CardsSummary
        filters={dataCleans["record_status"]}
        total_vendors={dataCleans.total_vendors}
      />
      <TableAdmin
        isExport={true}
        isSelest={true}
        isAction={true}
        titles={[
          vendorIdTxt,
          countryTxt,
          emailTxt,
          priorityTxt,
          statusTxt,
          byTxt,
          stateTxt,
          zipCodeTxt,
          addressTxt,
        ]}
        pagenation={{
          total: dataCleans["num_page"],
          currentPage: dataCleans.current_page,
          updateCurrentPage: ({ page, count }) => {
            dispatch(
              fetchRecord({ category: "data_final", page: page, count: count })
            );
          },
        }}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default DataFinal;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record) => {
      return <Record id={record.vendor_id} key={record.id} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={8} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <TDId {...record} />
      <TDCountry {...record} />
      <TDNameAndEmail {...record} />
      <TDPriority {...record} />
      <TDStatus {...record} />
      <TDBy {...record} />
      <TDState {...record} />
      <TDZipCode {...record} />
      <TDAddress1 {...record} />
      <td className="text-end">
        <VendorInvitationAction
          record={record}
          extraAction={<BtnPrimary text="Save To Data Cleans" />}
        />
      </td>
    </tr>
  );
};
