

const  TDAddress2= (props) => {
    
  const address2 = props.address_2;
  return (
    <td>{address2}</td>
  )
}

export default TDAddress2
