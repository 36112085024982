export const vendorIdTxt = "Vendor id";
export const emailTxt = "Ar Email";
export const taxTxt = "Tax";
export const contactTxt = "Ar Contact";
export const avgCountTxt = "AVG Count";
export const avgSpendTxt = "AVG spend";
export const priorityTxt = "Priority";
export const address1Txt = "Address 1";
export const address2Txt="address 2";
export const tinCheckStatusTxt = "TinCheck Status";
export const tinCheckCountTxt = "TinCheck Count";
export const statusTxt = "Status";
export const countryTxt = "country";
export const phonTxt = "Phone number";
export const verificationStatusTxt = "Verification Status";
export const verificationCountTxt = "Verification Count";
export const assigneeTxt = "assignee";
export const byTxt = "by";
export const stateTxt = "state";
export const zipCodeTxt = "Zip Code";
export const addressTxt = "Address ";
export const faxNumberTxt= "fax number";
export const remitAddressTxt= "remit address";
export const taxIdTxt= "tax id";
export const taxIdTypeTxt= "tax id 1 type";
export const taxId2Txt= "tax id 2";
export const taxIdType2Txt= "tax id 2 type";
export const paymentMethodTxt= "Payment Method";
export const spendTxt= "Spend";
export const poBoxCityTxt= "Po box city";
export const poBoxZipCodeTxt= "Po box zip code";
export const searchTermTxt= "Search term";
export const companyPhoneNumberTxt= "Company phone number";
export const firstNameTxt= "First Name";
export const lastNameTxt= "last Name";
export const accountMemoTxt= "Account memo";
export const name2Txt="Name 2";
export const reasonTxt="Reason";

export const houseNumberTxt="House Number";
export const cityTxt="City";
export const countyTxt="County";