///props

//img String url
//width int (option)
//hieght int (option)
const ImgPicker = (props) => {
  return (
    <>
      {/*begin::Image input*/}
      <div
        className="image-input image-input-outline"
        data-kt-image-input="true"
        style={{
          backgroundImage: 'url("assets/media/svg/avatars/blank.svg")',
        }}
      >
        {/*begin::Preview existing avatar*/}
        {props.img ? (
          <div
            className={`image-input-wrapper w-${props.width??'125px '} h-${props.hieght??'125px'}`}
            style={{
              backgroundImage: ` url(${props.img})`,
            }}
          />
        ) : (
          <div className="image-input-wrapper w-150px h-150px" />
        )}
        {/*end::Preview existing avatar*/}
        {/*begin::Label*/}
        <label
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip"
          title="Change avatar"
        >
          <i className="bi bi-pencil-fill fs-7" />
          {/*begin::Inputs*/}
          <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
          <input type="hidden" name="avatar_remove" />
          {/*end::Inputs*/}
        </label>
        {/*end::Label*/}
        {/*begin::Cancel*/}
        <span
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
          data-kt-image-input-action="cancel"
          data-bs-toggle="tooltip"
          title="Cancel avatar"
        >
          <i className="bi bi-x fs-2" />
        </span>
        {/*end::Cancel*/}
        {/*begin::Remove*/}
        <span
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
          data-kt-image-input-action="remove"
          data-bs-toggle="tooltip"
          title="Remove avatar"
        >
          <i className="bi bi-x fs-2" />
        </span>
        {/*end::Remove*/}
      </div>
      {/*end::Image input*/}
      {/*begin::Hint*/}
      <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
      {/*end::Hint*/}
    </>
  );
};

export default ImgPicker;
