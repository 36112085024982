
import { InputGroupRow, MarginBottomFiled, Radio } from "./index";
///props 
////
const EnablesRadiosBtn = (props) => {
  return (
    <MarginBottomFiled>
      <InputGroupRow
        col1={
          <Radio
            id={`y_opt_1_settings_${props.typeForm}`}
            name={`settings[onoffswitch_${props.typeForm}]`}
            title="enable"
          />
        }
        col2={
          <Radio
          id={`y_opt_2_settings_${props.typeForm}`}
          name={`settings[onoffswitch_${props.typeForm}]`}
            title="disable"
          />
        }
      />
    </MarginBottomFiled>
  );
};

export default EnablesRadiosBtn;
