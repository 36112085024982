import React from 'react'
import { MyLabel } from './lable'
//props
//isRequired bool (option)
//tooltip string (option)
//label String 
const TagsSelect = (props) => {
  return (
    <>
    {/*begin::Input group*/}
    <div className="d-flex flex-column mb-8 fv-row">
      {/*begin::Label*/}
    <MyLabel label={props.label }  tooltip={props.tooltip}  isRequired={props.isRequired}/>
      {/*end::Label*/}
      <input
        className="form-control form-control-solid"
        defaultValue="Important, Urgent"
        name="tags"
      />
    </div>
    {/*end::Input group*/}
  </>
  
  )
}

export default TagsSelect
