///props
////defaultValue    String ex 'defaultValue';
////id     String ex 'id';
////title     String ex 'title';
////desc bool (option);
//isHint bool option color text gray 
const CheckBox = (props) => {
  return (
    <div className="d-flex mt-3">
      {/*begin::Checkbox*/}
      <div className="form-check form-check-custom form-check-solid">
        {/*begin::Input*/}
        <input
          className="form-check-input me-3"
          name={props.name}
          type="checkbox"
          defaultValue={0}
          onClick={props.onClick}
          id={`kt_checkbox_${props.id}`}
          defaultChecked={props.defaultValue}
        />
        {/*end::Input*/}
        {/*begin::Label*/}
        <label
          className={props.isHint?"form-check-label":'fw-bold text-gray-800'}
          htmlFor={`kt_checkbox_${props.id}`}
        >
          <div className={"fw-bold"}>{props.title}</div>
          {props.desc ? <div className="text-gray-600">{props.desc}</div> : ""}
        </label>
        {/*end::Label*/}
      </div>
      {/*end::Checkbox*/}
    </div>
  );
};

export default CheckBox;
