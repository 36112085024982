import {
  BtnPrimary,
  Dialog,
  BtnSecundary,
  BtnWhite,
  BankVerificationForm,
} from "../../../../../index";

export const CreateBankVerificationBTN = () => {
  return (
    <>
      <BtnWhite
        text="Bank Verification"
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_bank_verification_add",
        }}
      />
    </>
  );
};
const CreateBankVerificationDialog = () => {
  return (
    <>
      <Dialog id="kt_modal_bank_verification_add" title="Bank Verification">
        <BankVerificationForm />
        <div className="modal-footer flex-center">
          <BtnSecundary text="close" />
          <BtnPrimary text="save" />
        </div>
      </Dialog>
    </>
  );
};

export default CreateBankVerificationDialog;
