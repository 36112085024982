import { CardWhite, LayoutAdmin, SendEmail } from "../../../index";
import FiledNamed from "./FiledNamed";

const SendEmailTemplatePage = () => {
  return (
    <LayoutAdmin
      title="Send Email Templates"
      pages={["Setup", "Send Email Templates"]}
    >
      <div className="row gy-5 g-xl-10 mt-5">
        <div className="col-xl-3 mb-5 mb-xl-10">
          <CardWhite>
            <div className="d-flex flex-column mb-7">
            
              <BoxFields title="Vendor" />
            </div>
          </CardWhite>
        </div>
        <div className="col-xl-9 mb-5 mb-xl-10">
          <SendEmail title='New Claim Opened (Opened by Staff, Sent to Vendor)'/>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default SendEmailTemplatePage;

const BoxFields = (props) => {
  return (
    <>
      {/*begin::Title*/}
      <a href="#" className="text-dark text-hover-primary fw-bold fs-3">
        {props.title}
      </a>
      {/*end::Title*/}
      <FiledNamed fieldNamed="Contact Firstname" fieldKey="contact_firstname" />
      <FiledNamed fieldNamed="Contact Lastname" fieldKey="contact_lastname" />
      <FiledNamed
        fieldNamed="Contact Phone Number"
        fieldKey="contact_phonenumber"
      />

      <FiledNamed fieldNamed="Contact Title" fieldKey="contact_title" />

      <FiledNamed fieldNamed="Contact Email" fieldKey="contact_email" />
    </>
  );
};
