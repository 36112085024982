

const PersonIcon = () => {
  return (
    <>  {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
    <span className="svg-icon svg-icon-3 svg-icon-muted me-3">
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
          fill="currentColor"
        />
        <path
          d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
          fill="currentColor"
        />
        <rect
          x={7}
          y={6}
          width={4}
          height={4}
          rx={2}
          fill="currentColor"
        />
      </svg>
    </span>
    {/*end::Svg Icon*/}</>
  )
}

export default PersonIcon;