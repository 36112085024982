
import { ColTextFiledWithLabel,BodyContentFormWithCard,TitleSmall } from "../../../../../../index";


const AVGSpend = () => {
  return (
    <BodyContentFormWithCard id="setting-avg-spend">
      <TitleSmall title="AVG Spend " className=" pb-5 pb-lg-10" />

      <div className="mb-10">
        
        <ColTextFiledWithLabel
          label="Filter From"
          name="settings[avg_spend_from]"
          type="number"
        />
      </div>
      <div className="mb-10">
     
        <ColTextFiledWithLabel
          label="Filter To"
          name="settings[avg_spend_to]"
          type="number"
        />
      </div>
    </BodyContentFormWithCard>
  );
};

export default AVGSpend;
