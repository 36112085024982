//props
//isRequired bool (option)
//tooltip string (option)
//label String 
const MyLabel = (props) => {
  return (
    <>
          {/*begin::Label*/}
          {props.label?  <label className={props.className??"d-flex align-items-center fs-6 fw-semibold my-2 text-captalize"}>
        <span className={props.isRequired ? "required" : ""}>
          {props.label}
        </span>
       {props.tooltip? <i
        className="fas fa-exclamation-circle ms-2 fs-7"
         data-bs-toggle="tooltip"
       title={props.tooltip}
      />:""}
      </label>:''}
      {/*end::Label*/}
      
    </>
  )
}

export default MyLabel
