////props
////id String for btn  data-bs-target
////title String
///isFullScreen bool
///extraAction widget

import { CloseIcon } from "./icon";

////closeAttributes (option) add new Attribut
const Dialog = (props) => {
  return (
    <>
      {/*begin::Modal - Create App*/}
      <div
        className="modal fade"
        id={props.id}
        tabIndex={-1}
        aria-hidden="true"
      >
        {/*begin::Modal dialog*/}
        <div
          className={`modal-dialog modal-dialog-centered ${
            props.isFullScreen ? "mw-100 h-100 m-0  rounded-0" : "mw-1000px"
          }`}
        >
          {/*begin::Modal content*/}
          <div
            className={`modal-content ${
              props.isFullScreen ? "h-100  py-10 px-18" : ""
            }`}
          >
            {/*begin::Modal header*/}
            <div className="modal-header">
              {/*begin::Modal title*/}
              <h2>{props.title}</h2>
              {/*end::Modal title*/}
              <div className="d-flex ">
                {props.extraAction}
                {/*begin::Close*/}
                <div
                  className="btn btn-sm btn-icon btn-active-color-primary ms-2"
                  data-bs-dismiss="modal"
                  {...props.closeAttributes}
                >
                  <CloseIcon />
                </div>
                {/*end::Close*/}
              </div>
            </div>
            {/*end::Modal header*/}
            {/*begin::Modal body*/}
            <div className={`modal-body  ${props.classNameBody ??'p-lg-10 '} `}>
              {props.children}
            </div>
            {/*end::Modal body*/}
          </div>
          {/*end::Modal content*/}
        </div>
        {/*end::Modal dialog*/}
      </div>
      {/*end::Modal - Create App*/}
    </>
  );
};

export default Dialog;
