import React from "react";
import { useState } from "react";
import {
  CardWhite,
  CheckBox,
  ColTextAreaWithLabel,
  ColTextFiledWithLabel,
  DateComp,
  InputGroupRow,
  MarginBottomFiled,
  MySelect,
  UploadFile,
} from "../.././../../index";
///props
///statement Object  (option)
const StatementForm = (props) => {
  const statement = props;
  const [noOpen, setNoOpen] = useState(false);
  return (
    <CardWhite margenTop margenBottom>
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Statement Name"
            name="name"
            isRequired={true}
            value={statement.name}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Company"
            name="company"
            isRequired={true}
          />
        }
      />
      <InputGroupRow
        col1={
          <MySelect
            lable="Status"
            option={[
              { value: "Need Review", title: "Need Review" },
              { value: "In Progress", title: "In Progress" },
              { value: "Not A Claim", title: "Not A Claim" },
            ]}
          />
        }
        col2={<MySelect lable="Auditor" option={[]} />}
      />
      <MarginBottomFiled>
        <DateComp label='Deadline'/>
      </MarginBottomFiled>
      <ColTextAreaWithLabel label="Description" />
      <MarginBottomFiled>
        <CheckBox
          title="No open invoices or credits since"
          onClick={() => setNoOpen(!noOpen)}
        />
      </MarginBottomFiled>
      {noOpen ? (
        <>
          <MarginBottomFiled>
            <DateComp label='Select Date of Last Activity' />
          </MarginBottomFiled>
          <CheckBox title="Send statement created email" />
        </>
      ) : (
        <UploadFile />
      )}
    </CardWhite>
  );
};

export default StatementForm;

