import React from 'react'

const TDPhon = (props) => {
    const phone=props.phone_number;
  return (
    <td>{phone}</td>
  )
}

export default TDPhon;
