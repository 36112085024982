
//props 
//colSpan num col
const EmptyTableTD = (props) => {
  return (
    <tr>
        <td colSpan={props.colSpan}>
     <div className="">
     <p className="fs-7 text-muted fw-bold text-capitalize">
            No entries found
          </p>
          
        <img className={`image-input-wrapper w-300px  h-300`}
            src={`${process.env.PUBLIC_URL}/assets/media/illustrations/sketchy-1/4.png`}
          /> 
     </div>
        </td>
      </tr>
  )
}

export default EmptyTableTD
