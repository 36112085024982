

const TDAddress1 = (props) => {
    
  const address1 = props.address_1;
  return (
    <td>{address1}</td>
  )
}

export default TDAddress1
