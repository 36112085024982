import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  result: {
    "AP":[
     {
      "Status": "Paid",
      "DataSource": "E221178",
      "Co": "Bedrock Facilities",
      "VndNum": 57439,
      "VndName": "Innovation SALES",
      "InvNum": 2120039086391,
      "InvDate": "10\/17\/2022",
      "InvAmt": 11212.39,
      "AbsInvAmt": 105.39,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": "USD",
      "FY": "01\/01\/1900",
      "InvTypeDesc": 578238.68,
      "PmtTypeDesc": "SUP",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14764928,
      "DupeX": "SUP",
      "BusinessUnitName": 25578069,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 330,
      "PaymentTermsName": 105.39,
      "ConcatenatedSegments": 0,
      "bInvAmt": 105.39,
      "bDiscAmt": 0,
      "bTaxAmt": 919086391,
      "APID": 503283,
      "Field1": 322667
     },
     {
      "Status": "Paid",
      "DataSource": "E201111",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "INV Q1 11.19-01.20 R",
      "InvDate": "01\/31\/2020",
      "InvAmt": 94703.13,
      "AbsInvAmt": 94703.13,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "PmtNum": 10397117,
      "PmtDate": "06\/08\/2020",
      "PmtAmt": 94703.13,
      "PmtTypeDesc": "Wire",
      "bInvAmt": 94703.13,
      "bDiscAmt": 0,
      "bTaxAmt": 11119,
      "APID": 1082394
     },
     {
      "Status": "Paid",
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 637542,
      "InvDate": "06\/05\/2022",
      "InvAmt": 28540.78,
      "AbsInvAmt": 28540.78,
      "ApplyToInvAmt": 28540.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 217588,
      "PmtDate": "06\/28\/2018",
      "PmtAmt": 28540.78,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40249,
      "InvDescr": "PO #40249",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 635510,
      "bInvAmt": 28540.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 169083
     },
     {
      "Status": "Paid",
      "DataSource": "E221106",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-910-25559",
      "InvDate": "10\/11\/2022",
      "InvAmt": 2773.72,
      "AbsInvAmt": 2773.72,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": "USD",
      "FY": "11\/01\/2022",
      "InvTypeDesc": 2773.72,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14768262,
      "DupeX": "UTL",
      "BusinessUnitName": 86222113,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 2773.72,
      "ConcatenatedSegments": 0,
      "bInvAmt": 2773.72,
      "bDiscAmt": 0,
      "bTaxAmt": 791025559,
      "APID": 1985713
     },
     {
      "Status": "Pending",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028686292,
      "InvDate": "07\/21\/2021",
      "InvAmt": 15543.8,
      "AbsInvAmt": 15543.8,
      "ApplyToInvAmt": 15543.8,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 312125,
      "PmtDate": "01\/11\/2022",
      "PmtAmt": 17963.26,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107063",
      "DupeX": 0,
      "MatchInvNum": 6028686292,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "01\/12\/2022",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 15543.8,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 38473
     },
     {
      "Status": "Pending",
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7413:60673",
      "VndName": "ARIBA INC",
      "InvNum": 6028769103,
      "InvDate": "10\/25\/2021",
      "InvAmt": 2224.39,
      "AbsInvAmt": 2224.39,
      "ApplyToInvAmt": 2224.39,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 354843,
      "PmtDate": "04\/28\/2022",
      "PmtAmt": 17768.19,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 48174,
      "InvDescr": "PO #48174",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 6028769103,
      "bInvAmt": 2224.39,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 140161
     },
     {
      "Status": "Cancelled ",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028594094,
      "InvDate": "05\/27\/2021",
      "InvAmt": 37592.48,
      "AbsInvAmt": 37592.48,
      "ApplyToInvAmt": 37592.48,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 284253,
      "PmtDate": "09\/01\/2021",
      "PmtAmt": 37592.48,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-106545",
      "DupeX": 0,
      "MatchInvNum": 6028594094,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "09\/02\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 37592.48,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 17029
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028050139,
      "InvDate": "12\/02\/2019",
      "InvAmt": 3.68,
      "AbsInvAmt": 3.68,
      "ApplyToInvAmt": 3.68,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 300369,
      "PmtDate": "11\/17\/2021",
      "PmtAmt": 151.02,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028050139,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/18\/2021",
      "PaymentTermsName": "Immediate -override",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 3.68,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 12823
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Digital Media, LLC",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028881931,
      "InvDate": "02\/11\/2022",
      "InvAmt": 31.39,
      "AbsInvAmt": 31.39,
      "ApplyToInvAmt": 31.39,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "EUR",
      "FY": 2022,
      "InvTypeDesc": "Standard",
      "PmtNum": 329962,
      "PmtDate": "04\/18\/2022",
      "PmtAmt": 544.83,
      "PmtTypeDesc": "International WIRE",
      "PoNum": "7008-101942",
      "DupeX": 0,
      "MatchInvNum": 6028881931,
      "BusinessUnitName": "7008 OBU",
      "PayGroup": "Standard",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "7008-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 45.94,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 23747
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Digital Media, LLC",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028580362,
      "InvDate": "04\/23\/2021",
      "InvAmt": 8171.63,
      "AbsInvAmt": 8171.63,
      "ApplyToInvAmt": 8171.63,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 323432,
      "PmtDate": "03\/11\/2022",
      "PmtAmt": 11600.12,
      "PmtTypeDesc": "Electronic",
      "PoNum": "7008-101881",
      "DupeX": 0,
      "MatchInvNum": 6028580362,
      "BusinessUnitName": "7008 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "03\/14\/2022",
      "PaymentTermsName": "Immediate -override",
      "ConcatenatedSegments": "7008-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 8171.63,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 41126
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028564704,
      "InvDate": "04\/19\/2021",
      "InvAmt": 7019.43,
      "AbsInvAmt": 7019.43,
      "ApplyToInvAmt": 7019.43,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 300087,
      "PmtDate": "11\/15\/2021",
      "PmtAmt": 65730.14,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107063",
      "DupeX": 0,
      "MatchInvNum": 6028564704,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/16\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 7019.43,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 75281
     },
     {
      "Status": "Cancelled ",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028537257,
      "InvDate": "03\/24\/2021",
      "InvAmt": 7767.94,
      "AbsInvAmt": 7767.94,
      "ApplyToInvAmt": 7767.94,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 261360,
      "PmtDate": "05\/03\/2021",
      "PmtAmt": 7767.94,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-106545",
      "DupeX": 0,
      "MatchInvNum": 6028537257,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "05\/04\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 7767.94,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 95939
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028724945,
      "InvDate": "08\/23\/2021",
      "InvAmt": 0.66,
      "AbsInvAmt": 0.66,
      "ApplyToInvAmt": 0.66,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 299305,
      "PmtDate": "11\/12\/2021",
      "PmtAmt": 2949.97,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028724945,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/15\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 0.66,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 106371
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028606205,
      "InvDate": "05\/29\/2021",
      "InvAmt": 13100.07,
      "AbsInvAmt": 13100.07,
      "ApplyToInvAmt": 13100.07,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 300087,
      "PmtDate": "11\/15\/2021",
      "PmtAmt": 65730.14,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107063",
      "DupeX": 0,
      "MatchInvNum": 6028606205,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/16\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 13100.07,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 120782
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028603097,
      "InvDate": "05\/28\/2021",
      "InvAmt": 52.41,
      "AbsInvAmt": 52.41,
      "ApplyToInvAmt": 52.41,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 299305,
      "PmtDate": "11\/12\/2021",
      "PmtAmt": 2949.97,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028603097,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/15\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 52.41,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 118664
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Digital Media, LLC",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028872429,
      "InvDate": "01\/13\/2022",
      "InvAmt": 340.91,
      "AbsInvAmt": 340.91,
      "ApplyToInvAmt": 340.91,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "EUR",
      "FY": 2022,
      "InvTypeDesc": "Standard",
      "PmtNum": 329962,
      "PmtDate": "04\/18\/2022",
      "PmtAmt": 544.83,
      "PmtTypeDesc": "International WIRE",
      "PoNum": "7008-101942",
      "DupeX": 0,
      "MatchInvNum": 6028872429,
      "BusinessUnitName": "7008 OBU",
      "PayGroup": "Standard",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "7008-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 498.89,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 118091
     },
     {
      "Status": "Paid",
      "DataSource": "C220624",
      "Co": "Bedrock Digital Media, LLC",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028698319,
      "InvDate": "07\/21\/2021",
      "InvAmt": 3428.49,
      "AbsInvAmt": 3428.49,
      "ApplyToInvAmt": 3428.49,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 323432,
      "PmtDate": "03\/11\/2022",
      "PmtAmt": 11600.12,
      "PmtTypeDesc": "Electronic",
      "PoNum": "7008-101881",
      "DupeX": 0,
      "MatchInvNum": 6028698319,
      "BusinessUnitName": "7008 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "03\/14\/2022",
      "PaymentTermsName": "Immediate -override",
      "ConcatenatedSegments": "7008-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 3428.49,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 133339
     },
     {
      "Status": "Paid",
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 1011425,
      "InvDate": "01\/25\/2018",
      "InvAmt": -2727.83,
      "AbsInvAmt": 2727.83,
      "DiscAmt": 0,
      "TaxAmt": -197.33,
      "CurrCode": "CAD",
      "FY": 2018,
      "InvTypeDesc": "CREDIT",
      "PmtAmt": 0,
      "PoNum": 36856,
      "InvDescr": "IC PO #36856",
      "VndType": "SUPPLIER",
      "DupeX": 1,
      "MatchInvNum": 1011425,
      "bInvAmt": -2729.21,
      "bDiscAmt": 0,
      "bTaxAmt": -197.43,
      "bNetAmt": 0,
      "APID": 633
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020634129-C",
      "InvDate": "12\/09\/2018",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtAmt": 0,
      "PoNum": 41091,
      "InvDescr": "IC PO #41091",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020634129,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 959
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020643531,
      "InvDate": "03\/06\/2019",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtAmt": 0,
      "PoNum": 42011,
      "InvDescr": "IC PO #42011",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020643531,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 137045
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "6628579-c",
      "InvDate": "01\/01\/2019",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "CAD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtAmt": 0,
      "PoNum": 41385,
      "InvDescr": "IC PO #41385",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 6628579,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 137440
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 2J2",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "6648580A",
      "InvDate": "12\/15\/2020",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "CAD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtAmt": 0,
      "PoNum": 45730,
      "InvDescr": "IC PO #45730 \/ UPDATE LINE ENTRY",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 6648580,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 137448
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": "2502999778-CANCELLED",
      "InvDate": "05\/08\/2019",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtAmt": 0,
      "PoNum": 41989,
      "InvDescr": "IC PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 1,
      "MatchInvNum": 2502999778,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 145412
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649291,
      "InvDate": "04\/10\/2019",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtAmt": 0,
      "PoNum": 41494,
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020649291,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 135393
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649787,
      "InvDate": "05\/06\/2019",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtAmt": 0,
      "PoNum": 41494,
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020649787,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 135400
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020651354,
      "InvDate": "05\/31\/2019",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtAmt": 0,
      "PoNum": 41494,
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020651354,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 135409
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503417334,
      "InvDate": "09\/10\/2020",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "CREDIT",
      "PmtAmt": 0,
      "PoNum": 41989,
      "InvDescr": "INCORRECT LIABILITY ACCOUNT",
      "VndType": "SUPPLIER",
      "DupeX": 1,
      "MatchInvNum": 2503417334,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 138680
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020620001,
      "InvDate": "07\/30\/2018",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtAmt": 0,
      "PoNum": 40712,
      "InvDescr": "IC PO #40712",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020620001,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 143717
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020634129-cancelled",
      "InvDate": "12\/09\/2018",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtAmt": 0,
      "InvDescr": "IC PO #41091",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020634129,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 143765
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020641354,
      "InvDate": "05\/31\/2019",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtAmt": 0,
      "PoNum": 41494,
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 1,
      "MatchInvNum": 2020641354,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 143772
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 637645,
      "InvDate": "01\/02\/2019",
      "InvAmt": 280661.28,
      "AbsInvAmt": 280661.28,
      "ApplyToInvAmt": 280661.28,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219465,
      "PmtDate": "01\/29\/2019",
      "PmtAmt": 280661.28,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41407,
      "InvDescr": "PO #41407",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 637645,
      "bInvAmt": 280661.28,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168231
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 640595,
      "InvDate": "11\/06\/2019",
      "InvAmt": 114894.45,
      "AbsInvAmt": 114894.45,
      "ApplyToInvAmt": 114894.45,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350411,
      "PmtDate": "11\/25\/2019",
      "PmtAmt": 859591.77,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41411,
      "InvDescr": "PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 640595,
      "bInvAmt": 114894.45,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168239
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020596581,
      "InvDate": "01\/03\/2018",
      "InvAmt": 133419.43,
      "AbsInvAmt": 133419.43,
      "ApplyToInvAmt": 133419.43,
      "DiscAmt": 0,
      "TaxAmt": 17377.32,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60516,
      "PmtDate": "02\/01\/2018",
      "PmtAmt": 133419.43,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39142,
      "InvDescr": "IC PO #39142",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020596581,
      "bInvAmt": 133419.43,
      "bDiscAmt": 0,
      "bTaxAmt": 17377.32,
      "bNetAmt": 0,
      "APID": 168400
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020599707,
      "InvDate": "01\/31\/2018",
      "InvAmt": 1741.82,
      "AbsInvAmt": 1741.82,
      "ApplyToInvAmt": 1741.82,
      "DiscAmt": 0,
      "TaxAmt": 226.87,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60608,
      "PmtDate": "03\/01\/2018",
      "PmtAmt": 2289.65,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39489,
      "InvDescr": "IC PO #39489",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020599707,
      "bInvAmt": 1741.82,
      "bDiscAmt": 0,
      "bTaxAmt": 226.87,
      "bNetAmt": 0,
      "APID": 168408
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020599850,
      "InvDate": "02\/01\/2018",
      "InvAmt": 547.83,
      "AbsInvAmt": 547.83,
      "ApplyToInvAmt": 547.83,
      "DiscAmt": 0,
      "TaxAmt": 71.35,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60608,
      "PmtDate": "03\/01\/2018",
      "PmtAmt": 2289.65,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39594,
      "InvDescr": "IC PO #39594",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020599850,
      "bInvAmt": 547.83,
      "bDiscAmt": 0,
      "bTaxAmt": 71.35,
      "bNetAmt": 0,
      "APID": 168418
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020611931,
      "InvDate": "05\/11\/2018",
      "InvAmt": 303659,
      "AbsInvAmt": 303659,
      "ApplyToInvAmt": 303659,
      "DiscAmt": 0,
      "TaxAmt": 39550.3,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60878,
      "PmtDate": "06\/06\/2018",
      "PmtAmt": 347385.88,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40070,
      "InvDescr": "IC PO #40070",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020611931,
      "bInvAmt": 303659,
      "bDiscAmt": 0,
      "bTaxAmt": 39550.3,
      "bNetAmt": 0,
      "APID": 168426
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020613637,
      "InvDate": "05\/31\/2018",
      "InvAmt": 416.79,
      "AbsInvAmt": 416.79,
      "ApplyToInvAmt": 416.79,
      "DiscAmt": 0,
      "TaxAmt": 54.29,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60907,
      "PmtDate": "06\/28\/2018",
      "PmtAmt": 196370.83,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40360,
      "InvDescr": "IC PO #40360",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020613637,
      "bInvAmt": 416.79,
      "bDiscAmt": 0,
      "bTaxAmt": 54.29,
      "bNetAmt": 0,
      "APID": 168433
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020624504,
      "InvDate": "09\/14\/2018",
      "InvAmt": 1395.57,
      "AbsInvAmt": 1395.57,
      "ApplyToInvAmt": 1395.57,
      "DiscAmt": 0,
      "TaxAmt": 181.77,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61209,
      "PmtDate": "10\/22\/2018",
      "PmtAmt": 279214.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40925,
      "InvDescr": "IC PO #40925",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020624504,
      "bInvAmt": 1395.57,
      "bDiscAmt": 0,
      "bTaxAmt": 181.77,
      "bNetAmt": 0,
      "APID": 168441
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020624890,
      "InvDate": "09\/28\/2018",
      "InvAmt": 65108.64,
      "AbsInvAmt": 65108.64,
      "ApplyToInvAmt": 65108.64,
      "DiscAmt": 0,
      "TaxAmt": 8480.14,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61209,
      "PmtDate": "10\/22\/2018",
      "PmtAmt": 279214.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40916,
      "InvDescr": "IC PO #40916",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020624890,
      "bInvAmt": 65108.64,
      "bDiscAmt": 0,
      "bTaxAmt": 8480.14,
      "bNetAmt": 0,
      "APID": 168449
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020625477,
      "InvDate": "09\/25\/2018",
      "InvAmt": 4489.78,
      "AbsInvAmt": 4489.78,
      "ApplyToInvAmt": 4489.78,
      "DiscAmt": 0,
      "TaxAmt": 584.78,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61194,
      "PmtDate": "10\/15\/2018",
      "PmtAmt": 82152.38,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41091,
      "InvDescr": "IC PO #41091",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020625477,
      "bInvAmt": 4489.78,
      "bDiscAmt": 0,
      "bTaxAmt": 584.78,
      "bNetAmt": 0,
      "APID": 168455
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020626527,
      "InvDate": "10\/04\/2018",
      "InvAmt": 3094.21,
      "AbsInvAmt": 3094.21,
      "ApplyToInvAmt": 3094.21,
      "DiscAmt": 0,
      "TaxAmt": 403.01,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61293,
      "PmtDate": "11\/27\/2018",
      "PmtAmt": 81173.76,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41113,
      "InvDescr": "IC PO #41113",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020626527,
      "bInvAmt": 3094.21,
      "bDiscAmt": 0,
      "bTaxAmt": 403.01,
      "bNetAmt": 0,
      "APID": 168465
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649610,
      "InvDate": "05\/03\/2019",
      "InvAmt": 661,
      "AbsInvAmt": 661,
      "ApplyToInvAmt": -661,
      "DiscAmt": 0,
      "TaxAmt": 86.1,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61777,
      "PmtDate": "05\/29\/2019",
      "PmtAmt": 1884.25,
      "PmtTypeDesc": "CHECK",
      "PmtVoidDate": "05\/30\/2019",
      "PoNum": 42414,
      "InvDescr": "IC PO #42414",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 2020649610,
      "bInvAmt": 661,
      "bDiscAmt": 0,
      "bTaxAmt": 86.1,
      "bNetAmt": 0,
      "APID": 168473
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649610,
      "InvDate": "05\/03\/2019",
      "InvAmt": 661,
      "AbsInvAmt": 661,
      "ApplyToInvAmt": 661,
      "DiscAmt": 0,
      "TaxAmt": 86.1,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61777,
      "PmtDate": "05\/29\/2019",
      "PmtAmt": 1884.25,
      "PmtTypeDesc": "CHECK",
      "PmtVoidDate": "05\/30\/2019",
      "PoNum": 42414,
      "InvDescr": "IC PO #42414",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 2020649610,
      "bInvAmt": 661,
      "bDiscAmt": 0,
      "bTaxAmt": 86.1,
      "bNetAmt": 0,
      "APID": 168479
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020649787-A",
      "InvDate": "05\/06\/2019",
      "InvAmt": -13415.99,
      "AbsInvAmt": 13415.99,
      "ApplyToInvAmt": -13415.99,
      "DiscAmt": 0,
      "TaxAmt": -1747.4,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtNum": 61929,
      "PmtDate": "07\/24\/2019",
      "PmtAmt": 268.4,
      "PmtTypeDesc": "CHECK",
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020649787,
      "bInvAmt": -13415.99,
      "bDiscAmt": 0,
      "bTaxAmt": -1747.4,
      "bNetAmt": 0,
      "APID": 168487
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020653635,
      "InvDate": "06\/13\/2019",
      "InvAmt": 12417.54,
      "AbsInvAmt": 12417.54,
      "ApplyToInvAmt": 12417.54,
      "DiscAmt": 0,
      "TaxAmt": 1614.69,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61929,
      "PmtDate": "07\/24\/2019",
      "PmtAmt": 268.4,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42617,
      "InvDescr": "IC PO #42617",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020653635,
      "bInvAmt": 12417.54,
      "bDiscAmt": 0,
      "bTaxAmt": 1614.69,
      "bNetAmt": 0,
      "APID": 168495
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503193078,
      "InvDate": "12\/12\/2019",
      "InvAmt": 2960,
      "AbsInvAmt": 2960,
      "ApplyToInvAmt": 2960,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350540,
      "PmtDate": "01\/06\/2020",
      "PmtAmt": 2960,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503193078,
      "bInvAmt": 2960,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168724
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503268603,
      "InvDate": "03\/06\/2020",
      "InvAmt": 2775,
      "AbsInvAmt": 2775,
      "ApplyToInvAmt": 2775,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352596,
      "PmtDate": "03\/04\/2021",
      "PmtAmt": 56448.62,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #49189",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503268603,
      "bInvAmt": 2775,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168732
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634183,
      "InvDate": "01\/29\/2018",
      "InvAmt": 27500,
      "AbsInvAmt": 27500,
      "ApplyToInvAmt": 27500,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216635,
      "PmtDate": "03\/15\/2018",
      "PmtAmt": 82500,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38810,
      "InvDescr": "PO #38810",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634183,
      "bInvAmt": 27500,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168832
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 636578,
      "InvDate": "09\/01\/2018",
      "InvAmt": 28540.78,
      "AbsInvAmt": 28540.78,
      "ApplyToInvAmt": 28540.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 218421,
      "PmtDate": "10\/01\/2018",
      "PmtAmt": 28540.78,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40249,
      "InvDescr": "PO #40249",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 636578,
      "bInvAmt": 28540.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168840
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60558726,
      "InvDate": "11\/20\/2019",
      "InvAmt": 6175,
      "AbsInvAmt": 6175,
      "ApplyToInvAmt": 6175,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350456,
      "PmtDate": "12\/10\/2019",
      "PmtAmt": 6175,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO 43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60558726,
      "bInvAmt": 6175,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176061
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60612241,
      "InvDate": "07\/21\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351858,
      "PmtDate": "10\/01\/2020",
      "PmtAmt": 24986.36,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60612241,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176067
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 638450,
      "InvDate": "03\/20\/2019",
      "InvAmt": 76596.3,
      "AbsInvAmt": 76596.3,
      "ApplyToInvAmt": 76596.3,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 220133,
      "PmtDate": "04\/17\/2019",
      "PmtAmt": 76596.3,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41411,
      "InvDescr": "PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 638450,
      "bInvAmt": 76596.3,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176091
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020605046,
      "InvDate": "03\/15\/2018",
      "InvAmt": 1435.81,
      "AbsInvAmt": 1435.81,
      "ApplyToInvAmt": 1435.81,
      "DiscAmt": 0,
      "TaxAmt": 187.01,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60729,
      "PmtDate": "04\/11\/2018",
      "PmtAmt": 14668.29,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39948,
      "InvDescr": "IC PO #39948",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020605046,
      "bInvAmt": 1435.81,
      "bDiscAmt": 0,
      "bTaxAmt": 187.01,
      "bNetAmt": 0,
      "APID": 176224
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:1593:P30384",
      "VndName": "HEWLETT PACKARD FINANCIAL SERVICES CO",
      "InvNum": 508325175,
      "InvDate": "05\/01\/2018",
      "InvAmt": 1575982.74,
      "AbsInvAmt": 1575982.74,
      "ApplyToInvAmt": 1575982.74,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 217174,
      "PmtDate": "05\/11\/2018",
      "PmtAmt": 1575982.74,
      "PmtTypeDesc": "CHECK",
      "InvDescr": "SCHEDULE #10114500010",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 508325175,
      "bInvAmt": 1575982.74,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176520
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60573522,
      "InvDate": "01\/21\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350743,
      "PmtDate": "02\/10\/2020",
      "PmtAmt": 6246.59,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60573522,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168646
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60596339,
      "InvDate": "05\/20\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351858,
      "PmtDate": "10\/01\/2020",
      "PmtAmt": 24986.36,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60596339,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 168654
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020597389,
      "InvDate": "01\/11\/2018",
      "InvAmt": 3134.45,
      "AbsInvAmt": 3134.45,
      "ApplyToInvAmt": 3134.45,
      "DiscAmt": 0,
      "TaxAmt": 408.25,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60540,
      "PmtDate": "02\/07\/2018",
      "PmtAmt": 3134.45,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39493,
      "InvDescr": "IC PO #39493",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020597389,
      "bInvAmt": 3134.45,
      "bDiscAmt": 0,
      "bTaxAmt": 408.25,
      "bNetAmt": 0,
      "APID": 168794
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020605045,
      "InvDate": "03\/15\/2018",
      "InvAmt": 1741.82,
      "AbsInvAmt": 1741.82,
      "ApplyToInvAmt": 1741.82,
      "DiscAmt": 0,
      "TaxAmt": 226.87,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60729,
      "PmtDate": "04\/11\/2018",
      "PmtAmt": 14668.29,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39951,
      "InvDescr": "IC PO #39951",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020605045,
      "bInvAmt": 1741.82,
      "bDiscAmt": 0,
      "bTaxAmt": 226.87,
      "bNetAmt": 0,
      "APID": 168800
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020607796,
      "InvDate": "04\/04\/2018",
      "InvAmt": 1617.7,
      "AbsInvAmt": 1617.7,
      "ApplyToInvAmt": 1617.7,
      "DiscAmt": 0,
      "TaxAmt": 210.7,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60789,
      "PmtDate": "05\/02\/2018",
      "PmtAmt": 96498.67,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40078,
      "InvDescr": "IC PO #40078",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020607796,
      "bInvAmt": 1617.7,
      "bDiscAmt": 0,
      "bTaxAmt": 210.7,
      "bNetAmt": 0,
      "APID": 168812
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020608302,
      "InvDate": "04\/09\/2018",
      "InvAmt": 31400.43,
      "AbsInvAmt": 31400.43,
      "ApplyToInvAmt": 31400.43,
      "DiscAmt": 0,
      "TaxAmt": 4089.75,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60907,
      "PmtDate": "06\/28\/2018",
      "PmtAmt": 196370.83,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39819,
      "InvDescr": "IC PO #39819",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020608302,
      "bInvAmt": 31400.43,
      "bDiscAmt": 0,
      "bTaxAmt": 4089.75,
      "bNetAmt": 0,
      "APID": 168819
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020608903,
      "InvDate": "04\/13\/2018",
      "InvAmt": 94756.85,
      "AbsInvAmt": 94756.85,
      "ApplyToInvAmt": 94756.85,
      "DiscAmt": 0,
      "TaxAmt": 12341.68,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60789,
      "PmtDate": "05\/02\/2018",
      "PmtAmt": 96498.67,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39341,
      "InvDescr": "IC PO #39341",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020608903,
      "bInvAmt": 94756.85,
      "bDiscAmt": 0,
      "bTaxAmt": 12341.68,
      "bNetAmt": 0,
      "APID": 168826
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020610889,
      "InvDate": "05\/01\/2018",
      "InvAmt": 164002.23,
      "AbsInvAmt": 164002.23,
      "ApplyToInvAmt": 164002.23,
      "DiscAmt": 0,
      "TaxAmt": 21360.58,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60858,
      "PmtDate": "05\/30\/2018",
      "PmtAmt": 161012.23,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40009,
      "InvDescr": "IC PO #40009",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020610889,
      "bInvAmt": 164002.23,
      "bDiscAmt": 0,
      "bTaxAmt": 21360.58,
      "bNetAmt": 0,
      "APID": 168833
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020620000,
      "InvDate": "07\/30\/2018",
      "InvAmt": 4717.94,
      "AbsInvAmt": 4717.94,
      "ApplyToInvAmt": 4717.94,
      "DiscAmt": 0,
      "TaxAmt": 614.51,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61060,
      "PmtDate": "08\/27\/2018",
      "PmtAmt": 36170.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39972,
      "InvDescr": "IC PO #39972",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020620000,
      "bInvAmt": 4717.94,
      "bDiscAmt": 0,
      "bTaxAmt": 614.51,
      "bNetAmt": 0,
      "APID": 168839
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020621635,
      "InvDate": "08\/24\/2018",
      "InvAmt": 845.99,
      "AbsInvAmt": 845.99,
      "ApplyToInvAmt": 845.99,
      "DiscAmt": 0,
      "TaxAmt": 110.19,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61120,
      "PmtDate": "09\/17\/2018",
      "PmtAmt": 157005.09,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40949,
      "InvDescr": "IC PO #40949",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020621635,
      "bInvAmt": 845.99,
      "bDiscAmt": 0,
      "bTaxAmt": 110.19,
      "bNetAmt": 0,
      "APID": 168846
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020627355,
      "InvDate": "10\/15\/2018",
      "InvAmt": 3094.21,
      "AbsInvAmt": 3094.21,
      "ApplyToInvAmt": 3094.21,
      "DiscAmt": 0,
      "TaxAmt": 403.01,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61262,
      "PmtDate": "11\/13\/2018",
      "PmtAmt": 3094.21,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41214,
      "InvDescr": "IC PO #41214",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020627355,
      "bInvAmt": 3094.21,
      "bDiscAmt": 0,
      "bTaxAmt": 403.01,
      "bNetAmt": 0,
      "APID": 168853
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020635130,
      "InvDate": "12\/18\/2018",
      "InvAmt": 2960.33,
      "AbsInvAmt": 2960.33,
      "ApplyToInvAmt": 2960.33,
      "DiscAmt": 0,
      "TaxAmt": 385.57,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61419,
      "PmtDate": "01\/08\/2019",
      "PmtAmt": 62595.18,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41510,
      "InvDescr": "IC PO #41510",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020635130,
      "bInvAmt": 2960.33,
      "bDiscAmt": 0,
      "bTaxAmt": 385.57,
      "bNetAmt": 0,
      "APID": 168862
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020635733,
      "InvDate": "12\/21\/2018",
      "InvAmt": 14940.99,
      "AbsInvAmt": 14940.99,
      "ApplyToInvAmt": 14940.99,
      "DiscAmt": 0,
      "TaxAmt": 918.2,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61450,
      "PmtDate": "01\/16\/2019",
      "PmtAmt": 24385.99,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41556,
      "InvDescr": "PO #41556",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020635733,
      "bInvAmt": 14940.99,
      "bDiscAmt": 0,
      "bTaxAmt": 918.2,
      "bNetAmt": 0,
      "APID": 168868
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020643531-A",
      "InvDate": "03\/06\/2019",
      "InvAmt": 1982.98,
      "AbsInvAmt": 1982.98,
      "ApplyToInvAmt": 1982.98,
      "DiscAmt": 0,
      "TaxAmt": 258.28,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61612,
      "PmtDate": "03\/27\/2019",
      "PmtAmt": 1982.98,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42011,
      "InvDescr": "IC PO #42011",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020643531,
      "bInvAmt": 1982.98,
      "bDiscAmt": 0,
      "bTaxAmt": 258.28,
      "bNetAmt": 0,
      "APID": 168877
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020653374,
      "InvDate": "06\/11\/2019",
      "InvAmt": 7418.07,
      "AbsInvAmt": 7418.07,
      "ApplyToInvAmt": 7418.07,
      "DiscAmt": 0,
      "TaxAmt": 564.75,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61929,
      "PmtDate": "07\/24\/2019",
      "PmtAmt": 268.4,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42551,
      "InvDescr": "IC PO #42551",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020653374,
      "bInvAmt": 7418.07,
      "bDiscAmt": 0,
      "bTaxAmt": 564.75,
      "bNetAmt": 0,
      "APID": 168885
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020655556,
      "InvDate": "07\/03\/2019",
      "InvAmt": 1835.53,
      "AbsInvAmt": 1835.53,
      "ApplyToInvAmt": 1835.53,
      "DiscAmt": 0,
      "TaxAmt": 229.54,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61929,
      "PmtDate": "07\/24\/2019",
      "PmtAmt": 268.4,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42716,
      "InvDescr": "IC PO #42716",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020655556,
      "bInvAmt": 1835.53,
      "bDiscAmt": 0,
      "bTaxAmt": 229.54,
      "bNetAmt": 0,
      "APID": 168893
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020657295,
      "InvDate": "07\/18\/2019",
      "InvAmt": 1395.57,
      "AbsInvAmt": 1395.57,
      "ApplyToInvAmt": -1395.57,
      "DiscAmt": 0,
      "TaxAmt": 181.77,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40006,
      "PmtDate": "08\/06\/2019",
      "PmtAmt": 1395.57,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PmtVoidDate": "08\/07\/2019",
      "PoNum": 42794,
      "InvDescr": "IC PO #42794",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 2020657295,
      "bInvAmt": 1395.57,
      "bDiscAmt": 0,
      "bTaxAmt": 181.77,
      "bNetAmt": 0,
      "APID": 168901
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020661451,
      "InvDate": "08\/27\/2019",
      "InvAmt": 361.02,
      "AbsInvAmt": 361.02,
      "ApplyToInvAmt": 361.02,
      "DiscAmt": 0,
      "TaxAmt": 47.02,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40084,
      "PmtDate": "09\/16\/2019",
      "PmtAmt": 361.02,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 42977,
      "InvDescr": "IC PO #42977",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020661451,
      "bInvAmt": 361.02,
      "bDiscAmt": 0,
      "bTaxAmt": 47.02,
      "bNetAmt": 0,
      "APID": 168908
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020673309,
      "InvDate": "12\/05\/2019",
      "InvAmt": 3783.49,
      "AbsInvAmt": 3783.49,
      "ApplyToInvAmt": 3783.49,
      "DiscAmt": 0,
      "TaxAmt": 492.79,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40265,
      "PmtDate": "01\/06\/2020",
      "PmtAmt": 4107.72,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43508,
      "InvDescr": "IC PO #43508",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020673309,
      "bInvAmt": 3783.49,
      "bDiscAmt": 0,
      "bTaxAmt": 492.79,
      "bNetAmt": 0,
      "APID": 168915
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 6616388,
      "InvDate": "01\/02\/2018",
      "InvAmt": 185033.29,
      "AbsInvAmt": 185033.29,
      "ApplyToInvAmt": 185033.29,
      "DiscAmt": 0,
      "TaxAmt": 20641.37,
      "CurrCode": "CAD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 17904,
      "PmtDate": "01\/24\/2018",
      "PmtAmt": 185033.29,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38912,
      "InvDescr": "IC PO#38912",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 6616388,
      "bInvAmt": 185126.98,
      "bDiscAmt": 0,
      "bTaxAmt": 20651.82,
      "bNetAmt": 0,
      "APID": 168923
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503113864,
      "InvDate": "09\/19\/2019",
      "InvAmt": 54345.77,
      "AbsInvAmt": 54345.77,
      "ApplyToInvAmt": 54345.77,
      "DiscAmt": 0,
      "TaxAmt": 25.09,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350194,
      "PmtDate": "10\/14\/2019",
      "PmtAmt": 135427.29,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43056,
      "InvDescr": "PO #43056",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503113864,
      "bInvAmt": 54345.77,
      "bDiscAmt": 0,
      "bTaxAmt": 25.09,
      "bNetAmt": 0,
      "APID": 169024
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60553652,
      "InvDate": "11\/03\/2019",
      "InvAmt": 578902.61,
      "AbsInvAmt": 578902.61,
      "ApplyToInvAmt": 578902.61,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350411,
      "PmtDate": "11\/25\/2019",
      "PmtAmt": 859591.77,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41402,
      "InvDescr": "PO #41402",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60553652,
      "bInvAmt": 578902.61,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179366
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60564065,
      "InvDate": "12\/11\/2019",
      "InvAmt": 147000,
      "AbsInvAmt": 147000,
      "ApplyToInvAmt": 147000,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350539,
      "PmtDate": "01\/06\/2020",
      "PmtAmt": 147000,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41452,
      "InvDescr": "PO #41452",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60564065,
      "bInvAmt": 147000,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179373
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60579704,
      "InvDate": "02\/21\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350936,
      "PmtDate": "03\/16\/2020",
      "PmtAmt": 6246.59,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60579704,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179381
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 1011427,
      "InvDate": "01\/25\/2018",
      "InvAmt": 29797.89,
      "AbsInvAmt": 29797.89,
      "ApplyToInvAmt": 29797.89,
      "DiscAmt": 0,
      "TaxAmt": 1418.95,
      "CurrCode": "CAD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 18087,
      "PmtDate": "03\/14\/2018",
      "PmtAmt": 62542.68,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38911,
      "InvDescr": "IC PO #38911",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 1011427,
      "bInvAmt": 29812.98,
      "bDiscAmt": 0,
      "bTaxAmt": 1419.67,
      "bNetAmt": 0,
      "APID": 179507
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020600885,
      "InvDate": "02\/14\/2018",
      "InvAmt": 20853.18,
      "AbsInvAmt": 20853.18,
      "ApplyToInvAmt": 20853.18,
      "DiscAmt": 0,
      "TaxAmt": 2716.04,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60648,
      "PmtDate": "03\/14\/2018",
      "PmtAmt": 20853.18,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39347,
      "InvDescr": "IC PO #39347",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020600885,
      "bInvAmt": 20853.18,
      "bDiscAmt": 0,
      "bTaxAmt": 2716.04,
      "bNetAmt": 0,
      "APID": 179562
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020605044,
      "InvDate": "03\/15\/2018",
      "InvAmt": 11490.66,
      "AbsInvAmt": 11490.66,
      "ApplyToInvAmt": 11490.66,
      "DiscAmt": 0,
      "TaxAmt": 1496.6,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60729,
      "PmtDate": "04\/11\/2018",
      "PmtAmt": 14668.29,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39924,
      "InvDescr": "IC PO #39924",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020605044,
      "bInvAmt": 11490.66,
      "bDiscAmt": 0,
      "bTaxAmt": 1496.6,
      "bNetAmt": 0,
      "APID": 179567
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020610268,
      "InvDate": "04\/25\/2018",
      "InvAmt": 1435.81,
      "AbsInvAmt": 1435.81,
      "ApplyToInvAmt": 1435.81,
      "DiscAmt": 0,
      "TaxAmt": 187.01,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60827,
      "PmtDate": "05\/16\/2018",
      "PmtAmt": 4570.26,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40203,
      "InvDescr": "IC PO #40203",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020610268,
      "bInvAmt": 1435.81,
      "bDiscAmt": 0,
      "bTaxAmt": 187.01,
      "bNetAmt": 0,
      "APID": 179573
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020616661,
      "InvDate": "06\/27\/2018",
      "InvAmt": 1435.81,
      "AbsInvAmt": 1435.81,
      "ApplyToInvAmt": 1435.81,
      "DiscAmt": 0,
      "TaxAmt": 187.01,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60952,
      "PmtDate": "07\/16\/2018",
      "PmtAmt": 355555.1,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40466,
      "InvDescr": "IC PO #40466",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020616661,
      "bInvAmt": 1435.81,
      "bDiscAmt": 0,
      "bTaxAmt": 187.01,
      "bNetAmt": 0,
      "APID": 179580
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020621956,
      "InvDate": "04\/25\/2018",
      "InvAmt": 978.47,
      "AbsInvAmt": 978.47,
      "ApplyToInvAmt": 978.47,
      "DiscAmt": 0,
      "TaxAmt": 127.44,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61139,
      "PmtDate": "09\/24\/2018",
      "PmtAmt": 209306.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40005,
      "InvDescr": "IC PO #40005",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020621956,
      "bInvAmt": 978.47,
      "bDiscAmt": 0,
      "bTaxAmt": 127.44,
      "bNetAmt": 0,
      "APID": 179588
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020622973,
      "InvDate": "08\/29\/2018",
      "InvAmt": 78079.55,
      "AbsInvAmt": 78079.55,
      "ApplyToInvAmt": 78079.55,
      "DiscAmt": 0,
      "TaxAmt": 10169.55,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61120,
      "PmtDate": "09\/17\/2018",
      "PmtAmt": 157005.09,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40912,
      "InvDescr": "IC PO #40912",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020622973,
      "bInvAmt": 78079.55,
      "bDiscAmt": 0,
      "bTaxAmt": 10169.55,
      "bNetAmt": 0,
      "APID": 179596
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020624888,
      "InvDate": "09\/28\/2018",
      "InvAmt": 65108.64,
      "AbsInvAmt": 65108.64,
      "ApplyToInvAmt": 65108.64,
      "DiscAmt": 0,
      "TaxAmt": 8480.14,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61209,
      "PmtDate": "10\/22\/2018",
      "PmtAmt": 279214.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40919,
      "InvDescr": "IC PO #40919",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020624888,
      "bInvAmt": 65108.64,
      "bDiscAmt": 0,
      "bTaxAmt": 8480.14,
      "bNetAmt": 0,
      "APID": 179604
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020624889,
      "InvDate": "09\/28\/2018",
      "InvAmt": 65108.64,
      "AbsInvAmt": 65108.64,
      "ApplyToInvAmt": 65108.64,
      "DiscAmt": 0,
      "TaxAmt": 8480.14,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61209,
      "PmtDate": "10\/22\/2018",
      "PmtAmt": 279214.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40918,
      "InvDescr": "IC PO #40918",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020624889,
      "bInvAmt": 65108.64,
      "bDiscAmt": 0,
      "bTaxAmt": 8480.14,
      "bNetAmt": 0,
      "APID": 179612
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649228,
      "InvDate": "04\/30\/2019",
      "InvAmt": 1223.25,
      "AbsInvAmt": 1223.25,
      "ApplyToInvAmt": 1223.25,
      "DiscAmt": 0,
      "TaxAmt": 159.32,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61777,
      "PmtDate": "05\/29\/2019",
      "PmtAmt": 1884.25,
      "PmtTypeDesc": "CHECK",
      "PmtVoidDate": "05\/30\/2019",
      "PoNum": 42341,
      "InvDescr": "IC PO #42341",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 2020649228,
      "bInvAmt": 1223.25,
      "bDiscAmt": 0,
      "bTaxAmt": 159.32,
      "bNetAmt": 0,
      "APID": 179619
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649610,
      "InvDate": "05\/03\/2019",
      "InvAmt": 661,
      "AbsInvAmt": 661,
      "ApplyToInvAmt": 661,
      "DiscAmt": 0,
      "TaxAmt": 86.1,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61826,
      "PmtDate": "06\/14\/2019",
      "PmtAmt": 15332.22,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42414,
      "InvDescr": "IC PO #42414",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020649610,
      "bInvAmt": 661,
      "bDiscAmt": 0,
      "bTaxAmt": 86.1,
      "bNetAmt": 0,
      "APID": 179627
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020651076,
      "InvDate": "05\/17\/2019",
      "InvAmt": 330.49,
      "AbsInvAmt": 330.49,
      "ApplyToInvAmt": 330.49,
      "DiscAmt": 0,
      "TaxAmt": 43.04,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61826,
      "PmtDate": "06\/14\/2019",
      "PmtAmt": 15332.22,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42477,
      "InvDescr": "IC PO #42477",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020651076,
      "bInvAmt": 330.49,
      "bDiscAmt": 0,
      "bTaxAmt": 43.04,
      "bNetAmt": 0,
      "APID": 179635
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 1P8",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020680140,
      "InvDate": "02\/06\/2020",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "ApplyToInvAmt": -324.23,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40356,
      "PmtDate": "02\/24\/2020",
      "PmtAmt": 324.23,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PmtVoidDate": "04\/07\/2020",
      "PoNum": 44018,
      "InvDescr": "UPDATE PAYMENT METHOD",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "OFFSET",
      "DupeX": 1,
      "MatchInvNum": 2020680140,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179642
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020682862A",
      "InvDate": "02\/28\/2020",
      "InvAmt": 63193.44,
      "AbsInvAmt": 63193.44,
      "ApplyToInvAmt": 63193.44,
      "DiscAmt": 0,
      "TaxAmt": 4611.89,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 6275,
      "PmtDate": "04\/14\/2020",
      "PmtAmt": 63517.67,
      "PmtTypeDesc": "EFT",
      "PoNum": 44125,
      "InvDescr": "IC PO #44125",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020682862,
      "bInvAmt": 63193.44,
      "bDiscAmt": 0,
      "bTaxAmt": 4611.89,
      "bNetAmt": 0,
      "APID": 179651
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2502973944,
      "InvDate": "04\/11\/2019",
      "InvAmt": 8140,
      "AbsInvAmt": 8140,
      "ApplyToInvAmt": 8140,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 30138,
      "PmtDate": "05\/06\/2019",
      "PmtAmt": 34070.08,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2502973944,
      "bInvAmt": 8140,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179757
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2502999778,
      "InvDate": "05\/08\/2019",
      "InvAmt": 28120,
      "AbsInvAmt": 28120,
      "ApplyToInvAmt": 28120,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 30351,
      "PmtDate": "06\/06\/2019",
      "PmtAmt": 30023.03,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41989,
      "InvDescr": "PO 41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2502999778,
      "bInvAmt": 28120,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179764
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503110054,
      "InvDate": "08\/13\/2019",
      "InvAmt": 1850,
      "AbsInvAmt": 1850,
      "ApplyToInvAmt": 1850,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350167,
      "PmtDate": "10\/02\/2019",
      "PmtAmt": 1850,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503110054,
      "bInvAmt": 1850,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179772
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503175066,
      "InvDate": "11\/22\/2019",
      "InvAmt": 2590,
      "AbsInvAmt": 2590,
      "ApplyToInvAmt": 2590,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350500,
      "PmtDate": "12\/17\/2019",
      "PmtAmt": 2590,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503175066,
      "bInvAmt": 2590,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179779
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": "2503417334A",
      "InvDate": "09\/10\/2020",
      "InvAmt": -555,
      "AbsInvAmt": 555,
      "ApplyToInvAmt": -555,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "CREDIT",
      "PmtNum": 352596,
      "PmtDate": "03\/04\/2021",
      "PmtAmt": 56448.62,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989 \/ RELATED INVOICE 2503380210",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503417334,
      "bInvAmt": -555,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179786
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503529777,
      "InvDate": "01\/22\/2021",
      "InvAmt": 1480,
      "AbsInvAmt": 1480,
      "ApplyToInvAmt": 1480,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352521,
      "PmtDate": "02\/22\/2021",
      "PmtAmt": 3515,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "IC PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503529777,
      "bInvAmt": 1480,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179794
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60644906,
      "InvDate": "01\/02\/2021",
      "InvAmt": 54228.62,
      "AbsInvAmt": 54228.62,
      "ApplyToInvAmt": 54228.62,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352472,
      "PmtDate": "02\/08\/2021",
      "PmtAmt": 54228.62,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 45446,
      "InvDescr": "PO #45446",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60644906,
      "bInvAmt": 54228.62,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179833
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634741,
      "InvDate": "03\/06\/2018",
      "InvAmt": 93158.67,
      "AbsInvAmt": 93158.67,
      "ApplyToInvAmt": 93158.67,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216845,
      "PmtDate": "04\/05\/2018",
      "PmtAmt": 209392.58,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39940,
      "InvDescr": "PO #39940",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634741,
      "bInvAmt": 93158.67,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179879
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634742,
      "InvDate": "03\/06\/2018",
      "InvAmt": 116233.91,
      "AbsInvAmt": 116233.91,
      "ApplyToInvAmt": 116233.91,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216845,
      "PmtDate": "04\/05\/2018",
      "PmtAmt": 209392.58,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39940,
      "InvDescr": "PO #39940",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634742,
      "bInvAmt": 116233.91,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179887
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 635352,
      "InvDate": "05\/02\/2018",
      "InvAmt": 28540.78,
      "AbsInvAmt": 28540.78,
      "ApplyToInvAmt": 28540.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 217357,
      "PmtDate": "05\/31\/2018",
      "PmtAmt": 28540.78,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40249,
      "InvDescr": "PO #40249",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 635352,
      "bInvAmt": 28540.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179895
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60481012,
      "InvDate": "01\/15\/2019",
      "InvAmt": 147000,
      "AbsInvAmt": 147000,
      "ApplyToInvAmt": 147000,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219574,
      "PmtDate": "02\/12\/2019",
      "PmtAmt": 147000,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41452,
      "InvDescr": "PO #41452",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60481012,
      "bInvAmt": 147000,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180974
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60552539,
      "InvDate": "10\/31\/2019",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350343,
      "PmtDate": "11\/19\/2019",
      "PmtAmt": 6246.59,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "IC PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60552539,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180982
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60590732,
      "InvDate": "04\/21\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351858,
      "PmtDate": "10\/01\/2020",
      "PmtAmt": 24986.36,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60590732,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180989
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 1011426,
      "InvDate": "01\/25\/2018",
      "InvAmt": 32744.79,
      "AbsInvAmt": 32744.79,
      "ApplyToInvAmt": 32744.79,
      "DiscAmt": 0,
      "TaxAmt": 3767.1,
      "CurrCode": "CAD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 18087,
      "PmtDate": "03\/14\/2018",
      "PmtAmt": 62542.68,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38911,
      "InvDescr": "IC PO #38911",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 1011426,
      "bInvAmt": 32761.37,
      "bDiscAmt": 0,
      "bTaxAmt": 3769.01,
      "bNetAmt": 0,
      "APID": 181067
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020604360,
      "InvDate": "03\/07\/2018",
      "InvAmt": 6228.65,
      "AbsInvAmt": 6228.65,
      "ApplyToInvAmt": 6228.65,
      "DiscAmt": 0,
      "TaxAmt": 274.36,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60706,
      "PmtDate": "04\/04\/2018",
      "PmtAmt": 6228.65,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39947,
      "InvDescr": "IC PO #39947",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020604360,
      "bInvAmt": 6228.65,
      "bDiscAmt": 0,
      "bTaxAmt": 274.36,
      "bNetAmt": 0,
      "APID": 181135
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020610359,
      "InvDate": "04\/26\/2018",
      "InvAmt": 3134.45,
      "AbsInvAmt": 3134.45,
      "ApplyToInvAmt": 3134.45,
      "DiscAmt": 0,
      "TaxAmt": 408.25,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60827,
      "PmtDate": "05\/16\/2018",
      "PmtAmt": 4570.26,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40204,
      "InvDescr": "IC PO #40204",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020610359,
      "bInvAmt": 3134.45,
      "bDiscAmt": 0,
      "bTaxAmt": 408.25,
      "bNetAmt": 0,
      "APID": 181145
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020611814,
      "InvDate": "05\/10\/2018",
      "InvAmt": 43726.88,
      "AbsInvAmt": 43726.88,
      "ApplyToInvAmt": 43726.88,
      "DiscAmt": 0,
      "TaxAmt": 5695.23,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60878,
      "PmtDate": "06\/06\/2018",
      "PmtAmt": 347385.88,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39952,
      "InvDescr": "IC PO #39952",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020611814,
      "bInvAmt": 43726.88,
      "bDiscAmt": 0,
      "bTaxAmt": 5695.23,
      "bNetAmt": 0,
      "APID": 181154
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020616967,
      "InvDate": "06\/29\/2018",
      "InvAmt": 9322.86,
      "AbsInvAmt": 9322.86,
      "ApplyToInvAmt": 9322.86,
      "DiscAmt": 0,
      "TaxAmt": 1214.26,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60963,
      "PmtDate": "07\/23\/2018",
      "PmtAmt": 9322.86,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40468,
      "InvDescr": "IC PO #40468",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020616967,
      "bInvAmt": 9322.86,
      "bDiscAmt": 0,
      "bTaxAmt": 1214.26,
      "bNetAmt": 0,
      "APID": 181162
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020617644,
      "InvDate": "07\/06\/2018",
      "InvAmt": 5210.89,
      "AbsInvAmt": 5210.89,
      "ApplyToInvAmt": 5210.89,
      "DiscAmt": 0,
      "TaxAmt": 678.7,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60988,
      "PmtDate": "07\/30\/2018",
      "PmtAmt": 5210.89,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40418,
      "InvDescr": "IC PO #40418",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020617644,
      "bInvAmt": 5210.89,
      "bDiscAmt": 0,
      "bTaxAmt": 678.7,
      "bNetAmt": 0,
      "APID": 181170
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020626154,
      "InvDate": "10\/04\/2018",
      "InvAmt": 78079.55,
      "AbsInvAmt": 78079.55,
      "ApplyToInvAmt": 78079.55,
      "DiscAmt": 0,
      "TaxAmt": 10169.55,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61293,
      "PmtDate": "11\/27\/2018",
      "PmtAmt": 81173.76,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40914,
      "InvDescr": "IC PO #40914",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020626154,
      "bInvAmt": 78079.55,
      "bDiscAmt": 0,
      "bTaxAmt": 10169.55,
      "bNetAmt": 0,
      "APID": 181177
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020635734,
      "InvDate": "12\/21\/2018",
      "InvAmt": 2918.19,
      "AbsInvAmt": 2918.19,
      "ApplyToInvAmt": 2918.19,
      "DiscAmt": 0,
      "TaxAmt": 380.09,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61450,
      "PmtDate": "01\/16\/2019",
      "PmtAmt": 24385.99,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41667,
      "InvDescr": "PO #41667",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020635734,
      "bInvAmt": 2918.19,
      "bDiscAmt": 0,
      "bTaxAmt": 380.09,
      "bNetAmt": 0,
      "APID": 181188
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020646375,
      "InvDate": "04\/01\/2019",
      "InvAmt": 685.25,
      "AbsInvAmt": 685.25,
      "ApplyToInvAmt": 685.25,
      "DiscAmt": 0,
      "TaxAmt": 89.25,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61689,
      "PmtDate": "04\/25\/2019",
      "PmtAmt": 8309.92,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42241,
      "InvDescr": "IC PO #42241",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020646375,
      "bInvAmt": 685.25,
      "bDiscAmt": 0,
      "bTaxAmt": 89.25,
      "bNetAmt": 0,
      "APID": 181196
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649228,
      "InvDate": "04\/30\/2019",
      "InvAmt": 1223.25,
      "AbsInvAmt": 1223.25,
      "ApplyToInvAmt": -1223.25,
      "DiscAmt": 0,
      "TaxAmt": 159.32,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61777,
      "PmtDate": "05\/29\/2019",
      "PmtAmt": 1884.25,
      "PmtTypeDesc": "CHECK",
      "PmtVoidDate": "05\/30\/2019",
      "PoNum": 42341,
      "InvDescr": "IC PO #42341",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 2020649228,
      "bInvAmt": 1223.25,
      "bDiscAmt": 0,
      "bTaxAmt": 159.32,
      "bNetAmt": 0,
      "APID": 181202
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020649228,
      "InvDate": "04\/30\/2019",
      "InvAmt": 1223.25,
      "AbsInvAmt": 1223.25,
      "ApplyToInvAmt": 1223.25,
      "DiscAmt": 0,
      "TaxAmt": 159.32,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61826,
      "PmtDate": "06\/14\/2019",
      "PmtAmt": 15332.22,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42341,
      "InvDescr": "IC PO #42341",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020649228,
      "bInvAmt": 1223.25,
      "bDiscAmt": 0,
      "bTaxAmt": 159.32,
      "bNetAmt": 0,
      "APID": 181211
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020651354-A",
      "InvDate": "05\/31\/2019",
      "InvAmt": -934.75,
      "AbsInvAmt": 934.75,
      "ApplyToInvAmt": -934.75,
      "DiscAmt": 0,
      "TaxAmt": -121.75,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtNum": 61929,
      "PmtDate": "07\/24\/2019",
      "PmtAmt": 268.4,
      "PmtTypeDesc": "CHECK",
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020651354,
      "bInvAmt": -934.75,
      "bDiscAmt": 0,
      "bTaxAmt": -121.75,
      "bNetAmt": 0,
      "APID": 181219
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020657295,
      "InvDate": "07\/18\/2019",
      "InvAmt": 1395.57,
      "AbsInvAmt": 1395.57,
      "ApplyToInvAmt": 1395.57,
      "DiscAmt": 0,
      "TaxAmt": 181.77,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40006,
      "PmtDate": "08\/06\/2019",
      "PmtAmt": 1395.57,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PmtVoidDate": "08\/07\/2019",
      "PoNum": 42794,
      "InvDescr": "IC PO #42794",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 2020657295,
      "bInvAmt": 1395.57,
      "bDiscAmt": 0,
      "bTaxAmt": 181.77,
      "bNetAmt": 0,
      "APID": 181287
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020665042,
      "InvDate": "09\/30\/2019",
      "InvAmt": 8778.52,
      "AbsInvAmt": 8778.52,
      "ApplyToInvAmt": 8778.52,
      "DiscAmt": 0,
      "TaxAmt": 1143.37,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40147,
      "PmtDate": "10\/21\/2019",
      "PmtAmt": 8778.52,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43123,
      "InvDescr": "IC PO #43123",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020665042,
      "bInvAmt": 8778.52,
      "bDiscAmt": 0,
      "bTaxAmt": 1143.37,
      "bNetAmt": 0,
      "APID": 181298
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020671782,
      "InvDate": "01\/02\/2020",
      "InvAmt": 12073.75,
      "AbsInvAmt": 12073.75,
      "ApplyToInvAmt": 12073.75,
      "DiscAmt": 0,
      "TaxAmt": 1572.55,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40316,
      "PmtDate": "02\/03\/2020",
      "PmtAmt": 12073.75,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43595,
      "InvDescr": "IC PO #43595",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020671782,
      "bInvAmt": 12073.75,
      "bDiscAmt": 0,
      "bTaxAmt": 1572.55,
      "bNetAmt": 0,
      "APID": 181305
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020673017,
      "InvDate": "12\/03\/2019",
      "InvAmt": 324.23,
      "AbsInvAmt": 324.23,
      "ApplyToInvAmt": 324.23,
      "DiscAmt": 0,
      "TaxAmt": 42.23,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40265,
      "PmtDate": "01\/06\/2020",
      "PmtAmt": 4107.72,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43401,
      "InvDescr": "IC PO #43401",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020673017,
      "bInvAmt": 324.23,
      "bDiscAmt": 0,
      "bTaxAmt": 42.23,
      "bNetAmt": 0,
      "APID": 181313
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020688219,
      "InvDate": "04\/30\/2020",
      "InvAmt": 5112.85,
      "AbsInvAmt": 5112.85,
      "ApplyToInvAmt": 5112.85,
      "DiscAmt": 0,
      "TaxAmt": 665.93,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 6344,
      "PmtDate": "06\/02\/2020",
      "PmtAmt": 5112.85,
      "PmtTypeDesc": "EFT",
      "PoNum": 44373,
      "InvDescr": "IC PO #44373",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020688219,
      "bInvAmt": 5112.85,
      "bDiscAmt": 0,
      "bTaxAmt": 665.93,
      "bNetAmt": 0,
      "APID": 181322
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020691594,
      "InvDate": "04\/20\/2020",
      "InvAmt": 3481.31,
      "AbsInvAmt": 3481.31,
      "ApplyToInvAmt": 3481.31,
      "DiscAmt": 0,
      "TaxAmt": 453.43,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 6330,
      "PmtDate": "05\/26\/2020",
      "PmtAmt": 3481.31,
      "PmtTypeDesc": "EFT",
      "PoNum": 44373,
      "InvDescr": "IC PO #44373",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020691594,
      "bInvAmt": 3481.31,
      "bDiscAmt": 0,
      "bTaxAmt": 453.43,
      "bNetAmt": 0,
      "APID": 181330
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 6625792,
      "InvDate": "10\/01\/2018",
      "InvAmt": 33314.2,
      "AbsInvAmt": 33314.2,
      "ApplyToInvAmt": 33314.2,
      "DiscAmt": 0,
      "TaxAmt": 3832.6,
      "CurrCode": "CAD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 18771,
      "PmtDate": "10\/22\/2018",
      "PmtAmt": 33314.2,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40812,
      "InvDescr": "IC PO #40812",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 6625792,
      "bInvAmt": 33331.07,
      "bDiscAmt": 0,
      "bTaxAmt": 3834.54,
      "bNetAmt": 0,
      "APID": 181343
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60497972,
      "InvDate": "03\/26\/2019",
      "InvAmt": 22994,
      "AbsInvAmt": 22994,
      "ApplyToInvAmt": 22994,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 220173,
      "PmtDate": "04\/25\/2019",
      "PmtAmt": 22994,
      "PmtTypeDesc": "CHECK",
      "PmtVoidDate": "05\/01\/2019",
      "PoNum": 41987,
      "InvDescr": "TRDO PO #41987",
      "VndType": "SUPPLIER",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 60497972,
      "bInvAmt": 22994,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 181508
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60534151,
      "InvDate": "09\/04\/2019",
      "InvAmt": 59488.58,
      "AbsInvAmt": 59488.58,
      "ApplyToInvAmt": 59488.58,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350194,
      "PmtDate": "10\/14\/2019",
      "PmtAmt": 135427.29,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43042,
      "InvDescr": "PO #43042",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60534151,
      "bInvAmt": 59488.58,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 181517
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60642415,
      "InvDate": "12\/16\/2020",
      "InvAmt": 54228.62,
      "AbsInvAmt": 54228.62,
      "ApplyToInvAmt": 54228.62,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352346,
      "PmtDate": "01\/19\/2021",
      "PmtAmt": 108456.22,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 45446,
      "InvDescr": "PO #45446",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60642415,
      "bInvAmt": 54228.62,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 181523
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 2502820060,
      "InvDate": "10\/18\/2018",
      "InvAmt": 548228.23,
      "AbsInvAmt": 548228.23,
      "ApplyToInvAmt": 548228.23,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219073,
      "PmtDate": "12\/17\/2018",
      "PmtAmt": 548228.23,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41308,
      "InvDescr": "TRDO PO #41308",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2502820060,
      "bInvAmt": 548228.23,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170300
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60566954,
      "InvDate": "12\/21\/2019",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350584,
      "PmtDate": "01\/14\/2020",
      "PmtAmt": 6246.59,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60566954,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170324
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60585053,
      "InvDate": "03\/20\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351114,
      "PmtDate": "04\/27\/2020",
      "PmtAmt": 6246.59,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60585053,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170333
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60624893,
      "InvDate": "09\/22\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351998,
      "PmtDate": "11\/03\/2020",
      "PmtAmt": 12493.18,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60624893,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170341
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 637337,
      "InvDate": "11\/07\/2018",
      "InvAmt": 61642.96,
      "AbsInvAmt": 61642.96,
      "ApplyToInvAmt": 61642.96,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219102,
      "PmtDate": "12\/21\/2018",
      "PmtAmt": 61642.96,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41411,
      "InvDescr": "PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 637337,
      "bInvAmt": 61642.96,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170348
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 638281,
      "InvDate": "03\/01\/2019",
      "InvAmt": 5666.76,
      "AbsInvAmt": 5666.76,
      "ApplyToInvAmt": 5666.76,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219982,
      "PmtDate": "03\/27\/2019",
      "PmtAmt": 69434.5,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41411,
      "InvDescr": "PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 638281,
      "bInvAmt": 5666.76,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170354
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 640594,
      "InvDate": "11\/06\/2019",
      "InvAmt": 95651.61,
      "AbsInvAmt": 95651.61,
      "ApplyToInvAmt": 95651.61,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350411,
      "PmtDate": "11\/25\/2019",
      "PmtAmt": 859591.77,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41411,
      "InvDescr": "PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 640594,
      "bInvAmt": 95651.61,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170362
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 640596,
      "InvDate": "11\/06\/2019",
      "InvAmt": 61642.96,
      "AbsInvAmt": 61642.96,
      "ApplyToInvAmt": 61642.96,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350411,
      "PmtDate": "11\/25\/2019",
      "PmtAmt": 859591.77,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41411,
      "InvDescr": "IC PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 640596,
      "bInvAmt": 61642.96,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170408
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020599075,
      "InvDate": "01\/26\/2018",
      "InvAmt": 3134.45,
      "AbsInvAmt": 3134.45,
      "ApplyToInvAmt": 3134.45,
      "DiscAmt": 0,
      "TaxAmt": 408.25,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60590,
      "PmtDate": "02\/21\/2018",
      "PmtAmt": 123100.04,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39593,
      "InvDescr": "IC PO #39593",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020599075,
      "bInvAmt": 3134.45,
      "bDiscAmt": 0,
      "bTaxAmt": 408.25,
      "bNetAmt": 0,
      "APID": 170500
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020612731,
      "InvDate": "05\/23\/2018",
      "InvAmt": -2990,
      "AbsInvAmt": 2990,
      "ApplyToInvAmt": -2990,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "CREDIT",
      "PmtNum": 60858,
      "PmtDate": "05\/30\/2018",
      "PmtAmt": 161012.23,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39819,
      "InvDescr": "IC PO #39819",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020612731,
      "bInvAmt": -2990,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170508
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020620402,
      "InvDate": "08\/01\/2018",
      "InvAmt": 60978.43,
      "AbsInvAmt": 60978.43,
      "ApplyToInvAmt": 60978.43,
      "DiscAmt": 0,
      "TaxAmt": 7942.19,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61139,
      "PmtDate": "09\/24\/2018",
      "PmtAmt": 209306.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40563,
      "InvDescr": "IC PO #40563",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020620402,
      "bInvAmt": 60978.43,
      "bDiscAmt": 0,
      "bTaxAmt": 7942.19,
      "bNetAmt": 0,
      "APID": 170516
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020621364,
      "InvDate": "08\/14\/2018",
      "InvAmt": 15399.51,
      "AbsInvAmt": 15399.51,
      "ApplyToInvAmt": 15399.51,
      "DiscAmt": 0,
      "TaxAmt": 733.31,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61139,
      "PmtDate": "09\/24\/2018",
      "PmtAmt": 209306.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40563,
      "InvDescr": "IC PO #40563",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020621364,
      "bInvAmt": 15399.51,
      "bDiscAmt": 0,
      "bTaxAmt": 733.31,
      "bNetAmt": 0,
      "APID": 170524
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020630649,
      "InvDate": "12\/19\/2018",
      "InvAmt": 19840.24,
      "AbsInvAmt": 19840.24,
      "ApplyToInvAmt": 19840.24,
      "DiscAmt": 0,
      "TaxAmt": 2584.12,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61419,
      "PmtDate": "01\/08\/2019",
      "PmtAmt": 62595.18,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41467,
      "InvDescr": "IC PO #41467",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020630649,
      "bInvAmt": 19840.24,
      "bDiscAmt": 0,
      "bTaxAmt": 2584.12,
      "bNetAmt": 0,
      "APID": 170532
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020634031,
      "InvDate": "12\/19\/2018",
      "InvAmt": 17192.92,
      "AbsInvAmt": 17192.92,
      "ApplyToInvAmt": 17192.92,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61419,
      "PmtDate": "01\/08\/2019",
      "PmtAmt": 62595.18,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41494,
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020634031,
      "bInvAmt": 17192.92,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170540
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020634129,
      "InvDate": "12\/09\/2018",
      "InvAmt": 40.24,
      "AbsInvAmt": 40.24,
      "ApplyToInvAmt": 40.24,
      "DiscAmt": 0,
      "TaxAmt": 5.24,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61489,
      "PmtDate": "01\/29\/2019",
      "PmtAmt": 40.24,
      "PmtTypeDesc": "CHECK",
      "InvDescr": "IC PO #41091",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020634129,
      "bInvAmt": 40.24,
      "bDiscAmt": 0,
      "bTaxAmt": 5.24,
      "bNetAmt": 0,
      "APID": 170548
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020638446,
      "InvDate": "01\/14\/2019",
      "InvAmt": 3965.95,
      "AbsInvAmt": 3965.95,
      "ApplyToInvAmt": 3965.95,
      "DiscAmt": 0,
      "TaxAmt": 516.55,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61553,
      "PmtDate": "02\/28\/2019",
      "PmtAmt": 3965.95,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41665,
      "InvDescr": "IC PO #41665",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020638446,
      "bInvAmt": 3965.95,
      "bDiscAmt": 0,
      "bTaxAmt": 516.55,
      "bNetAmt": 0,
      "APID": 170557
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020646376,
      "InvDate": "04\/01\/2019",
      "InvAmt": 7624.67,
      "AbsInvAmt": 7624.67,
      "ApplyToInvAmt": 7624.67,
      "DiscAmt": 0,
      "TaxAmt": 990.92,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61689,
      "PmtDate": "04\/25\/2019",
      "PmtAmt": 8309.92,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42063,
      "InvDescr": "IC PO #42063",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020646376,
      "bInvAmt": 7624.67,
      "bDiscAmt": 0,
      "bTaxAmt": 990.92,
      "bNetAmt": 0,
      "APID": 170565
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020678126,
      "InvDate": "01\/18\/2020",
      "InvAmt": 12073.75,
      "AbsInvAmt": 12073.75,
      "ApplyToInvAmt": 12073.75,
      "DiscAmt": 0,
      "TaxAmt": 1572.55,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40373,
      "PmtDate": "03\/09\/2020",
      "PmtAmt": 14056.73,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43827,
      "InvDescr": "IC PO #43827",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020678126,
      "bInvAmt": 12073.75,
      "bDiscAmt": 0,
      "bTaxAmt": 1572.55,
      "bNetAmt": 0,
      "APID": 170574
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 1P8",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020680140,
      "InvDate": "02\/06\/2020",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "ApplyToInvAmt": 324.23,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40356,
      "PmtDate": "02\/24\/2020",
      "PmtAmt": 324.23,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PmtVoidDate": "04\/07\/2020",
      "PoNum": 44018,
      "InvDescr": "UPDATE PAYMENT METHOD",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "OFFSET",
      "DupeX": 1,
      "MatchInvNum": 2020680140,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170585
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020680140A",
      "InvDate": "02\/06\/2020",
      "InvAmt": 324.23,
      "AbsInvAmt": 324.23,
      "ApplyToInvAmt": 324.23,
      "DiscAmt": 0,
      "TaxAmt": 42.23,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 6275,
      "PmtDate": "04\/14\/2020",
      "PmtAmt": 63517.67,
      "PmtTypeDesc": "EFT",
      "PoNum": 44018,
      "InvDescr": "IC PO #44018",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020680140,
      "bInvAmt": 324.23,
      "bDiscAmt": 0,
      "bTaxAmt": 42.23,
      "bNetAmt": 0,
      "APID": 170593
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 6628579,
      "InvDate": "01\/01\/2019",
      "InvAmt": 234220.44,
      "AbsInvAmt": 234220.44,
      "ApplyToInvAmt": 234220.44,
      "DiscAmt": 0,
      "TaxAmt": 26128.44,
      "CurrCode": "CAD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 19163,
      "PmtDate": "03\/06\/2019",
      "PmtAmt": 234220.44,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41385,
      "InvDescr": "IC PO #41385",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "OFFSET",
      "DupeX": 1,
      "MatchInvNum": 6628579,
      "bInvAmt": 234339.03,
      "bDiscAmt": 0,
      "bTaxAmt": 26141.67,
      "bNetAmt": 0,
      "APID": 170611
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 2J2",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 6648580,
      "InvDate": "12\/15\/2020",
      "InvAmt": 56651.39,
      "AbsInvAmt": 56651.39,
      "ApplyToInvAmt": 56651.39,
      "DiscAmt": 0,
      "TaxAmt": 6069.79,
      "CurrCode": "CAD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 2590,
      "PmtDate": "01\/20\/2021",
      "PmtAmt": 56651.39,
      "PmtTypeDesc": "EFT",
      "PoNum": 45730,
      "InvDescr": "IC PO #45730",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 6648580,
      "bInvAmt": 56680.07,
      "bDiscAmt": 0,
      "bTaxAmt": 6072.86,
      "bNetAmt": 0,
      "APID": 170619
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634181,
      "InvDate": "01\/29\/2018",
      "InvAmt": 27500,
      "AbsInvAmt": 27500,
      "ApplyToInvAmt": 27500,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216635,
      "PmtDate": "03\/15\/2018",
      "PmtAmt": 82500,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38811,
      "InvDescr": "PO #38811",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634181,
      "bInvAmt": 27500,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175070
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634854,
      "InvDate": "04\/01\/2018",
      "InvAmt": 28609.04,
      "AbsInvAmt": 28609.04,
      "ApplyToInvAmt": 28609.04,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 217032,
      "PmtDate": "04\/26\/2018",
      "PmtAmt": 28609.04,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38828,
      "InvDescr": "PO #38828",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634854,
      "bInvAmt": 28609.04,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175077
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 636229,
      "InvDate": "08\/01\/2018",
      "InvAmt": 28540.78,
      "AbsInvAmt": 28540.78,
      "ApplyToInvAmt": 28540.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 218124,
      "PmtDate": "08\/27\/2018",
      "PmtAmt": 28540.78,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40249,
      "InvDescr": "PO #40249",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 636229,
      "bInvAmt": 28540.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175083
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 7740065876,
      "InvDate": "01\/31\/2020",
      "InvAmt": 355.45,
      "AbsInvAmt": 355.45,
      "ApplyToInvAmt": 355.45,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40357,
      "PmtDate": "02\/24\/2020",
      "PmtAmt": 355.45,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 44043,
      "InvDescr": "IC PO #44043",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 7740065876,
      "bInvAmt": 355.45,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175097
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60602917,
      "InvDate": "06\/21\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351858,
      "PmtDate": "10\/01\/2020",
      "PmtAmt": 24986.36,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60602917,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179761
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 640597,
      "InvDate": "11\/06\/2019",
      "InvAmt": 8500.14,
      "AbsInvAmt": 8500.14,
      "ApplyToInvAmt": 8500.14,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350411,
      "PmtDate": "11\/25\/2019",
      "PmtAmt": 859591.77,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41411,
      "InvDescr": "IC PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 640597,
      "bInvAmt": 8500.14,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179776
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 7740043992,
      "InvDate": "02\/23\/2018",
      "InvAmt": 114.19,
      "AbsInvAmt": 114.19,
      "ApplyToInvAmt": 114.19,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60670,
      "PmtDate": "03\/21\/2018",
      "PmtAmt": 114.19,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39640,
      "InvDescr": "IC PO #39640",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 7740043992,
      "bInvAmt": 114.19,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179822
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 7740043993,
      "InvDate": "02\/23\/2018",
      "InvAmt": 122.29,
      "AbsInvAmt": 122.29,
      "ApplyToInvAmt": 122.29,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60690,
      "PmtDate": "03\/28\/2018",
      "PmtAmt": 122.29,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39581,
      "InvDescr": "IC PO #39581",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 7740043993,
      "bInvAmt": 122.29,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 179830
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020609396,
      "InvDate": "06\/02\/2018",
      "InvAmt": 5262.12,
      "AbsInvAmt": 5262.12,
      "ApplyToInvAmt": 5262.12,
      "DiscAmt": 0,
      "TaxAmt": 685.37,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60907,
      "PmtDate": "06\/28\/2018",
      "PmtAmt": 196370.83,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40360,
      "InvDescr": "IC PO #40360",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020609396,
      "bInvAmt": 5262.12,
      "bDiscAmt": 0,
      "bTaxAmt": 685.37,
      "bNetAmt": 0,
      "APID": 179927
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020615265,
      "InvDate": "06\/13\/2018",
      "InvAmt": 159291.49,
      "AbsInvAmt": 159291.49,
      "ApplyToInvAmt": 159291.49,
      "DiscAmt": 0,
      "TaxAmt": 20747.03,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60907,
      "PmtDate": "06\/28\/2018",
      "PmtAmt": 196370.83,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40241,
      "InvDescr": "IC PO #40241",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020615265,
      "bInvAmt": 159291.49,
      "bDiscAmt": 0,
      "bTaxAmt": 20747.03,
      "bNetAmt": 0,
      "APID": 179933
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020618818,
      "InvDate": "08\/02\/2018",
      "InvAmt": 1835.53,
      "AbsInvAmt": 1835.53,
      "ApplyToInvAmt": 1835.53,
      "DiscAmt": 0,
      "TaxAmt": 239.07,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61194,
      "PmtDate": "10\/15\/2018",
      "PmtAmt": 82152.38,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40798,
      "InvDescr": "IC PO #40798",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020618818,
      "bInvAmt": 1835.53,
      "bDiscAmt": 0,
      "bTaxAmt": 239.07,
      "bNetAmt": 0,
      "APID": 179940
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020620169,
      "InvDate": "07\/31\/2018",
      "InvAmt": 31452.8,
      "AbsInvAmt": 31452.8,
      "ApplyToInvAmt": 31452.8,
      "DiscAmt": 0,
      "TaxAmt": 4096.6,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61060,
      "PmtDate": "08\/27\/2018",
      "PmtAmt": 36170.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40005,
      "InvDescr": "IC PO #40005",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020620169,
      "bInvAmt": 31452.8,
      "bDiscAmt": 0,
      "bTaxAmt": 4096.6,
      "bNetAmt": 0,
      "APID": 179945
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020621569,
      "InvDate": "09\/04\/2018",
      "InvAmt": 65108.64,
      "AbsInvAmt": 65108.64,
      "ApplyToInvAmt": 65108.64,
      "DiscAmt": 0,
      "TaxAmt": 8480.14,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61139,
      "PmtDate": "09\/24\/2018",
      "PmtAmt": 209306.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40917,
      "InvDescr": "IC PO #40917",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020621569,
      "bInvAmt": 65108.64,
      "bDiscAmt": 0,
      "bTaxAmt": 8480.14,
      "bNetAmt": 0,
      "APID": 179956
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020645570,
      "InvDate": "03\/25\/2019",
      "InvAmt": 648.46,
      "AbsInvAmt": 648.46,
      "ApplyToInvAmt": 648.46,
      "DiscAmt": 0,
      "TaxAmt": 84.46,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61670,
      "PmtDate": "04\/17\/2019",
      "PmtAmt": 648.46,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42011,
      "InvDescr": "IC PO #42011",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020645570,
      "bInvAmt": 648.46,
      "bDiscAmt": 0,
      "bTaxAmt": 84.46,
      "bNetAmt": 0,
      "APID": 179960
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020651613,
      "InvDate": "05\/24\/2019",
      "InvAmt": 13117.48,
      "AbsInvAmt": 13117.48,
      "ApplyToInvAmt": 13117.48,
      "DiscAmt": 0,
      "TaxAmt": 1674.58,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61826,
      "PmtDate": "06\/14\/2019",
      "PmtAmt": 15332.22,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42507,
      "InvDescr": "IC PO #42507",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020651613,
      "bInvAmt": 13117.48,
      "bDiscAmt": 0,
      "bTaxAmt": 1674.58,
      "bNetAmt": 0,
      "APID": 179967
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020657295,
      "InvDate": "07\/18\/2019",
      "InvAmt": 1395.57,
      "AbsInvAmt": 1395.57,
      "ApplyToInvAmt": 1395.57,
      "DiscAmt": 0,
      "TaxAmt": 181.77,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40025,
      "PmtDate": "08\/19\/2019",
      "PmtAmt": 1395.57,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 42794,
      "InvDescr": "IC PO #42794",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020657295,
      "bInvAmt": 1395.57,
      "bDiscAmt": 0,
      "bTaxAmt": 181.77,
      "bNetAmt": 0,
      "APID": 179974
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503380210,
      "InvDate": "07\/24\/2020",
      "InvAmt": 555,
      "AbsInvAmt": 555,
      "ApplyToInvAmt": 555,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351694,
      "PmtDate": "09\/01\/2020",
      "PmtAmt": 555,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503380210,
      "bInvAmt": 555,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180057
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60377956,
      "InvDate": "04\/03\/2018",
      "InvAmt": 5250.95,
      "AbsInvAmt": 5250.95,
      "ApplyToInvAmt": 5250.95,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 217120,
      "PmtDate": "05\/03\/2018",
      "PmtAmt": 5250.95,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39955,
      "InvDescr": "PO #39955",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60377956,
      "bInvAmt": 5250.95,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180073
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60497972,
      "InvDate": "03\/26\/2019",
      "InvAmt": 22994,
      "AbsInvAmt": 22994,
      "ApplyToInvAmt": -22994,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 220173,
      "PmtDate": "04\/25\/2019",
      "PmtAmt": 22994,
      "PmtTypeDesc": "CHECK",
      "PmtVoidDate": "05\/01\/2019",
      "PoNum": 41987,
      "InvDescr": "TRDO PO #41987",
      "VndType": "SUPPLIER",
      "APStatus": "Void",
      "DupeX": 1,
      "MatchInvNum": 60497972,
      "bInvAmt": 22994,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180081
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60497972,
      "InvDate": "03\/26\/2019",
      "InvAmt": 22994,
      "AbsInvAmt": 22994,
      "ApplyToInvAmt": 22994,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 30138,
      "PmtDate": "05\/06\/2019",
      "PmtAmt": 34070.08,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41987,
      "InvDescr": "TRDO PO #41987",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60497972,
      "bInvAmt": 22994,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180089
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 633988,
      "InvDate": "01\/01\/2018",
      "InvAmt": 28609.04,
      "AbsInvAmt": 28609.04,
      "ApplyToInvAmt": 28609.04,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216075,
      "PmtDate": "01\/25\/2018",
      "PmtAmt": 28609.04,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38828,
      "InvDescr": "PO #38828",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 633988,
      "bInvAmt": 28609.04,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180120
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634669,
      "InvDate": "01\/03\/2018",
      "InvAmt": 28609.04,
      "AbsInvAmt": 28609.04,
      "ApplyToInvAmt": 28609.04,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216553,
      "PmtDate": "03\/08\/2018",
      "PmtAmt": 28609.04,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38828,
      "InvDescr": "PO #38828",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634669,
      "bInvAmt": 28609.04,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180127
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 635996,
      "InvDate": "07\/02\/2018",
      "InvAmt": 28540.78,
      "AbsInvAmt": 28540.78,
      "ApplyToInvAmt": 28540.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 217874,
      "PmtDate": "07\/30\/2018",
      "PmtAmt": 28540.78,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40249,
      "InvDescr": "PO #40249",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 635996,
      "bInvAmt": 28540.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180134
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 636745,
      "InvDate": "10\/04\/2018",
      "InvAmt": 28540.78,
      "AbsInvAmt": 28540.78,
      "ApplyToInvAmt": 28540.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 218702,
      "PmtDate": "10\/30\/2018",
      "PmtAmt": 28540.78,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40249,
      "InvDescr": "PO #40249",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 636745,
      "bInvAmt": 28540.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 180142
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 7740057556,
      "InvDate": "04\/05\/2019",
      "InvAmt": 432.17,
      "AbsInvAmt": 432.17,
      "ApplyToInvAmt": 432.17,
      "DiscAmt": 0,
      "TaxAmt": 0.9,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61690,
      "PmtDate": "04\/25\/2019",
      "PmtAmt": 432.17,
      "PmtTypeDesc": "CHECK",
      "PoNum": 42334,
      "InvDescr": "IC PO #42334",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 7740057556,
      "bInvAmt": 432.17,
      "bDiscAmt": 0,
      "bTaxAmt": 0.9,
      "bNetAmt": 0,
      "APID": 180150
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503046365,
      "InvDate": "07\/04\/2019",
      "InvAmt": 925,
      "AbsInvAmt": 925,
      "ApplyToInvAmt": 925,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 30623,
      "PmtDate": "07\/30\/2019",
      "PmtAmt": 925,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41989,
      "InvDescr": "PO 41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503046365,
      "bInvAmt": 925,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 183118
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503421509,
      "InvDate": "09\/15\/2020",
      "InvAmt": 555,
      "AbsInvAmt": 555,
      "ApplyToInvAmt": 555,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351906,
      "PmtDate": "10\/14\/2020",
      "PmtAmt": 555,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503421509,
      "bInvAmt": 555,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 183127
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 25035293777,
      "InvDate": "01\/22\/2021",
      "InvAmt": 2035,
      "AbsInvAmt": 2035,
      "ApplyToInvAmt": 2035,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352521,
      "PmtDate": "02\/22\/2021",
      "PmtAmt": 3515,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41989,
      "InvDescr": "IC PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 25035293777,
      "bInvAmt": 2035,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 183133
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60578912,
      "InvDate": "02\/17\/2020",
      "InvAmt": 10715.67,
      "AbsInvAmt": 10715.67,
      "ApplyToInvAmt": 10715.67,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350937,
      "PmtDate": "03\/16\/2020",
      "PmtAmt": 10715.67,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43992,
      "InvDescr": "PO #43992",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60578912,
      "bInvAmt": 10715.67,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 167651
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634300,
      "InvDate": "02\/01\/2018",
      "InvAmt": 28609.04,
      "AbsInvAmt": 28609.04,
      "ApplyToInvAmt": 28609.04,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216456,
      "PmtDate": "03\/01\/2018",
      "PmtAmt": 28609.04,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38828,
      "InvDescr": "PO #38828",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634300,
      "bInvAmt": 28609.04,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 167702
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60390760,
      "InvDate": "06\/01\/2018",
      "InvAmt": -643.67,
      "AbsInvAmt": 643.67,
      "ApplyToInvAmt": -643.67,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "CREDIT",
      "PmtNum": 219017,
      "PmtDate": "12\/10\/2018",
      "PmtAmt": 578248.1,
      "PmtTypeDesc": "CHECK",
      "PoNum": 30410,
      "InvDescr": "PO #30410 \/ INVOICE #69128637",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60390760,
      "bInvAmt": -643.67,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175038
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60461548,
      "InvDate": "11\/13\/2018",
      "InvAmt": 578891.77,
      "AbsInvAmt": 578891.77,
      "ApplyToInvAmt": 578891.77,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219017,
      "PmtDate": "12\/10\/2018",
      "PmtAmt": 578248.1,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41402,
      "InvDescr": "PO #41402",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60461548,
      "bInvAmt": 578891.77,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175046
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60619268,
      "InvDate": "08\/20\/2020",
      "InvAmt": 6246.59,
      "AbsInvAmt": 6246.59,
      "ApplyToInvAmt": 6246.59,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 351998,
      "PmtDate": "11\/03\/2020",
      "PmtAmt": 12493.18,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43362,
      "InvDescr": "PO #43362",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60619268,
      "bInvAmt": 6246.59,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175050
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 60645010,
      "InvDate": "01\/02\/2021",
      "InvAmt": 269773.79,
      "AbsInvAmt": 269773.79,
      "ApplyToInvAmt": 269773.79,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352471,
      "PmtDate": "02\/08\/2021",
      "PmtAmt": 269773.79,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 45842,
      "InvDescr": "PO #45842",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60645010,
      "bInvAmt": 269773.79,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175056
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:372:P30392",
      "VndName": "HEWLETT PACKARD COMPANY",
      "InvNum": 638280,
      "InvDate": "03\/01\/2019",
      "InvAmt": 63767.74,
      "AbsInvAmt": 63767.74,
      "ApplyToInvAmt": 63767.74,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219982,
      "PmtDate": "03\/27\/2019",
      "PmtAmt": 69434.5,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41411,
      "InvDescr": "PO #41411",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 638280,
      "bInvAmt": 63767.74,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175062
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020621568,
      "InvDate": "09\/04\/2018",
      "InvAmt": 65108.64,
      "AbsInvAmt": 65108.64,
      "ApplyToInvAmt": 65108.64,
      "DiscAmt": 0,
      "TaxAmt": 8480.14,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61139,
      "PmtDate": "09\/24\/2018",
      "PmtAmt": 209306.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40920,
      "InvDescr": "IC PO #40920",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020621568,
      "bInvAmt": 65108.64,
      "bDiscAmt": 0,
      "bTaxAmt": 8480.14,
      "bNetAmt": 0,
      "APID": 175242
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020621957,
      "InvDate": "08\/29\/2018",
      "InvAmt": 146.76,
      "AbsInvAmt": 146.76,
      "ApplyToInvAmt": 146.76,
      "DiscAmt": 0,
      "TaxAmt": 19.11,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61139,
      "PmtDate": "09\/24\/2018",
      "PmtAmt": 209306.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 39972,
      "InvDescr": "PO #39972",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020621957,
      "bInvAmt": 146.76,
      "bDiscAmt": 0,
      "bTaxAmt": 19.11,
      "bNetAmt": 0,
      "APID": 175250
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020624503,
      "InvDate": "09\/14\/2018",
      "InvAmt": 1343.49,
      "AbsInvAmt": 1343.49,
      "ApplyToInvAmt": 1343.49,
      "DiscAmt": 0,
      "TaxAmt": 174.99,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61209,
      "PmtDate": "10\/22\/2018",
      "PmtAmt": 279214.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40956,
      "InvDescr": "IC PO #40956",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020624503,
      "bInvAmt": 1343.49,
      "bDiscAmt": 0,
      "bTaxAmt": 174.99,
      "bNetAmt": 0,
      "APID": 175258
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020625360,
      "InvDate": "09\/24\/2018",
      "InvAmt": 65108.64,
      "AbsInvAmt": 65108.64,
      "ApplyToInvAmt": 65108.64,
      "DiscAmt": 0,
      "TaxAmt": 8480.14,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61194,
      "PmtDate": "10\/15\/2018",
      "PmtAmt": 82152.38,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40921,
      "InvDescr": "IC PO #40921",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020625360,
      "bInvAmt": 65108.64,
      "bDiscAmt": 0,
      "bTaxAmt": 8480.14,
      "bNetAmt": 0,
      "APID": 175266
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020625646,
      "InvDate": "09\/26\/2018",
      "InvAmt": 10718.43,
      "AbsInvAmt": 10718.43,
      "ApplyToInvAmt": 10718.43,
      "DiscAmt": 0,
      "TaxAmt": 1396.03,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61194,
      "PmtDate": "10\/15\/2018",
      "PmtAmt": 82152.38,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41066,
      "InvDescr": "IC PO #41066",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020625646,
      "bInvAmt": 10718.43,
      "bDiscAmt": 0,
      "bTaxAmt": 1396.03,
      "bNetAmt": 0,
      "APID": 175274
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020625775,
      "InvDate": "09\/27\/2018",
      "InvAmt": 72756.53,
      "AbsInvAmt": 72756.53,
      "ApplyToInvAmt": 72756.53,
      "DiscAmt": 0,
      "TaxAmt": 9476.23,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61209,
      "PmtDate": "10\/22\/2018",
      "PmtAmt": 279214.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41087,
      "InvDescr": "IC PO #41087",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020625775,
      "bInvAmt": 72756.53,
      "bDiscAmt": 0,
      "bTaxAmt": 9476.23,
      "bNetAmt": 0,
      "APID": 175282
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020633514,
      "InvDate": "12\/12\/2018",
      "InvAmt": 7986.75,
      "AbsInvAmt": 7986.75,
      "ApplyToInvAmt": 7986.75,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61419,
      "PmtDate": "01\/08\/2019",
      "PmtAmt": 62595.18,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41494,
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020633514,
      "bInvAmt": 7986.75,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175291
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020635574,
      "InvDate": "12\/20\/2018",
      "InvAmt": 3965.95,
      "AbsInvAmt": 3965.95,
      "ApplyToInvAmt": 3965.95,
      "DiscAmt": 0,
      "TaxAmt": 516.55,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61419,
      "PmtDate": "01\/08\/2019",
      "PmtAmt": 62595.18,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41510,
      "InvDescr": "IC PO #41510",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020635574,
      "bInvAmt": 3965.95,
      "bDiscAmt": 0,
      "bTaxAmt": 516.55,
      "bNetAmt": 0,
      "APID": 175299
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020636662,
      "InvDate": "12\/28\/2018",
      "InvAmt": 6526.81,
      "AbsInvAmt": 6526.81,
      "ApplyToInvAmt": 6526.81,
      "DiscAmt": 0,
      "TaxAmt": 313.2,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61450,
      "PmtDate": "01\/16\/2019",
      "PmtAmt": 24385.99,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41682,
      "InvDescr": "PO #41682",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020636662,
      "bInvAmt": 6526.81,
      "bDiscAmt": 0,
      "bTaxAmt": 313.2,
      "bNetAmt": 0,
      "APID": 175310
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": "2020649291-A",
      "InvDate": "05\/22\/2019",
      "InvAmt": -7052,
      "AbsInvAmt": 7052,
      "ApplyToInvAmt": -7052,
      "DiscAmt": 0,
      "TaxAmt": -918.5,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtNum": 61929,
      "PmtDate": "07\/24\/2019",
      "PmtAmt": 268.4,
      "PmtTypeDesc": "CHECK",
      "InvDescr": "IC PO #41494",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020649291,
      "bInvAmt": -7052,
      "bDiscAmt": 0,
      "bTaxAmt": -918.5,
      "bNetAmt": 0,
      "APID": 175319
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020677149,
      "InvDate": "01\/09\/2020",
      "InvAmt": 1982.98,
      "AbsInvAmt": 1982.98,
      "ApplyToInvAmt": 1982.98,
      "DiscAmt": 0,
      "TaxAmt": 258.28,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40373,
      "PmtDate": "03\/09\/2020",
      "PmtAmt": 14056.73,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43833,
      "InvDescr": "IC PO #43833",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020677149,
      "bInvAmt": 1982.98,
      "bDiscAmt": 0,
      "bTaxAmt": 258.28,
      "bNetAmt": 0,
      "APID": 175327
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 1P8",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020682862,
      "InvDate": "02\/28\/2020",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "ApplyToInvAmt": 63193.44,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40388,
      "PmtDate": "03\/23\/2020",
      "PmtAmt": 63193.44,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PmtVoidDate": "04\/07\/2020",
      "PoNum": 44125,
      "InvDescr": "UPDATE PAYMENT METHOD",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "OFFSET",
      "DupeX": 1,
      "MatchInvNum": 2020682862,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175336
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 6636514,
      "InvDate": "09\/25\/2019",
      "InvAmt": -234220.44,
      "AbsInvAmt": 234220.44,
      "ApplyToInvAmt": -234220.44,
      "DiscAmt": 0,
      "TaxAmt": -26128.44,
      "CurrCode": "CAD",
      "FY": 2019,
      "InvTypeDesc": "CREDIT",
      "PmtNum": 80293,
      "PmtDate": "01\/14\/2020",
      "PmtAmt": 51657.14,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41385,
      "InvDescr": "RELATED INVOICE: 6628579 BUT HAS BEEN PAID, REFER TO INV: 6636515",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "OFFSET",
      "DupeX": 1,
      "MatchInvNum": 6636514,
      "bInvAmt": -234339.03,
      "bDiscAmt": 0,
      "bTaxAmt": -26141.67,
      "bNetAmt": 0,
      "APID": 175346
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 6638318,
      "InvDate": "12\/11\/2019",
      "InvAmt": 77681.61,
      "AbsInvAmt": 77681.61,
      "ApplyToInvAmt": 77681.61,
      "DiscAmt": 0,
      "TaxAmt": 8323.01,
      "CurrCode": "CAD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 80293,
      "PmtDate": "01\/14\/2020",
      "PmtAmt": 51657.14,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43674,
      "InvDescr": "IC PO #43674",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 6638318,
      "bInvAmt": 77720.94,
      "bDiscAmt": 0,
      "bTaxAmt": 8327.22,
      "bNetAmt": 0,
      "APID": 175353
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503019087,
      "InvDate": "06\/03\/2019",
      "InvAmt": 11470,
      "AbsInvAmt": 11470,
      "ApplyToInvAmt": 11470,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 30435,
      "PmtDate": "06\/27\/2019",
      "PmtAmt": 11470,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41989,
      "InvDescr": "PO 41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503019087,
      "bInvAmt": 11470,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175443
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2503113800,
      "InvDate": "09\/18\/2019",
      "InvAmt": 21592.94,
      "AbsInvAmt": 21592.94,
      "ApplyToInvAmt": 21592.94,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350194,
      "PmtDate": "10\/14\/2019",
      "PmtAmt": 135427.29,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43056,
      "InvDescr": "PO #43056",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2503113800,
      "bInvAmt": 21592.94,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175451
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60464325,
      "InvDate": "11\/19\/2018",
      "InvAmt": 7137.6,
      "AbsInvAmt": 7137.6,
      "ApplyToInvAmt": 7137.6,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219074,
      "PmtDate": "12\/17\/2018",
      "PmtAmt": 7137.6,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41340,
      "InvDescr": "PO #41340",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60464325,
      "bInvAmt": 7137.6,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175524
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60544051,
      "InvDate": "10\/03\/2019",
      "InvAmt": 499096.4,
      "AbsInvAmt": 499096.4,
      "ApplyToInvAmt": 499096.4,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 350222,
      "PmtDate": "10\/21\/2019",
      "PmtAmt": 499096.4,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 43042,
      "InvDescr": "PO #43042",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60544051,
      "bInvAmt": 499096.4,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170589
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60642413,
      "InvDate": "12\/16\/2020",
      "InvAmt": 54227.6,
      "AbsInvAmt": 54227.6,
      "ApplyToInvAmt": 54227.6,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352346,
      "PmtDate": "01\/19\/2021",
      "PmtAmt": 108456.22,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 45446,
      "InvDescr": "PO #45446",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60642413,
      "bInvAmt": 54227.6,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170597
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 643712,
      "InvDate": "11\/03\/2020",
      "InvAmt": 281996.6,
      "AbsInvAmt": 281996.6,
      "ApplyToInvAmt": 281996.6,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352166,
      "PmtDate": "12\/07\/2020",
      "PmtAmt": 281996.6,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 45445,
      "InvDescr": "PO #45445",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 643712,
      "bInvAmt": 281996.6,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 170636
     },
     {
      "DataSource": "D220304",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q1 NOV21-JAN22",
      "InvDate": "01\/31\/2022",
      "InvAmt": 73956.86,
      "AbsInvAmt": 73956.86,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2022,
      "PmtNum": 10599937,
      "PmtDate": "02\/28\/2022",
      "PmtAmt": 73956.86,
      "PmtTypeDesc": "CHECK",
      "bInvAmt": 73956.86,
      "bDiscAmt": 0,
      "bTaxAmt": 12,
      "APID": 90927
     },
     {
      "DataSource": "D220304",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q3 MAY-JULY 19",
      "InvDate": "07\/31\/2019",
      "InvAmt": 125915.09,
      "AbsInvAmt": 125915.09,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "PmtNum": 10305408,
      "PmtDate": "09\/11\/2019",
      "PmtAmt": 125915.09,
      "PmtTypeDesc": "CHECK",
      "bInvAmt": 125915.09,
      "bDiscAmt": 0,
      "bTaxAmt": 3,
      "APID": 252877
     },
     {
      "DataSource": "D220304",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q1 NOV-JAN 19",
      "InvDate": "01\/31\/2019",
      "InvAmt": 165686.09,
      "AbsInvAmt": 165686.09,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "PmtNum": 10233190,
      "PmtDate": "03\/13\/2019",
      "PmtAmt": 165686.09,
      "PmtTypeDesc": "CHECK",
      "bInvAmt": 165686.09,
      "bDiscAmt": 0,
      "bTaxAmt": 1,
      "APID": 601637
     },
     {
      "DataSource": "D220304",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q2 FEB-APR 19",
      "InvDate": "04\/30\/2019",
      "InvAmt": 83149.71,
      "AbsInvAmt": 83149.71,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "PmtNum": 10268960,
      "PmtDate": "06\/10\/2019",
      "PmtAmt": 83149.71,
      "PmtTypeDesc": "CHECK",
      "bInvAmt": 83149.71,
      "bDiscAmt": 0,
      "bTaxAmt": 2,
      "APID": 636882
     },
     {
      "DataSource": "D220304",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q4 AUG-OCT 19",
      "InvDate": "10\/31\/2019",
      "InvAmt": 96590.35,
      "AbsInvAmt": 96590.35,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "PmtNum": 10338490,
      "PmtDate": "12\/09\/2019",
      "PmtAmt": 96590.35,
      "PmtTypeDesc": "CHECK",
      "bInvAmt": 96590.35,
      "bDiscAmt": 0,
      "bTaxAmt": 4,
      "APID": 691298
     },
     {
      "DataSource": "E201111",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q2 FEB-APRL 20",
      "InvDate": "04\/30\/2020",
      "InvAmt": 54889.94,
      "AbsInvAmt": 54889.94,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "PmtNum": 10402577,
      "PmtDate": "06\/25\/2020",
      "PmtAmt": 54889.94,
      "PmtTypeDesc": "Wire",
      "bInvAmt": 54889.94,
      "bDiscAmt": 0,
      "bTaxAmt": 2,
      "APID": 1315123
     },
     {
      "DataSource": "E210203",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q4 AUG20-OCT20",
      "InvDate": "10\/31\/2020",
      "InvAmt": 79444,
      "AbsInvAmt": 79444,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "PmtNum": 10473970,
      "PmtDate": "02\/02\/2021",
      "PmtAmt": 79444,
      "PmtTypeDesc": "Wire",
      "bInvAmt": 79444,
      "bDiscAmt": 0,
      "bTaxAmt": 42,
      "APID": 1367793
     },
     {
      "DataSource": "E210419",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q1 NOV20-JAN21",
      "InvDate": "03\/09\/2021",
      "InvAmt": 74569,
      "AbsInvAmt": 74569,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "PmtDate": "01\/01\/1900",
      "PmtAmt": 74569,
      "PmtTypeDesc": "Wire",
      "bInvAmt": 74569,
      "bDiscAmt": 0,
      "bTaxAmt": 12,
      "APID": 1534569
     },
     {
      "DataSource": "D210707",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q2 FEB21-APR21",
      "InvDate": "05\/31\/2021",
      "InvAmt": 109377,
      "AbsInvAmt": 109377,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "PmtDate": "01\/01\/1900",
      "PmtAmt": 109377,
      "PmtTypeDesc": "Wire",
      "bInvAmt": 109377,
      "bDiscAmt": 0,
      "bTaxAmt": 22,
      "APID": 1689594
     },
     {
      "DataSource": "D211007",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q3 MAY21-JUL21",
      "InvDate": "07\/31\/2021",
      "InvAmt": 95183,
      "AbsInvAmt": 95183,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "PmtNum": 10550903,
      "PmtDate": "09\/28\/2021",
      "PmtAmt": 95183,
      "PmtTypeDesc": "Wire",
      "bInvAmt": 95183,
      "bDiscAmt": 0,
      "bTaxAmt": 32,
      "APID": 1821081
     },
     {
      "DataSource": "D211228",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "TBM REBATE Q4 AUG21-OCT21",
      "InvDate": "10\/31\/2021",
      "InvAmt": 83425.4,
      "AbsInvAmt": 83425.4,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "PmtNum": 10572512,
      "PmtDate": "12\/03\/2021",
      "PmtAmt": 83425.4,
      "PmtTypeDesc": "Wire",
      "bInvAmt": 83425.4,
      "bDiscAmt": 0,
      "bTaxAmt": 42,
      "APID": 1944753
     },
     {
      "DataSource": "D211228",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-097-36739",
      "InvDate": "10\/28\/2022",
      "InvAmt": 91.06,
      "AbsInvAmt": 91.06,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771915,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 91.06,
      "ConcatenatedSegments": 0,
      "bInvAmt": 91.06,
      "bDiscAmt": 0,
      "bTaxAmt": 209736739,
      "APID": 516776
     },
     {
      "DataSource": "D211229",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-927-94378",
      "InvDate": "10\/27\/2022",
      "InvAmt": 22217.85,
      "AbsInvAmt": 22217.85,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771921,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 22217.85,
      "ConcatenatedSegments": 0,
      "bInvAmt": 22217.85,
      "bDiscAmt": 0,
      "bTaxAmt": 792794378,
      "APID": 674813
     },
     {
      "DataSource": "D211230",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-896-09455",
      "InvDate": "10\/27\/2022",
      "InvAmt": 375.58,
      "AbsInvAmt": 375.58,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679065,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 440.01,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14730182,
      "DupeX": "UTL",
      "BusinessUnitName": 7500,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 750,
      "PaymentTermsName": 375.58,
      "ConcatenatedSegments": 0,
      "bInvAmt": 375.58,
      "bDiscAmt": 0,
      "bTaxAmt": 789609455,
      "APID": 588361
     },
     {
      "DataSource": "D211231",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-928-07079",
      "InvDate": "10\/27\/2022",
      "InvAmt": 11.71,
      "AbsInvAmt": 11.71,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680755,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 559.51,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14767054,
      "DupeX": "UTL",
      "BusinessUnitName": 8555,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 11.71,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.71,
      "bDiscAmt": 0,
      "bTaxAmt": 792807079,
      "APID": 1982680
     },
     {
      "DataSource": "D211232",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-927-33768",
      "InvDate": "10\/27\/2022",
      "InvAmt": 244.71,
      "AbsInvAmt": 244.71,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680755,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 559.51,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14767018,
      "DupeX": "UTL",
      "BusinessUnitName": 8559,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 244.71,
      "ConcatenatedSegments": 0,
      "bInvAmt": 244.71,
      "bDiscAmt": 0,
      "bTaxAmt": 792733768,
      "APID": 1968399
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-81943",
      "InvDate": "10\/26\/2022",
      "InvAmt": 581.71,
      "AbsInvAmt": 581.71,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/31\/2022",
      "VndType": "PV",
      "APStatus": 2309048,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 581.71,
      "ConcatenatedSegments": 0,
      "bInvAmt": 581.71,
      "bDiscAmt": 0,
      "bTaxAmt": 792681943,
      "APID": 2001177
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-76794",
      "InvDate": "10\/26\/2022",
      "InvAmt": 33.36,
      "AbsInvAmt": 33.36,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308082,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 33.36,
      "ConcatenatedSegments": 0,
      "bInvAmt": 33.36,
      "bDiscAmt": 0,
      "bTaxAmt": 792676794,
      "APID": 2001168
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-71583",
      "InvDate": "10\/26\/2022",
      "InvAmt": 149.58,
      "AbsInvAmt": 149.58,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308076,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 149.58,
      "ConcatenatedSegments": 0,
      "bInvAmt": 149.58,
      "bDiscAmt": 0,
      "bTaxAmt": 792671583,
      "APID": 2001160
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-67544",
      "InvDate": "10\/26\/2022",
      "InvAmt": 7.53,
      "AbsInvAmt": 7.53,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308080,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 7.53,
      "ConcatenatedSegments": 0,
      "bInvAmt": 7.53,
      "bDiscAmt": 0,
      "bTaxAmt": 792667544,
      "APID": 2001153
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-99601",
      "InvDate": "10\/26\/2022",
      "InvAmt": 33.68,
      "AbsInvAmt": 33.68,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308077,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 33.68,
      "ConcatenatedSegments": 0,
      "bInvAmt": 33.68,
      "bDiscAmt": 0,
      "bTaxAmt": 792699601,
      "APID": 2001193
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-52756",
      "InvDate": "10\/26\/2022",
      "InvAmt": 96.39,
      "AbsInvAmt": 96.39,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308078,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 96.39,
      "ConcatenatedSegments": 0,
      "bInvAmt": 96.39,
      "bDiscAmt": 0,
      "bTaxAmt": 792652756,
      "APID": 1998460
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-927-04842",
      "InvDate": "10\/26\/2022",
      "InvAmt": 7.53,
      "AbsInvAmt": 7.53,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308083,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 7.53,
      "ConcatenatedSegments": 0,
      "bInvAmt": 7.53,
      "bDiscAmt": 0,
      "bTaxAmt": 792704842,
      "APID": 2001201
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-48290",
      "InvDate": "10\/26\/2022",
      "InvAmt": 38.29,
      "AbsInvAmt": 38.29,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308079,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 38.29,
      "ConcatenatedSegments": 0,
      "bInvAmt": 38.29,
      "bDiscAmt": 0,
      "bTaxAmt": 792648290,
      "APID": 1998455
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-39415",
      "InvDate": "10\/26\/2022",
      "InvAmt": 49.01,
      "AbsInvAmt": 49.01,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308075,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 49.01,
      "ConcatenatedSegments": 0,
      "bInvAmt": 49.01,
      "bDiscAmt": 0,
      "bTaxAmt": 792639415,
      "APID": 1998447
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-25148",
      "InvDate": "10\/26\/2022",
      "InvAmt": 161.15,
      "AbsInvAmt": 161.15,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/31\/2022",
      "VndType": "PV",
      "APStatus": 2309046,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 161.15,
      "ConcatenatedSegments": 0,
      "bInvAmt": 161.15,
      "bDiscAmt": 0,
      "bTaxAmt": 792625148,
      "APID": 1998439
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-18379",
      "InvDate": "10\/26\/2022",
      "InvAmt": 46.11,
      "AbsInvAmt": 46.11,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308081,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 46.11,
      "ConcatenatedSegments": 0,
      "bInvAmt": 46.11,
      "bDiscAmt": 0,
      "bTaxAmt": 792618379,
      "APID": 1998432
     },
     {
      "DataSource": "D211232",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-926-21855",
      "InvDate": "10\/26\/2022",
      "InvAmt": 10.11,
      "AbsInvAmt": 10.11,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836438,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 10.11,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14772841,
      "DupeX": "UTL",
      "BusinessUnitName": 8515,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 10.11,
      "ConcatenatedSegments": 0,
      "bInvAmt": 10.11,
      "bDiscAmt": 0,
      "bTaxAmt": 792621855,
      "APID": 1984768
     },
     {
      "DataSource": "D211232",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-928-02883",
      "InvDate": "10\/26\/2022",
      "InvAmt": 11.27,
      "AbsInvAmt": 11.27,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680755,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 559.51,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14767014,
      "DupeX": "UTL",
      "BusinessUnitName": 8551,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 11.27,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.27,
      "bDiscAmt": 0,
      "bTaxAmt": 792802883,
      "APID": 1970054
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-65417",
      "InvDate": "10\/26\/2022",
      "InvAmt": 124.01,
      "AbsInvAmt": 124.01,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308074,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 124.01,
      "ConcatenatedSegments": 0,
      "bInvAmt": 124.01,
      "bDiscAmt": 0,
      "bTaxAmt": 792665417,
      "APID": 2001146
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-927-05614",
      "InvDate": "10\/26\/2022",
      "InvAmt": 52.71,
      "AbsInvAmt": 52.71,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308084,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 52.71,
      "ConcatenatedSegments": 0,
      "bInvAmt": 52.71,
      "bDiscAmt": 0,
      "bTaxAmt": 792705614,
      "APID": 2001208
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-927-08493",
      "InvDate": "10\/26\/2022",
      "InvAmt": 25.16,
      "AbsInvAmt": 25.16,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/31\/2022",
      "VndType": "PV",
      "APStatus": 2309049,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 25.16,
      "ConcatenatedSegments": 0,
      "bInvAmt": 25.16,
      "bDiscAmt": 0,
      "bTaxAmt": 792708493,
      "APID": 2001217
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-927-08496",
      "InvDate": "10\/26\/2022",
      "InvAmt": 442.77,
      "AbsInvAmt": 442.77,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/31\/2022",
      "VndType": "PV",
      "APStatus": 2309050,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 442.77,
      "ConcatenatedSegments": 0,
      "bInvAmt": 442.77,
      "bDiscAmt": 0,
      "bTaxAmt": 792708496,
      "APID": 2001225
     },
     {
      "DataSource": "D211232",
      "Co": "Bedrock Facilities",
      "VndNum": 130185,
      "VndName": "FEDEX GROUND INC",
      "InvNum": "7-926-69914",
      "InvDate": "10\/26\/2022",
      "InvAmt": 16.23,
      "AbsInvAmt": 16.23,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680760,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 56.67,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14768565,
      "DupeX": "UTL",
      "MatchInvNum": "7-926-69914",
      "BusinessUnitName": 68001726,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 680,
      "PaymentTermsName": 16.23,
      "ConcatenatedSegments": 0,
      "bInvAmt": 16.23,
      "bDiscAmt": 0,
      "bTaxAmt": 792669914,
      "APID": 1900527
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-926-97370",
      "InvDate": "10\/26\/2022",
      "InvAmt": 3068.69,
      "AbsInvAmt": 3068.69,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/31\/2022",
      "VndType": "PV",
      "APStatus": 2309051,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 3068.69,
      "ConcatenatedSegments": 0,
      "bInvAmt": 3068.69,
      "bDiscAmt": 0,
      "bTaxAmt": 792697370,
      "APID": 2001185
     },
     {
      "DataSource": "D211232",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-925-63905",
      "InvDate": "10\/25\/2022",
      "InvAmt": 12.32,
      "AbsInvAmt": 12.32,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680755,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 559.51,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14770217,
      "DupeX": "UTL",
      "BusinessUnitName": 25012273,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 325,
      "PaymentTermsName": 12.32,
      "ConcatenatedSegments": 0,
      "bInvAmt": 12.32,
      "bDiscAmt": 0,
      "bTaxAmt": 792563905,
      "APID": 1993018
     },
     {
      "DataSource": "D211233",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-924-82794",
      "InvDate": "10\/25\/2022",
      "InvAmt": 27.81,
      "AbsInvAmt": 27.81,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836435,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 27.81,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771153,
      "DupeX": "UTL",
      "MatchInvNum": "7-924-82794",
      "BusinessUnitName": 8635,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 27.81,
      "ConcatenatedSegments": 0,
      "bInvAmt": 27.81,
      "bDiscAmt": 0,
      "bTaxAmt": 792482794,
      "APID": 1989172
     },
     {
      "DataSource": "D211234",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-925-03564",
      "InvDate": "10\/25\/2022",
      "InvAmt": 57.52,
      "AbsInvAmt": 57.52,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836437,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 57.52,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14772702,
      "DupeX": "UTL",
      "MatchInvNum": "7-925-03564",
      "BusinessUnitName": 86303040,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 57.52,
      "ConcatenatedSegments": 0,
      "bInvAmt": 57.52,
      "bDiscAmt": 0,
      "bTaxAmt": 792503564,
      "APID": 1981955
     },
     {
      "DataSource": "D211235",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-925-24797",
      "InvDate": "10\/25\/2022",
      "InvAmt": 114.43,
      "AbsInvAmt": 114.43,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680250,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 191.72,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14765575,
      "DupeX": "UTL",
      "BusinessUnitName": 48848888,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 114.43,
      "ConcatenatedSegments": 0,
      "bInvAmt": 114.43,
      "bDiscAmt": 0,
      "bTaxAmt": 792524797,
      "APID": 2018596
     },
     {
      "DataSource": "D211236",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-924-78552",
      "InvDate": "10\/25\/2022",
      "InvAmt": 114.07,
      "AbsInvAmt": 114.07,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680757,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 114.07,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14772923,
      "DupeX": "UTL",
      "BusinessUnitName": 8519,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 114.07,
      "ConcatenatedSegments": 0,
      "bInvAmt": 114.07,
      "bDiscAmt": 0,
      "bTaxAmt": 792478552,
      "APID": 2018090
     },
     {
      "DataSource": "D211237",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-921-26339",
      "InvDate": "10\/21\/2022",
      "InvAmt": 14.47,
      "AbsInvAmt": 14.47,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679067,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 24.86,
      "PmtNum": "PK",
      "PmtAmt": "0CPO8158",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14762565,
      "DupeX": "UTL",
      "BusinessUnitName": 5239,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 553,
      "PaymentTermsName": 14.47,
      "ConcatenatedSegments": 0,
      "bInvAmt": 14.47,
      "bDiscAmt": 0,
      "bTaxAmt": 792126339,
      "APID": 674806
     },
     {
      "DataSource": "D211238",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-095-16810",
      "InvDate": "10\/21\/2022",
      "InvAmt": 87.02,
      "AbsInvAmt": 87.02,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771901,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 87.02,
      "ConcatenatedSegments": 0,
      "bInvAmt": 87.02,
      "bDiscAmt": 0,
      "bTaxAmt": 209516810,
      "APID": 686410
     },
     {
      "DataSource": "D211239",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-921-24621",
      "InvDate": "10\/21\/2022",
      "InvAmt": 59.09,
      "AbsInvAmt": 59.09,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680754,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 59.09,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773863,
      "DupeX": "UTL",
      "BusinessUnitName": 48848864,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 59.09,
      "ConcatenatedSegments": 0,
      "bInvAmt": 59.09,
      "bDiscAmt": 0,
      "bTaxAmt": 792124621,
      "APID": 1987624
     },
     {
      "DataSource": "D211240",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-889-30378",
      "InvDate": "10\/20\/2022",
      "InvAmt": 22.37,
      "AbsInvAmt": 22.37,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677049,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 22.37,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/12\/2022",
      "VndType": "P5",
      "APStatus": 14734747,
      "DupeX": "UTL",
      "BusinessUnitName": 48848240,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 750,
      "PaymentTermsName": 22.37,
      "ConcatenatedSegments": 0,
      "bInvAmt": 22.37,
      "bDiscAmt": 0,
      "bTaxAmt": 788930378,
      "APID": 587388
     },
     {
      "DataSource": "D211241",
      "Co": "Bedrock Facilities",
      "VndNum": 11071,
      "VndName": "FEDEX - PASADENA",
      "InvNum": "7-920-67322",
      "InvDate": "10\/20\/2022",
      "InvAmt": 31.59,
      "AbsInvAmt": 31.59,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680751,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 31.59,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14772823,
      "DupeX": "UTL",
      "BusinessUnitName": 48846490,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 31.59,
      "ConcatenatedSegments": 0,
      "bInvAmt": 31.59,
      "bDiscAmt": 0,
      "bTaxAmt": 792067322,
      "APID": 517137
     },
     {
      "DataSource": "D211242",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-920-92501",
      "InvDate": "10\/20\/2022",
      "InvAmt": 16177.08,
      "AbsInvAmt": 16177.08,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771924,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 16177.08,
      "ConcatenatedSegments": 0,
      "bInvAmt": 16177.08,
      "bDiscAmt": 0,
      "bTaxAmt": 792092501,
      "APID": 686426
     },
     {
      "DataSource": "D211243",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-920-04273",
      "InvDate": "10\/20\/2022",
      "InvAmt": 81.57,
      "AbsInvAmt": 81.57,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679060,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 368.6,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14759233,
      "DupeX": "UTL",
      "BusinessUnitName": 8559,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 81.57,
      "ConcatenatedSegments": 0,
      "bInvAmt": 81.57,
      "bDiscAmt": 0,
      "bTaxAmt": 792004273,
      "APID": 1968391
     },
     {
      "DataSource": "D211244",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "9-636-82240",
      "InvDate": "10\/20\/2022",
      "InvAmt": 1257.48,
      "AbsInvAmt": 1257.48,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771885,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 1257.48,
      "ConcatenatedSegments": 0,
      "bInvAmt": 1257.48,
      "bDiscAmt": 0,
      "bTaxAmt": 963682240,
      "APID": 1965595
     },
     {
      "DataSource": "D211245",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-921-60955",
      "InvDate": "10\/20\/2022",
      "InvAmt": 11.27,
      "AbsInvAmt": 11.27,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680578,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 215.54,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/30\/2022",
      "VndType": "P5",
      "APStatus": 14769755,
      "DupeX": "UTL",
      "MatchInvNum": "INVOICE: 7-921-60955",
      "BusinessUnitName": 8539,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 11.27,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.27,
      "bDiscAmt": 0,
      "bTaxAmt": 792160955,
      "APID": 1978999
     },
     {
      "DataSource": "D211246",
      "Co": "Bedrock Facilities",
      "VndNum": 130185,
      "VndName": "FEDEX GROUND INC",
      "InvNum": "7-920-73838",
      "InvDate": "10\/20\/2022",
      "InvAmt": 88.52,
      "AbsInvAmt": 88.52,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680252,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 341.35,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14765004,
      "DupeX": "UTL",
      "MatchInvNum": "7-920-73838",
      "BusinessUnitName": 48842150,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 685,
      "PaymentTermsName": 88.52,
      "ConcatenatedSegments": 0,
      "bInvAmt": 88.52,
      "bDiscAmt": 0,
      "bTaxAmt": 792073838,
      "APID": 1973709
     },
     {
      "DataSource": "D211247",
      "Co": "Bedrock Facilities",
      "VndNum": 11071,
      "VndName": "FEDEX - PASADENA",
      "InvNum": "7-920-2101",
      "InvDate": "10\/20\/2022",
      "InvAmt": 22.38,
      "AbsInvAmt": 22.38,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677961,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 22.38,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/20\/2022",
      "VndType": "P5",
      "APStatus": 14752891,
      "DupeX": "UTL",
      "BusinessUnitName": 48848843,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 22.38,
      "ConcatenatedSegments": 0,
      "bInvAmt": 22.38,
      "bDiscAmt": 0,
      "bTaxAmt": 79202101,
      "APID": 1970207
     },
     {
      "DataSource": "D211248",
      "Co": "Bedrock Facilities",
      "VndNum": 1107630,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "9-636-74752",
      "InvDate": "10\/19\/2022",
      "InvAmt": 13.58,
      "AbsInvAmt": 13.58,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "FY": "01\/01\/1900",
      "InvTypeDesc": 275.74,
      "PmtAmt": 52212516,
      "PmtTypeDesc": "GEN",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14768092,
      "DupeX": "GEN",
      "BusinessUnitName": 48846110,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 13.58,
      "ConcatenatedSegments": 0,
      "bInvAmt": 13.58,
      "bDiscAmt": 0,
      "bTaxAmt": 963674752,
      "APID": 1958026
     },
     {
      "DataSource": "D211249",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-72090",
      "InvDate": "10\/19\/2022",
      "InvAmt": 218.14,
      "AbsInvAmt": 218.14,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306942,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 218.14,
      "ConcatenatedSegments": 0,
      "bInvAmt": 218.14,
      "bDiscAmt": 0,
      "bTaxAmt": 791972090,
      "APID": 2025546
     },
     {
      "DataSource": "D211250",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-094-97109",
      "InvDate": "10\/19\/2022",
      "InvAmt": 32.73,
      "AbsInvAmt": 32.73,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680578,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 215.54,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14766922,
      "DupeX": "UTL",
      "MatchInvNum": "INVOICE: 2-094-97109",
      "BusinessUnitName": 8538,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 32.73,
      "ConcatenatedSegments": 0,
      "bInvAmt": 32.73,
      "bDiscAmt": 0,
      "bTaxAmt": 209497109,
      "APID": 1989343
     },
     {
      "DataSource": "D211251",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-094-45857",
      "InvDate": "10\/19\/2022",
      "InvAmt": 60.31,
      "AbsInvAmt": 60.31,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833991,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 60.31,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/24\/2022",
      "VndType": "P5",
      "APStatus": 14756083,
      "DupeX": "UTL",
      "BusinessUnitName": 86679930,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 60.31,
      "ConcatenatedSegments": 0,
      "bInvAmt": 60.31,
      "bDiscAmt": 0,
      "bTaxAmt": 209445857,
      "APID": 1969963
     },
     {
      "DataSource": "D211252",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-919-63691",
      "InvDate": "10\/19\/2022",
      "InvAmt": 50.14,
      "AbsInvAmt": 50.14,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680755,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 559.51,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14766159,
      "DupeX": "UTL",
      "BusinessUnitName": 48845290,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 50.14,
      "ConcatenatedSegments": 0,
      "bInvAmt": 50.14,
      "bDiscAmt": 0,
      "bTaxAmt": 791963691,
      "APID": 1970046
     },
     {
      "DataSource": "D211253",
      "Co": "Bedrock Facilities",
      "VndNum": 432370,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-919-87732",
      "InvDate": "10\/19\/2022",
      "InvAmt": 66.27,
      "AbsInvAmt": 66.27,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679061,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 267.08,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14761585,
      "DupeX": "UTL",
      "BusinessUnitName": 48845320,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 66.27,
      "ConcatenatedSegments": 0,
      "bInvAmt": 66.27,
      "bDiscAmt": 0,
      "bTaxAmt": 791987732,
      "APID": 1968432
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-72080",
      "InvDate": "10\/19\/2022",
      "InvAmt": 14.94,
      "AbsInvAmt": 14.94,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306944,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 14.94,
      "ConcatenatedSegments": 0,
      "bInvAmt": 14.94,
      "bDiscAmt": 0,
      "bTaxAmt": 791972080,
      "APID": 2025538
     },
     {
      "DataSource": "GCA221104",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-75119",
      "InvDate": "10\/19\/2022",
      "InvAmt": 155.89,
      "AbsInvAmt": 155.89,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306948,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 155.89,
      "ConcatenatedSegments": 0,
      "bInvAmt": 155.89,
      "bDiscAmt": 0,
      "bTaxAmt": 791975119,
      "APID": 1998425
     },
     {
      "DataSource": "D211253",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-094-46421",
      "InvDate": "10\/19\/2022",
      "InvAmt": 646.27,
      "AbsInvAmt": 646.27,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833990,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 646.27,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/24\/2022",
      "VndType": "P5",
      "APStatus": 14756082,
      "DupeX": "UTL",
      "BusinessUnitName": 86679930,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 646.27,
      "ConcatenatedSegments": 0,
      "bInvAmt": 646.27,
      "bDiscAmt": 0,
      "bTaxAmt": 209446421,
      "APID": 1984713
     },
     {
      "DataSource": "D211254",
      "Co": "Bedrock Facilities",
      "VndNum": 7185930,
      "VndName": "FEDEX (SSC ACCT. #3702-0848-4)",
      "InvNum": "7-919-13888",
      "InvDate": "10\/19\/2022",
      "InvAmt": 109.42,
      "AbsInvAmt": 109.42,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836443,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 701.14,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773802,
      "DupeX": "UTL",
      "BusinessUnitName": 48845030,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 109.42,
      "ConcatenatedSegments": 0,
      "bInvAmt": 109.42,
      "bDiscAmt": 0,
      "bTaxAmt": 791913888,
      "APID": 1994779
     },
     {
      "DataSource": "D211255",
      "Co": "Bedrock Facilities",
      "VndNum": 1107630,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-919-38117",
      "InvDate": "10\/19\/2022",
      "InvAmt": 21.98,
      "AbsInvAmt": 21.98,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "FY": "01\/01\/1900",
      "InvTypeDesc": 275.74,
      "PmtAmt": 52212515,
      "PmtTypeDesc": "GEN",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14768093,
      "DupeX": "GEN",
      "BusinessUnitName": 48846110,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 21.98,
      "ConcatenatedSegments": 0,
      "bInvAmt": 21.98,
      "bDiscAmt": 0,
      "bTaxAmt": 791938117,
      "APID": 1958018
     },
     {
      "DataSource": "D211256",
      "Co": "Bedrock Facilities",
      "VndNum": 7185930,
      "VndName": "FEDEX (SSC ACCT. #3702-0848-4)",
      "InvNum": "9-636-73594",
      "InvDate": "10\/19\/2022",
      "InvAmt": 3.49,
      "AbsInvAmt": 3.49,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 834532,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 135.08,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14765669,
      "DupeX": "UTL",
      "BusinessUnitName": 48845000,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 3.49,
      "ConcatenatedSegments": 0,
      "bInvAmt": 3.49,
      "bDiscAmt": 0,
      "bTaxAmt": 963673594,
      "APID": 1994786
     },
     {
      "DataSource": "D211257",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-918-84822",
      "InvDate": "10\/19\/2022",
      "InvAmt": 195.09,
      "AbsInvAmt": 195.09,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308089,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 195.09,
      "ConcatenatedSegments": 0,
      "bInvAmt": 195.09,
      "bDiscAmt": 0,
      "bTaxAmt": 791884822,
      "APID": 2025469
     },
     {
      "DataSource": "D211258",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-918-90642",
      "InvDate": "10\/19\/2022",
      "InvAmt": 195.48,
      "AbsInvAmt": 195.48,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306941,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 195.48,
      "ConcatenatedSegments": 0,
      "bInvAmt": 195.48,
      "bDiscAmt": 0,
      "bTaxAmt": 791890642,
      "APID": 2025476
     },
     {
      "DataSource": "D211259",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-918-94116",
      "InvDate": "10\/19\/2022",
      "InvAmt": 37.37,
      "AbsInvAmt": 37.37,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306939,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 37.37,
      "ConcatenatedSegments": 0,
      "bInvAmt": 37.37,
      "bDiscAmt": 0,
      "bTaxAmt": 791894116,
      "APID": 2025482
     },
     {
      "DataSource": "D211260",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-07900",
      "InvDate": "10\/19\/2022",
      "InvAmt": 62.35,
      "AbsInvAmt": 62.35,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306940,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 62.35,
      "ConcatenatedSegments": 0,
      "bInvAmt": 62.35,
      "bDiscAmt": 0,
      "bTaxAmt": 791907900,
      "APID": 2025490
     },
     {
      "DataSource": "D211261",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-11918",
      "InvDate": "10\/19\/2022",
      "InvAmt": 98.87,
      "AbsInvAmt": 98.87,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306938,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 98.87,
      "ConcatenatedSegments": 0,
      "bInvAmt": 98.87,
      "bDiscAmt": 0,
      "bTaxAmt": 791911918,
      "APID": 2025499
     },
     {
      "DataSource": "D211262",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-23004",
      "InvDate": "10\/19\/2022",
      "InvAmt": 8.09,
      "AbsInvAmt": 8.09,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403218,
      "FY": "11\/02\/2022",
      "InvTypeDesc": 6811.24,
      "PmtNum": "PK",
      "PoNum": "10\/26\/2022",
      "VndType": "PV",
      "APStatus": 2308091,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 8.09,
      "ConcatenatedSegments": 0,
      "bInvAmt": 8.09,
      "bDiscAmt": 0,
      "bTaxAmt": 791923004,
      "APID": 2025507
     },
     {
      "DataSource": "D211263",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-43663",
      "InvDate": "10\/19\/2022",
      "InvAmt": 53.47,
      "AbsInvAmt": 53.47,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306945,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 53.47,
      "ConcatenatedSegments": 0,
      "bInvAmt": 53.47,
      "bDiscAmt": 0,
      "bTaxAmt": 791943663,
      "APID": 2025515
     },
     {
      "DataSource": "D211264",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-59915",
      "InvDate": "10\/19\/2022",
      "InvAmt": 65.71,
      "AbsInvAmt": 65.71,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306947,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 65.71,
      "ConcatenatedSegments": 0,
      "bInvAmt": 65.71,
      "bDiscAmt": 0,
      "bTaxAmt": 791959915,
      "APID": 2025523
     },
     {
      "DataSource": "D211265",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-68010",
      "InvDate": "10\/19\/2022",
      "InvAmt": 25.13,
      "AbsInvAmt": 25.13,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306946,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 25.13,
      "ConcatenatedSegments": 0,
      "bInvAmt": 25.13,
      "bDiscAmt": 0,
      "bTaxAmt": 791968010,
      "APID": 2025530
     },
     {
      "DataSource": "D211266",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-919-74293",
      "InvDate": "10\/19\/2022",
      "InvAmt": 43.01,
      "AbsInvAmt": 43.01,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11403009,
      "FY": "10\/26\/2022",
      "InvTypeDesc": 970.36,
      "PmtNum": "PK",
      "PoNum": "10\/19\/2022",
      "VndType": "PV",
      "APStatus": 2306950,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 43.01,
      "ConcatenatedSegments": 0,
      "bInvAmt": 43.01,
      "bDiscAmt": 0,
      "bTaxAmt": 791974293,
      "APID": 1998418
     },
     {
      "DataSource": "D211267",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-917-78016",
      "InvDate": "10\/18\/2022",
      "InvAmt": 36.46,
      "AbsInvAmt": 36.46,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679065,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 440.01,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14761320,
      "DupeX": "UTL",
      "BusinessUnitName": 8587,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 36.46,
      "ConcatenatedSegments": 0,
      "bInvAmt": 36.46,
      "bDiscAmt": 0,
      "bTaxAmt": 791778016,
      "APID": 586907
     },
     {
      "DataSource": "D211268",
      "Co": "Bedrock Facilities",
      "VndNum": 7185930,
      "VndName": "FEDEX (SSC ACCT. #3702-0848-4)",
      "InvNum": "7-917-81013",
      "InvDate": "10\/18\/2022",
      "InvAmt": 106.83,
      "AbsInvAmt": 106.83,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836443,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 701.14,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14772698,
      "DupeX": "UTL",
      "BusinessUnitName": 48845030,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 106.83,
      "ConcatenatedSegments": 0,
      "bInvAmt": 106.83,
      "bDiscAmt": 0,
      "bTaxAmt": 791781013,
      "APID": 2008907
     },
     {
      "DataSource": "D211269",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "9-636-61709",
      "InvDate": "10\/18\/2022",
      "InvAmt": 62.11,
      "AbsInvAmt": 62.11,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836433,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 62.11,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14768744,
      "DupeX": "UTL",
      "BusinessUnitName": 8627,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 62.11,
      "ConcatenatedSegments": 0,
      "bInvAmt": 62.11,
      "bDiscAmt": 0,
      "bTaxAmt": 963661709,
      "APID": 1984778
     },
     {
      "DataSource": "D211270",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "9-636-63437",
      "InvDate": "10\/18\/2022",
      "InvAmt": 3.38,
      "AbsInvAmt": 3.38,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836439,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 3.38,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773225,
      "DupeX": "UTL",
      "BusinessUnitName": 8575,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 3.38,
      "ConcatenatedSegments": 0,
      "bInvAmt": 3.38,
      "bDiscAmt": 0,
      "bTaxAmt": 963663437,
      "APID": 1984785
     },
     {
      "DataSource": "D211271",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-917-93392",
      "InvDate": "10\/18\/2022",
      "InvAmt": 251.96,
      "AbsInvAmt": 251.96,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679064,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 1403.56,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14764545,
      "DupeX": "UTL",
      "BusinessUnitName": 8519,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 251.96,
      "ConcatenatedSegments": 0,
      "bInvAmt": 251.96,
      "bDiscAmt": 0,
      "bTaxAmt": 791793392,
      "APID": 2008724
     },
     {
      "DataSource": "D211272",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-918-03007",
      "InvDate": "10\/18\/2022",
      "InvAmt": 29.63,
      "AbsInvAmt": 29.63,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836426,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 29.63,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14766061,
      "DupeX": "UTL",
      "BusinessUnitName": 8648,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 29.63,
      "ConcatenatedSegments": 0,
      "bInvAmt": 29.63,
      "bDiscAmt": 0,
      "bTaxAmt": 791803007,
      "APID": 1970011
     },
     {
      "DataSource": "D211273",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-917-55577",
      "InvDate": "10\/18\/2022",
      "InvAmt": 16.25,
      "AbsInvAmt": 16.25,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679064,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 1403.56,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14762546,
      "DupeX": "UTL",
      "BusinessUnitName": 69000165,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 690,
      "PaymentTermsName": 16.25,
      "ConcatenatedSegments": 0,
      "bInvAmt": 16.25,
      "bDiscAmt": 0,
      "bTaxAmt": 791755577,
      "APID": 2009506
     },
     {
      "DataSource": "D211274",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-918-27579",
      "InvDate": "10\/18\/2022",
      "InvAmt": 49.8,
      "AbsInvAmt": 49.8,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833996,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 49.8,
      "PmtNum": "PT",
      "PmtAmt": 23812035,
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14758458,
      "DupeX": "UTL",
      "MatchInvNum": "7-918-27579",
      "BusinessUnitName": 5238,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 553,
      "PaymentTermsName": 49.8,
      "ConcatenatedSegments": 0,
      "bInvAmt": 49.8,
      "bDiscAmt": 0,
      "bTaxAmt": 791827579,
      "APID": 1969616
     },
     {
      "DataSource": "D211275",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-918-01427",
      "InvDate": "10\/18\/2022",
      "InvAmt": 11.19,
      "AbsInvAmt": 11.19,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679062,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 11.19,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14762529,
      "DupeX": "UTL",
      "MatchInvNum": "7-918-01427",
      "BusinessUnitName": 24811606,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 326,
      "PaymentTermsName": 11.19,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.19,
      "bDiscAmt": 0,
      "bTaxAmt": 791801427,
      "APID": 1968788
     },
     {
      "DataSource": "D211276",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "9-636-65587",
      "InvDate": "10\/18\/2022",
      "InvAmt": 5.25,
      "AbsInvAmt": 5.25,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836434,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 5.25,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14770933,
      "DupeX": "UTL",
      "BusinessUnitName": 8623,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 5.25,
      "ConcatenatedSegments": 0,
      "bInvAmt": 5.25,
      "bDiscAmt": 0,
      "bTaxAmt": 963665587,
      "APID": 1989179
     },
     {
      "DataSource": "D211277",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-918-03882",
      "InvDate": "10\/18\/2022",
      "InvAmt": 11.94,
      "AbsInvAmt": 11.94,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836429,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 11.94,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14767574,
      "DupeX": "UTL",
      "BusinessUnitName": 8665,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 11.94,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.94,
      "bDiscAmt": 0,
      "bTaxAmt": 791803882,
      "APID": 1981425
     },
     {
      "DataSource": "D211278",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-918-37028",
      "InvDate": "10\/18\/2022",
      "InvAmt": 53.59,
      "AbsInvAmt": 53.59,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679064,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 1403.56,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14761177,
      "DupeX": "UTL",
      "BusinessUnitName": 48845300,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 53.59,
      "ConcatenatedSegments": 0,
      "bInvAmt": 53.59,
      "bDiscAmt": 0,
      "bTaxAmt": 791837028,
      "APID": 2015230
     },
     {
      "DataSource": "D211279",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-093-79691",
      "InvDate": "10\/17\/2022",
      "InvAmt": 29.48,
      "AbsInvAmt": 29.48,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677965,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 371.64,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/21\/2022",
      "VndType": "P5",
      "APStatus": 14753504,
      "DupeX": "UTL",
      "BusinessUnitName": 8231,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 833,
      "PaymentTermsName": 29.48,
      "ConcatenatedSegments": 0,
      "bInvAmt": 29.48,
      "bDiscAmt": 0,
      "bTaxAmt": 209379691,
      "APID": 1970802
     },
     {
      "DataSource": "D211280",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-915-59017",
      "InvDate": "10\/17\/2022",
      "InvAmt": 342.16,
      "AbsInvAmt": 342.16,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677965,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 371.64,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/21\/2022",
      "VndType": "P5",
      "APStatus": 14754504,
      "DupeX": "UTL",
      "BusinessUnitName": 8206,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 833,
      "PaymentTermsName": 342.16,
      "ConcatenatedSegments": 0,
      "bInvAmt": 342.16,
      "bDiscAmt": 0,
      "bTaxAmt": 791559017,
      "APID": 1969909
     },
     {
      "DataSource": "D211281",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-916-74538",
      "InvDate": "10\/17\/2022",
      "InvAmt": 96.82,
      "AbsInvAmt": 96.82,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 834528,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 96.82,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14762530,
      "DupeX": "UTL",
      "BusinessUnitName": 24821610,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 326,
      "PaymentTermsName": 96.82,
      "ConcatenatedSegments": 0,
      "bInvAmt": 96.82,
      "bDiscAmt": 0,
      "bTaxAmt": 791674538,
      "APID": 1981418
     },
     {
      "DataSource": "D211282",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-092-92349",
      "InvDate": "10\/14\/2022",
      "InvAmt": 139.81,
      "AbsInvAmt": 139.81,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771902,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 139.81,
      "ConcatenatedSegments": 0,
      "bInvAmt": 139.81,
      "bDiscAmt": 0,
      "bTaxAmt": 209292349,
      "APID": 675920
     },
     {
      "DataSource": "D211283",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "9-636-43507",
      "InvDate": "10\/14\/2022",
      "InvAmt": 5.75,
      "AbsInvAmt": 5.75,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771704,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 5.75,
      "ConcatenatedSegments": 0,
      "bInvAmt": 5.75,
      "bDiscAmt": 0,
      "bTaxAmt": 963643507,
      "APID": 727905
     },
     {
      "DataSource": "D211284",
      "Co": "Bedrock Facilities",
      "VndNum": 11071,
      "VndName": "FEDEX - PASADENA",
      "InvNum": "7-915-00517",
      "InvDate": "10\/14\/2022",
      "InvAmt": 193.11,
      "AbsInvAmt": 193.11,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679055,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 193.11,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14759451,
      "DupeX": "UTL",
      "MatchInvNum": "Acct no 1736 7603 4 101422",
      "BusinessUnitName": 75500164,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 755,
      "PaymentTermsName": 193.11,
      "ConcatenatedSegments": 0,
      "bInvAmt": 193.11,
      "bDiscAmt": 0,
      "bTaxAmt": 791500517,
      "APID": 1970200
     },
     {
      "DataSource": "D211285",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-912-74410",
      "InvDate": "10\/13\/2022",
      "InvAmt": 12.26,
      "AbsInvAmt": 12.26,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680758,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 169.64,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773034,
      "DupeX": "UTL",
      "BusinessUnitName": 48845090,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 12.26,
      "ConcatenatedSegments": 0,
      "bInvAmt": 12.26,
      "bDiscAmt": 0,
      "bTaxAmt": 791274410,
      "APID": 586899
     },
     {
      "DataSource": "D211286",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-912-64865",
      "InvDate": "10\/13\/2022",
      "InvAmt": 23961.81,
      "AbsInvAmt": 23961.81,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14771911,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 23961.81,
      "ConcatenatedSegments": 0,
      "bInvAmt": 23961.81,
      "bDiscAmt": 0,
      "bTaxAmt": 791264865,
      "APID": 728761
     },
     {
      "DataSource": "D211287",
      "Co": "Bedrock Facilities",
      "VndNum": 130185,
      "VndName": "FEDEX GROUND INC",
      "InvNum": "7-913-68088",
      "InvDate": "10\/13\/2022",
      "InvAmt": 11.56,
      "AbsInvAmt": 11.56,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680252,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 341.35,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14765007,
      "DupeX": "UTL",
      "MatchInvNum": "7-913-68088",
      "BusinessUnitName": 48842150,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 685,
      "PaymentTermsName": 11.56,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.56,
      "bDiscAmt": 0,
      "bTaxAmt": 791368088,
      "APID": 1900519
     },
     {
      "DataSource": "D211288",
      "Co": "Bedrock Facilities",
      "VndNum": 1107630,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-912-37712",
      "InvDate": "10\/12\/2022",
      "InvAmt": 224.79,
      "AbsInvAmt": 224.79,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "FY": "01\/01\/1900",
      "InvTypeDesc": 275.74,
      "PmtAmt": 52212513,
      "PmtTypeDesc": "GEN",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14768088,
      "DupeX": "GEN",
      "BusinessUnitName": 48846110,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 224.79,
      "ConcatenatedSegments": 0,
      "bInvAmt": 224.79,
      "bDiscAmt": 0,
      "bTaxAmt": 791237712,
      "APID": 685246
     },
     {
      "DataSource": "D211289",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-38484",
      "InvDate": "10\/12\/2022",
      "InvAmt": 2999.83,
      "AbsInvAmt": 2999.83,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/13\/2022",
      "VndType": "PV",
      "APStatus": 2305754,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 2999.83,
      "ConcatenatedSegments": 0,
      "bInvAmt": 2999.83,
      "bDiscAmt": 0,
      "bTaxAmt": 791238484,
      "APID": 2025448
     },
     {
      "DataSource": "D211290",
      "Co": "Bedrock Facilities",
      "VndNum": 432370,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-912-15360",
      "InvDate": "10\/12\/2022",
      "InvAmt": 32.04,
      "AbsInvAmt": 32.04,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680249,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 32.04,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14764563,
      "DupeX": "UTL",
      "BusinessUnitName": 48845310,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 32.04,
      "ConcatenatedSegments": 0,
      "bInvAmt": 32.04,
      "bDiscAmt": 0,
      "bTaxAmt": 791215360,
      "APID": 1968771
     },
     {
      "DataSource": "D211291",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-092-29373",
      "InvDate": "10\/12\/2022",
      "InvAmt": 1053.21,
      "AbsInvAmt": 1053.21,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833483,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 1053.21,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/19\/2022",
      "VndType": "P5",
      "APStatus": 14748035,
      "DupeX": "UTL",
      "BusinessUnitName": 86679930,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 1053.21,
      "ConcatenatedSegments": 0,
      "bInvAmt": 1053.21,
      "bDiscAmt": 0,
      "bTaxAmt": 209229373,
      "APID": 1969955
     },
     {
      "DataSource": "D211292",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-23743",
      "InvDate": "10\/12\/2022",
      "InvAmt": 24.83,
      "AbsInvAmt": 24.83,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/13\/2022",
      "VndType": "PV",
      "APStatus": 2305766,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 24.83,
      "ConcatenatedSegments": 0,
      "bInvAmt": 24.83,
      "bDiscAmt": 0,
      "bTaxAmt": 791223743,
      "APID": 2025392
     },
     {
      "DataSource": "D211293",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-092-29374",
      "InvDate": "10\/12\/2022",
      "InvAmt": 1142.13,
      "AbsInvAmt": 1142.13,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833995,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 1142.13,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14758183,
      "DupeX": "UTL",
      "BusinessUnitName": 8665,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 1142.13,
      "ConcatenatedSegments": 0,
      "bInvAmt": 1142.13,
      "bDiscAmt": 0,
      "bTaxAmt": 209229374,
      "APID": 1981906
     },
     {
      "DataSource": "D211294",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-23744",
      "InvDate": "10\/12\/2022",
      "InvAmt": 630.52,
      "AbsInvAmt": 630.52,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/13\/2022",
      "VndType": "PV",
      "APStatus": 2305775,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 630.52,
      "ConcatenatedSegments": 0,
      "bInvAmt": 630.52,
      "bDiscAmt": 0,
      "bTaxAmt": 791223744,
      "APID": 2025400
     },
     {
      "DataSource": "D211295",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-23745",
      "InvDate": "10\/12\/2022",
      "InvAmt": 218.1,
      "AbsInvAmt": 218.1,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/13\/2022",
      "VndType": "PV",
      "APStatus": 2305779,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 218.1,
      "ConcatenatedSegments": 0,
      "bInvAmt": 218.1,
      "bDiscAmt": 0,
      "bTaxAmt": 791223745,
      "APID": 2025427
     },
     {
      "DataSource": "D211296",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-34718",
      "InvDate": "10\/12\/2022",
      "InvAmt": 7.44,
      "AbsInvAmt": 7.44,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305598,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 7.44,
      "ConcatenatedSegments": 0,
      "bInvAmt": 7.44,
      "bDiscAmt": 0,
      "bTaxAmt": 791234718,
      "APID": 2025441
     },
     {
      "DataSource": "D211297",
      "Co": "Bedrock Facilities",
      "VndNum": 509199,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-912-38367",
      "InvDate": "10\/12\/2022",
      "InvAmt": 401.95,
      "AbsInvAmt": 401.95,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679063,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 1886.78,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14760097,
      "DupeX": "UTL",
      "BusinessUnitName": 48844200,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 558,
      "PaymentTermsName": 401.95,
      "ConcatenatedSegments": 0,
      "bInvAmt": 401.95,
      "bDiscAmt": 0,
      "bTaxAmt": 791238367,
      "APID": 1993872
     },
     {
      "DataSource": "D211298",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-47127",
      "InvDate": "10\/12\/2022",
      "InvAmt": 14.88,
      "AbsInvAmt": 14.88,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305608,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 14.88,
      "ConcatenatedSegments": 0,
      "bInvAmt": 14.88,
      "bDiscAmt": 0,
      "bTaxAmt": 791247127,
      "APID": 2025455
     },
     {
      "DataSource": "D211299",
      "Co": "Bedrock Facilities",
      "VndNum": 7185930,
      "VndName": "FEDEX (SSC ACCT. #3702-0848-4)",
      "InvNum": "7-911-89699",
      "InvDate": "10\/12\/2022",
      "InvAmt": 11.56,
      "AbsInvAmt": 11.56,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836443,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 701.14,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773849,
      "DupeX": "UTL",
      "BusinessUnitName": 48845030,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 11.56,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.56,
      "bDiscAmt": 0,
      "bTaxAmt": 791189699,
      "APID": 2007464
     },
     {
      "DataSource": "D211300",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-911-91139",
      "InvDate": "10\/12\/2022",
      "InvAmt": 150.84,
      "AbsInvAmt": 150.84,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833988,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 150.84,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/20\/2022",
      "VndType": "P5",
      "APStatus": 14751942,
      "DupeX": "UTL",
      "BusinessUnitName": 48811230,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 841,
      "PaymentTermsName": 150.84,
      "ConcatenatedSegments": 0,
      "bInvAmt": 150.84,
      "bDiscAmt": 0,
      "bTaxAmt": 791191139,
      "APID": 1981413
     },
     {
      "DataSource": "D211301",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-092-27557",
      "InvDate": "10\/12\/2022",
      "InvAmt": 133.4,
      "AbsInvAmt": 133.4,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836441,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 133.4,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773566,
      "DupeX": "UTL",
      "BusinessUnitName": 17580019,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 173,
      "PaymentTermsName": 133.4,
      "ConcatenatedSegments": 0,
      "bInvAmt": 133.4,
      "bDiscAmt": 0,
      "bTaxAmt": 209227557,
      "APID": 1969563
     },
     {
      "DataSource": "D211302",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-48763",
      "InvDate": "10\/12\/2022",
      "InvAmt": 136.18,
      "AbsInvAmt": 136.18,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305599,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 136.18,
      "ConcatenatedSegments": 0,
      "bInvAmt": 136.18,
      "bDiscAmt": 0,
      "bTaxAmt": 791248763,
      "APID": 2025462
     },
     {
      "DataSource": "D211303",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-32443",
      "InvDate": "10\/12\/2022",
      "InvAmt": 69.99,
      "AbsInvAmt": 69.99,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305590,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 69.99,
      "ConcatenatedSegments": 0,
      "bInvAmt": 69.99,
      "bDiscAmt": 0,
      "bTaxAmt": 791232443,
      "APID": 2025434
     },
     {
      "DataSource": "D211304",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-911-85384",
      "InvDate": "10\/12\/2022",
      "InvAmt": 11.56,
      "AbsInvAmt": 11.56,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680755,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 559.51,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14766173,
      "DupeX": "UTL",
      "BusinessUnitName": 48845290,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 11.56,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.56,
      "bDiscAmt": 0,
      "bTaxAmt": 791185384,
      "APID": 1985952
     },
     {
      "DataSource": "D211305",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-61936",
      "InvDate": "10\/12\/2022",
      "InvAmt": 21.33,
      "AbsInvAmt": 21.33,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305596,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 21.33,
      "ConcatenatedSegments": 0,
      "bInvAmt": 21.33,
      "bDiscAmt": 0,
      "bTaxAmt": 791161936,
      "APID": 2016829
     },
     {
      "DataSource": "D211306",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-66229",
      "InvDate": "10\/12\/2022",
      "InvAmt": 11.9,
      "AbsInvAmt": 11.9,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305589,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 11.9,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.9,
      "bDiscAmt": 0,
      "bTaxAmt": 791166229,
      "APID": 2016837
     },
     {
      "DataSource": "D211307",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-66237",
      "InvDate": "10\/12\/2022",
      "InvAmt": 29.48,
      "AbsInvAmt": 29.48,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305591,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 29.48,
      "ConcatenatedSegments": 0,
      "bInvAmt": 29.48,
      "bDiscAmt": 0,
      "bTaxAmt": 791166237,
      "APID": 2016845
     },
     {
      "DataSource": "D211308",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-66254",
      "InvDate": "10\/12\/2022",
      "InvAmt": 145.15,
      "AbsInvAmt": 145.15,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305595,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 145.15,
      "ConcatenatedSegments": 0,
      "bInvAmt": 145.15,
      "bDiscAmt": 0,
      "bTaxAmt": 791166254,
      "APID": 2016852
     },
     {
      "DataSource": "D211309",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-87784",
      "InvDate": "10\/12\/2022",
      "InvAmt": 167.61,
      "AbsInvAmt": 167.61,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305594,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 167.61,
      "ConcatenatedSegments": 0,
      "bInvAmt": 167.61,
      "bDiscAmt": 0,
      "bTaxAmt": 791187784,
      "APID": 2016860
     },
     {
      "DataSource": "D211310",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-91345",
      "InvDate": "10\/12\/2022",
      "InvAmt": 26.87,
      "AbsInvAmt": 26.87,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305592,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 26.87,
      "ConcatenatedSegments": 0,
      "bInvAmt": 26.87,
      "bDiscAmt": 0,
      "bTaxAmt": 791191345,
      "APID": 2016868
     },
     {
      "DataSource": "D211311",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-92740",
      "InvDate": "10\/12\/2022",
      "InvAmt": 35.1,
      "AbsInvAmt": 35.1,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305614,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 35.1,
      "ConcatenatedSegments": 0,
      "bInvAmt": 35.1,
      "bDiscAmt": 0,
      "bTaxAmt": 791192740,
      "APID": 2016881
     },
     {
      "DataSource": "D211312",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-23737",
      "InvDate": "10\/12\/2022",
      "InvAmt": 161.25,
      "AbsInvAmt": 161.25,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/13\/2022",
      "VndType": "PV",
      "APStatus": 2305765,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 161.25,
      "ConcatenatedSegments": 0,
      "bInvAmt": 161.25,
      "bDiscAmt": 0,
      "bTaxAmt": 791223737,
      "APID": 2025384
     },
     {
      "DataSource": "D211313",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-911-95261",
      "InvDate": "10\/12\/2022",
      "InvAmt": 12.21,
      "AbsInvAmt": 12.21,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305616,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 12.21,
      "ConcatenatedSegments": 0,
      "bInvAmt": 12.21,
      "bDiscAmt": 0,
      "bTaxAmt": 791195261,
      "APID": 2025360
     },
     {
      "DataSource": "D211314",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-21649",
      "InvDate": "10\/12\/2022",
      "InvAmt": 41.04,
      "AbsInvAmt": 41.04,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305615,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 41.04,
      "ConcatenatedSegments": 0,
      "bInvAmt": 41.04,
      "bDiscAmt": 0,
      "bTaxAmt": 791221649,
      "APID": 2025376
     },
     {
      "DataSource": "D211315",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-913-20483",
      "InvDate": "10\/12\/2022",
      "InvAmt": 11.13,
      "AbsInvAmt": 11.13,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677046,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 215.08,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/18\/2022",
      "VndType": "P5",
      "APStatus": 14745167,
      "DupeX": "UTL",
      "BusinessUnitName": 8551,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 11.13,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.13,
      "bDiscAmt": 0,
      "bTaxAmt": 791320483,
      "APID": 1985960
     },
     {
      "DataSource": "D211316",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-912-11837",
      "InvDate": "10\/12\/2022",
      "InvAmt": 29.89,
      "AbsInvAmt": 29.89,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/12\/2022",
      "VndType": "PV",
      "APStatus": 2305600,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 29.89,
      "ConcatenatedSegments": 0,
      "bInvAmt": 29.89,
      "bDiscAmt": 0,
      "bTaxAmt": 791211837,
      "APID": 2025368
     },
     {
      "DataSource": "D211317",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-912-08036",
      "InvDate": "10\/12\/2022",
      "InvAmt": 122.9,
      "AbsInvAmt": 122.9,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677047,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 122.9,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/19\/2022",
      "VndType": "P5",
      "APStatus": 14747843,
      "DupeX": "UTL",
      "BusinessUnitName": 48848805,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 122.9,
      "ConcatenatedSegments": 0,
      "bInvAmt": 122.9,
      "bDiscAmt": 0,
      "bTaxAmt": 791208036,
      "APID": 1993026
     },
     {
      "DataSource": "D211318",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-910-99230",
      "InvDate": "10\/11\/2022",
      "InvAmt": 27.97,
      "AbsInvAmt": 27.97,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679065,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 440.01,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14761318,
      "DupeX": "UTL",
      "BusinessUnitName": 8587,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 27.97,
      "ConcatenatedSegments": 0,
      "bInvAmt": 27.97,
      "bDiscAmt": 0,
      "bTaxAmt": 791099230,
      "APID": 516228
     },
     {
      "DataSource": "D211319",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-910-69261",
      "InvDate": "10\/11\/2022",
      "InvAmt": 221.68,
      "AbsInvAmt": 221.68,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 834526,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 221.68,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/26\/2022",
      "VndType": "P5",
      "APStatus": 14761277,
      "DupeX": "UTL",
      "BusinessUnitName": 8640,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 221.68,
      "ConcatenatedSegments": 0,
      "bInvAmt": 221.68,
      "bDiscAmt": 0,
      "bTaxAmt": 791069261,
      "APID": 1989165
     },
     {
      "DataSource": "D211320",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-092-04654",
      "InvDate": "10\/11\/2022",
      "InvAmt": 32.78,
      "AbsInvAmt": 32.78,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836442,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 32.78,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773569,
      "DupeX": "UTL",
      "BusinessUnitName": 17580019,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 173,
      "PaymentTermsName": 32.78,
      "ConcatenatedSegments": 0,
      "bInvAmt": 32.78,
      "bDiscAmt": 0,
      "bTaxAmt": 209204654,
      "APID": 1987966
     },
     {
      "DataSource": "D211321",
      "Co": "Bedrock Facilities",
      "VndNum": 509199,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-091-60348",
      "InvDate": "10\/11\/2022",
      "InvAmt": 41.6,
      "AbsInvAmt": 41.6,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680756,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 75.82,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773083,
      "DupeX": "UTL",
      "BusinessUnitName": 65350246,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 710,
      "PaymentTermsName": 41.6,
      "ConcatenatedSegments": 0,
      "bInvAmt": 41.6,
      "bDiscAmt": 0,
      "bTaxAmt": 209160348,
      "APID": 1986614
     },
     {
      "DataSource": "D211322",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-910-74160",
      "InvDate": "10\/11\/2022",
      "InvAmt": 313.34,
      "AbsInvAmt": 313.34,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833992,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 313.34,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/24\/2022",
      "VndType": "P5",
      "APStatus": 14757890,
      "DupeX": "UTL",
      "BusinessUnitName": 86479390,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 313.34,
      "ConcatenatedSegments": 0,
      "bInvAmt": 313.34,
      "bDiscAmt": 0,
      "bTaxAmt": 791074160,
      "APID": 1970003
     },
     {
      "DataSource": "D211323",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-911-06664",
      "InvDate": "10\/11\/2022",
      "InvAmt": 24.6,
      "AbsInvAmt": 24.6,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833479,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 24.6,
      "PmtNum": "PT",
      "PmtAmt": 23812035,
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/18\/2022",
      "VndType": "P5",
      "APStatus": 14746861,
      "DupeX": "UTL",
      "MatchInvNum": "7-911-06664",
      "BusinessUnitName": 5238,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 553,
      "PaymentTermsName": 24.6,
      "ConcatenatedSegments": 0,
      "bInvAmt": 24.6,
      "bDiscAmt": 0,
      "bTaxAmt": 791106664,
      "APID": 1982412
     },
     {
      "DataSource": "D211324",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-910-87833",
      "InvDate": "10\/11\/2022",
      "InvAmt": 109.02,
      "AbsInvAmt": 109.02,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833987,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 109.02,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/20\/2022",
      "VndType": "P5",
      "APStatus": 14751700,
      "DupeX": "UTL",
      "BusinessUnitName": 86261346,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 109.02,
      "ConcatenatedSegments": 0,
      "bInvAmt": 109.02,
      "bDiscAmt": 0,
      "bTaxAmt": 791087833,
      "APID": 1981946
     },
     {
      "DataSource": "D211325",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-910-99579",
      "InvDate": "10\/11\/2022",
      "InvAmt": 81.54,
      "AbsInvAmt": 81.54,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833989,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 81.54,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/21\/2022",
      "VndType": "P5",
      "APStatus": 14753467,
      "DupeX": "UTL",
      "BusinessUnitName": 8631,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 81.54,
      "ConcatenatedSegments": 0,
      "bInvAmt": 81.54,
      "bDiscAmt": 0,
      "bTaxAmt": 791099579,
      "APID": 1984761
     },
     {
      "DataSource": "D211326",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-911-15364",
      "InvDate": "10\/11\/2022",
      "InvAmt": 11.54,
      "AbsInvAmt": 11.54,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676250,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 11.54,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/17\/2022",
      "VndType": "P5",
      "APStatus": 14743133,
      "DupeX": "UTL",
      "BusinessUnitName": 25012273,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 325,
      "PaymentTermsName": 11.54,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.54,
      "bDiscAmt": 0,
      "bTaxAmt": 791115364,
      "APID": 1989257
     },
     {
      "DataSource": "E221104",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-910-32276",
      "InvDate": "10\/11\/2022",
      "InvAmt": 911.21,
      "AbsInvAmt": 911.21,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833994,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 911.21,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14758182,
      "DupeX": "UTL",
      "BusinessUnitName": 8665,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 911.21,
      "ConcatenatedSegments": 0,
      "bInvAmt": 911.21,
      "bDiscAmt": 0,
      "bTaxAmt": 791032276,
      "APID": 1981409
     },
     {
      "DataSource": "E221105",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-910-69260",
      "InvDate": "10\/11\/2022",
      "InvAmt": 83.73,
      "AbsInvAmt": 83.73,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833993,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 83.73,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14758180,
      "DupeX": "UTL",
      "BusinessUnitName": 86341496,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 83.73,
      "ConcatenatedSegments": 0,
      "bInvAmt": 83.73,
      "bDiscAmt": 0,
      "bTaxAmt": 791069260,
      "APID": 1984754
     },
     {
      "DataSource": "E221107",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-910-24567",
      "InvDate": "10\/11\/2022",
      "InvAmt": 11.13,
      "AbsInvAmt": 11.13,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676254,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 85.46,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/17\/2022",
      "VndType": "P5",
      "APStatus": 14741428,
      "DupeX": "UTL",
      "BusinessUnitName": 69000218,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 690,
      "PaymentTermsName": 11.13,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.13,
      "bDiscAmt": 0,
      "bTaxAmt": 791024567,
      "APID": 2009501
     },
     {
      "DataSource": "E221109",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-911-17366",
      "InvDate": "10\/11\/2022",
      "InvAmt": 74.33,
      "AbsInvAmt": 74.33,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676254,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 85.46,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/17\/2022",
      "VndType": "P5",
      "APStatus": 14743174,
      "DupeX": "UTL",
      "BusinessUnitName": 8519,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 74.33,
      "ConcatenatedSegments": 0,
      "bInvAmt": 74.33,
      "bDiscAmt": 0,
      "bTaxAmt": 791117366,
      "APID": 2014608
     },
     {
      "DataSource": "E221110",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-908-81709",
      "InvDate": "10\/10\/2022",
      "InvAmt": 48.79,
      "AbsInvAmt": 48.79,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676252,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 155.66,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/13\/2022",
      "VndType": "P5",
      "APStatus": 14738513,
      "DupeX": "UTL",
      "BusinessUnitName": 8227,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 833,
      "PaymentTermsName": 48.79,
      "ConcatenatedSegments": 0,
      "bInvAmt": 48.79,
      "bDiscAmt": 0,
      "bTaxAmt": 790881709,
      "APID": 1970069
     },
     {
      "DataSource": "E221111",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "9-636-07579",
      "InvDate": "10\/10\/2022",
      "InvAmt": 3.1,
      "AbsInvAmt": 3.1,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836436,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 3.1,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14772148,
      "DupeX": "UTL",
      "BusinessUnitName": 8503,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 3.1,
      "ConcatenatedSegments": 0,
      "bInvAmt": 3.1,
      "bDiscAmt": 0,
      "bTaxAmt": 963607579,
      "APID": 1969623
     },
     {
      "DataSource": "E221112",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "9-636-05295",
      "InvDate": "10\/10\/2022",
      "InvAmt": 3.82,
      "AbsInvAmt": 3.82,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676249,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 328.92,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/13\/2022",
      "VndType": "P5",
      "APStatus": 14738099,
      "DupeX": "UTL",
      "BusinessUnitName": 48848863,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 3.82,
      "ConcatenatedSegments": 0,
      "bInvAmt": 3.82,
      "bDiscAmt": 0,
      "bTaxAmt": 963605295,
      "APID": 1987631
     },
     {
      "DataSource": "E221113",
      "Co": "Bedrock Facilities",
      "VndNum": 509199,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-909-04563",
      "InvDate": "10\/10\/2022",
      "InvAmt": 257.94,
      "AbsInvAmt": 257.94,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679063,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 1886.78,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14758637,
      "DupeX": "UTL",
      "BusinessUnitName": 1087,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 12,
      "PaymentTermsName": 257.94,
      "ConcatenatedSegments": 0,
      "bInvAmt": 257.94,
      "bDiscAmt": 0,
      "bTaxAmt": 790904563,
      "APID": 1970530
     },
     {
      "DataSource": "E221114",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-908-37884",
      "InvDate": "10\/10\/2022",
      "InvAmt": 14.43,
      "AbsInvAmt": 14.43,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833484,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 14.43,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/19\/2022",
      "VndType": "P5",
      "APStatus": 14748765,
      "DupeX": "UTL",
      "BusinessUnitName": 24821613,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 326,
      "PaymentTermsName": 14.43,
      "ConcatenatedSegments": 0,
      "bInvAmt": 14.43,
      "bDiscAmt": 0,
      "bTaxAmt": 790837884,
      "APID": 1984746
     },
     {
      "DataSource": "E221115",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-908-96921",
      "InvDate": "10\/10\/2022",
      "InvAmt": 11.67,
      "AbsInvAmt": 11.67,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836430,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 11.67,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14768254,
      "DupeX": "UTL",
      "BusinessUnitName": 48844320,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 558,
      "PaymentTermsName": 11.67,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.67,
      "bDiscAmt": 0,
      "bTaxAmt": 790896921,
      "APID": 1982393
     },
     {
      "DataSource": "E221116",
      "Co": "Bedrock Facilities",
      "VndNum": 11071,
      "VndName": "FEDEX - PASADENA",
      "InvNum": "7-906-71309",
      "InvDate": "10\/07\/2022",
      "InvAmt": 100.84,
      "AbsInvAmt": 100.84,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679054,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 100.84,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14759428,
      "DupeX": "UTL",
      "MatchInvNum": "Acct no 1736 7603 4 100722",
      "BusinessUnitName": 75500164,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 755,
      "PaymentTermsName": 100.84,
      "ConcatenatedSegments": 0,
      "bInvAmt": 100.84,
      "bDiscAmt": 0,
      "bTaxAmt": 790671309,
      "APID": 675662
     },
     {
      "DataSource": "E221117",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-090-76314",
      "InvDate": "10\/07\/2022",
      "InvAmt": 86.28,
      "AbsInvAmt": 86.28,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674339,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 17255.19,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/07\/2022",
      "VndType": "P5",
      "APStatus": 14727547,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 86.28,
      "ConcatenatedSegments": 0,
      "bInvAmt": 86.28,
      "bDiscAmt": 0,
      "bTaxAmt": 209076314,
      "APID": 518388
     },
     {
      "DataSource": "E221118",
      "Co": "Bedrock Facilities",
      "VndNum": 543161,
      "VndName": "FEDEX",
      "InvNum": "9-635-96927",
      "InvDate": "10\/07\/2022",
      "InvAmt": 3.31,
      "AbsInvAmt": 3.31,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "FY": "01\/01\/1900",
      "InvTypeDesc": 45.55,
      "PmtTypeDesc": "GEN",
      "PoNum": "10\/14\/2022",
      "VndType": "P5",
      "APStatus": 14740579,
      "DupeX": "GEN",
      "BusinessUnitName": 48846130,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 3.31,
      "ConcatenatedSegments": 0,
      "bInvAmt": 3.31,
      "bDiscAmt": 0,
      "bTaxAmt": 963596927,
      "APID": 1985132
     },
     {
      "DataSource": "E221119",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-907-32440",
      "InvDate": "10\/07\/2022",
      "InvAmt": 623.47,
      "AbsInvAmt": 623.47,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679059,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 623.47,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14759436,
      "DupeX": "UTL",
      "MatchInvNum": "Acc#1010-2622-1",
      "BusinessUnitName": 48848878,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 623.47,
      "ConcatenatedSegments": 0,
      "bInvAmt": 623.47,
      "bDiscAmt": 0,
      "bTaxAmt": 790732440,
      "APID": 2000304
     },
     {
      "DataSource": "E221120",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-907-11929",
      "InvDate": "10\/07\/2022",
      "InvAmt": 296.2,
      "AbsInvAmt": 296.2,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676249,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 328.92,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/14\/2022",
      "VndType": "P5",
      "APStatus": 14739977,
      "DupeX": "UTL",
      "BusinessUnitName": 48847860,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 750,
      "PaymentTermsName": 296.2,
      "ConcatenatedSegments": 0,
      "bInvAmt": 296.2,
      "bDiscAmt": 0,
      "bTaxAmt": 790711929,
      "APID": 1979229
     },
     {
      "DataSource": "E221121",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-907-69153",
      "InvDate": "10\/07\/2022",
      "InvAmt": 28.9,
      "AbsInvAmt": 28.9,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676249,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 328.92,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/13\/2022",
      "VndType": "P5",
      "APStatus": 14738146,
      "DupeX": "UTL",
      "BusinessUnitName": 48848869,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 28.9,
      "ConcatenatedSegments": 0,
      "bInvAmt": 28.9,
      "bDiscAmt": 0,
      "bTaxAmt": 790769153,
      "APID": 1978992
     },
     {
      "DataSource": "E221122",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-906-45491",
      "InvDate": "10\/06\/2022",
      "InvAmt": 12.23,
      "AbsInvAmt": 12.23,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680758,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 169.64,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773049,
      "DupeX": "UTL",
      "BusinessUnitName": 48845090,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 12.23,
      "ConcatenatedSegments": 0,
      "bInvAmt": 12.23,
      "bDiscAmt": 0,
      "bTaxAmt": 790645491,
      "APID": 586714
     },
     {
      "DataSource": "E221123",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-905-36172",
      "InvDate": "10\/06\/2022",
      "InvAmt": 16034.8,
      "AbsInvAmt": 16034.8,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674339,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 17255.19,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/07\/2022",
      "VndType": "P5",
      "APStatus": 14727555,
      "DupeX": "UTL",
      "BusinessUnitName": 2023,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 11,
      "PaymentTermsName": 16034.8,
      "ConcatenatedSegments": 0,
      "bInvAmt": 16034.8,
      "bDiscAmt": 0,
      "bTaxAmt": 790536172,
      "APID": 516813
     },
     {
      "DataSource": "E221124",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-874-31544",
      "InvDate": "10\/06\/2022",
      "InvAmt": 93.9,
      "AbsInvAmt": 93.9,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674338,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 6717.77,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/11\/2022",
      "VndType": "P5",
      "APStatus": 14731894,
      "DupeX": "UTL",
      "BusinessUnitName": 48837880,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 750,
      "PaymentTermsName": 93.9,
      "ConcatenatedSegments": 0,
      "bInvAmt": 93.9,
      "bDiscAmt": 0,
      "bTaxAmt": 787431544,
      "APID": 515523
     },
     {
      "DataSource": "E221125",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "9-635-87063",
      "InvDate": "10\/06\/2022",
      "InvAmt": 3.36,
      "AbsInvAmt": 3.36,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677968,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 206.34,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/20\/2022",
      "VndType": "P5",
      "APStatus": 14751293,
      "DupeX": "UTL",
      "BusinessUnitName": 40261021,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 3.36,
      "ConcatenatedSegments": 0,
      "bInvAmt": 3.36,
      "bDiscAmt": 0,
      "bTaxAmt": 963587063,
      "APID": 515329
     },
     {
      "DataSource": "E221126",
      "Co": "Bedrock Facilities",
      "VndNum": 130185,
      "VndName": "FEDEX GROUND INC",
      "InvNum": "7-905-42290",
      "InvDate": "10\/06\/2022",
      "InvAmt": 98.62,
      "AbsInvAmt": 98.62,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677970,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 432.3,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/21\/2022",
      "VndType": "P5",
      "APStatus": 14754600,
      "DupeX": "UTL",
      "MatchInvNum": "7-905-42290",
      "BusinessUnitName": 48842150,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 685,
      "PaymentTermsName": 98.62,
      "ConcatenatedSegments": 0,
      "bInvAmt": 98.62,
      "bDiscAmt": 0,
      "bTaxAmt": 790542290,
      "APID": 1954596
     },
     {
      "DataSource": "E221127",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-906-21672",
      "InvDate": "10\/06\/2022",
      "InvAmt": 11.54,
      "AbsInvAmt": 11.54,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680755,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 559.51,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14767059,
      "DupeX": "UTL",
      "BusinessUnitName": 8555,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 11.54,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.54,
      "bDiscAmt": 0,
      "bTaxAmt": 790621672,
      "APID": 1968384
     },
     {
      "DataSource": "E221128",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-906-70011",
      "InvDate": "10\/06\/2022",
      "InvAmt": 54.95,
      "AbsInvAmt": 54.95,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680578,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 215.54,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/28\/2022",
      "VndType": "P5",
      "APStatus": 14766920,
      "DupeX": "UTL",
      "MatchInvNum": "INVOICE: 7-906-70011",
      "BusinessUnitName": 8538,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 54.95,
      "ConcatenatedSegments": 0,
      "bInvAmt": 54.95,
      "bDiscAmt": 0,
      "bTaxAmt": 790670011,
      "APID": 1978985
     },
     {
      "DataSource": "E221129",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-905-45936",
      "InvDate": "10\/06\/2022",
      "InvAmt": 91.96,
      "AbsInvAmt": 91.96,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674333,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 134.12,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/12\/2022",
      "VndType": "P5",
      "APStatus": 14735414,
      "DupeX": "UTL",
      "BusinessUnitName": 8559,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 91.96,
      "ConcatenatedSegments": 0,
      "bInvAmt": 91.96,
      "bDiscAmt": 0,
      "bTaxAmt": 790545936,
      "APID": 1982490
     },
     {
      "DataSource": "E221130",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-904-28522",
      "InvDate": "10\/05\/2022",
      "InvAmt": 95.81,
      "AbsInvAmt": 95.81,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680758,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 169.64,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14772291,
      "DupeX": "UTL",
      "BusinessUnitName": 48845410,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 95.81,
      "ConcatenatedSegments": 0,
      "bInvAmt": 95.81,
      "bDiscAmt": 0,
      "bTaxAmt": 790428522,
      "APID": 588367
     },
     {
      "DataSource": "E221131",
      "Co": "Bedrock Facilities",
      "VndNum": 180994,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-904-52968",
      "InvDate": "10\/05\/2022",
      "InvAmt": 16.68,
      "AbsInvAmt": 16.68,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677962,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 118.56,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/21\/2022",
      "VndType": "P5",
      "APStatus": 14754607,
      "DupeX": "UTL",
      "BusinessUnitName": 42856211,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 501,
      "PaymentTermsName": 16.68,
      "ConcatenatedSegments": 0,
      "bInvAmt": 16.68,
      "bDiscAmt": 0,
      "bTaxAmt": 790452968,
      "APID": 526310
     },
     {
      "DataSource": "E221132",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-090-17065",
      "InvDate": "10\/05\/2022",
      "InvAmt": 871.91,
      "AbsInvAmt": 871.91,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833481,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 871.91,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/19\/2022",
      "VndType": "P5",
      "APStatus": 14747662,
      "DupeX": "UTL",
      "BusinessUnitName": 8665,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 871.91,
      "ConcatenatedSegments": 0,
      "bInvAmt": 871.91,
      "bDiscAmt": 0,
      "bTaxAmt": 209017065,
      "APID": 1985670
     },
     {
      "DataSource": "E221133",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-905-23284",
      "InvDate": "10\/05\/2022",
      "InvAmt": 395.38,
      "AbsInvAmt": 395.38,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/13\/2022",
      "VndType": "PV",
      "APStatus": 2305762,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 395.38,
      "ConcatenatedSegments": 0,
      "bInvAmt": 395.38,
      "bDiscAmt": 0,
      "bTaxAmt": 790523284,
      "APID": 2016822
     },
     {
      "DataSource": "E221134",
      "Co": "Bedrock Facilities",
      "VndNum": 1118692,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-904-20104",
      "InvDate": "10\/05\/2022",
      "InvAmt": 20.79,
      "AbsInvAmt": 20.79,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10680759,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 63958.65,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14766188,
      "DupeX": "UTL",
      "BusinessUnitName": 48845270,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 20.79,
      "ConcatenatedSegments": 0,
      "bInvAmt": 20.79,
      "bDiscAmt": 0,
      "bTaxAmt": 790420104,
      "APID": 1965587
     },
     {
      "DataSource": "E221135",
      "Co": "Bedrock Facilities",
      "VndNum": 414170,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-905-90043",
      "InvDate": "10\/05\/2022",
      "InvAmt": 42.16,
      "AbsInvAmt": 42.16,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674333,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 134.12,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/07\/2022",
      "VndType": "P5",
      "APStatus": 14726681,
      "DupeX": "UTL",
      "BusinessUnitName": 8551,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 42.16,
      "ConcatenatedSegments": 0,
      "bInvAmt": 42.16,
      "bDiscAmt": 0,
      "bTaxAmt": 790590043,
      "APID": 1982498
     },
     {
      "DataSource": "E221136",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "2-090-17064",
      "InvDate": "10\/05\/2022",
      "InvAmt": 1452.85,
      "AbsInvAmt": 1452.85,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833480,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 1452.85,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/19\/2022",
      "VndType": "P5",
      "APStatus": 14747661,
      "DupeX": "UTL",
      "BusinessUnitName": 86689498,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 1452.85,
      "ConcatenatedSegments": 0,
      "bInvAmt": 1452.85,
      "bDiscAmt": 0,
      "bTaxAmt": 209017064,
      "APID": 1987954
     },
     {
      "DataSource": "E221137",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-13591",
      "InvDate": "10\/05\/2022",
      "InvAmt": 2044.35,
      "AbsInvAmt": 2044.35,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402824,
      "FY": "10\/19\/2022",
      "InvTypeDesc": 7223.33,
      "PmtNum": "PK",
      "PoNum": "10\/13\/2022",
      "VndType": "PV",
      "APStatus": 2305759,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 2044.35,
      "ConcatenatedSegments": 0,
      "bInvAmt": 2044.35,
      "bDiscAmt": 0,
      "bTaxAmt": 790413591,
      "APID": 2016731
     },
     {
      "DataSource": "E221138",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-91883",
      "InvDate": "10\/05\/2022",
      "InvAmt": 47.76,
      "AbsInvAmt": 47.76,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304322,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 47.76,
      "ConcatenatedSegments": 0,
      "bInvAmt": 47.76,
      "bDiscAmt": 0,
      "bTaxAmt": 790491883,
      "APID": 2016792
     },
     {
      "DataSource": "E221139",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-905-18784",
      "InvDate": "10\/05\/2022",
      "InvAmt": 24.25,
      "AbsInvAmt": 24.25,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304321,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 24.25,
      "ConcatenatedSegments": 0,
      "bInvAmt": 24.25,
      "bDiscAmt": 0,
      "bTaxAmt": 790518784,
      "APID": 2016814
     },
     {
      "DataSource": "E221140",
      "Co": "Bedrock Facilities",
      "VndNum": 432370,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-904-86948",
      "InvDate": "10\/05\/2022",
      "InvAmt": 200.81,
      "AbsInvAmt": 200.81,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679061,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 267.08,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14758503,
      "DupeX": "UTL",
      "BusinessUnitName": 48845310,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 200.81,
      "ConcatenatedSegments": 0,
      "bInvAmt": 200.81,
      "bDiscAmt": 0,
      "bTaxAmt": 790486948,
      "APID": 1970794
     },
     {
      "DataSource": "E221141",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-904-79148",
      "InvDate": "10\/05\/2022",
      "InvAmt": 106.87,
      "AbsInvAmt": 106.87,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676252,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 155.66,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/14\/2022",
      "VndType": "P5",
      "APStatus": 14739200,
      "DupeX": "UTL",
      "BusinessUnitName": 48848805,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 451,
      "PaymentTermsName": 106.87,
      "ConcatenatedSegments": 0,
      "bInvAmt": 106.87,
      "bDiscAmt": 0,
      "bTaxAmt": 790479148,
      "APID": 1969901
     },
     {
      "DataSource": "E221142",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-905-11973",
      "InvDate": "10\/05\/2022",
      "InvAmt": 23.78,
      "AbsInvAmt": 23.78,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304313,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 23.78,
      "ConcatenatedSegments": 0,
      "bInvAmt": 23.78,
      "bDiscAmt": 0,
      "bTaxAmt": 790511973,
      "APID": 2016806
     },
     {
      "DataSource": "E221143",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-905-07798",
      "InvDate": "10\/05\/2022",
      "InvAmt": 11.89,
      "AbsInvAmt": 11.89,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304320,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 11.89,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.89,
      "bDiscAmt": 0,
      "bTaxAmt": 790507798,
      "APID": 2016800
     },
     {
      "DataSource": "E221144",
      "Co": "Bedrock Facilities",
      "VndNum": 288134,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-905-32475",
      "InvDate": "10\/05\/2022",
      "InvAmt": 102.73,
      "AbsInvAmt": 102.73,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677963,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 102.73,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/24\/2022",
      "VndType": "P5",
      "APStatus": 14757413,
      "DupeX": "UTL",
      "BusinessUnitName": 76060037,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 750,
      "PaymentTermsName": 102.73,
      "ConcatenatedSegments": 0,
      "bInvAmt": 102.73,
      "bDiscAmt": 0,
      "bTaxAmt": 790532475,
      "APID": 1979221
     },
     {
      "DataSource": "E221145",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-30720",
      "InvDate": "10\/05\/2022",
      "InvAmt": 58.78,
      "AbsInvAmt": 58.78,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304309,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 58.78,
      "ConcatenatedSegments": 0,
      "bInvAmt": 58.78,
      "bDiscAmt": 0,
      "bTaxAmt": 790430720,
      "APID": 2016738
     },
     {
      "DataSource": "E221146",
      "Co": "Bedrock Facilities",
      "VndNum": 432370,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-904-48324",
      "InvDate": "10\/05\/2022",
      "InvAmt": 124.74,
      "AbsInvAmt": 124.74,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676251,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 163.58,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/17\/2022",
      "VndType": "P5",
      "APStatus": 14744039,
      "DupeX": "UTL",
      "BusinessUnitName": 48845320,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 124.74,
      "ConcatenatedSegments": 0,
      "bInvAmt": 124.74,
      "bDiscAmt": 0,
      "bTaxAmt": 790448324,
      "APID": 1989274
     },
     {
      "DataSource": "E221147",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-35296",
      "InvDate": "10\/05\/2022",
      "InvAmt": 202.88,
      "AbsInvAmt": 202.88,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304314,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 202.88,
      "ConcatenatedSegments": 0,
      "bInvAmt": 202.88,
      "bDiscAmt": 0,
      "bTaxAmt": 790435296,
      "APID": 2016746
     },
     {
      "DataSource": "E221148",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-85193",
      "InvDate": "10\/05\/2022",
      "InvAmt": 92.92,
      "AbsInvAmt": 92.92,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304316,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 92.92,
      "ConcatenatedSegments": 0,
      "bInvAmt": 92.92,
      "bDiscAmt": 0,
      "bTaxAmt": 790485193,
      "APID": 2016784
     },
     {
      "DataSource": "E221149",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-66141",
      "InvDate": "10\/05\/2022",
      "InvAmt": 7.73,
      "AbsInvAmt": 7.73,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304319,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 7.73,
      "ConcatenatedSegments": 0,
      "bInvAmt": 7.73,
      "bDiscAmt": 0,
      "bTaxAmt": 790466141,
      "APID": 2016776
     },
     {
      "DataSource": "E221150",
      "Co": "Bedrock Facilities",
      "VndNum": 7185930,
      "VndName": "FEDEX (SSC ACCT. #3702-0848-4)",
      "InvNum": "7-904-28791",
      "InvDate": "10\/05\/2022",
      "InvAmt": 27.54,
      "AbsInvAmt": 27.54,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836443,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 701.14,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773799,
      "DupeX": "UTL",
      "BusinessUnitName": 48845030,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 27.54,
      "ConcatenatedSegments": 0,
      "bInvAmt": 27.54,
      "bDiscAmt": 0,
      "bTaxAmt": 790428791,
      "APID": 2008591
     },
     {
      "DataSource": "E221151",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-65459",
      "InvDate": "10\/05\/2022",
      "InvAmt": 22.37,
      "AbsInvAmt": 22.37,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304308,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 22.37,
      "ConcatenatedSegments": 0,
      "bInvAmt": 22.37,
      "bDiscAmt": 0,
      "bTaxAmt": 790465459,
      "APID": 2016769
     },
     {
      "DataSource": "E221152",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-63295",
      "InvDate": "10\/05\/2022",
      "InvAmt": 27.08,
      "AbsInvAmt": 27.08,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304318,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 27.08,
      "ConcatenatedSegments": 0,
      "bInvAmt": 27.08,
      "bDiscAmt": 0,
      "bTaxAmt": 790463295,
      "APID": 2016762
     },
     {
      "DataSource": "E221153",
      "Co": "Bedrock Facilities",
      "VndNum": 113120,
      "VndName": "FedEx",
      "InvNum": "7-904-58901",
      "InvDate": "10\/05\/2022",
      "InvAmt": 21.31,
      "AbsInvAmt": 21.31,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": 11402637,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 4524.44,
      "PmtNum": "PK",
      "PoNum": "10\/05\/2022",
      "VndType": "PV",
      "APStatus": 2304310,
      "BusinessUnitName": 1,
      "PayGroup": "PKELLY",
      "PaymentClearedDate": 1,
      "PaymentTermsName": 21.31,
      "ConcatenatedSegments": 0,
      "bInvAmt": 21.31,
      "bDiscAmt": 0,
      "bTaxAmt": 790458901,
      "APID": 2016754
     },
     {
      "DataSource": "E221154",
      "Co": "Bedrock Facilities",
      "VndNum": 1019362,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-903-84597",
      "InvDate": "10\/04\/2022",
      "InvAmt": 462.61,
      "AbsInvAmt": 462.61,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674338,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 6717.77,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14730144,
      "DupeX": "UTL",
      "BusinessUnitName": 7500,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 750,
      "PaymentTermsName": 462.61,
      "ConcatenatedSegments": 0,
      "bInvAmt": 462.61,
      "bDiscAmt": 0,
      "bTaxAmt": 790384597,
      "APID": 515322
     },
     {
      "DataSource": "E221155",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-903-01407",
      "InvDate": "10\/04\/2022",
      "InvAmt": 123.14,
      "AbsInvAmt": 123.14,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833115,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 123.14,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/17\/2022",
      "VndType": "P5",
      "APStatus": 14743271,
      "DupeX": "UTL",
      "BusinessUnitName": 8732,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 849,
      "PaymentTermsName": 123.14,
      "ConcatenatedSegments": 0,
      "bInvAmt": 123.14,
      "bDiscAmt": 0,
      "bTaxAmt": 790301407,
      "APID": 1984730
     },
     {
      "DataSource": "E221156",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-903-05355",
      "InvDate": "10\/04\/2022",
      "InvAmt": 271.81,
      "AbsInvAmt": 271.81,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831894,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 271.81,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14729536,
      "DupeX": "UTL",
      "BusinessUnitName": 8640,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 271.81,
      "ConcatenatedSegments": 0,
      "bInvAmt": 271.81,
      "bDiscAmt": 0,
      "bTaxAmt": 790305355,
      "APID": 1984738
     },
     {
      "DataSource": "E221157",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "9-635-72856",
      "InvDate": "10\/04\/2022",
      "InvAmt": 12.6,
      "AbsInvAmt": 12.6,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831895,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 12.6,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14729537,
      "DupeX": "UTL",
      "BusinessUnitName": 8720,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 12.6,
      "ConcatenatedSegments": 0,
      "bInvAmt": 12.6,
      "bDiscAmt": 0,
      "bTaxAmt": 963572856,
      "APID": 1985721
     },
     {
      "DataSource": "E221158",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-903-45081",
      "InvDate": "10\/04\/2022",
      "InvAmt": 88.71,
      "AbsInvAmt": 88.71,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674337,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 1580.22,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14729890,
      "DupeX": "UTL",
      "BusinessUnitName": 8519,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 88.71,
      "ConcatenatedSegments": 0,
      "bInvAmt": 88.71,
      "bDiscAmt": 0,
      "bTaxAmt": 790345081,
      "APID": 2009497
     },
     {
      "DataSource": "E221159",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-903-95730",
      "InvDate": "10\/04\/2022",
      "InvAmt": 884.3,
      "AbsInvAmt": 884.3,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831889,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 884.3,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/07\/2022",
      "VndType": "P5",
      "APStatus": 14726524,
      "DupeX": "UTL",
      "BusinessUnitName": 8665,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 884.3,
      "ConcatenatedSegments": 0,
      "bInvAmt": 884.3,
      "bDiscAmt": 0,
      "bTaxAmt": 790395730,
      "APID": 1982377
     },
     {
      "DataSource": "E221160",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-903-56858",
      "InvDate": "10\/04\/2022",
      "InvAmt": 73.16,
      "AbsInvAmt": 73.16,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831888,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 73.16,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/06\/2022",
      "VndType": "P5",
      "APStatus": 14726108,
      "DupeX": "UTL",
      "BusinessUnitName": 8648,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 806,
      "PaymentTermsName": 73.16,
      "ConcatenatedSegments": 0,
      "bInvAmt": 73.16,
      "bDiscAmt": 0,
      "bTaxAmt": 790356858,
      "APID": 1969996
     },
     {
      "DataSource": "E221161",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-903-52430",
      "InvDate": "10\/04\/2022",
      "InvAmt": 132.31,
      "AbsInvAmt": 132.31,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836440,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 132.31,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773238,
      "DupeX": "UTL",
      "BusinessUnitName": 8575,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 828,
      "PaymentTermsName": 132.31,
      "ConcatenatedSegments": 0,
      "bInvAmt": 132.31,
      "bDiscAmt": 0,
      "bTaxAmt": 790352430,
      "APID": 1985706
     },
     {
      "DataSource": "E221162",
      "Co": "Bedrock Facilities",
      "VndNum": 509199,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-089-49485",
      "InvDate": "10\/04\/2022",
      "InvAmt": 40.65,
      "AbsInvAmt": 40.65,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10676253,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 81.99,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/14\/2022",
      "VndType": "P5",
      "APStatus": 14740009,
      "DupeX": "UTL",
      "BusinessUnitName": 65350246,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 710,
      "PaymentTermsName": 40.65,
      "ConcatenatedSegments": 0,
      "bInvAmt": 40.65,
      "bDiscAmt": 0,
      "bTaxAmt": 208949485,
      "APID": 1994572
     },
     {
      "DataSource": "E221163",
      "Co": "Bedrock Facilities",
      "VndNum": 7185930,
      "VndName": "FEDEX (SSC ACCT. #3702-0848-4)",
      "InvNum": "7-902-90291",
      "InvDate": "10\/04\/2022",
      "InvAmt": 17.23,
      "AbsInvAmt": 17.23,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 836443,
      "FY": "11\/01\/2022",
      "InvTypeDesc": 701.14,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/31\/2022",
      "VndType": "P5",
      "APStatus": 14773822,
      "DupeX": "UTL",
      "BusinessUnitName": 48845030,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 17.23,
      "ConcatenatedSegments": 0,
      "bInvAmt": 17.23,
      "bDiscAmt": 0,
      "bTaxAmt": 790290291,
      "APID": 2007457
     },
     {
      "DataSource": "E221164",
      "Co": "Bedrock Facilities",
      "VndNum": 8678894,
      "VndName": "FEDEX FREIGHT",
      "InvNum": 8480022084,
      "InvDate": "10\/04\/2022",
      "InvAmt": 347.2,
      "AbsInvAmt": 347.2,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677969,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 347.2,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/20\/2022",
      "VndType": "P5",
      "APStatus": 14752107,
      "DupeX": "UTL",
      "MatchInvNum": "ACCT: 953915120",
      "BusinessUnitName": 86261570,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 347.2,
      "ConcatenatedSegments": 0,
      "bInvAmt": 347.2,
      "bDiscAmt": 0,
      "bTaxAmt": 8480022084,
      "APID": 2017122
     },
     {
      "DataSource": "E221165",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-904-09538",
      "InvDate": "10\/04\/2022",
      "InvAmt": 14.03,
      "AbsInvAmt": 14.03,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831892,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 14.03,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14728969,
      "DupeX": "UTL",
      "MatchInvNum": "7-904-09538",
      "BusinessUnitName": 86261609,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 14.03,
      "ConcatenatedSegments": 0,
      "bInvAmt": 14.03,
      "bDiscAmt": 0,
      "bTaxAmt": 790409538,
      "APID": 1982385
     },
     {
      "DataSource": "E221166",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-903-05353",
      "InvDate": "10\/04\/2022",
      "InvAmt": 391.9,
      "AbsInvAmt": 391.9,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831893,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 391.9,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14728972,
      "DupeX": "UTL",
      "MatchInvNum": "7-903-05353",
      "BusinessUnitName": 8623,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 391.9,
      "ConcatenatedSegments": 0,
      "bInvAmt": 391.9,
      "bDiscAmt": 0,
      "bTaxAmt": 790305353,
      "APID": 1989151
     },
     {
      "DataSource": "E221167",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-902-87936",
      "InvDate": "10\/04\/2022",
      "InvAmt": 342.23,
      "AbsInvAmt": 342.23,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831890,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 342.23,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/07\/2022",
      "VndType": "P5",
      "APStatus": 14726682,
      "DupeX": "UTL",
      "MatchInvNum": "7-902-87936",
      "BusinessUnitName": 8631,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 805,
      "PaymentTermsName": 342.23,
      "ConcatenatedSegments": 0,
      "bInvAmt": 342.23,
      "bDiscAmt": 0,
      "bTaxAmt": 790287936,
      "APID": 1969987
     },
     {
      "DataSource": "E221168",
      "Co": "Bedrock Facilities",
      "VndNum": 509199,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-903-08274",
      "InvDate": "10\/04\/2022",
      "InvAmt": 18.25,
      "AbsInvAmt": 18.25,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10677966,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 242.82,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/24\/2022",
      "VndType": "P5",
      "APStatus": 14756233,
      "DupeX": "UTL",
      "BusinessUnitName": 48844200,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 558,
      "PaymentTermsName": 18.25,
      "ConcatenatedSegments": 0,
      "bInvAmt": 18.25,
      "bDiscAmt": 0,
      "bTaxAmt": 790308274,
      "APID": 1994587
     },
     {
      "DataSource": "E221169",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-903-85080",
      "InvDate": "10\/04\/2022",
      "InvAmt": 182.14,
      "AbsInvAmt": 182.14,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674337,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 1580.22,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/12\/2022",
      "VndType": "P5",
      "APStatus": 14734938,
      "DupeX": "UTL",
      "BusinessUnitName": 48845300,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 182.14,
      "ConcatenatedSegments": 0,
      "bInvAmt": 182.14,
      "bDiscAmt": 0,
      "bTaxAmt": 790385080,
      "APID": 2019710
     },
     {
      "DataSource": "E221170",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-903-34129",
      "InvDate": "10\/04\/2022",
      "InvAmt": 11.61,
      "AbsInvAmt": 11.61,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833114,
      "FY": "10\/18\/2022",
      "InvTypeDesc": 11.61,
      "PmtNum": "PT",
      "PmtAmt": 23812034,
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/17\/2022",
      "VndType": "P5",
      "APStatus": 14743019,
      "DupeX": "UTL",
      "MatchInvNum": "7-903-34129",
      "BusinessUnitName": 5238,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 553,
      "PaymentTermsName": 11.61,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.61,
      "bDiscAmt": 0,
      "bTaxAmt": 790334129,
      "APID": 1989158
     },
     {
      "DataSource": "E221171",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-901-59523",
      "InvDate": "10\/03\/2022",
      "InvAmt": 28.86,
      "AbsInvAmt": 28.86,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 832355,
      "FY": "10\/13\/2022",
      "InvTypeDesc": 28.86,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/13\/2022",
      "VndType": "P5",
      "APStatus": 14738168,
      "DupeX": "UTL",
      "BusinessUnitName": 24821610,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 326,
      "PaymentTermsName": 28.86,
      "ConcatenatedSegments": 0,
      "bInvAmt": 28.86,
      "bDiscAmt": 0,
      "bTaxAmt": 790159523,
      "APID": 1984721
     },
     {
      "DataSource": "E221172",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-902-03386",
      "InvDate": "10\/03\/2022",
      "InvAmt": 21.68,
      "AbsInvAmt": 21.68,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674335,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 131.04,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/05\/2022",
      "VndType": "P5",
      "APStatus": 14722919,
      "DupeX": "UTL",
      "BusinessUnitName": 8206,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 833,
      "PaymentTermsName": 21.68,
      "ConcatenatedSegments": 0,
      "bInvAmt": 21.68,
      "bDiscAmt": 0,
      "bTaxAmt": 790203386,
      "APID": 1970809
     },
     {
      "DataSource": "E221173",
      "Co": "Bedrock Facilities",
      "VndNum": 433665,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "2-089-53665",
      "InvDate": "10\/03\/2022",
      "InvAmt": 51.12,
      "AbsInvAmt": 51.12,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10674335,
      "FY": "10\/12\/2022",
      "InvTypeDesc": 131.04,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/05\/2022",
      "VndType": "P5",
      "APStatus": 14722926,
      "DupeX": "UTL",
      "BusinessUnitName": 8231,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 833,
      "PaymentTermsName": 51.12,
      "ConcatenatedSegments": 0,
      "bInvAmt": 51.12,
      "bDiscAmt": 0,
      "bTaxAmt": 208953665,
      "APID": 1968779
     },
     {
      "DataSource": "E221174",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "9-635-63936",
      "InvDate": "10\/03\/2022",
      "InvAmt": 4.78,
      "AbsInvAmt": 4.78,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 831896,
      "FY": "10\/11\/2022",
      "InvTypeDesc": 4.78,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/10\/2022",
      "VndType": "P5",
      "APStatus": 14730088,
      "DupeX": "UTL",
      "BusinessUnitName": 24821610,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 326,
      "PaymentTermsName": 4.78,
      "ConcatenatedSegments": 0,
      "bInvAmt": 4.78,
      "bDiscAmt": 0,
      "bTaxAmt": 963563936,
      "APID": 1981972
     },
     {
      "DataSource": "E221175",
      "Co": "Bedrock Facilities",
      "VndNum": 509199,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-901-20255",
      "InvDate": "10\/03\/2022",
      "InvAmt": 331.51,
      "AbsInvAmt": 331.51,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 10679063,
      "FY": "10\/27\/2022",
      "InvTypeDesc": 1886.78,
      "PmtNum": "PK",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/25\/2022",
      "VndType": "P5",
      "APStatus": 14758642,
      "DupeX": "UTL",
      "BusinessUnitName": 1087,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 12,
      "PaymentTermsName": 331.51,
      "ConcatenatedSegments": 0,
      "bInvAmt": 331.51,
      "bDiscAmt": 0,
      "bTaxAmt": 790120255,
      "APID": 1985853
     },
     {
      "DataSource": "E221176",
      "Co": "Bedrock Facilities",
      "VndNum": 379049,
      "VndName": "FEDEX",
      "InvNum": "7-900-94449",
      "InvDate": "10\/03\/2022",
      "InvAmt": 11.18,
      "AbsInvAmt": 11.18,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "CurrCode": 833486,
      "FY": "10\/20\/2022",
      "InvTypeDesc": 11.18,
      "PmtNum": "PT",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/19\/2022",
      "VndType": "P5",
      "APStatus": 14750399,
      "DupeX": "UTL",
      "BusinessUnitName": 8503,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 827,
      "PaymentTermsName": 11.18,
      "ConcatenatedSegments": 0,
      "bInvAmt": 11.18,
      "bDiscAmt": 0,
      "bTaxAmt": 790094449,
      "APID": 1982371
     }
    ],
    "Payment file to upload":[
     {
      "DataSource": "D221104",
      "Co": "Bedrock Facilities",
      "VndNum": 1132313,
      "VndName": "Innovation SALES & SERVICE CO.",
      "InvNum": 919086391,
      "InvDate": "10\/17\/2022",
      "InvAmt": 11212.39,
      "AbsInvAmt": 11212.39,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "NetAmt": 2022,
      "FY": "01\/01\/1900",
      "InvTypeDesc": 578238.68,
      "PmtDate": "05\/08\/2023",
      "PmtTypeDesc": "SUP",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14764928,
      "DupeX": "SUP",
      "BusinessUnitName": 25578069,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 330,
      "PaymentTermsName": 105.39,
      "ConcatenatedSegments": 0,
      "bInvAmt": 105.39,
      "bDiscAmt": 0,
      "bTaxAmt": 919086391,
      "APID": 503283,
      "Field1": 322667
     },
     {
      "DataSource": "D220304",
      "Co": "Bedrock Facilities",
      "VndNum": 6461647,
      "VndName": "STAHL TBM MANAGEMENT CO LLC",
      "InvNum": "INV Q1 NOV19-JAN20",
      "InvDate": "01\/31\/2020",
      "InvAmt": 94703.13,
      "AbsInvAmt": 94703.13,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "PmtNum": 10372551,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 94703.13,
      "PmtTypeDesc": "CHECK",
      "bInvAmt": 94703.13,
      "bDiscAmt": 0,
      "bTaxAmt": 11,
      "APID": 691291
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": "637542A",
      "InvDate": "07\/05\/2022",
      "InvAmt": 28540.78,
      "AbsInvAmt": 28540.78,
      "ApplyToInvAmt": 28540.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219185,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 28540.78,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40249,
      "InvDescr": "PO #40249",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 637542,
      "bInvAmt": 28540.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 175090
     },
     {
      "DataSource": "D211323",
      "Co": "Bedrock Facilities",
      "VndNum": 982908,
      "VndName": "FEDEX CORPORATION",
      "InvNum": "7-910-25559",
      "InvDate": "10\/11\/2022",
      "InvAmt": 2773.72,
      "AbsInvAmt": 518.81,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "NetAmt": 2022,
      "CurrCode": 10677967,
      "FY": "10\/25\/2022",
      "InvTypeDesc": 1584.63,
      "PmtNum": "PK",
      "PmtDate": "05\/08\/2023",
      "PmtTypeDesc": "UTL",
      "PoNum": "10\/24\/2022",
      "VndType": "P5",
      "APStatus": 14757070,
      "DupeX": "UTL",
      "BusinessUnitName": 48845300,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 500,
      "PaymentTermsName": 518.81,
      "ConcatenatedSegments": 0,
      "bInvAmt": 518.81,
      "bDiscAmt": 0,
      "bTaxAmt": 791062997,
      "APID": 2014601
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:7413:60673",
      "VndName": "ARIBA INC",
      "InvNum": 6028686292,
      "InvDate": "07\/21\/2021",
      "InvAmt": 15543.8,
      "AbsInvAmt": 15543.8,
      "ApplyToInvAmt": 15543.8,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 354843,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 17768.19,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 48174,
      "InvDescr": "PO #48174",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 6028686292,
      "bInvAmt": 15543.8,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 136802
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028769103,
      "InvDate": "10\/25\/2021",
      "InvAmt": 2224.39,
      "AbsInvAmt": 2224.39,
      "ApplyToInvAmt": 2224.39,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 312125,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 17963.26,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107063",
      "DupeX": 0,
      "MatchInvNum": 6028769103,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "01\/12\/2022",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 2224.39,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 15403
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028526650,
      "InvDate": "02\/24\/2021",
      "InvAmt": 139.36,
      "AbsInvAmt": 139.36,
      "ApplyToInvAmt": 139.36,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 299305,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 2949.97,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028526650,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/15\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 139.36,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 31976
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028123495,
      "InvDate": "12\/24\/2019",
      "InvAmt": 43.18,
      "AbsInvAmt": 43.18,
      "ApplyToInvAmt": 43.18,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 299305,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 2949.97,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028123495,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/15\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 43.18,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 40087
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028585890,
      "InvDate": "04\/27\/2021",
      "InvAmt": 45610.64,
      "AbsInvAmt": 45610.64,
      "ApplyToInvAmt": 45610.64,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 300087,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 65730.14,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107063",
      "DupeX": 0,
      "MatchInvNum": 6028585890,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/16\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 45610.64,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 52696
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028804370,
      "InvDate": "11\/23\/2021",
      "InvAmt": 195.07,
      "AbsInvAmt": 195.07,
      "ApplyToInvAmt": 195.07,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 312125,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 17963.26,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028804370,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "01\/12\/2022",
      "PaymentTermsName": "Immediate",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 195.07,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 60046
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028279094,
      "InvDate": "06\/02\/2020",
      "InvAmt": 2612.78,
      "AbsInvAmt": 2612.78,
      "ApplyToInvAmt": 2612.78,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 299305,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 2949.97,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028279094,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/15\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 2612.78,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 63598
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6890789482,
      "InvDate": "03\/15\/2019",
      "InvAmt": 147.34,
      "AbsInvAmt": 147.34,
      "ApplyToInvAmt": 147.34,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 300369,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 151.02,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6890789482,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/18\/2021",
      "PaymentTermsName": "Immediate -override",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 147.34,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 63588
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028346470,
      "InvDate": "08\/29\/2020",
      "InvAmt": 101.58,
      "AbsInvAmt": 101.58,
      "ApplyToInvAmt": 101.58,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 299305,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 2949.97,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-107144",
      "DupeX": 0,
      "MatchInvNum": 6028346470,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "11\/15\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 101.58,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 71236
     },
     {
      "DataSource": "C220624",
      "Co": "Bedrock Facilities",
      "VndNum": 100125,
      "VndName": "ARIBA INC",
      "InvNum": 6028560743,
      "InvDate": "04\/09\/2021",
      "InvAmt": 8497.7,
      "AbsInvAmt": 8497.7,
      "ApplyToInvAmt": 8497.7,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "Standard NPR",
      "PmtNum": 267197,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 8497.7,
      "PmtTypeDesc": "Electronic",
      "PoNum": "1012-106545",
      "DupeX": 0,
      "MatchInvNum": 6028560743,
      "BusinessUnitName": "1012 OBU",
      "PayGroup": "Standard NPR",
      "PaymentClearedDate": "06\/02\/2021",
      "PaymentTermsName": "N30",
      "ConcatenatedSegments": "1012-000-30000-0000-0000-00000-0000-00000-00000",
      "bInvAmt": 8497.7,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 77123
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020607795,
      "InvDate": "04\/04\/2018",
      "InvAmt": 124.12,
      "AbsInvAmt": 124.12,
      "ApplyToInvAmt": 124.12,
      "DiscAmt": 0,
      "TaxAmt": 16.17,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60789,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 96498.67,
      "PmtTypeDesc": "CHECK",
      "InvDescr": "IC PO #40078 \/ FREIGHT",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020607795,
      "bInvAmt": 124.12,
      "bDiscAmt": 0,
      "bTaxAmt": 16.17,
      "bNetAmt": 0,
      "APID": 176233
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020616232,
      "InvDate": "06\/22\/2018",
      "InvAmt": 354119.29,
      "AbsInvAmt": 354119.29,
      "ApplyToInvAmt": 354119.29,
      "DiscAmt": 0,
      "TaxAmt": 46122.54,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 60952,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 355555.1,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40402,
      "InvDescr": "IC PO #40402",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020616232,
      "bInvAmt": 354119.29,
      "bDiscAmt": 0,
      "bTaxAmt": 46122.54,
      "bNetAmt": 0,
      "APID": 176242
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020620001,
      "InvDate": "07\/30\/2018",
      "InvAmt": 1586.29,
      "AbsInvAmt": 1586.29,
      "ApplyToInvAmt": 1586.29,
      "DiscAmt": 0,
      "TaxAmt": 75.54,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61383,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 1586.29,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40712,
      "InvDescr": "IC PO #40712",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020620001,
      "bInvAmt": 1586.29,
      "bDiscAmt": 0,
      "bTaxAmt": 75.54,
      "bNetAmt": 0,
      "APID": 176250
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020621130,
      "InvDate": "08\/10\/2018",
      "InvAmt": 1586.29,
      "AbsInvAmt": 1586.29,
      "ApplyToInvAmt": 1586.29,
      "DiscAmt": 0,
      "TaxAmt": 75.54,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61139,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 209306.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40826,
      "InvDescr": "IC PO #40826",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020621130,
      "bInvAmt": 1586.29,
      "bDiscAmt": 0,
      "bTaxAmt": 75.54,
      "bNetAmt": 0,
      "APID": 176258
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020622632,
      "InvDate": "09\/17\/2018",
      "InvAmt": 3629.19,
      "AbsInvAmt": 3629.19,
      "ApplyToInvAmt": 3629.19,
      "DiscAmt": 0,
      "TaxAmt": 472.69,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61173,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 3629.19,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40965,
      "InvDescr": "IC PO #40965",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020622632,
      "bInvAmt": 3629.19,
      "bDiscAmt": 0,
      "bTaxAmt": 472.69,
      "bNetAmt": 0,
      "APID": 176267
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020623144,
      "InvDate": "08\/16\/2018",
      "InvAmt": 78079.55,
      "AbsInvAmt": 78079.55,
      "ApplyToInvAmt": 78079.55,
      "DiscAmt": 0,
      "TaxAmt": 10169.55,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61120,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 157005.09,
      "PmtTypeDesc": "CHECK",
      "PoNum": 40913,
      "InvDescr": "IC PO #40913",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020623144,
      "bInvAmt": 78079.55,
      "bDiscAmt": 0,
      "bTaxAmt": 10169.55,
      "bNetAmt": 0,
      "APID": 176275
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Dev",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020625774,
      "InvDate": "09\/27\/2018",
      "InvAmt": 8393.23,
      "AbsInvAmt": 8393.23,
      "ApplyToInvAmt": 8393.23,
      "DiscAmt": 0,
      "TaxAmt": 1093.2,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61209,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 279214.74,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41087,
      "InvDescr": "IC PO #41087",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020625774,
      "bInvAmt": 8393.23,
      "bDiscAmt": 0,
      "bTaxAmt": 1093.2,
      "bNetAmt": 0,
      "APID": 176285
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020633513,
      "InvDate": "12\/12\/2018",
      "InvAmt": 10648.99,
      "AbsInvAmt": 10648.99,
      "ApplyToInvAmt": 10648.99,
      "DiscAmt": 0,
      "TaxAmt": 1386.99,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 61419,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 62595.18,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41467,
      "InvDescr": "IC PO #41467",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 2020633513,
      "bInvAmt": 10648.99,
      "bDiscAmt": 0,
      "bTaxAmt": 1386.99,
      "bNetAmt": 0,
      "APID": 176291
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 1P8",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 2020682862,
      "InvDate": "02\/28\/2020",
      "InvAmt": 0,
      "AbsInvAmt": 0,
      "ApplyToInvAmt": -63193.44,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2020,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 40388,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 63193.44,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PmtVoidDate": "04\/07\/2020",
      "PoNum": 44125,
      "InvDescr": "UPDATE PAYMENT METHOD",
      "VndType": "SUPPLIER-PPSS",
      "APStatus": "OFFSET",
      "DupeX": 1,
      "MatchInvNum": 2020682862,
      "bInvAmt": 0,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176299
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:5301:M5W 6B5",
      "VndName": "HEWLETT PACKARD ENTERPRISE CANADA CO",
      "InvNum": 6636515,
      "InvDate": "09\/25\/2019",
      "InvAmt": 208195.97,
      "AbsInvAmt": 208195.97,
      "ApplyToInvAmt": 208195.97,
      "DiscAmt": 0,
      "TaxAmt": 23225.28,
      "NetAmt": 0,
      "CurrCode": "CAD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 80293,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 51657.14,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 41385,
      "InvDescr": "IC PO #41385",
      "VndType": "SUPPLIER-PPSS",
      "DupeX": 0,
      "MatchInvNum": 6636515,
      "bInvAmt": 208301.39,
      "bDiscAmt": 0,
      "bTaxAmt": 23237.04,
      "bNetAmt": 0,
      "APID": 176307
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2502936447,
      "InvDate": "02\/28\/2019",
      "InvAmt": 375488.12,
      "AbsInvAmt": 375488.12,
      "ApplyToInvAmt": 375488.12,
      "DiscAmt": 0,
      "TaxAmt": 6.35,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 219983,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 375488.12,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41735,
      "InvDescr": "PO #41735",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2502936447,
      "bInvAmt": 375488.12,
      "bDiscAmt": 0,
      "bTaxAmt": 6.35,
      "bNetAmt": 0,
      "APID": 176383
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2502973945,
      "InvDate": "04\/11\/2019",
      "InvAmt": 2936.08,
      "AbsInvAmt": 2936.08,
      "ApplyToInvAmt": 2936.08,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 30138,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 34070.08,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41989,
      "InvDescr": "PO #41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2502973945,
      "bInvAmt": 2936.08,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176390
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 2502999882,
      "InvDate": "05\/08\/2019",
      "InvAmt": 1903.03,
      "AbsInvAmt": 1903.03,
      "ApplyToInvAmt": 1903.03,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2019,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 30351,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 30023.03,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41989,
      "InvDescr": "PO 41989",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 2502999882,
      "bInvAmt": 1903.03,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176396
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60424186,
      "InvDate": "09\/09\/2018",
      "InvAmt": 3090.53,
      "AbsInvAmt": 3090.53,
      "ApplyToInvAmt": 3090.53,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 218485,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 3090.53,
      "PmtTypeDesc": "CHECK",
      "PoNum": 41030,
      "InvDescr": "PO #41030",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60424186,
      "bInvAmt": 3090.53,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176441
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 60652490,
      "InvDate": "02\/02\/2021",
      "InvAmt": 54228.62,
      "AbsInvAmt": 54228.62,
      "ApplyToInvAmt": 54228.62,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2021,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 352596,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 56448.62,
      "PmtTypeDesc": "OUTSOURCED_CHECK",
      "PoNum": 45446,
      "InvDescr": "PO #45446",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 60652490,
      "bInvAmt": 54228.62,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176447
     },
     {
      "DataSource": "E220624",
      "Co": "Bedrock Facilities",
      "VndNum": "81:7749:30392",
      "VndName": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "InvNum": 634182,
      "InvDate": "01\/29\/2018",
      "InvAmt": 27500,
      "AbsInvAmt": 27500,
      "ApplyToInvAmt": 27500,
      "DiscAmt": 0,
      "TaxAmt": 0,
      "NetAmt": 0,
      "CurrCode": "USD",
      "FY": 2018,
      "InvTypeDesc": "STANDARD",
      "PmtNum": 216635,
      "PmtDate": "05\/08\/2023",
      "PmtAmt": 82500,
      "PmtTypeDesc": "CHECK",
      "PoNum": 38813,
      "InvDescr": "PO #38813",
      "VndType": "SUPPLIER",
      "DupeX": 0,
      "MatchInvNum": 634182,
      "bInvAmt": 27500,
      "bDiscAmt": 0,
      "bTaxAmt": 0,
      "bNetAmt": 0,
      "APID": 176487
     },
     null,
     {
      "DataSource": "DataSource",
      "Co": "Co",
      "VndNum": "VndNum",
      "VndName": "VndName",
      "InvNum": "InvNum",
      "InvDate": "InvDate",
      "InvAmt": "InvAmt",
      "AbsInvAmt": "AbsInvAmt",
      "ApplyToInvAmt": "DiscAmt",
      "DiscAmt": "TaxAmt",
      "TaxAmt": "CurrCode",
      "NetAmt": "FY",
      "CurrCode": "PmtNum",
      "FY": "PmtDate",
      "InvTypeDesc": "PmtAmt",
      "PmtNum": "PmtTypeDesc",
      "PmtDate": "PmtVoidDate",
      "PmtAmt": "PoNum",
      "PmtTypeDesc": "VndType",
      "PmtVoidDate": "APStatus",
      "PoNum": "G L DATE",
      "InvDescr": "ADJUSTING DOCUMENT TYPE",
      "VndType": "VOUCHER DOCUMENT TYPE",
      "APStatus": "VOUCHER NUMBER",
      "DupeX": "VENDOR GROUP",
      "MatchInvNum": "INVOICE DESCRIPTION REMARKS",
      "BusinessUnitName": "BUSINESS UNIT LOCATION",
      "PayGroup": "TRANSACTION ORIGINATOR PROCESSOR",
      "PaymentClearedDate": "COMPANY NUMBER",
      "PaymentTermsName": "bInvAmt",
      "ConcatenatedSegments": "bDiscAmt",
      "bInvAmt": "applytoinvamt",
      "bDiscAmt": "DupeX",
      "bTaxAmt": "MatchInvNum",
      "bNetAmt": "SplitPay",
      "APID": "APID",
      "Field1": "ID"
     },
     {
      "DataSource": "ABM221104",
      "VndNum": 1132313,
      "VndName": "TENNANT SALES & SERVICE CO.",
      "InvNum": 919086391,
      "InvDate": "10\/17\/2022",
      "InvAmt": 105.39,
      "AbsInvAmt": 105.39,
      "ApplyToInvAmt": 0,
      "DiscAmt": 0,
      "TaxAmt": "USD",
      "NetAmt": 2022,
      "FY": "01\/01\/1900",
      "InvTypeDesc": 578238.68,
      "PmtTypeDesc": "SUP",
      "PoNum": "10\/27\/2022",
      "VndType": "P5",
      "APStatus": 14764928,
      "DupeX": "SUP",
      "BusinessUnitName": 25578069,
      "PayGroup": "WEBGATEWAY",
      "PaymentClearedDate": 330,
      "PaymentTermsName": 105.39,
      "ConcatenatedSegments": 0,
      "bInvAmt": 105.39,
      "bDiscAmt": 0,
      "bTaxAmt": 919086391,
      "APID": 503283,
      "Field1": 322667
     }
    ],
    "Prevention - Fields to Present":[
     {
      "id": 1,
      "Reason": "Multiple Supplier ID",
      "Description": "Last digits of the invoice match",
      "Source": "E221177",
      "Supplier_Number": 57439,
      "Supplier_Name": "Innovation SALES",
      "Invoice_Number": 2123919086391,
      "Invoice_Date": "10\/17\/2022",
      "Gross_Amount": 11212.39,
      "Currency": "USD",
      "PO": 488921,
      "Payment_Date": "11\/01\/2022",
      "Payment_Status": "Paid",
      "Payment_Type": "ACH",
      "Staff": "John Chem"
     },
     {
      "id": 1,
      "Status": "Pending",
      "Reason": "Multiple Supplier ID",
      "Description": "Last digits of the invoice match",
      "Incorrect Invoice": "x",
      "Source": "D221104",
      "Supplier_Number": 1132313,
      "Supplier_Name": "Innovation SALES & SERVICE CO.",
      "Invoice_Number": 21919086391,
      "Invoice_Date": "10\/17\/2022",
      "Gross_Amount": 11212.39,
      "Currency": "USD",
      "Payment_Date": "12\/02\/2022",
      "Payment_Status": "Pending",
      "Payment_Type": "ACH",
      "Staff": "John Chem"
     },
     {
      "id": 2,
      "Status": "Pending",
      "Reason": "Same Invoice",
      "Description": "Numeric and Character ",
      "Source": "D220304",
      "Supplier_Number": 6461647,
      "Supplier_Name": "STAHL TBM MANAGEMENT CO LLC",
      "Invoice_Number": "INV Q1 NOV19-JAN20",
      "Invoice_Date": "01\/31\/2020",
      "Gross_Amount": 94703.13,
      "Currency": "USD",
      "Payment_Date": "03\/05\/2020",
      "Payment_Status": "Paid",
      "Payment_Type": "ACH",
      "Staff": "Rita Belle"
     },
     {
      "id": 2,
      "Reason": "Same Invoice",
      "Description": "Numeric and Character ",
      "Incorrect Invoice": "x",
      "Source": "E201111",
      "Supplier_Number": 6461647,
      "Supplier_Name": "STAHL TBM MANAGEMENT CO LLC",
      "Invoice_Number": "INV Q1 11.19-01.20 R",
      "Invoice_Date": "01\/31\/2020",
      "Gross_Amount": 94703.13,
      "Currency": "USD",
      "Payment_Date": "06\/08\/2020",
      "Payment_Status": "Pending",
      "Payment_Type": "ACH",
      "Staff": "John Chem"
     },
     {
      "id": 3,
      "Status": "Pending",
      "Reason": "Same Invoice",
      "Description": "Special Character ",
      "Source": "ORACLE",
      "Supplier_Number": "81:7749:30392",
      "Supplier_Name": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "Invoice_Number": 637542,
      "Invoice_Date": "06\/05\/2022",
      "Gross_Amount": 28540.78,
      "Currency": "USD",
      "Payment_Date": "08\/04\/2022",
      "Payment_Status": "Pending",
      "Payment_Type": "Check",
      "Staff": "Rita Belle"
     },
     {
      "id": 3,
      "Reason": "Same Invoice",
      "Description": "Special Character ",
      "Incorrect Invoice": "x",
      "Source": "SAP01",
      "Supplier_Number": "81:7749:30392",
      "Supplier_Name": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "Invoice_Number": "637542A",
      "Invoice_Date": "07\/05\/2022",
      "Gross_Amount": 28540.78,
      "Currency": "USD",
      "PO": 41091,
      "Payment_Status": "Paid",
      "Payment_Type": "Check",
      "Staff": "Rita Belle"
     },
     {
      "id": 4,
      "Status": "Pending",
      "Reason": "Multiple Supplier ID",
      "Description": "Same Invoice Number ",
      "Source": "SAP01",
      "Supplier_Number": 379049,
      "Supplier_Name": "FEDEX",
      "Invoice_Number": "7-910-25559",
      "Invoice_Date": "10\/11\/2022",
      "Gross_Amount": 2773.72,
      "Currency": "USD",
      "PO": 14768262,
      "Payment_Date": "11\/12\/2022",
      "Payment_Status": "Paid",
      "Payment_Type": "ACH",
      "Staff": "Rita Belle"
     },
     {
      "id": 4,
      "Reason": "Multiple Supplier ID",
      "Description": "Same Invoice Number ",
      "Incorrect Invoice": "x",
      "Source": "SAP02",
      "Supplier_Number": 982908,
      "Supplier_Name": "FEDEX CORPORATION",
      "Invoice_Number": "7-910-25559",
      "Invoice_Date": "10\/11\/2022",
      "Gross_Amount": 2773.72,
      "Currency": "USD",
      "Payment_Status": "Pending",
      "Payment_Type": "ACH",
      "Staff": "Rita Belle"
     },
     {
      "id": 5,
      "Status": "Pending",
      "Reason": "Multiple Supplier ID",
      "Description": "Multiple ERP",
      "Source": "E220624",
      "Supplier_Number": "81:7413:60673",
      "Supplier_Name": "ARIBA INC",
      "Invoice_Number": 6028686292,
      "Invoice_Date": "07\/21\/2021",
      "Gross_Amount": 15543.8,
      "Currency": "USD",
      "Payment_Date": "04\/28\/2022",
      "Payment_Status": "Paid",
      "Payment_Type": "OUTSOURCED_CHECK",
      "Staff": "John Chem"
     },
     {
      "id": 5,
      "Reason": "Multiple Supplier ID",
      "Description": "Multiple ERP",
      "Incorrect Invoice": "x",
      "Source": "C220624",
      "Supplier_Number": 100125,
      "Supplier_Name": "ARIBA INC",
      "Invoice_Number": 6028686292,
      "Invoice_Date": "07\/21\/2021",
      "Gross_Amount": 15543.8,
      "Currency": "EUR",
      "Payment_Date": "5\/8\/203",
      "Payment_Status": "Pending",
      "Payment_Type": "Electronic",
      "Staff": "John Chem"
     },
     {
      "id": 6,
      "Status": "Pending",
      "Reason": "Multiple Supplier ID",
      "Description": "Multiple ERP",
      "Source": "E220624",
      "Supplier_Number": "81:7413:60673",
      "Supplier_Name": "ARIBA INC",
      "Invoice_Number": 6028769103,
      "Invoice_Date": "10\/25\/2021",
      "Gross_Amount": 2224.39,
      "Currency": "USD",
      "Payment_Date": "04\/28\/2022",
      "Payment_Status": "Paid",
      "Payment_Type": "OUTSOURCED_CHECK",
      "Staff": "John Chem"
     },
     {
      "id": 6,
      "Reason": "Multiple Supplier ID",
      "Description": "Multiple ERP",
      "Incorrect Invoice": "x",
      "Source": "C220624",
      "Supplier_Number": 100125,
      "Supplier_Name": "ARIBA INC",
      "Invoice_Number": 6028769103,
      "Invoice_Date": "10\/25\/2021",
      "Gross_Amount": 2224.39,
      "Currency": "EUR",
      "Payment_Date": "5\/8\/203",
      "Payment_Status": "Pending",
      "Payment_Type": "Electronic",
      "Staff": "John Chem"
     },
     null,
     {
      "Status": "Pending"
     },
     {
      "Status": "Stop"
     },
     {
      "Status": "Process"
     },
     null,
     null,
     null,
     {
      "id": "id",
      "Status": "Status",
      "Reason": "Reason",
      "Description": "Staff",
      "Incorrect Invoice": "Description",
      "Source": "Incorrect Invoice",
      "Supplier_Number": "Source",
      "Supplier_Name": "Supplier_Number",
      "Invoice_Number": "Supplier Name",
      "Invoice_Date": "Invoice Number",
      "Gross_Amount": "Invoice Date",
      "Currency": "Gross Amount",
      "PO": "Currency",
      "Payment_Date": "PO",
      "Payment_Status": "Payment_Date",
      "Payment_Type": "Payment_Type"
     },
     {
      "Status": "Stop",
      "Reason": "Split Payment",
      "Description": "John Chem"
     },
     {
      "Status": "Stop",
      "Reason": "Multiple Product\/Services",
      "Description": "John Chem"
     },
     {
      "Status": "Stop",
      "Reason": "Multiple Product\/Services",
      "Description": "Rita Belle"
     },
     {
      "Status": "Process",
      "Reason": "Same Invoice",
      "Description": "John Chem"
     },
     {
      "Status": "Process",
      "Reason": "Same Invoice",
      "Description": "Rita Belle"
     },
     {
      "Status": "Process",
      "Reason": "Same Invoice",
      "Description": "Rita Belle"
     },
     {
      "Status": "Process",
      "Reason": "Same Invoice",
      "Description": "Rita Belle"
     },
     {
      "Status": "Process",
      "Reason": "Same Invoice",
      "Description": "Rita Belle"
     },
     {
      "Status": "Stop",
      "Reason": "Different Location",
      "Description": "John Chem"
     },
     {
      "Status": "Stop",
      "Reason": "2nd Payment",
      "Description": "John Chem"
     }
    ],
    "Prevention - Report":[
     {
      "Spend VS Prevent": "Paid",
      "Column2": 12121100,
      "Column3": 14210023,
      "Column4": 16298946,
      "Column5": 14387869.76,
      "Column6": 14476792,
      "Column7": 19565715.21,
      "Column8": 24654638,
      "Column9": 22743561
     },
     {
      "Spend VS Prevent": "Count",
      "Column2": 1920,
      "Column3": 2230,
      "Column4": 2540,
      "Column5": 2850,
      "Column6": 3160,
      "Column7": 3470,
      "Column8": 2780,
      "Column9": 3090
     },
     {
      "Spend VS Prevent": "Prevented",
      "Column2": 429011,
      "Column3": 102032,
      "Column4": 523210.21,
      "Column5": 578950.21,
      "Column6": 226049.5,
      "Column7": 873149,
      "Column8": 700248.5,
      "Column9": 1167348
     },
     {
      "Spend VS Prevent": "Count",
      "Column2": 9,
      "Column3": 18,
      "Column4": 38,
      "Column5": 13,
      "Column6": 22,
      "Column7": 39,
      "Column8": 32,
      "Column9": 46
     },
     {
      "Spend VS Prevent": "% of $",
      "Column2": 0.03539373489204775,
      "Column3": 0.007180283944649491,
      "Column4": 0.03210086161399639,
      "Column5": 0.040238771941733226,
      "Column6": 0.01561461268490975,
      "Column7": 0.04462648007642139,
      "Column8": 0.028402303047402277,
      "Column9": 0.0513265270992524
     },
     {
      "Spend VS Prevent": "% of Count",
      "Column2": 0.0046875,
      "Column3": 0.008071748878923767,
      "Column4": 0.014960629921259842,
      "Column5": 0.004561403508771929,
      "Column6": 0.006962025316455696,
      "Column7": 0.011239193083573486,
      "Column8": 0.011510791366906475,
      "Column9": 0.01488673139158576
     },
     {
      "Column2": "Week 1",
      "Column3": "Week 2",
      "Column4": "Week 3",
      "Column5": "Week 4",
      "Column6": "Week 5",
      "Column7": "Week 6",
      "Column8": "Week 7",
      "Column9": "Week 8"
     },
     null,
     {
      "Spend VS Prevent": "RootCause",
      "Column5": "Weekly",
      "Column6": "Monthly",
      "Column7": "Quarterly",
      "Column8": "Annual"
     },
     {
      "Spend VS Prevent": "Multiple Vendor ID",
      "Column3": 422,
      "Column4": 6306866.9
     },
     {
      "Spend VS Prevent": "Multiple Vendor Name",
      "Column3": 375,
      "Column4": 2965900
     },
     {
      "Spend VS Prevent": "Multiple Currency",
      "Column3": 56,
      "Column4": 2624933.1
     },
     {
      "Spend VS Prevent": "Multiple ERP",
      "Column3": 189,
      "Column4": 2283966.2
     },
     {
      "Spend VS Prevent": "Multiple Payment_Type",
      "Column3": 621,
      "Column4": 1942999.3
     },
     {
      "Spend VS Prevent": "Same Invoice",
      "Column3": 386,
      "Column4": 1602032.4,
      "Column9": "V"
     },
     null,
     {
      "Spend VS Prevent": "Supplier"
     },
     {
      "Spend VS Prevent": "Brand Marketing Inc",
      "Column2": 2201544,
      "Column3": 6
     },
     {
      "Spend VS Prevent": "HEWLETT PACKARD ENTERPRISE COMPANY",
      "Column2": 1832553,
      "Column3": 22
     },
     {
      "Spend VS Prevent": "Innovation SALES",
      "Column2": 1663562,
      "Column3": 522
     },
     {
      "Spend VS Prevent": "STAHL TBM MANAGEMENT CO LLC",
      "Column2": 994571,
      "Column3": 41
     },
     {
      "Spend VS Prevent": "FEDEX CORPORATION",
      "Column2": 151122,
      "Column3": 173
     },
     {
      "Spend VS Prevent": "ARIBA INC",
      "Column2": 48322,
      "Column3": 3
     },
     null,
     {
      "Spend VS Prevent": "Summary Results"
     },
     {
      "Column2": "Count",
      "Column3": "$"
     },
     {
      "Spend VS Prevent": "Potential",
      "Column2": 10422,
      "Column3": 115502391
     },
     {
      "Spend VS Prevent": "Pending Review",
      "Column2": 288,
      "Column3": 3129921
     },
     {
      "Spend VS Prevent": "Prevented",
      "Column2": 2049,
      "Column3": 17726697.9
     },
     {
      "Spend VS Prevent": "False Positive",
      "Column2": 8085,
      "Column3": 94645772.1
     }
    ],
    "Reason":[
     {
      "Claim Type": "Duplicate",
      "Error Reason": "Currency Issue"
     },
     {
      "Claim Type": "Duplicate",
      "Error Reason": "Payment in Error- dif vendors "
     },
     {
      "Claim Type": "Duplicate",
      "Error Reason": "Payment in Error- wrong invoice "
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Return"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Overpayment"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Rebate"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Open credit"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Pricing Issue"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Tax issue"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Unapplied cash"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Paid credit memo"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Caceled invoice"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Rebill"
     }
    ],
    "Description":[
     {
      "Claim Type": "Duplicate",
      "Error Reason": "Currency Issue",
      "Description \/ Action needed": "One payment was  for one currency and second payment for another currency  - supplier may call this an overpayment, but It's a currency issue. \/ Only Deduction  - refund  approval is needed"
     },
     {
      "Claim Type": "Duplicate",
      "Error Reason": "Payment in Error- dif vendors ",
      "Description \/ Action needed": "Submitted payment to the same invoice for two different vendor numbers - same supplier name \/ Only Deduction - refund  approval is needed"
     },
     {
      "Claim Type": "Duplicate",
      "Error Reason": "Payment in Error- wrong invoice ",
      "Description \/ Action needed": "Paid an invoice the belongs a diferent vendor   \/ Only Deduction - refund  approval is needed"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Return",
      "Description \/ Action needed": "Goods were returned , usually you will see specific items listed in the credit memos. \/ We will need credit memo copies and Originating invoice numbers for each return along with approval"
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Overpayment",
      "Description \/ Action needed": "When invoice is paid for a bigger amount than what the invoice is for, a lot of times invoice amount is changed by the supplier and not sent to client.  \/ Invoice copy with the corrected amount along with the approval."
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Rebate",
      "Description \/ Action needed": "Money the client earned for they bought \/ Only the credit memo is needed along with approval."
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Open credit",
      "Description \/ Action needed": "If no documentation is available  \/ Request a refund or approval."
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Pricing Issue",
      "Description \/ Action needed": "Very smiliar to overpayment \/  Depending on the amount of credits, we can just ask for a spread sheet including the invoice number, date, correct amount,  amount paid and date paid and use this is backup along with deduciton approval. "
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Tax issue",
      "Description \/ Action needed": "Usually client paid taxes and they were exempt  \/ Sometimes we may have the invoice copy without taxes, if the invoice the client paid had taxes, please request the credit memo or the corrected invoice copy showing no taxes, along with deduction approval."
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Unapplied cash",
      "Description \/ Action needed": "This probably means the supplier received a payment with no remittance, so research payment data base from clinet and try to find the payment information or this could be an overpayment  \/ Need confirmarion from supplier."
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Paid credit memo",
      "Description \/ Action needed": "Client paid the credit memo as an invoice instead of taking as a deduction, most of the time the credit amount open is double the amount of the credit memo \/ We need the credit memo copy and dedution approval."
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Caceled invoice",
      "Description \/ Action needed": "Usually supplier issued a credit memo to cancel the invoice and client paid anyway \/  Ask for the credit memo copy and the appoval."
     },
     {
      "Claim Type": "Statement ",
      "Error Reason": "Rebill",
      "Description \/ Action needed": "When an invoice was issued for the wrong price, PO, goods, etc. and supplier issues a new invoice to correct it. Ask for original invoices and payments,  for original invoice and rebilled invoice. If we don't client access."
     }
    ]
    },
  status: "unStatus",
  error: null,
};
const preventAndInviewSlice = createSlice({
  initialState: initialState,
  name: "preventAndInviewSlice",
  reducers: {
    searchRecord: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrevent.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPrevent.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array

        let result = action.payload;
        let onbording = result.onboarding_tab;
        // onbording.sort((a,b) => a.order - b.order);
        // for (let index = 0; index < onbording.length; index++) {
        //   const element = onbording[index];
        //   element?.with?.sort((a,b) => a.field_order - b.field_order);
        // }
        state.result = onbording;

        return state;
      })
      .addCase(fetchPrevent.rejected, (state, action) => {
        console.log('action:', action)
        state.status = "failed";

        state.error = action;
      });
  },
});
export const { searchProduct, filterRecord, updateCurrentPage } =
  preventAndInviewSlice.actions;
export default preventAndInviewSlice.reducer;

export const fetchPrevent = createAsyncThunk(
  "preventAndInviewSlice/fetchVendor",
  async (props) => {
   const cleanse=props.cleanse?'_cleans':'';
    const query = queryLink(props);

    const link = `https://bedrock-api.ews-jo.com/api/onboarding${cleanse}?${query}`;

    console.log("link:", link);
    const client = axios.create({
      baseURL:
      //link
     'http://localhost:3000/json/prevention_and_iniew.json',
    });

    let response = await client
      .get()
      .then((result) => {
        return result;
      })
      ////////
      .catch((error) => {
        console.log(error, "error test");
      });

    return response.data;
  }
);
function queryLink(props) {
  const vendor = props?.vendor ? `vendor=${props.vendor}` : "";
  const query = vendor;
  console.log('query:', query)
  return query;
}

