import React from 'react'
///props
//attributes Object  ex:{onClick:function}
//text String
const ItemMenuUserLink = (props) => {
  return (
    <>
        {/*begin::Menu item*/}
        <div className="menu-item px-5">
              <a      {...props} className="menu-link px-5">
              {props.text}
              </a>
            </div>
            {/*end::Menu item*/}
    </>
  )
}

export default ItemMenuUserLink
