
import Step from "./Step";

const TimeLineVertical = (props) => {
  const steps = [
    { title: "Details", desc: "Name your App", isFirst: true },
    { title: "Frameworks", desc: "Define your app framework" },
    { title: "Database", desc: "Select the app database type" },
    { title: "Billing", desc: "Provide payment details" },
  ];
  const complete = {
    title: "Completed",
    desc: "Review and Submit",
    isCompleted: true,
  };
  return (
    <>
      {/*begin::Stepper*/}
      <div  className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
        {/*begin::Nav*/}
        <div className="stepper-nav ps-lg-10">
            {steps.map((element,key)=><Step key={key} num={key+1} {...element} />)}
     

          {/*begin::Step isCompleted*/}
          <Step num={steps.length+1} {...complete} />

          {/*end::Step isCompleted*/}
        </div>
        {/*end::Nav*/}
      </div>
      {/*begin::Stepper*/}
    </>
  );
};

export default TimeLineVertical;
