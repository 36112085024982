import "../style.css";
import { Logo, HeaderMobileToggle, NavbarHeaderPrimary } from "../../index";
const HeaderPrimary = (props) => {
  return (
    <>
      {/*begin::Header primary*/}
      <div
        className="app-header-primary"
        data-kt-sticky="true"
        data-kt-sticky-activate="{default: false, lg: true}"
        data-kt-sticky-name="app-header-primary-sticky"
        data-kt-sticky-offset="{default: false, lg: '300px'}"
      >
        {/*begin::Header primary container*/}
        <div className="app-container container-xxl d-flex align-items-stretch justify-content-between">
          <HeaderMobileToggle />
          <Logo />
          <NavbarHeaderPrimary menuUser={props.menuUser}/>
        </div>
        {/*end::Header primary container*/}
      </div>
      {/*end::Header primary*/}
    </>
  );
};

export default HeaderPrimary;
