import { useNavigate } from "react-router-dom";
import { PopupMenuItem, ActionPopup } from "../../../../../../../index";

//props
////isCleanse bool
const ActionDiscussion = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <ActionPopup>
        <PopupMenuItem
          text="View"
          attributes={{
            onClick: () => {
              navigate(`/statement_discussions_details_page`);
            },
          }}
        />
        <PopupMenuItem
          text="Edit"
          attributes={{
           
          }}
        />
        <PopupMenuItem
          text="Delete"
          attributes={{
            style: { color: "var(--bs-danger)" },
          }}
        />
      </ActionPopup>
    </>
  );
};

export default ActionDiscussion;
