import React from "react";
import { useSelector } from "react-redux";
import { EmptyTableTD, TableAdmin , LayoutCleanse} from "../../../../../index";

import CardUploadExcelSupplier from "./CardUploadExcelSupplier";
const ImportSupplier = () => {
   const dataCleans = useSelector((state) => state.records?.result
  ??[]);
  return (
    <LayoutCleanse
      title="Import Supplier"
      pages={[ "Import Supplier"]}
    >
    < CardUploadExcelSupplier/>
      <TableAdmin
        isExport={true}
        isSelest={true}
        titles={[
          "File Name",
          "Total Records",
          "Total Records Auto Archive",
          "Total Records Archive",
          "Total Vendors Responded By TinCheck Verification",
          "Uploaded By",
          "Date Uploaded",
          "Date Uploaded",
        ]}
      >
        <TableBody records={dataCleans.records??[]} />
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default ImportSupplier;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;
   
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} />;
    });

    return <>{recordList}</>;
  } else {
    return (
      < EmptyTableTD colSpan={9}/>
    );
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

      <td className="text-end">
        </td>
    </tr>
  );
};
