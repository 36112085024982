import React from "react";
import {

  BtnPrimary,

  EmptyTableTD,
  TableAdmin,
  TDCheck,
} from "../../../../index";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StatementVendorTab = () => {
  const navigate = useNavigate();
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (

    <TableAdmin
    isTableTabs={true}
      isSelest={true}
      isAction={true}
      ActionExtra={
        <BtnPrimary
          text="Upload File"
        //   attributes={{ onClick: ()=>{ navigate("/upload_statements")} }}
        />
      }
      titles={[ "Files",'Show to vendors area','Date Uploaded','Options']}
    >
      <TableBody records={dataCleans.records ?? []} />
    </TableAdmin>
    
  );
};

export default StatementVendorTab;
const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={3} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <td></td>
      <td></td>
      
      <td className="text-end"></td>
    </tr>
  );
};
