

const  TDReason= (props) => {
    
  const Reason = props.reason;
  return (
    <td>{Reason}</td>
  )
}

export default TDReason
