import React from "react";
import MyLabel from "./MyLabel";

const RowInputWithLable = (props) => {
  return (
    <>
      {/*begin::Input group*/}
      <div className={`row mb-6 ${props.className ?? ""}`}>
        {/*begin::Label*/}
        <MyLabel
          {...props}
          className={`col-lg-4 col-form-label fw-semibold fs-6`}
        />

        {/*end::Label*/}
        {/*begin::Col*/}
        <div className="col-lg-8 fv-row">{props.children}</div>
        {/*end::Col*/}
      </div>
      {/*end::Input group*/}
    </>
  );
};

export default RowInputWithLable;
