import { useNavigate } from "react-router-dom";
import { PopupMenuItem ,ActionPopup} from "../../index";


//props 
//id 
const VendorAction = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <ActionPopup>

        <PopupMenuItem
          text="View"
          attributes={{
            "onClick": ()=>{navigate(`/vendor_view_admin_profile_page/`)},
          }}
        />
         <PopupMenuItem
          text="Contact"
          attributes={{
            "onClick": ()=>{navigate(`/vendor_contacts_page/`)},
          }}
        />
        <PopupMenuItem
          text="Delete"
          attributes={{
            style: { color: "var(--bs-danger)" },
          }}
        />
      </ActionPopup>
     
    </>
  );
};

export default VendorAction;


