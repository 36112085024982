import {
  CardWhite,
  CheckBox,
  ColTextFiledWithLabel,
  EnablesRadiosBtn,
  InputGroupRow,
  LayoutAdmin,
  MarginBottomFiled,
  MyLabel,
  MySelect,
  TitleSmall,
} from "../../../index";
const SetupFeaturesEnable = () => {
  return (
    <LayoutAdmin
      title="Features Enable"
      pages={["Setup", "Features Enable", "Audit"]}
    >
      <CardWhite margenTop margenBottom>
        <MyLabel label="Verification" /> <EnablesRadiosBtn />
      </CardWhite>
      <CardWhite margenBottom>
        <TitleSmall title="Tin Check" className=" pb-5 pb-lg-10" />
        <MarginBottomFiled>
          <ColTextFiledWithLabel
            label="Username"
            value="engineering@sphereaccess.com"
            name="settings[tincheck_username]"
          />
        </MarginBottomFiled>
        <MarginBottomFiled>
          <ColTextFiledWithLabel
            label="Password"
            type="password"
            name="settings[tincheck_password]"
          />
          <CheckBox title="Show Password" isHint={true} />
        </MarginBottomFiled>
        <MarginBottomFiled>
          <InputGroupRow
            col1={
              <ColTextFiledWithLabel
                label="tincheck max calls"
                type="number"
                name="settings[tincheck_max_calls]"
              />
            }
            col2={
              <ColTextFiledWithLabel
                label="tincheck remaining calls"
                type="number"
                name="settings[tincheck_remaining_calls]"
              />
            }
          />
        </MarginBottomFiled>
      </CardWhite>
      <CardWhite margenBottom>
        <TitleSmall
          title="Tincheck Filter Status"
          className="pt-5  pb-5 pb-lg-10"
        />
        <MarginBottomFiled>
          <MySelect label="pass" name="pass" />
        </MarginBottomFiled>
        <MarginBottomFiled>
          <MySelect label="Fail" name="Fail" />
        </MarginBottomFiled>
        <MarginBottomFiled>
          <MySelect label="Low Pass" name="Low Pass" />
        </MarginBottomFiled>
        <TitleSmall
          title="Tincheck Filter Status"
          className="pt-10 pt-lg-15 pb-5 pb-lg-10"
        />
        <MarginBottomFiled>
          <ColTextFiledWithLabel
            label="API Link (must end with slash / )"
            name="settings[bank_verification_api_link]"
          />
        </MarginBottomFiled>
        <MarginBottomFiled>
          <ColTextFiledWithLabel
            label="Client ID"
            name="settings[bank_verification_client_id]"
          />
        </MarginBottomFiled>
        <MarginBottomFiled>
          <ColTextFiledWithLabel
            label="Client Secret"
            name="settings[bank_verification_client_secret]"
          />
        </MarginBottomFiled>
      </CardWhite>
    </LayoutAdmin>
  );
};

export default SetupFeaturesEnable;
