import React from 'react'

const TDState = (props) => {
    const state=props.state;
  return (
 
      <td>{state}</td>

  )
}

export default TDState
