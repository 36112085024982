import React from 'react'

function Spain() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path fill="#C8414B" d="M1.985 0H510.015V512H1.985z"></path>
            <path
                fill="#FFD250"
                d="M1.985 107.79H510.015V404.21000000000004H1.985z"
            ></path>
            <path
                fill="#C8414B"
                d="M223.347 256.409l8.191-33.404c.754-3.076-1.829-5.994-5.306-5.994h-5.77c-3.477 0-6.061 2.918-5.306 5.994l8.191 33.404z"
            ></path>
            <path
                fill="#F5F5F5"
                d="M213.681 238.89H233.00300000000001V313.31H213.681z"
            ></path>
            <path fill="#FAB446" d="M208.861 230.14H237.845V238.896H208.861z"></path>
            <g fill="#C8414B">
                <path d="M189.531 256.41H237.83100000000002V265.16600000000005H189.531z"></path>
                <path d="M237.839 291.429L208.856 282.674 208.856 273.919 237.839 282.674z"></path>
                <path d="M78.435 256.409l8.191-33.404c.754-3.076-1.829-5.994-5.306-5.994h-5.77c-3.477 0-6.061 2.918-5.306 5.994l8.191 33.404z"></path>
            </g>
            <path
                fill="#F5F5F5"
                d="M112.248 230.143c-5.335 0-9.661 3.919-9.661 8.756v56.908c0 10.638 10.955 30.643 48.305 30.643s48.305-20.006 48.305-30.643v-56.908c0-4.835-4.325-8.756-9.661-8.756h-77.288z"
            ></path>
            <g fill="#C8414B">
                <path d="M150.891 273.919h-48.305V239.34a9.197 9.197 0 019.197-9.197h39.107v43.776h.001zM150.891 273.919h48.305v20.784c0 12.698-10.294 22.992-22.992 22.992h-2.32c-12.698 0-22.992-10.294-22.992-22.992l-.001-20.784z"></path>
            </g>
            <path
                fill="#FAB446"
                d="M102.587 273.919h48.305v20.784c0 12.698-10.294 22.992-22.992 22.992h-2.32c-12.698 0-22.992-10.294-22.992-22.992l-.001-20.784z"
            ></path>
            <g fill="#C8414B">
                <path d="M141.231 313.218v-39.299h-9.661v43.334c3.592-.661 6.84-2.103 9.661-4.035zM121.909 317.253v-43.334h-9.661v39.299c2.821 1.932 6.068 3.374 9.661 4.035z"></path>
            </g>
            <path
                fill="#FFB441"
                d="M112.241 256.41H141.225V265.16600000000005H112.241z"
            ></path>
            <g fill="#FAB446">
                <path d="M112.241 238.89H141.225V247.646H112.241z"></path>
                <path d="M117.081 244.1H136.403V259.961H117.081z"></path>
            </g>
            <path fill="#F5F5F5" d="M68.774 238.89H88.096V313.31H68.774z"></path>
            <g fill="#FAB446">
                <path d="M63.941 308.94H92.92500000000001V317.696H63.941z"></path>
                <path d="M63.941 230.14H92.92500000000001V238.896H63.941z"></path>
            </g>
            <path
                fill="#5064AA"
                d="M59.111 317.7H97.75399999999999V326.456H59.111z"
            ></path>
            <path
                fill="#FAB446"
                d="M213.681 308.94H242.66500000000002V317.696H213.681z"
            ></path>
            <path fill="#5064AA" d="M204.031 317.7H242.674V326.456H204.031z"></path>
            <path fill="#FAB446" d="M121.911 221.39H179.878V230.146H121.911z"></path>
            <path fill="#FFB441" d="M146.061 195.13H155.722V221.4H146.061z"></path>
            <g fill="#F5F5F5">
                <path d="M141.231 208.255c-7.991 0-14.491-5.891-14.491-13.132s6.5-13.132 14.491-13.132 14.491 5.891 14.491 13.132c-.001 7.241-6.501 13.132-14.491 13.132zm0-17.51c-2.665 0-4.83 1.963-4.83 4.378s2.165 4.378 4.83 4.378 4.83-1.963 4.83-4.378c0-2.416-2.165-4.378-4.83-4.378z"></path>
                <path d="M160.552 208.255c-7.991 0-14.491-5.891-14.491-13.132s6.5-13.132 14.491-13.132 14.491 5.891 14.491 13.132c-.001 7.241-6.5 13.132-14.491 13.132zm0-17.51c-2.665 0-4.83 1.963-4.83 4.378s2.165 4.378 4.83 4.378 4.83-1.963 4.83-4.378c0-2.416-2.165-4.378-4.83-4.378z"></path>
                <path d="M179.874 217.011c-7.991 0-14.491-5.891-14.491-13.132s6.5-13.132 14.491-13.132 14.491 5.891 14.491 13.132-6.501 13.132-14.491 13.132zm0-17.511c-2.665 0-4.83 1.963-4.83 4.378s2.165 4.378 4.83 4.378 4.83-1.963 4.83-4.378c0-2.416-2.165-4.378-4.83-4.378zM121.909 217.011c-7.991 0-14.491-5.891-14.491-13.132s6.5-13.132 14.491-13.132 14.491 5.891 14.491 13.132c-.001 7.241-6.501 13.132-14.491 13.132zm0-17.511c-2.665 0-4.83 1.963-4.83 4.378s2.165 4.378 4.83 4.378 4.83-1.963 4.83-4.378c0-2.416-2.165-4.378-4.83-4.378z"></path>
            </g>
            <path
                fill="#FAB446"
                d="M179.874 291.429v4.378c0 2.414-2.167 4.378-4.83 4.378s-4.83-1.964-4.83-4.378v-4.378h9.66m9.66-8.755h-28.983v13.132c0 7.241 6.501 13.132 14.491 13.132 7.991 0 14.491-5.891 14.491-13.132l.001-13.132z"
            ></path>
            <path
                fill="#FFA0D2"
                d="M175.507 265.163h-.928a9.197 9.197 0 01-9.197-9.197v-7.872a9.197 9.197 0 019.197-9.197h.928a9.197 9.197 0 019.197 9.197v7.872a9.196 9.196 0 01-9.197 9.197z"
            ></path>
            <ellipse
                cx="150.891"
                cy="273.92"
                fill="#5064AA"
                rx="14.491"
                ry="13.13"
            ></ellipse>
            <path
                fill="#FAB446"
                d="M146.061 177.61H155.722V203.88000000000002H146.061z"
            ></path>
            <path
                fill="#C8414B"
                d="M121.909 221.388l-9.661-8.756 5.659-5.129c8.748-7.928 20.613-12.381 32.984-12.381 12.371 0 24.237 4.454 32.984 12.381l5.659 5.129-9.661 8.756h-57.964z"
            ></path>
            <g fill="#FFD250">
                <ellipse cx="150.891" cy="212.63" rx="4.83" ry="4.378"></ellipse>
                <ellipse cx="131.571" cy="212.63" rx="4.83" ry="4.378"></ellipse>
                <ellipse cx="170.211" cy="212.63" rx="4.83" ry="4.378"></ellipse>
            </g>
            <g fill="#C8414B">
                <path d="M63.941 256.41H112.241V265.16600000000005H63.941z"></path>
                <path d="M63.943 291.429L92.926 282.674 92.926 273.919 63.943 282.674z"></path>
            </g>
        </svg>
    )
}

export default Spain