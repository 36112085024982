
/////////props
//////////title String 
/////////Children Widget
/////width (Optional)

const PopupDialog = (props) => {
  return (
    <>
      {/*begin::Menu 1*/}
      <div
        className={`menu menu-sub menu-sub-dropdown w-300px w-md-${props.width?? 325}px`}
        data-kt-menu="true"
      >
        {/*begin::Header*/}
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bold">{props.title}</div>
        </div>
        {/*end::Header*/}
        {/*begin::Separator*/}
        <div className="separator border-gray-200" />
        {/*end::Separator*/}
        {/*begin::Content*/}
        <div className="px-7 py-5" data-kt-user-table-filter="form">
        {props.children}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Menu 1*/}
    </>
  );
};

export default PopupDialog;
