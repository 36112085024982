import React from 'react'

const TDVerificationStatus = (props) => {
  const verificationStates = props.verification_states;
  return (
    <td>{verificationStates}</td>
  )
}

export default TDVerificationStatus
