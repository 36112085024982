
////props
//tabs array [ {idContent: String, title: String,isActive: bool }]
const Tabs = (props) => {
    const tabs = props.tabs;
  return (
    <>
           {/*begin:Tabs nav*/}
       <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5">
          <ul className="nav flex-wrap border-transparent fw-bold">
            {tabs?.map((element,key) => (
              <NavitemTabDialog key={key}  title={element.title} id={element.idContent} isActive={element.isActive}/>
            )) ?? ""}
          </ul>
          </div>
        {/*end:Tabs nav*/}  
    </>
  )
}

export default Tabs;

function NavitemTabDialog(props) {
    const isActive=props?.isActive??false;
    return (
      <>
         
        {/*begin:Nav item*/}
        <li className="nav-item mt-1">
          <a
            className={`btn btn-color-gray-500 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase  ${isActive ? "active" : ""}`}
            href="#"
            data-bs-toggle="tab"
            data-bs-target={`#${props.id}`}
          >
            {props.title}
          </a>
        </li>
        {/*end:Nav item*/}
      </>
    );
  }
