
import { useSelector } from "react-redux";
import { TableAdmin, LayoutAdmin} from "../../../index";


import AddStaff from "./AddStaff";

const SetupStaff = () => {
  const dataCleans = useSelector((state) => state.records?.result
  ??[]);
  return (
    <>
      <LayoutAdmin title="Staff" pages={["Setup", "Staff"]}  extraAction={<AddStaff />}>
        <TableAdmin
          isExport={true}
         isAction={true}
          titles={["Full Name", "Email", "Role", "Last Login", "Active"]}
        >
          <TableBody records={dataCleans.records??[]} />
        </TableAdmin>
        {/* <Pagenation
          total={100}
          limit={20}
          updateCurrentPage={updateCurrentPage}
        /> */}
      </LayoutAdmin>
    </>
  );
};

export default SetupStaff;
const TableBody = (props) => {
  const recordes = props.records;

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return (
        <Record
          key={key}
          record={record}
          id={record.vendor_id}
          name={record.company}
          email={record.email}
          tax={record.tax_id}
          address1={record.address_1}
          country={record.country}
          status={record.status}
        />
      );
    });

    return <>{recordList}</>;
  } else {
    return (
      <tr>
        <td colSpan={9}>
          <center>Empty data</center>
        </td>
      </tr>
    );
  }
};

const Record = (props) => {
  const record = props.record;

  return (
    <tr key={props.id}>
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            className="form-check-input widget-13-check"
            type="checkbox"
            value="1"
          />
        </div>
      </td>
      <td>{props.id}</td>
      {/* <td>{props.name}</td>  */}

      <td></td>

      <td></td>

      <td></td>
      <td></td>

      <td className="text-end"></td>
    </tr>
  );
};
