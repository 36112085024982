import React from 'react'
import { GroupCheckBox, TitleSmall } from '../../../index'

const FormEmailNotificationVendor = (props) => {
  return (
    <>   <TitleSmall title="Email Notification" className='pt-0 mb-xl-15 mb-5' />
    <GroupCheckBox
      options={[
        { name: "Statement", checked: props.statement ? "checked" : "" },
        { name: "Claims", checked: props.claims ? "checked" : "" },
      ]}
    /></>
  )
}

export default FormEmailNotificationVendor