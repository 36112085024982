
import { BulkActionBtn, ExportBtn, FilterButton, SearchFeild, } from "../index";


export const CardHeader = (props) => {
  return (
    <>
      {/*begin::Card header*/}
      <div className="card-header border-0 pt-6">
        {/*begin::Card title*/}
        <div className="card-title">
          <SearchFeild />
        </div>
        {/*begin::Card title*/}
        {/*begin::Card toolbar*/}
        <div className="card-toolbar">
          {/*begin::Toolbar*/}
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >
            {props.ActionExtra}
            {props.isFilter ? <FilterButton /> : ""}
            {props.isExport ? <ExportBtn /> : ""}
            {props.bulks ? <BulkActionBtn bulks={props.bulks} /> : ""}
          </div>
          {/*end::Toolbar*/}
          {/*begin::Group actions*/}
          <div
            className="d-flex justify-content-end align-items-center d-none"
            data-kt-user-table-toolbar="selected"
          >
            <div className="fw-bold me-5">
              <span
                className="me-2"
                data-kt-user-table-select="selected_count"
              />
              Selected
            </div>
            <button
              type="button"
              className="btn btn-danger"
              data-kt-user-table-select="delete_selected"
            >
              Delete Selected
            </button>
          </div>
          {/*end::Group actions*/}
        </div>
        {/*end::Card toolbar*/}
      </div>
      {/*end::Card header*/}
    </>
  );
};

export default CardHeader;
