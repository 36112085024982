import React from "react";
import {
  CardWhite,
  ColTextAreaWithLabel,
  InputGroupRow,
  MySelect,
  TitleSmall,
  UploadFile,
} from "../../../../index";

const FormClaim = (props) => {
  const claim = { ...props };
  return (
    <CardWhite  margenBottom>
      <TitleSmall title="Claim Body" className="pb-5" />
      <InputGroupRow
        col1={<MySelect option={[]} />}
        col2={<MySelect option={[]} />}
      />
      <ColTextAreaWithLabel />
      <UploadFile />
    </CardWhite>
  );
};

export default FormClaim;
