
//props
// title String
const TitleSmall = (props) => {
  return (
    <>
        <div className={props.className??"pt-10 pt-lg-15 pb-5 pb-lg-10"}>
        {/*begin::Title*/}
        <h2 className="fw-bold d-flex align-items-center text-dark">
     {props.title}
        </h2>
        {/*end::Title*/}
      </div>
    </>
  )
}

export default TitleSmall;
