import React from "react";
import {
  CardWhite,
  CheckBox,
  FormControlContect,
  TitleSmall,
} from "../../../../index";

const AddAPrimaryUserByInvite = () => {
  return (
    <CardWhite margenBottom >
      <TitleSmall title="Add a Primary User" className="pt-0 mb-xl-15 mb-5" />
    <FormControlContect/>
    <CheckBox title="Primary Contact" defaultValue={true} />
    </CardWhite>
  );
};

export default AddAPrimaryUserByInvite;
