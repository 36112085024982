import React from "react";
import {  CardsSummary, EmptyTableTD, TableAdmin, TDCheck } from "../../index";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ClaimsPortalTabs = () => {
  const navigate = useNavigate();
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <div className="mt-5 mt-xl-15"><CardsSummary
    WithoutMT={true}
        filters={[]}
        total_vendors={0}
      />
    <TableAdmin
      titles={[
        "Claim #",
        "Subject",
        "Vendor Name ",
        "Department",
        "Statements",
        "Claim Type",
        "Priority",
        "Department",

        "Status",
        "Last Reply",
      ]}
    >
      <TableBody records={dataCleans.records ?? []} />
    </TableAdmin></div>
  );
};

export default ClaimsPortalTabs;
const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={4} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <td></td>
      <td></td>
      <td></td>
      <td className="text-end"></td>
    </tr>
  );
};
