import React from "react";
import { CardsSummary, EmptyTableTD, LayoutAdmin, LayoutCleanse, TableAdmin } from "../../../../index";
import { useSelector } from "react-redux";
import { TDCheck } from "../data-cleans";

const ApprovalList = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <LayoutCleanse
      title="Approval List"
      pages={["Supplier Invition", "Approval List"]}
    >
      <CardsSummary filters={dataCleans["record_status"]} />
      <TableAdmin
        isExport={true}
        titles={[
          "Company Name",
          "Primary Contact",
          "Primary Email",
          "Phone",
          "Approval",
          "Action",
          "Note",
          "Created Date",
        ]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default ApprovalList;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={8} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={record.id}>
      <TDCheck />
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td className="text-end"></td>
    </tr>
  );
};
