import React from 'react'

const TaxType2 = (props) => {
    const taxType=props.tax_2_type;
  return (
    <td>{taxType}</td>
  )
}

export default TaxType2
