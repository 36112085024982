
import { ColTextFiledWithLabel,TitleSmall,BodyContentFormWithCard } from "../../../../../../index";


const AVGCount = () => {
  return (
    <BodyContentFormWithCard id="setting-avg-count">
      <TitleSmall title="AVG Count " className=" pb-5 pb-lg-10" />

      <div className="mb-10">
      
        <ColTextFiledWithLabel
          label="Filter From"
          name="settings[avg_count_from]"
          type="number"
        />
      </div>
      <div className="mb-10">
      
        <ColTextFiledWithLabel
          label="Filter To"
          name="settings[avg_count_to]"
          type="number"
        />
      </div>
    </BodyContentFormWithCard>
  );
};

export default AVGCount;
