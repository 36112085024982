

const TDAccountMEMO= (props) => {
    const accountMEMO=props.account_memo;
  return (
 
      <td>{accountMEMO}</td>

  )
}

export default TDAccountMEMO
