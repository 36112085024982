import React from "react";


import { useNavigate } from "react-router-dom";
import { LayoutAdmin, HeaderAudit, HeaderAdmin} from "../../index";


///props
//sidebar widget(option)
//children widget
///extraAction btn (option)
//title Strig
//pages array ex:[ "Bedrock Verifiction", "Bedrock Verifiction"]
const LayoutAudit= (props) => {

  const navigate = useNavigate();
  props.pages?.unshift('Audit');

  return (
    <LayoutAdmin  
    title={props.title}
    pages={props.pages}
    extraAction={props.extraAction}
    sidebar={props.sidebar}
      menuNavigationHeader={<HeaderAdmin />}
    
    >
      {props.children}
    </LayoutAdmin>
  );

  
};

export default LayoutAudit;


