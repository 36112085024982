const FormViewTitle = (props) => {
    return (
      <>
        <div
          className={`position-relative mb-8 ${
            props.isFirst ? "mt-4" : "mt-15"
          } px-2 py-1 ${props.className??''}`}
        >
          <div
            className="d-flex flex-row text-muted fs-6 fw-semibold ls-1 text-active-primary ms-0 py-0 me-10 ps-2 border-0 active text-uppercase"
           
          >
            {props.title}
            {/*begin::Bullet*/}
            <span className="bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end" />
            {/*end::Bullet*/}
          </div>
        </div>
      </>
    );
  };

  export default FormViewTitle;