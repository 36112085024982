

const SetupGDPR = () => {
  return (
    <>
      SetupGDPR
    </>
  )
}

export default SetupGDPR;
