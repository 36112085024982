import {
  FormControlContect,
  GroupCheckBox,
  MarginBottomFiled,
} from "../../../index";

const AddPrimaryUser = (props) => {
  const isViewVendor = props.isViewVendor;
  console.log('isViewVendor:', isViewVendor)
  const checkItem = [
    { name: "Do not send welcome email", checked: "" },
    { name: "Send SET password email", checked: "" },
  ];
  if (isViewVendor!=true) {
    checkItem.push({ name: "Primary Contact", checked: "checked" });
  }
  return (
    <>
      <MarginBottomFiled>
        <FormControlContect isViewVendor />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <GroupCheckBox options={checkItem} /> 
      </MarginBottomFiled>
    </>
  );
};

export default AddPrimaryUser;
