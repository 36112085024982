import React from "react";
import {  SubItem } from "../../../index";

const HeaderVendor = () => {
  return (
  
        <>
          <SubItem title="Home" namePage="vendor_layout_page" isNotSubMenu={true}/>
          <SubItem title="Knowledge Base" namePage="knowledge-base-vendor" isNotSubMenu={true}/>
          <SubItem title="Statements" namePage="vendor_my_statements_page" isNotSubMenu={true}/>
          <SubItem title="Claims " namePage="vendor_my_claims_page" isNotSubMenu={true}/>
          <SubItem title="My Request help " namePage="my-help-request-vendor" isNotSubMenu={true}/>
          <SubItem title="Request Help" namePage="help-request-vendor" isNotSubMenu={true}/>
        </>
     
  );
};

export default HeaderVendor;
