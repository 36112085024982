
//props
//children ( NavitemSideBarMneu , TilteitemSideBarMneu )
// isnav bool (option)
const SideBarMneu = (props) => {


  return (
    <>
      {/*begin::Sidebar*/}
      <div
        id="kt_app_sidebar"
        className="app-sidebar align-self-start"
        data-kt-drawer="true"
        data-kt-drawer-name="app-sidebar"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="auto"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_app_sidebar_toggle"
      >
        {/*begin::Sidebar secondary menu*/}
        <div
          className="card flex-grow-1 py-5"
          data-kt-sticky="true"
          data-kt-sticky-name="app-sidebar-menu-sticky"
          data-kt-sticky-offset="{default: false, xl: '400px'}"
          data-kt-sticky-width="260px"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="100px"
          data-kt-sticky-animation="false"
          data-kt-sticky-zindex={95}
        >
          <div
            className={`hover-scroll-y ${
              props.isnav ? "pe-2 pe-lg-5" : "px-2 px-lg-5"
            }`}
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_app_toolbar, #kt_app_footer"
            data-kt-scroll-wrappers1="#kt_app_content"
            data-kt-scroll-offset="5px"
          >
            {/*begin::Navbar*/}
            <div
              id="kt_app_sidebar_menu"
              data-kt-menu="true"
              className="menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6"
            >
              {/*begin::Navs*/}
              <ul className="nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold">
                {props.children}
              </ul>
              {/*begin::Navs*/}
            </div>
            {/*end::Navbar*/}
          </div>
        </div>
        {/*end::Sidebar secondary menu*/}
      </div>
      {/*end::Sidebar*/}
    </>
  );
};

export default SideBarMneu;


