import React from 'react'


///single Page
const SingleItemPagenation = ({ page, onPageChange, currentPage }) => {
  
    return (
      <li
        className={`page-item ${page == currentPage ? "active" : ""}`}
        onClick={() => onPageChange(page)}
      >
        <a className="page-link">{page}</a>
      </li>
    );
  };
  

export default SingleItemPagenation