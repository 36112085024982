import React from "react";
import {
  ColTextFiledWithLabel,
  GroupCheckBox,
  InputGroupRow,
  MarginBottomFiled,
  MyLabel,
  MySelect,
} from "../../../../../index";

const CreateTabFieldOnborading = (props) => {
  return (
    <>
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="Field Belongs to"
              name="fieldto"
              value={props.fieldto}
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="Field Name"
              name="name"
              value={props.name}
            />
          }
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <MySelect
              label="Type"
              name="type"
              value={props.type}
              options={[
                { value: "input", title: "input" },
                { value: "number", title: "Number" },
                { value: "textarea", title: "Textarea" },
                { value: "select", title: "Select" },
                { value: "multi_select", title: "Multi Select" },
                { value: "checkbox", title: "Checkbox" },
                { value: "radio", title: "radio" },
                { value: "date_picker", title: "Date Picker" },
                { value: "datetime_picker", title: "Datetime Picker" },
                { value: "color_picker", title: "Color Picker" },
                { value: "file", title: "File" },
                { value: "country", title: "Country" },
                { value: "link", title: "HyperLink" },
              ]}
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="* Grid (Bootstrap Column eq. 12) - Max is 12"
              name="col"
              type="number"
              value={props.bs_column}
            />
          }
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Default Value"
          name="default-value "
          value={props.default_value}
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Order"
          name="order"
          value={props.field_order}
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <MySelect
          label="related to vendor field"
          value={props.related_to_vendor_field}
          name="related_to_vendor_field"
          options={[
            { value: "input", title: "input" },
            { value: "number", title: "Number" },
            { value: "textarea", title: "Textarea" },
            { value: "select", title: "Select" },
            { value: "multi_select", title: "Multi Select" },
            { value: "checkbox", title: "Checkbox" },
            { value: "radio", title: "radio" },
            { value: "date_picker", title: "Date Picker" },
            { value: "datetime_picker", title: "Datetime Picker" },
            { value: "color_picker", title: "Color Picker" },
            { value: "file", title: "File" },
            { value: "country", title: "Country" },
            { value: "hyperlink", title: "HyperLink" },
          ]}
        />
      </MarginBottomFiled>

      <MarginBottomFiled>
        <GroupCheckBox
          name="form_active"
          options={[
            {
              name: "multi_upload",
              checked: props.multi_upload ? "checked" : "",
            },
            {
              name: "Not Active",
              checked: props.active == "0" ? "checked" : "",
            },
            { name: "Require", checked: props.required ? "checked" : "" },
            {
              name: "Restrict visibility for administrators only",
              checked: props.only_admin ? "checked" : "",
            },
          ]}
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <MyLabel label="Visibility" />
        <GroupCheckBox
          name="form_active"
          options={[{ name: " Show on vendor portal", checked:props.show_on_client_portal? "checked" :''}]}
        />
      </MarginBottomFiled>
    </>
  );
};

export default CreateTabFieldOnborading;
