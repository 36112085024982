import React from "react";
import MenuLink from "./MenuLink";

const MenuItem = (props) => {
  return (
    <>
     
      {/*begin:Menu item*/}
      <div
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        data-kt-menu-placement="bottom-start"
        className="menu-item here show menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2"
      >
        <MenuLink title={props.title ?? ""} />

        {/*begin:Menu sub*/}
        <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-250px">
          {props.children}
        </div>
        {/*end:Menu sub*/}
      </div>
      {/*end:Menu item*/}
    </>
  );
};

export default MenuItem;
