import {
  BtnSecundary,
  BtnPrimary,
  Dialog,
  BtnGray,
  GlobalVerificationForm,
  BankVerificationForm,
} from "../../../../../index";

const CreateFullFormGlobalVerificationDialog = () => {
  return (
    <>
      <Dialog id="kt_modal_global_verification_add" title="Global Verification">
        <GlobalVerificationForm /> <BankVerificationForm />
        <div className="modal-footer flex-center">
          <BtnSecundary text="close" />
          <BtnPrimary text="save" />
        </div>
      </Dialog>
    </>
  );
};

export default CreateFullFormGlobalVerificationDialog;

export const CreateGlobalVerificationFullFormBTN = () => {
  return (
    <>
      <BtnGray
        text="Global Compliance Verification"
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_global_verification_add",
        }}
      />
    </>
  );
};
