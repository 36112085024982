import {ColInputWithLable, TextFiled} from "../index";
///props
////lable    String ex 'lable' (option);
////name     String ex 'name';
////isRequired bool option by default false;
////type bool option by default text;



///tooltip String (option)
const ColTextFiledWithLabel = (props) => {
  return (
    <ColInputWithLable {...props}>
      <TextFiled {...props}/>
    </ColInputWithLable>
  );
};

export default ColTextFiledWithLabel;
