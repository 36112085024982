import "./style.css";

///props
///id 
//classname (option)
///attributes add for btn
///icon (option)
////text string 
const BtnPrimary = (props) => {
  return (
    <>
      <button
        type="button"
        className={`btn btn-primary ${props.className??""}`}
        {...props.attributes}
      >
        {props.icon}
        {props.text}
      </button>
    </>
  );
};

export default BtnPrimary;
