import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TableAdmin,
  CardsSummary,
  BtnPrimary,
  EmptyTableTD,
  VendorArchiveAction,
  TDStatus,
  TDId,
  TDCheck,
  TDNameAndEmail,
  TDCountry,
  TDAddress2,
  TDReason,
  TDAddress1,
  TDHouseNumber,
  TDState,
  TDZipCode,
  TDCity,
  TDCompanyPhoneMumber,
  TDContact,
  TDSearchTrem,
  TDPhon,
  TDFaxNumber,
  TDTax,
  TDTaxType,
  TDTaxType2,
  TDTax2,
  TDAvgSpend,
  TDPaymentMethod,LayoutAdmin, LayoutCleanse
} from "../../../../../index";


import {
  accountMemoTxt,
  address1Txt,
  address2Txt,
  avgSpendTxt,
  cityTxt,
  companyPhoneNumberTxt,
  contactTxt,
  countryTxt,
  countyTxt,
  emailTxt,
  faxNumberTxt,
  houseNumberTxt,
  name2Txt,
  paymentMethodTxt,
  phonTxt,
  poBoxCityTxt,
  poBoxZipCodeTxt,
  reasonTxt,
  searchTermTxt,
  stateTxt,
  statusTxt,
  taxId2Txt,
  taxIdTxt,
  taxIdType2Txt,
  taxIdTypeTxt,
  vendorIdTxt,
  zipCodeTxt,
} from "./constTitleFiled";
import TDAccountMEMO from "./TDs/TDAccountMEMO";
import {
  fetchRecord,
  updateCurrentPage,
} from "../../../../../../rtk/Slices/Records-Slices";
const VendorArchive = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCurrentPage(1));
    dispatch(fetchRecord({ category: "supplier_archive" }));
  }, []);
  return (
    <LayoutCleanse
      title="Vendor Archive"
      pages={[ "Vendor Archive"]}
    >
      <CardsSummary
        filters={dataCleans["record_status"]}
        total_vendors={dataCleans.total_vendors}
      />
      <TableAdmin
        isExport={true}
        isAction={true}
        isSelest={true}
        titles={[
          vendorIdTxt,
          countryTxt,
          emailTxt,
          statusTxt,
          name2Txt,
          reasonTxt,
          address1Txt,
          address2Txt,
          houseNumberTxt,
          cityTxt,
          countyTxt,
          stateTxt,
          zipCodeTxt,
          poBoxCityTxt,
          poBoxZipCodeTxt,
          searchTermTxt,
          companyPhoneNumberTxt,
          contactTxt,
          accountMemoTxt,
          phonTxt,
          faxNumberTxt,
          // remitAddressTxt,
          taxIdTxt,
          taxIdTypeTxt,
          taxId2Txt,
          taxIdType2Txt,
          paymentMethodTxt,
          avgSpendTxt,
        ]}
        bulks={{
          name: "archive_bulk",
          list: [
            {
              defaultValue: 1,
              id: "kt_modal_archive_bulk_option_1",

              title: "Restore",
              desc: "Best for developers or people primarily using the API",
            },
          
          ],
        }}
        pagenation={{
          total: dataCleans["num_page"] ?? 1,
          currentPage: dataCleans.current_page,
          updateCurrentPage: ({ page, count }) => {
            dispatch(
              fetchRecord({
                category: "supplier_archive",
                page: page,
                count: count,
              })
            );
          },
        }}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default VendorArchive;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record) => {
      return <Record id={record.vendor_id} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={21} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <TDId {...record} />
      <TDCountry {...record} />
      <TDNameAndEmail {...record} />
      <TDStatus {...record} />
      <td>{props.name2}</td>
      <TDReason {...record} />
      <TDAddress1 {...record} />
      <TDAddress2 {...record} />
      <TDHouseNumber {...record} />
      <TDCity {...record} />
      <TDCountry {...record} />
      <TDState {...record} />
      <TDZipCode {...record} />
      <TDAddress1 {...record} /> <td></td>
      <TDSearchTrem {...record} />
      <TDCompanyPhoneMumber {...record} />
      <TDContact {...record} />
      <TDAccountMEMO {...record} />
      <TDPhon {...record} />
      <TDFaxNumber {...record} />
      {/* <td>Remit Address</td> */}
      <TDTax {...record} singleTax={true} />
      <TDTaxType {...record} />
      <TDTax2 {...record} />
      <TDTaxType2 {...record} />
      <TDPaymentMethod {...record} />
      {/* <TDTax3  {...record} />
      <TDTaxType3 {...record} /> */}
      <TDAvgSpend {...record} />
      <td className="text-end">
        <VendorArchiveAction
          record={record}
          extraAction={<BtnPrimary text="Restore" />}
        />
      </td>
    </tr>
  );
};
