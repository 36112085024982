import React from 'react'

const TDCheck = () => {
  return (
      <td>
   <div className="form-check form-check-sm form-check-custom form-check-solid">
     <input
       className="form-check-input widget-13-check"
       type="checkbox"
       value="1"
     />
   </div>
 </td>
  )
}

export default TDCheck
