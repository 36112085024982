import React from "react";
import {
  ColTextFiledWithLabel,
  ImgPicker,
  InputGroupRow,
  MarginBottomFiled,
  MySelect,
} from "../../../index";

const FormControlContect = (props) => {
  const isViewVendor = props.isViewVendor;
  return (
    <>
      <MarginBottomFiled>
        <ImgPicker />
      </MarginBottomFiled>
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="First Name"
            name="contact[firstname]"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Last Name"
            name="contact[lastname]"
            isRequired={true}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Position"
            name="contact[title]"
            isRequired={true}
          />
        }
        col2={
          isViewVendor != true ? (
            <MySelect
              label="Direction"
              options={[
                { title: "Syetm Default", value: 1 },
                { title: "rtl", value: 2 },
                { title: "ltr", value: 3 },
              ]}
            />
          ) : (
            <MySelect
              label="Business Contact Type "
              name="tax_document_type_contact"
              isRequired={true}
            />
          )
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Email"
            name="contact[email]"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Phone"
            name="contact[phonenumber]"
            isRequired={true}
          />
        }
      />
    </>
  );
};

export default FormControlContect;
