
import React from "react";

import { Outlet, useNavigate } from "react-router-dom";
import { ItemMenuUserLink, Layout, HeaderVendor } from "../../../index";
///props
//sidebar widget(option)
//children widget
///extraAction btn (option)
//title Strig
//pages array ex:[ "Bedrock Verifiction", "Bedrock Verifiction"]
//menuNavigationHeader Widget

const LayoutVendor = (props) => {
  const navigate = useNavigate();
  return (
    <Layout
      title={props.title}
      pages={props.pages}
      extraAction={props.extraAction}
      sidebar={props.sidebar}
      menuNavigationHeader={props.menuNavigationHeader ?? <HeaderVendor />}
      menuUserHeader={
        <>
         <ItemMenuUserLink
                  text="My Profile"
                 onClick={() => {navigate('vendor_my_profile_page')} }
                />
                <ItemMenuUserLink
                  text="Contacts"
                   onClick={() => {navigate('vendor_my_contacts_page')} }
                />
                <ItemMenuUserLink
                  text="Company Details"
                   onClick={() =>   navigate(`/vendor_invitation_onborading/149`) }
                />
        </>
      }
    >
   {props.children?? <Outlet />}
    </Layout>
  );
};

export default LayoutVendor;
