import React from 'react'

const TDAvgSpend = (props) => {
    
  const avgSpend = props.avg_spend;
  return (
 <td>{avgSpend}</td>
  )
}

export default TDAvgSpend
