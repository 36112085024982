
import {
  CheckBox,
  InputGroupRow,
  MarginBottomFiled,
  ColTextFiledWithLabel,
  MySelect,BodyContentFormWithCard,TitleSmall
} from "../../../../index";

const CompanyInformation = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-company-information">
      <TitleSmall title="Company Information" className=" pb-5 pb-lg-10" />
      {/* <EnablesRadiosBtn typeForms='tin_check'/> */}
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Website URL"
          value="sphereaccess.com"
          name="settings[company_website_url]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Company Email"
          value="info@ds-jo.com"
          type="email"
          name="settings[company_email]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Customer Service Email"
          value="info@ds-jo.com"
          type="email"
          name="settings[customer_service_email]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Fax"
          value="09854585855254"
          type="number"
          name="settings[company_fax]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <MySelect
              label="Country"
              name="country"
              options={[
                { value: "AF", country: "kkAfghanistan" },
                { value: "AX", country: "Aland Islands" },
                { value: "AL", country: "Albania" },
                { value: "DZ", country: "Algeria" },
                { value: "AS", country: "American Samoa" },
                { value: "AD", country: "Andorra" },
                { value: "AO", country: "Angola" },
                { value: "AI", country: "Anguilla" },
                { value: "AG", country: "Antigua and Barbuda" },
                { value: "AR", country: "Argentina" },
                { value: "AM", country: "Armenia" },
              ]}
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="state"
              name="settings[company_state]"
            />
          }
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="Address #1"
              name="settings[invoice_company_address]"
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="Address #2"
              name="settings[invoice_company_address2]"
            />
          }
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="Address #3"
              name="settings[invoice_company_address3]"
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="Postal Code"
              name="settings[company_postal_code]"
            />
          }
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="Country Code"
              name="settings[invoice_company_country_code]"
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="Zip Code"
              name="settings[invoice_company_postal_code]"
            />
          }
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="Support Phone"
              type="number"
              name="settings[invoice_company_phonenumber]"
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="VAT Number"
              type="number"
              name="settings[company_vat]"
            />
          }
        />
      </MarginBottomFiled>

      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="SubDomain"
              prefix="https://"
              name="settings[company_sub_domain]"
            />
          }
          col2={<ColTextFiledWithLabel label="Domain" name="main-domain" />}
        />
        <CheckBox title="Your Domain" />
      </MarginBottomFiled>
      
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="Parent Client ID"
              type="number"
              name="settings[company_parent_client_ID]"
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="Children Client IDs"
              type="number"
              name="settings[company_children_client_ID]"
            />
          }
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
      <ColTextFiledWithLabel
              label="Company Information Format (PDF and HTML)"
              value="{company_name}
              {address}
              {city} {state}
              {country_code} {zip_code}
              {vat_number_with_label}"
              name="settings[company_info_format]"
            />
      </MarginBottomFiled>
    </BodyContentFormWithCard>
  );
};

export default CompanyInformation;
