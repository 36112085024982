import React from "react";
import {LayoutCleanse} from "../../../index";

const OverviewDataCleanse = () => {
  return (
    <LayoutCleanse title={"Overview"} pages={["Data Cleans"]}>
      <div id="kt_app_content" className="app-content px-0">
        {/*begin:: hala Add */}
        {/*begin::Row1*/}
        <div className="row g-5 g-xl-10 mb-xl-10">
          {/*begin::Col*/}
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 4*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                      $
                    </span>
                    {/*end::Currency*/}
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      69,700
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-primary fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Potential Parent/Child
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-2 pb-4 d-flex align-items-center">
                {/*begin::Chart*/}
                <div className="d-flex flex-center me-5 pt-2">
                  <div
                    id="kt_card_widget_4_chart"
                    style={{ minWidth: 70, minHeight: 70 }}
                    data-kt-size={70}
                    data-kt-line={11}
                  />
                </div>
                {/*end::Chart*/}
                {/*begin::Labels*/}
                <div className="d-flex flex-column content-justify-center w-100">
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">id</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $7,660
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center my-3">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-primary me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">name</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $2,820
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div
                      className="bullet w-8px h-6px rounded-2 me-3"
                      style={{ backgroundColor: "#e4e6ef" }}
                    />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">tax</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $45,257
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                </div>
                {/*end::Labels*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 4*/}
            {/*begin::Card widget 5*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      1,836
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-danger fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-danger ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={11}
                            y={18}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 18)"
                            fill="currentColor"
                          />
                          <path
                            d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Complete
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end pt-0">
                {/*begin::Progress*/}
                <div className="d-flex align-items-center flex-column mt-3 w-100">
                  <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-bolder fs-6 text-dark">
                      1,048 to Goal
                    </span>
                    <span className="fw-bold fs-6 text-gray-400">62%</span>
                  </div>
                  <div className="h-8px mx-3 w-100 bg-light-primary rounded">
                    <div
                      className="bg-primary rounded h-8px"
                      role="progressbar"
                      style={{ width: "62%" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                {/*end::Progress*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 5*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 6*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                      $
                    </span>
                    {/*end::Currency*/}
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      2,420
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-primary fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.6%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Duplicate Eamil
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end px-0 pb-0">
                {/*begin::Chart*/}
                <div
                  id="kt_card_widget_6_chart"
                  className="w-100"
                  style={{ height: 80 }}
                />
                {/*end::Chart*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 6*/}
            {/*begin::Card widget 7*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Amount*/}
                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                    6.3k
                  </span>
                  {/*end::Amount*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Avg Spead
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex flex-column justify-content-end pe-0">
                {/*begin::Title*/}
                <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                  Count
                </span>
                {/*end::Title*/}
                {/*begin::Users group*/}
                <div className="symbol-group symbol-hover flex-nowrap">
                  <div
                    className="symbol symbol-40px symbol-circle"
                    data-bs-toggle="tooltip"
                    title="Alan Warden"
                  >
                    <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                      1
                    </span>
                  </div>
                  <div
                    className="symbol symbol-40px symbol-circle"
                    data-bs-toggle="tooltip"
                    title="Susan Redwood"
                  >
                    <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                      2
                    </span>
                  </div>
                  <div
                    className="symbol symbol-40px symbol-circle"
                    data-bs-toggle="tooltip"
                    title="Perry Matthew"
                  >
                    <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                      3
                    </span>
                  </div>
                </div>
                {/*end::Users group*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 7*/}
          </div>
          {/*end::Col*/}   {/*begin::Col*/}
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 4*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                      $
                    </span>
                    {/*end::Currency*/}
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      69,700
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-primary fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Potential Parent/Child
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-2 pb-4 d-flex align-items-center">
                {/*begin::Chart*/}
                <div className="d-flex flex-center me-5 pt-2">
                  <div
                    id="kt_card_widget_4_chart"
                    style={{ minWidth: 70, minHeight: 70 }}
                    data-kt-size={70}
                    data-kt-line={11}
                  />
                </div>
                {/*end::Chart*/}
                {/*begin::Labels*/}
                <div className="d-flex flex-column content-justify-center w-100">
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">id</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $7,660
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center my-3">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-primary me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">name</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $2,820
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div
                      className="bullet w-8px h-6px rounded-2 me-3"
                      style={{ backgroundColor: "#e4e6ef" }}
                    />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">tax</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $45,257
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                </div>
                {/*end::Labels*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 4*/}
            {/*begin::Card widget 5*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      1,836
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-danger fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-danger ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={11}
                            y={18}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 18)"
                            fill="currentColor"
                          />
                          <path
                            d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Complete
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end pt-0">
                {/*begin::Progress*/}
                <div className="d-flex align-items-center flex-column mt-3 w-100">
                  <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-bolder fs-6 text-dark">
                      1,048 to Goal
                    </span>
                    <span className="fw-bold fs-6 text-gray-400">62%</span>
                  </div>
                  <div className="h-8px mx-3 w-100 bg-light-primary rounded">
                    <div
                      className="bg-primary rounded h-8px"
                      role="progressbar"
                      style={{ width: "62%" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                {/*end::Progress*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 5*/}
          </div>
          {/*end::Col*/}   {/*begin::Col*/}
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 4*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                      $
                    </span>
                    {/*end::Currency*/}
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      69,700
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-primary fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Potential Parent/Child
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-2 pb-4 d-flex align-items-center">
                {/*begin::Chart*/}
                <div className="d-flex flex-center me-5 pt-2">
                  <div
                    id="kt_card_widget_4_chart"
                    style={{ minWidth: 70, minHeight: 70 }}
                    data-kt-size={70}
                    data-kt-line={11}
                  />
                </div>
                {/*end::Chart*/}
                {/*begin::Labels*/}
                <div className="d-flex flex-column content-justify-center w-100">
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">id</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $7,660
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center my-3">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-primary me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">name</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $2,820
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div
                      className="bullet w-8px h-6px rounded-2 me-3"
                      style={{ backgroundColor: "#e4e6ef" }}
                    />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">tax</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $45,257
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                </div>
                {/*end::Labels*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 4*/}
            {/*begin::Card widget 5*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      1,836
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-danger fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-danger ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={11}
                            y={18}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 18)"
                            fill="currentColor"
                          />
                          <path
                            d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Complete
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end pt-0">
                {/*begin::Progress*/}
                <div className="d-flex align-items-center flex-column mt-3 w-100">
                  <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-bolder fs-6 text-dark">
                      1,048 to Goal
                    </span>
                    <span className="fw-bold fs-6 text-gray-400">62%</span>
                  </div>
                  <div className="h-8px mx-3 w-100 bg-light-primary rounded">
                    <div
                      className="bg-primary rounded h-8px"
                      role="progressbar"
                      style={{ width: "62%" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                {/*end::Progress*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 5*/}
          </div>
          {/*end::Col*/}
        
        </div>
        {/*end::Row1*/}
        {/*begin::Row2*/}
        <div className="row gy-5 g-xl-10 mx-1">
          {/*begin::Card*/}
          <div className="card">
            {/*begin::Card header*/}
            <div className="card-header border-0 pt-6">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">
                    Vendor Summary
                  </span>
                  <span className="text-gray-400 mt-1 fw-semibold fs-6">
                    Total 2,356 Items in the Vendor
                  </span>
                </h3>
                {/*end::Title*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Actions*/}
                <div className="card-toolbar">
                  {/*begin::Filters*/}
                  <div className="d-flex flex-stack flex-wrap gap-4">
                    {/*begin::Destination*/}
                    <div className="d-flex align-items-center fw-bold">
                      {/*begin::Label*/}
                      <div className="text-muted fs-7 me-2">Cateogry</div>
                      {/*end::Label*/}
                      {/*begin::Select*/}
                      <select
                        className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                        data-control="select2"
                        data-hide-search="true"
                        data-dropdown-css-class="w-150px"
                        data-placeholder="Select an option"
                      >
                        <option />
                        <option value="Show All" selected="selected">
                          Show All
                        </option>
                        <option value="a">Category A</option>
                        <option value="b">Category B</option>
                      </select>
                      {/*end::Select*/}
                    </div>
                    {/*end::Destination*/}
                    {/*begin::Status*/}
                    <div className="d-flex align-items-center fw-bold">
                      {/*begin::Label*/}
                      <div className="text-muted fs-7 me-2">Status</div>
                      {/*end::Label*/}
                      {/*begin::Select*/}
                      <select
                        className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                        data-control="select2"
                        data-hide-search="true"
                        data-dropdown-css-class="w-150px"
                        data-placeholder="Select an option"
                        data-kt-table-widget-5="filter_status"
                      >
                        <option />
                        <option value="Show All" selected="selected">
                          Show All
                        </option>
                        <option value="In Stock">In Stock</option>
                        <option value="Out of Stock">Out of Stock</option>
                        <option value="Low Stock">Low Stock</option>
                      </select>
                      {/*end::Select*/}
                    </div>
                    {/*end::Status*/}
                    {/*begin::Search*/}
                    <a
                      href="apps/ecommerce/catalog/products.html"
                      className="btn btn-light btn-sm"
                    >
                      View Stock
                    </a>
                    {/*end::Search*/}
                  </div>
                  {/*begin::Filters*/}
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-0">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed fs-6 gy-5"
                  id="kt_subscriptions_table"
                >
                  {/*begin::Table head*/}
                  <thead>
                    {/*begin::Table row*/}
                    <tr>
                      <th className="min-w-200px">
                        status
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        reason
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        description
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        Incorrect Invoice
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        Scource
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-150px">
                        cc
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        Vendor Number
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        Vendor name
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        invoice number
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        invoice Date
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        GMM Amount
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-125px">
                        Currency
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-125px">
                        PO
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-125px">
                        Due
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-125px">
                        ATh
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-200px">
                        payment Status
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      <th className="min-w-125px">
                        Entered
                        <a
                          href="https://ews-jo.com/proto/page/home.html"
                          className="text-hover-primary ps-4"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                          <span className="svg-icon svg-icon-2 svg-icon-gray-400">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </th>
                      {/*end::Table row*/}
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody className="text-gray-600 fw-semibold">
                    {/* begin::record1 */}
                    <tr>
                      {/*begin::Customer=*/}
                      <td>Pending Review</td>
                      {/*end::Customer=*/}
                      {/*begin::Status=*/}
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          defaultValue="Date entry Error "
                        />
                      </td>
                      {/*end::Status=*/}
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          defaultValue="Auro Payment mold"
                        />
                      </td>
                      {/*begin::Checkbox*/}
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                          />
                        </div>
                      </td>
                      {/*end::Checkbox*/}
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          AS101
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          100
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          374AC4
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          Control value description
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          1254789
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          500.005.285
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          USA
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          30005678
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light">i</div>
                      </td>
                      <td>Due</td>
                      <td className="text-end">Aug 19, 2023</td>
                    </tr>
                    {/* end::record1 */}
                    {/* begin::record2 */}
                    <tr>
                      {/*begin::Customer=*/}
                      <td />
                      {/*end::Customer=*/}
                      {/*begin::Status=*/}
                      <td />
                      {/*end::Status=*/}
                      <td />
                      {/*begin::Checkbox*/}
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                          />
                        </div>
                      </td>
                      {/*end::Checkbox*/}
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          AS101
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          100
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          374AC4
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          Control value description
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          1254789
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          500.005.285
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          USA
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          30005678
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light">i</div>
                      </td>
                      <td>Due</td>
                      <td className="text-end">Aug 19, 2023</td>
                    </tr>
                    {/* end::record2 */}
                    {/* begin::record3 */}
                    <tr>
                      {/*begin::Customer=*/}
                      <td>Pending Review</td>
                      {/*end::Customer=*/}
                      {/*begin::Status=*/}
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          defaultValue="Date entry Error "
                        />
                      </td>
                      {/*end::Status=*/}
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          defaultValue="Auro Payment mold"
                        />
                      </td>
                      {/*begin::Checkbox*/}
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                          />
                        </div>
                      </td>
                      {/*end::Checkbox*/}
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          AS101
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          100
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          374AC4
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          Control value description
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          1254789
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          500.005.285
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          USA
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          30005678
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light">i</div>
                      </td>
                      <td>Due</td>
                      <td className="text-end">Aug 19, 2023</td>
                    </tr>
                    {/* end::record3 */}
                    {/* begin::record4 */}
                    <tr>
                      {/*begin::Customer=*/}
                      <td />
                      {/*end::Customer=*/}
                      {/*begin::Status=*/}
                      <td />
                      {/*end::Status=*/}
                      <td />
                      {/*begin::Checkbox*/}
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={0}
                          />
                        </div>
                      </td>
                      {/*end::Checkbox*/}
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          AS101
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          100
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          374AC4
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          Control value description
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          1254789
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          500.005.285
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          GEP
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          30005678
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light">i</div>
                      </td>
                      <td>Due</td>
                      <td className="text-end">Aug 19, 2023</td>
                    </tr>
                    {/* end::record4 */}
                    {/* begin::record5 */}
                    <tr>
                      {/*begin::Customer=*/}
                      <td>Pending Review</td>
                      {/*end::Customer=*/}
                      {/*begin::Status=*/}
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          defaultValue="Date entry Error "
                        />
                      </td>
                      {/*end::Status=*/}
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          defaultValue="Auro Payment mold"
                        />
                      </td>
                      {/*begin::Checkbox*/}
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                          />
                        </div>
                      </td>
                      {/*end::Checkbox*/}
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          AS101
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          100
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          374AC4
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          Control value description
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          1254789
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          500.005.285
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          USA
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          30005678
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light">i</div>
                      </td>
                      <td>Due</td>
                      <td className="text-end">Aug 19, 2023</td>
                    </tr>
                    {/* end::record5 */}
                    {/* begin::record6 */}
                    <tr>
                      {/*begin::Customer=*/}
                      <td />
                      {/*end::Customer=*/}
                      {/*begin::Status=*/}
                      <td />
                      {/*end::Status=*/}
                      <td />
                      {/*begin::Checkbox*/}
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                          />
                        </div>
                      </td>
                      {/*end::Checkbox*/}
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          AS101
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          100
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          374AC4
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-warning">
                          Control value description
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          1254789
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          500.005.285
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          USA
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          30005678
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light-primary">
                          4/15/22
                        </div>
                      </td>
                      <td>
                        <div className="badge py-5 px-3 badge-light">i</div>
                      </td>
                      <td>Due</td>
                      <td className="text-end">Aug 19, 2023</td>
                    </tr>
                    {/* end::record6 */}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Row2*/}
        {/*begin::Row3*/}
        <div className="row gy-5 g-xl-10 mx-1 mt-1">
          {/*begin::Card*/}
          <div className="card">
            {/*begin::Card header*/}
            <div className="card-header border-0 pt-6">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">
                    Stock Report
                  </span>
                  <span className="text-gray-400 mt-1 fw-semibold fs-6">
                    Total 2,356 Items in the Stock
                  </span>
                </h3>
                {/*end::Title*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Actions*/}
                <div className="card-toolbar">
                  {/*begin::Filters*/}
                  <div className="d-flex flex-stack flex-wrap gap-4">
                    {/*begin::Search*/}
                    <a
                      href="https://ews-jo.com/proto/page/home.html"
                      className="btn btn-light btn-sm"
                    >
                      All
                    </a>
                    {/*end::Search*/}
                  </div>
                  {/*begin::Filters*/}
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-0">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed fs-6 gy-5"
                  id="kt_subscriptions_table"
                >
                  {/*begin::Table head*/}
                  <thead>
                    {/*begin::Table row*/}
                    <tr>
                      <th className="min-w-200px">
                        id
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        description
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        Assigned To
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        Due
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        InQueut
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-150px">
                        Notfication
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        Most Recent
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-125px">
                        Notes
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      {/*end::Table row*/}
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody className="text-gray-600 fw-semibold">
                    {/* begin::record1 */}
                    <tr>
                      <td>11112255</td>
                      <td>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </td>
                      {/*end::Status=*/}
                      <td>Lorem ipsum</td>
                      <td>4/15/22</td>
                      <td>od 1h 23m</td>
                      <td />
                      <td>4/15/22 6:01</td>
                      <td />
                    </tr>
                    {/* end::record1 */}
                    {/* begin::record1 */}
                    <tr>
                      <td>11112255</td>
                      <td>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </td>
                      {/*end::Status=*/}
                      <td>Lorem ipsum</td>
                      <td>4/15/22</td>
                      <td>od 1h 23m</td>
                      <td />
                      <td>4/15/22 6:01</td>
                      <td />
                    </tr>
                    {/* end::record1 */}
                    {/* begin::record1 */}
                    <tr>
                      <td>11112255</td>
                      <td>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </td>
                      {/*end::Status=*/}
                      <td>Lorem ipsum</td>
                      <td>4/15/22</td>
                      <td>od 1h 23m</td>
                      <td />
                      <td>4/15/22 6:01</td>
                      <td />
                    </tr>
                    {/* end::record1 */}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Row3*/}
        {/* end::hala Add */}
      </div>
    </LayoutCleanse>
  );
};

export default OverviewDataCleanse;
