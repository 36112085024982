
import {
  CheckBox,TitleSmall,
  ColTextFiledWithLabel,BodyContentFormWithCard,
  ImgPicker,
  MarginBottomFiled,
} from "../../../../index";

const General = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-general" isActive={true}>
      <TitleSmall title="General" className=" pb-5 pb-lg-10" />
      <MarginBottomFiled>
        <ImgPicker
          width='500px '
          img="https://xpro.ews-jo.com/uploads/company/31aa5ecb86c56199f70a485e191fecd8.png"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ImgPicker
          width='500px '
          img="https://xpro.ews-jo.com/uploads/company/31aa5ecb86c56199f70a485e191fecd8.png"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ImgPicker    width='50px' hieght='50px' img="https://xpro.ews-jo.com/uploads/company/favicon.png" />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Company Name"
          name="settings[companyname]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Company Main Domain"
          name="settings[main_domain]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <CheckBox
          name="rtl_support_admin"
          title="RTL"
          desc="Admin Area (Right to Left)"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <CheckBox
          name="rtl_support_client"
          title="RTL"
          desc=" Vendors Area (Right to Left)"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Allowed file types"
          name="settings[allowed_files]"
        />
      </MarginBottomFiled>
    </BodyContentFormWithCard>
  );
};

export default General;
