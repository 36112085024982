
///props
////options  Array  ex [{value,title}];
////label    String ex 'lable' (option);
////name     String ex 'name';
////isHeadenSearch bool option by default false;

import { useRef } from "react";
import Label from "../lable/MyLabel";
import { ColInputWithLable } from "../lable";

////isRequird bool option by default false;
function MySelect(props) {


  const selectComponnt = useRef(null);
  const submitForm = (event) => {
    console.log('event:', event)
    // var e =selectComponnt.current;
    // var t = function (e) {
    //   console.log('e:', e)
    //   if (!e.id) return e.text;
    //   var t = document.createElement("span"),
    //     a = "";
    //   return (
       
    //     (a += e.text),
    //     (t.innerHTML = a),
    //    window.$(t)
    //   );
    // };
    // window.$(e).select2({
    //   placeholder: "Select coin",
    //   minimumResultsForSearch: 1 / 0,
    //   templateSelection: t,
    //   templateResult: t,
    // });
  }
  

  const options = props.options;
  const optionsLength = options?.length;
  const checkIsNotEmpty = () => options && optionsLength >= 1;
  return (
    <ColInputWithLable label={props.label}>
     
    <select
  onMouseEnter={submitForm}
  ref={selectComponnt}
    name={props.name}
    defaultValue={props.value}
    data-hide-search={props.isHeadenSearch ?? false}
    data-allow-clear='true'
    data-control="select2"
    
    data-dropdown-parent="#kt_modal_new_address"
    data-placeholder={`Select a ${props.label??''}...`}
    className="form-select form-select-solid fw-bold"
  >
    <option value="">{`Select a ${props.label??''}...`}</option>
    {checkIsNotEmpty()
      ? props.options.map((element,index) => (
          <option key={+element.value+'_'+index} {...element}>
            {element.country??element.value}
          </option>
        ))
      : ""}
  </select>
  {/*end::Select*/}
</ColInputWithLable>
    // <>
    //   {/*begin::Input group*/}
    //   <div className="d-flex flex-column mb-5 fv-row">
    //   <Label {...props}/>
    //     {/*begin::Select*/}
     
    //     <select
    //     onMouseEnter={submitForm}
    //     ref={selectComponnt}
    //       name={props.name}
    //       defaultValue={props.value}
    //       data-hide-search={props.isHeadenSearch ?? false}
    //       data-allow-clear='true'
    //       data-control="select2"
          
    //       data-dropdown-parent="#kt_modal_new_address"
    //       data-placeholder={`Select a ${props.label??''}...`}
    //       className="form-select form-select-solid fw-bold"
    //     >
    //       <option value="">{`Select a ${props.label??''}...`}</option>
    //       {checkIsNotEmpty()
    //         ? props.options.map((element,index) => (
    //             <option key={+element.value+'_'+index} {...element}>
    //               {element.country??element.value}
    //             </option>
    //           ))
    //         : ""}
    //     </select>
    //     {/*end::Select*/}
    //   </div>
    //   {/*end::Input group*/}
    // </>
  );
}

export default MySelect;
