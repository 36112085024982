import { useEffect } from "react";
import { LayoutAdmin, ReadyToDataCleans } from "../../index";

const HomePage = () => {
  return (
    <LayoutAdmin>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content px-0">
            {/*begin:: hala Add */}
            {/*begin::Row1*/}
            <div className="row gy-5 g-xl-10">
              {/*begin::Col*/}
              <div>
                {/*begin::Chart widget 17*/}
                <div className="card card-flush h-xl-100">
                  {/*begin::Header*/}
                  <div className="card-header pt-7">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                         
                        Statistics
                      </span>
                      <span className="text-gray-400 pt-2 fw-semibold fs-6">
                        Analytics data 
                      </span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      {/*begin::Menu*/}
                      <button
                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-overflow="true"
                      >
                        {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                        <span className="svg-icon svg-icon-1 svg-icon-gray-300 me-n1">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={4}
                              fill="currentColor"
                            />
                            <rect
                              x={11}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                            <rect
                              x={15}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                            <rect
                              x={7}
                              y={11}
                              width="2.6"
                              height="2.6"
                              rx="1.3"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </button>
                      {/*begin::Menu 3*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                        data-kt-menu="true"
                      >
                        {/*begin::Heading*/}
                        <div className="menu-item px-3">
                          <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                            Payments
                          </div>
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link px-3"
                          >
                            Create Invoice
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link flex-stack px-3"
                          >
                            Create Payment
                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Specify a target name for future usage and reference"
                            />
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link px-3"
                          >
                            Generate Bill
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div
                          className="menu-item px-3"
                          data-kt-menu-trigger="hover"
                          data-kt-menu-placement="right-end"
                        >
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link px-3"
                          >
                            <span className="menu-title">Subscription</span>
                            <span className="menu-arrow" />
                          </a>
                          {/*begin::Menu sub*/}
                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="menu-link px-3"
                              >
                                Plans
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="menu-link px-3"
                              >
                                Billing
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="menu-link px-3"
                              >
                                Statements
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu separator*/}
                            <div className="separator my-2" />
                            {/*end::Menu separator*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <div className="menu-content px-3">
                                {/*begin::Switch*/}
                                <label className="form-check form-switch form-check-custom form-check-solid">
                                  {/*begin::Input*/}
                                  <input
                                    className="form-check-input w-30px h-20px"
                                    type="checkbox"
                                    defaultValue={1}
                                    defaultChecked="checked"
                                    name="notifications"
                                  />
                                  {/*end::Input*/}
                                  {/*end::Label*/}
                                  <span className="form-check-label text-muted fs-6">
                                    Recuring
                                  </span>
                                  {/*end::Label*/}
                                </label>
                                {/*end::Switch*/}
                              </div>
                            </div>
                            {/*end::Menu item*/}
                          </div>
                          {/*end::Menu sub*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3 my-1">
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link px-3"
                          >
                            Settings
                          </a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu 3*/}
                      {/*end::Menu*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-5">
                    {/*begin::Chart container*/}
                    <ChartHome />
                    {/*end::Chart container*/}
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Chart widget 17*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row1*/}
            {/*begin::Row2*/}
            <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-5">
              {/*begin::Col*/}
              <div className="col-xxl-8">
                {/*begin::Chart widget 22*/}
                <div className="card h-xl-100">
                  {/*begin::Header*/}
                  <div className="card-header position-relative py-0 border-bottom-2">
                    {/*begin::Nav*/}
                    <ul className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3">
                      {/*begin::Item*/}
                      <li className="nav-item p-0 ms-0 me-8">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-color-muted active px-0"
                          data-bs-toggle="tab"
                          id="kt_chart_widgets_22_tab_1"
                          href="#kt_chart_widgets_22_tab_content_1"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text fw-semibold fs-4 mb-3">
                            Vendor Cleans
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item p-0 ms-8">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-color-muted px-0"
                          data-bs-toggle="tab"
                          id="kt_chart_widgets_22_tab_2"
                          href="#kt_chart_widgets_22_tab_content_2"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text fw-semibold fs-4 mb-3">
                            Recover
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item p-0 ms-8">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-color-muted px-0"
                          data-bs-toggle="tab"
                          id="kt_chart_widgets_22_tab_2"
                          href="#kt_chart_widgets_22_tab_content_2"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text fw-semibold fs-4 mb-3">
                            Prevention
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item p-0 ms-8">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-color-muted px-0"
                          data-bs-toggle="tab"
                          id="kt_chart_widgets_22_tab_2"
                          href="#kt_chart_widgets_22_tab_content_2"
                        >
                          {/*begin::Subtitle*/}
                          <span className="nav-text fw-semibold fs-4 mb-3">
                            Inview
                          </span>
                          {/*end::Subtitle*/}
                          {/*begin::Bullet*/}
                          <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                          {/*end::Bullet*/}
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                    </ul>
                    {/*end::Nav*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                      <div
                        data-kt-daterangepicker="true"
                        data-kt-daterangepicker-opens="left"
                        className="btn btn-sm btn-light d-flex align-items-center px-4"
                      >
                        {/*begin::Display range*/}
                        <div className="text-gray-600 fw-bold">
                          Loading date range...
                        </div>
                        {/*end::Display range*/}
                        {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                        <span className="svg-icon svg-icon-1 ms-2 me-0">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                              fill="currentColor"
                            />
                            <path
                              d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </div>
                      {/*end::Daterangepicker*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pb-3">
                    {/*begin::Tab Content*/}
                    <div className="tab-content">
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade show active"
                        id="kt_chart_widgets_22_tab_content_1"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex flex-wrap flex-md-nowrap">
                          {/*begin::Items*/}
                          <div className="me-md-5 w-100">
                            {/*begin::Item*/}
                            <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                              {/*begin::Block*/}
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen013.svg*/}
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Section*/}
                                <div className="me-2">
                                  <a
                                    href="https://ews-jo.com/proto/page/home.html"
                                    className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                  >
                                    Complete
                                  </a>
                                  <span className="text-gray-400 fw-bold d-block fs-7">
                                    Great, you always attending class. keep it
                                    up
                                  </span>
                                </div>
                                {/*end::Section*/}
                              </div>
                              {/*end::Block*/}
                              {/*begin::Info*/}
                              <div className="d-flex align-items-center">
                                <span className="text-dark fw-bolder fs-2x">
                                  73
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  76
                                </span>
                                <span className="badge badge-lg badge-light-success align-self-center px-2">
                                  95%
                                </span>
                              </div>
                              {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                              {/*begin::Block*/}
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x={2}
                                          y={2}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x={13}
                                          y={2}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x={13}
                                          y={13}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={13}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Section*/}
                                <div className="me-2">
                                  <a
                                    href="https://ews-jo.com/proto/page/home.html"
                                    className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                  >
                                    Potential Parent/Child
                                  </a>
                                  <span className="text-gray-400 fw-bold d-block fs-7">
                                    Don’t forget to turn in your task
                                  </span>
                                </div>
                                {/*end::Section*/}
                              </div>
                              {/*end::Block*/}
                              {/*begin::Info*/}
                              <div className="d-flex align-items-center">
                                <span className="text-dark fw-bolder fs-2x">
                                  207
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  214
                                </span>
                                <span className="badge badge-lg badge-light-success align-self-center px-2">
                                  92%
                                </span>
                              </div>
                              {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                              {/*begin::Block*/}
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    {/*begin::Svg Icon | path: icons/duotune/abstract/abs025.svg*/}
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          opacity="0.3"
                                          d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Section*/}
                                <div className="me-2">
                                  <a
                                    href="https://ews-jo.com/proto/page/home.html"
                                    className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                  >
                                    Status
                                  </a>
                                  <span className="text-gray-400 fw-bold d-block fs-7">
                                    You take 12 subjects at this semester
                                  </span>
                                </div>
                                {/*end::Section*/}
                              </div>
                              {/*end::Block*/}
                              {/*begin::Info*/}
                              <div className="d-flex align-items-center">
                                <span className="text-dark fw-bolder fs-2x">
                                  27
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  38
                                </span>
                                <span className="badge badge-lg badge-light-warning align-self-center px-2">
                                  80%
                                </span>
                              </div>
                              {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                          </div>
                          {/*end::Items*/}
                          {/*begin::Container*/}
                          <div className="d-flex justify-content-between flex-column w-225px w-md-600px mx-auto mx-md-0 pt-3 pb-10">
                            {/*begin::Title*/}
                            <div className="fs-4 fw-bold text-gray-900 text-center mb-5">
                              record Complete <br />
                              for Current vendors Year
                            </div>
                            {/*end::Title*/}
                            {/*begin::Chart*/}
                            <div
                              id="kt_chart_widgets_22_chart_1"
                              className="mx-auto mb-4"
                            />
                            {/*end::Chart*/}
                            {/*begin::Labels*/}
                            <div className="mx-auto">
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-success me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Completed(133)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-primary me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Mixed(9)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-info me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Missing Some(2)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-danger me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Other(3)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Labels*/}
                          </div>
                          {/*end::Container*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_chart_widgets_22_tab_content_2"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex flex-wrap flex-md-nowrap">
                          {/*begin::Items*/}
                          <div className="me-md-5 w-100">
                            {/*begin::Item*/}
                            <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                              {/*begin::Block*/}
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x={2}
                                          y={2}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x={13}
                                          y={2}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x={13}
                                          y={13}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x={2}
                                          y={13}
                                          width={9}
                                          height={9}
                                          rx={2}
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Section*/}
                                <div className="me-2">
                                  <a
                                    href="https://ews-jo.com/proto/page/home.html"
                                    className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                  >
                                    Potential Parent/ChildPotential Parent/Child
                                  </a>
                                  <span className="text-gray-400 fw-bold d-block fs-7">
                                    Don’t forget to turn in your task
                                  </span>
                                </div>
                                {/*end::Section*/}
                              </div>
                              {/*end::Block*/}
                              {/*begin::Info*/}
                              <div className="d-flex align-items-center">
                                <span className="text-dark fw-bolder fs-2x">
                                  423
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  154
                                </span>
                                <span className="badge badge-lg badge-light-danger align-self-center px-2">
                                  74%
                                </span>
                              </div>
                              {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                              {/*begin::Block*/}
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    {/*begin::Svg Icon | path: icons/duotune/abstract/abs025.svg*/}
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          opacity="0.3"
                                          d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Section*/}
                                <div className="me-2">
                                  <a
                                    href="https://ews-jo.com/proto/page/home.html"
                                    className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                  >
                                    Tests
                                  </a>
                                  <span className="text-gray-400 fw-bold d-block fs-7">
                                    You take 12 subjects at this semester
                                  </span>
                                </div>
                                {/*end::Section*/}
                              </div>
                              {/*end::Block*/}
                              {/*begin::Info*/}
                              <div className="d-flex align-items-center">
                                <span className="text-dark fw-bolder fs-2x">
                                  43
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  53
                                </span>
                                <span className="badge badge-lg badge-light-info align-self-center px-2">
                                  65%
                                </span>
                              </div>
                              {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                              {/*begin::Block*/}
                              <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-4">
                                  <span className="symbol-label">
                                    {/*begin::Svg Icon | path: icons/duotune/general/gen013.svg*/}
                                    <span className="svg-icon svg-icon-2qx svg-icon-primary">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Section*/}
                                <div className="me-2">
                                  <a
                                    href="https://ews-jo.com/proto/page/home.html"
                                    className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                  >
                                    Complete
                                  </a>
                                  <span className="text-gray-400 fw-bold d-block fs-7">
                                    Great, you always attending class. keep it
                                    up
                                  </span>
                                </div>
                                {/*end::Section*/}
                              </div>
                              {/*end::Block*/}
                              {/*begin::Info*/}
                              <div className="d-flex align-items-center">
                                <span className="text-dark fw-bolder fs-2x">
                                  53
                                </span>
                                <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                                  /
                                </span>
                                <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                                  94
                                </span>
                                <span className="badge badge-lg badge-light-primary align-self-center px-2">
                                  87%
                                </span>
                              </div>
                              {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                          </div>
                          {/*end::Items*/}
                          {/*begin::Container*/}
                          <div className="d-flex justify-content-between flex-column w-225px w-md-600px mx-auto mx-md-0 pt-3 pb-10">
                            {/*begin::Title*/}
                            <div className="fs-4 fw-bold text-gray-900 text-center mb-5">
                              record Complete <br />
                              for Current vendors Year
                            </div>
                            {/*end::Title*/}
                            {/*begin::Chart*/}
                            <div
                              id="kt_chart_widgets_22_chart_2"
                              className="mx-auto mb-4"
                            />
                            {/*end::Chart*/}
                            {/*begin::Labels*/}
                            <div className="mx-auto">
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-success me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Precent(133)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-primary me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Illness(9)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-info me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Late(2)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                              {/*begin::Label*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Bullet*/}
                                <div className="bullet bullet-dot w-8px h-7px bg-danger me-2" />
                                {/*end::Bullet*/}
                                {/*begin::Label*/}
                                <div className="fs-8 fw-semibold text-muted">
                                  Absent(3)
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Labels*/}
                          </div>
                          {/*end::Container*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                    </div>
                    {/*end::Tab Content*/}
                  </div>
                  {/*end: Card Body*/}
                </div>
                {/*end::Chart widget 22*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-xxl-4">
                {/*begin::Col*/}
                {/*begin::List widget 18*/}
                <div className="card card-flush h-xl-100">
                  {/*begin::Header*/}
                  <div className="card-header pt-7">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        Lading Companies
                      </span>
                      <span className="text-gray-400 mt-1 fw-semibold fs-6">
                        8k social visitors
                      </span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      <ul className="nav me-n1" id="kt_chart_widget_11_tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1"
                            data-bs-toggle="tab"
                            id="kt_list_widget_18_tab_1"
                            href="#kt_list_widget_18_tab_content_1"
                          >
                            2021
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 active"
                            data-bs-toggle="tab"
                            id="kt_list_widget_18_tab_2"
                            href="#kt_list_widget_18_tab_content_2"
                          >
                            Month
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-4">
                    {/*begin::Tab Content*/}
                    <div className="tab-content">
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_list_widget_18_tab_content_1"
                      >
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Recover
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Cancelsction
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              1,578
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}4.1%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Vendor Clense
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Completed
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              794
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}0.2%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Data Prevention
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Awaiting Response
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              2,047
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}1.9%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Onboard
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Awaiting Response
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              3,458
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}8.3%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Recover
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Audit Completion
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              579
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}2.6%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/verification.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Verify
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Verified
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              2,588
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-danger fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-danger ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={11}
                                      y={18}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(-90 11 18)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}0.4%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade show active"
                        id="kt_list_widget_18_tab_content_2"
                      >
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Recover
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Audit Completion
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              579
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}2.6%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/verification.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Verify
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Verified
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              2,588
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-danger fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-danger ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={11}
                                      y={18}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(-90 11 18)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}0.4%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Vendor Clense
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Completed
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              794
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}0.2%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Recover
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Video Channel
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              1,578
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}4.1%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Onboard
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Awaiting Response
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              3,458
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}8.3%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Separator*/}
                        <div className="separator separator-dashed my-4" />
                        {/*end::Separator*/}
                        {/*begin::Item*/}
                        <div className="d-flex flex-stack">
                          {/*begin::Section*/}
                          <div className="d-flex align-items-center me-5">
                            {/*begin::Content*/}
                            <div className="me-5">
                              {/*begin::Title*/}
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Data Prevention
                              </a>
                              {/*end::Title*/}
                              {/*begin::Desc*/}
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                Awaiting Response
                              </span>
                              {/*end::Desc*/}
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Number*/}
                            <span className="text-gray-800 fw-bold fs-4 me-3">
                              2,047
                            </span>
                            {/*end::Number*/}
                            {/*begin::Info*/}
                            <div className="m-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x={13}
                                      y={6}
                                      width={13}
                                      height={2}
                                      rx={1}
                                      transform="rotate(90 13 6)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}1.9%
                              </span>
                              {/*end::Label*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Item*/}
                      </div>
                      {/*end::Tap pane*/}
                    </div>
                    {/*end::Tab Content*/}
                  </div>
                  {/*end: Card Body*/}
                </div>
                {/*end::List widget 18*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row2*/}
            {/*begin::Row3*/}
            <div className="row gy-5 g-xl-10 mx-1">
              {/*begin::Card*/}
              <div className="card">
                {/*begin::Header*/}
                <div className="card-header position-relative py-0 border-bottom-2">
                  {/*begin::Nav*/}
                  <ul className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3">
                    {/*begin::Item*/}
                    <li className="nav-item p-0 ms-0 me-8">
                      {/*begin::Link*/}
                      <a
                        className="nav-link btn btn-color-muted active px-0"
                        data-bs-toggle="tab"
                        id="kt_chart_widgets_22_tab_1"
                        href="#vc_tab"
                      >
                        {/*begin::Subtitle*/}
                        <span className="nav-text fw-semibold fs-4 mb-3">
                          Vendor Cleans
                        </span>
                        {/*end::Subtitle*/}
                        {/*begin::Bullet*/}
                        <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        {/*end::Bullet*/}
                      </a>
                      {/*end::Link*/}
                    </li>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <li className="nav-item p-0 ms-8">
                      {/*begin::Link*/}
                      <a
                        className="nav-link btn btn-color-muted px-0"
                        data-bs-toggle="tab"
                        id="kt_chart_widgets_22_tab_2"
                        href="#Audit_tab"
                      >
                        {/*begin::Subtitle*/}
                        <span className="nav-text fw-semibold fs-4 mb-3">
                          Recover
                        </span>
                        {/*end::Subtitle*/}
                        {/*begin::Bullet*/}
                        <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        {/*end::Bullet*/}
                      </a>
                      {/*end::Link*/}
                    </li>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <li className="nav-item p-0 ms-8">
                      {/*begin::Link*/}
                      <a
                        className="nav-link btn btn-color-muted px-0"
                        data-bs-toggle="tab"
                        id="kt_chart_widgets_22_tab_2"
                        href="#prevention_tab"
                      >
                        {/*begin::Subtitle*/}
                        <span className="nav-text fw-semibold fs-4 mb-3">
                          Prevention
                        </span>
                        {/*end::Subtitle*/}
                        {/*begin::Bullet*/}
                        <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        {/*end::Bullet*/}
                      </a>
                      {/*end::Link*/}
                    </li>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <li className="nav-item p-0 ms-8">
                      {/*begin::Link*/}
                      <a
                        className="nav-link btn btn-color-muted px-0"
                        data-bs-toggle="tab"
                        id="kt_chart_widgets_22_tab_2"
                        href="#kt_chart_widgets_22_tab_content_2"
                      >
                        {/*begin::Subtitle*/}
                        <span className="nav-text fw-semibold fs-4 mb-3">
                          Inview
                        </span>
                        {/*end::Subtitle*/}
                        {/*begin::Bullet*/}
                        <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        {/*end::Bullet*/}
                      </a>
                      {/*end::Link*/}
                    </li>
                    {/*end::Item*/}
                  </ul>
                  {/*end::Nav*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                    {/*begin::Actions*/}
                    <div className="card-toolbar">
                      {/*begin::Filters*/}
                      <div className="d-flex flex-stack flex-wrap gap-4">
                        {/*begin::Destination*/}
                        <div className="d-flex align-items-center fw-bold">
                          {/*begin::Label*/}
                          <div className="text-muted fs-7 me-2">Cateogry</div>
                          {/*end::Label*/}
                          {/*begin::Select*/}
                          <select
                            className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                            data-control="select2"
                            data-hide-search="true"
                            data-dropdown-css-class="w-150px"
                            data-placeholder="Select an option"
                          >
                            <option />
                            <option value="Show All" selected="selected">
                              Show All
                            </option>
                            <option value="a">Category A</option>
                            <option value="b">Category B</option>
                          </select>
                          {/*end::Select*/}
                        </div>
                        {/*end::Destination*/}
                        {/*begin::Status*/}
                        <div className="d-flex align-items-center fw-bold">
                          {/*begin::Label*/}
                          <div className="text-muted fs-7 me-2">Status</div>
                          {/*end::Label*/}
                          {/*begin::Select*/}
                          <select
                            className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                            data-control="select2"
                            data-hide-search="true"
                            data-dropdown-css-class="w-150px"
                            data-placeholder="Select an option"
                            data-kt-table-widget-5="filter_status"
                          >
                            <option />
                            <option value="Show All" selected="selected">
                              Show All
                            </option>
                            <option value="In  ">In</option>
                            <option value="Out of  ">Out of</option>
                            <option value="Low  ">Low</option>
                          </select>
                          {/*end::Select*/}
                        </div>
                        {/*end::Status*/}
                        {/*begin::Search*/}
                        <a
                          href="apps/ecommerce/catalog/products.html"
                          className="btn btn-light btn-sm"
                        >
                          View More
                        </a>
                        {/*end::Search*/}
                      </div>
                      {/*begin::Filters*/}
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Header*/}
                <div className="tab-content">
                  {/*begin::Card body Vc*/}
                  <div
                    className="card-body pt-0 active show tab-pane fade"
                    role="tabpanel"
                    id="vc_tab"
                  >
                    <div className="table-responsive">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5 mt-5"
                        id="kt_subscriptions_table"
                      >
                        {/*begin::Table head*/}
                        <thead>
                          {/*begin::Table row*/}
                          <tr className="fw-bold text-muted ms-2">
                            <th className="min-w-200px">status</th>
                            <th className="min-w-200px">reason</th>
                            <th className="min-w-200px">description</th>
                            <th className="min-w-200px">Incorrect Invoice </th>
                            <th className="min-w-200px">Scource</th>
                            <th className="min-w-150px">cc</th>
                            <th className="min-w-200px">Vendor Number</th>
                            <th className="min-w-200px">Vendor name</th>
                            <th className="min-w-200px">invoice number</th>
                            <th className="min-w-200px">invoice Date</th>
                            <th className="min-w-200px">GMM Amount</th>
                            <th className="min-w-125px">Currency</th>
                            <th className="min-w-125px">PO</th>
                            <th className="min-w-125px">Due</th>
                            <th className="min-w-125px">ATh</th>
                            <th className="min-w-200px">payment Status</th>
                            <th className="min-w-125px">Entered</th>
                          </tr>
                          {/*end::Table row*/}
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}
                        <tbody className="text-gray-600 fw-semibold">
                          {/* begin::record1 */}
                          <tr>
                            {/*begin::Customer=*/}
                            <td>Pending Review</td>
                            {/*end::Customer=*/}
                            {/*begin::Status=*/}
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-solid"
                                name="name"
                                defaultValue="Date entry Error "
                              />
                            </td>
                            {/*end::Status=*/}
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-solid"
                                name="name"
                                defaultValue="Auro Payment mold"
                              />
                            </td>
                            {/*begin::Checkbox*/}
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            {/*end::Checkbox*/}
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                AS101
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                100
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                374AC4
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                Control value description
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                1254789
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                500.005.285
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                USA
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                30005678
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light">
                                i
                              </div>
                            </td>
                            <td>Due</td>
                            <td className="text-end">Aug 19, 2023</td>
                          </tr>
                          {/* end::record1 */}
                          {/* begin::record2 */}
                          <tr>
                            {/*begin::Customer=*/}
                            <td />
                            {/*end::Customer=*/}
                            {/*begin::Status=*/}
                            <td />
                            {/*end::Status=*/}
                            <td />
                            {/*begin::Checkbox*/}
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            {/*end::Checkbox*/}
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                AS101
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                100
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                374AC4
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                Control value description
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                1254789
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                500.005.285
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                USA
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                30005678
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light">
                                i
                              </div>
                            </td>
                            <td>Due</td>
                            <td className="text-end">Aug 19, 2023</td>
                          </tr>
                          {/* end::record2 */}
                          {/* begin::record3 */}
                          <tr>
                            {/*begin::Customer=*/}
                            <td>Pending Review</td>
                            {/*end::Customer=*/}
                            {/*begin::Status=*/}
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-solid"
                                name="name"
                                defaultValue="Date entry Error "
                              />
                            </td>
                            {/*end::Status=*/}
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-solid"
                                name="name"
                                defaultValue="Auro Payment mold"
                              />
                            </td>
                            {/*begin::Checkbox*/}
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            {/*end::Checkbox*/}
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                AS101
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                100
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                374AC4
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                Control value description
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                1254789
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                500.005.285
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                USA
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                30005678
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light">
                                i
                              </div>
                            </td>
                            <td>Due</td>
                            <td className="text-end">Aug 19, 2023</td>
                          </tr>
                          {/* end::record3 */}
                          {/* begin::record4 */}
                          <tr>
                            {/*begin::Customer=*/}
                            <td />
                            {/*end::Customer=*/}
                            {/*begin::Status=*/}
                            <td />
                            {/*end::Status=*/}
                            <td />
                            {/*begin::Checkbox*/}
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={0}
                                />
                              </div>
                            </td>
                            {/*end::Checkbox*/}
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                AS101
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                100
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                374AC4
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                Control value description
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                1254789
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                500.005.285
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                GEP
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                30005678
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light">
                                i
                              </div>
                            </td>
                            <td>Due</td>
                            <td className="text-end">Aug 19, 2023</td>
                          </tr>
                          {/* end::record4 */}
                          {/* begin::record5 */}
                          <tr>
                            {/*begin::Customer=*/}
                            <td>Pending Review</td>
                            {/*end::Customer=*/}
                            {/*begin::Status=*/}
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-solid"
                                name="name"
                                defaultValue="Date entry Error "
                              />
                            </td>
                            {/*end::Status=*/}
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-solid"
                                name="name"
                                defaultValue="Auro Payment mold"
                              />
                            </td>
                            {/*begin::Checkbox*/}
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            {/*end::Checkbox*/}
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                AS101
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                100
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                374AC4
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                Control value description
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                1254789
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                500.005.285
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                USA
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                30005678
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light">
                                i
                              </div>
                            </td>
                            <td>Due</td>
                            <td className="text-end">Aug 19, 2023</td>
                          </tr>
                          {/* end::record5 */}
                          {/* begin::record6 */}
                          <tr>
                            {/*begin::Customer=*/}
                            <td />
                            {/*end::Customer=*/}
                            {/*begin::Status=*/}
                            <td />
                            {/*end::Status=*/}
                            <td />
                            {/*begin::Checkbox*/}
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            {/*end::Checkbox*/}
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                AS101
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                100
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                374AC4
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-warning">
                                Control value description
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                1254789
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                500.005.285
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                USA
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                30005678
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light-primary">
                                4/15/22
                              </div>
                            </td>
                            <td>
                              <div className="badge py-5 px-3 badge-light">
                                i
                              </div>
                            </td>
                            <td>Due</td>
                            <td className="text-end">Aug 19, 2023</td>
                          </tr>
                          {/* end::record6 */}
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                      {/*end::Table*/}
                    </div>
                  </div>
                  {/*begin::Body Audit_tab*/}
                  <div
                    className="card-body py-3 pt-5 tab-pane fade"
                    role="tabpanel"
                    id="Audit_tab"
                  >
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                      {/*begin::Table*/}
                      <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                        {/*begin::Table head*/}
                        <thead>
                          <tr className="fw-bold text-muted">
                            <th className="w-25px">
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue={1}
                                  data-kt-check="true"
                                  data-kt-check-target=".widget-13-check"
                                />
                              </div>
                            </th>
                            <th className="min-w-150px">Order Id</th>
                            <th className="min-w-140px">Country</th>
                            <th className="min-w-120px">Date</th>
                            <th className="min-w-120px">Company</th>
                            <th className="min-w-120px">Total</th>
                            <th className="min-w-120px">Status</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input widget-13-check"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary fs-6"
                              >
                                56037-XDER
                              </a>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Brasil
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: PH
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                05/28/2020
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: Paid
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Intertico
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Web, UI/UX Design
                              </span>
                            </td>
                            <td className="text-dark fw-bold text-hover-primary fs-6">
                              $3560
                            </td>
                            <td>
                              <span className="badge badge-light-success">
                                Approved
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input widget-13-check"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary fs-6"
                              >
                                05822-FXSP
                              </a>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Belarus
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: BY
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                04/18/2021
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: Paid
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Agoda
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Houses &amp; Hotels
                              </span>
                            </td>
                            <td className="text-dark fw-bold text-hover-primary fs-6">
                              $4850
                            </td>
                            <td>
                              <span className="badge badge-light-warning">
                                In Progress
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input widget-13-check"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary fs-6"
                              >
                                4472-QREX
                              </a>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Phillipines
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: BH
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                07/23/2019
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: Paid
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                RoadGee
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Transportation
                              </span>
                            </td>
                            <td className="text-dark fw-bold text-hover-primary fs-6">
                              $8376
                            </td>
                            <td>
                              <span className="badge badge-light-danger">
                                Success
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input widget-13-check"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary fs-6"
                              >
                                00347-BCLQ
                              </a>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Argentina
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: BR
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                12/21/2021
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: Paid
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                The Hill
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Insurance
                              </span>
                            </td>
                            <td className="text-dark fw-bold text-hover-primary fs-6">
                              $9486
                            </td>
                            <td>
                              <span className="badge badge-light-info">
                                Rejected
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input widget-13-check"
                                  type="checkbox"
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary fs-6"
                              >
                                59486-XDER
                              </a>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Agoda
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: BT
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                05/28/2020
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Code: Paid
                              </span>
                            </td>
                            <td>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                              >
                                Phillipines
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                Transportation
                              </span>
                            </td>
                            <td className="text-dark fw-bold text-hover-primary fs-6">
                              $8476
                            </td>
                            <td>
                              <span className="badge badge-light-primary">
                                Approved
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              <a
                                href="https://ews-jo.com/proto/page/home.html"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                      {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                  </div>
                  {/*begin::Body*/}
                  {/*begin::Body*/}
                  {/*end::Card body*/}
                </div>
              </div>
              {/*end::Card*/}
            </div>
            {/*end::Row3*/}
            {/* end::hala Add */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
  
      </div>
    </LayoutAdmin>
  );
};

export default HomePage;
function ChartHome() {
  useEffect(() => {

    // window.KTChartsWidgetOverview.init();
  }, []);

  return (
    <div
      id="kt_charts_widget_analytics_overview_chart"
      className="w-100 h-400px"
    />
  );
}

