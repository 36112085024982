import {
  CardWhite,
  CheckBox,
  ColTextFiledWithLabel,
  ContentForm,
  InputGroupRow,
  MarginBottomFiled,
  MySelect,
  TitleSmall,
  UploadFile,
} from "../../../../index";

const PdfSetting = () => {
  return (
    <ContentForm id="setting-setup-pdf">
      <General />
      <Signature />
    </ContentForm>
  );
};

export default PdfSetting;

const General = () => (
  <CardWhite>
    <TitleSmall title="General" className=" pb-5 pb-lg-10" />
    <MarginBottomFiled>
      <InputGroupRow
        col1={<MySelect label="PDF Font" name="settings[tincheck_username]" />}
        col2={
          <ColTextFiledWithLabel
            label="Defualt Font Size"
            type="number"
            name="settings[font_size]"
          />
        }
      />
    </MarginBottomFiled>
    <MarginBottomFiled>
      <CheckBox title="Swap Company/Vendor Details (company details to right side, vendor details to left side)" />
    </MarginBottomFiled>
    <MarginBottomFiled>
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Items table heading color"
            type="color"
            name="settings[tincheck_username]"
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Items table heading text color"
            type="color"
            name="settings[font_size]"
          />
        }
      />
    </MarginBottomFiled>
    <MarginBottomFiled>
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Custom PDF Company Logo URL"
            name="settings[custom_pdf_logo_image_url]"
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Logo Width (PX)"
            type="number"
            name="settings[pdf_logo_width]"
          />
        }
      />
    </MarginBottomFiled>
    <MarginBottomFiled>
      <CheckBox title=" Show page number on PDF " />
    </MarginBottomFiled>
  </CardWhite>
);
const Signature = () => (
  <CardWhite>
    <TitleSmall title="Signature" className=" pb-5 pb-lg-10" />
    <UploadFile />
  </CardWhite>
);
