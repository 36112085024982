import React from "react";
import { MenuItem, SubItem } from "../../../index";

const HeaderAdmin = () => {
  return (

    <>
      <Dashboard />
      <VendorsItems />
      <RecoverItems />
      <VerifyItems/>
      <CleansItems/>
      <PreventItems/>

      {/* <SubItem title="Recover" namePage="audit_overview" isNotSubMenu={true} /> */}
     
      {/* <SubItem title="Verify" namePage="verify_overview" isNotSubMenu={true} /> */}

      {/* <SubItem title="Cleanse" namePage="cleanse_overview" isNotSubMenu={true} /> */}
      {/* <SubItem title="Prevent" namePage="prevent_overview" isNotSubMenu={true} /> */}
      <RequestHelpItem />
      <UtilitiesItems />
      <SetupItems />
    </>

  );
};

export default HeaderAdmin;

function UtilitiesItems() {
  return (
    <MenuItem title="Utilities">
      <SubItem title="Announcements" namePage="announcements_utilities" />
      <SubItem title="Activity Log" namePage="activity_log_utilities" />
    </MenuItem>
  );
}

function Dashboard() {
  return (
    <MenuItem title="Dashboard">
      <SubItem title="Overview" namePage="home" />
    </MenuItem>
  );
}

function VendorsItems() {
  return (
    <MenuItem title="Vendors">
      <SubItem title="OverView" namePage="overview_vendor" />
      <SubItem title="Vendors" namePage="vendor_page" />
    </MenuItem>
  );
}

function RecoverItems() {
  return (
    <MenuItem title="Recover">
      <SubItem title="OverView" namePage="audit_overview" />

      <SubItem title="Statement" tabs={[
        {title:"OverView", namePage: "overview_statements" },
        { title: 'Collected', namePage: "collected_statements" },
      { title: 'Outstanding', namePage: "outstanding_statements" },
      { title: 'Upload Statement', namePage: "upload_statements" }]} />



      <SubItem title="Claims" tabs={[{ title: 'Overview', namePage: "overview_Claims" },
      { title: 'All Claims', namePage: "all_Claims" },
      { title: 'New Claim', namePage: "new_claims" }]} />



    </MenuItem>
  );
}


function VerifyItems(){
  return(
  <MenuItem  title="Verify" > 
  <SubItem title="OverView" namePage="verify_overview"/>
  </MenuItem>
  )
  
}

function CleansItems() {
  return (
    <MenuItem title="Cleanse">
      <SubItem title="OverView" namePage="cleanse_overview" />

      <SubItem title="Data Cleans" tabs={[
        {title:"OverView", namePage: "data_cleanse_overview" },
        { title: 'Original Vendor Master', namePage: "original_vendor" },
      { title: 'Vendor Archive', namePage: "outstanding_statements" },
      { title: 'Ready To Data Cleans', namePage: "ready_to_data_cleans" },
      { title: 'Data Final', namePage: "data_final" },
      { title: 'Supplier Invitation', namePage: "vendor_invitation" },
      { title: 'Report', namePage: "report" },
      { title: 'Setting', namePage: "setting" },
      { title: 'Import Supplier', namePage: "import_supplier" },]} />



      <SubItem title="Supplier Invitation" tabs={[
        { title: 'Overview', namePage: "supplier_invite_cleanse_overview" },
      { title: 'Supplier Invite', namePage: "supplier_invite_si" },
      { title: 'Approval List', namePage: "approval_list_si" },
      ]} />

<SubItem title="Bedrock Verifiction" tabs={[
        { title: 'Overview', namePage: "verification_cleanse_overview" },
      { title: 'Bedrock Verifiction', namePage: "bedrock_verifiction" },
      { title: 'Bank Verification', namePage: "bank_verification" },
      { title: 'Global Verification', namePage: "global_verification" }]} />



    </MenuItem>
  );
}

function PreventItems() {
  return (
    <MenuItem title="Prevent">
      <SubItem title="OverView" namePage="prevent_overview" />

      <SubItem title="Prevent" tabs={[
        {title:"Pending", namePage: "upload_overview/Pending" },
        { title: 'Stop', namePage: "upload_overview/Stop" },
      { title: 'Process', namePage: "upload_overview/Process" },
      ]} />



      <SubItem title="Inview" namePage="inview_overview" />



    </MenuItem>
  );
}

function SetupItems() {
  return (
    <MenuItem title="Setup">
      <SubItem title="Staff" namePage="staff_setup" />
      <SubItem
        title="Finance"
        tabs={[{ title: "Currencies", namenamePage: "currencies_setup" }]}
      />
      <SubItem title="Email Templates" namePage="email_templates_setup" />
      <SubItem title="Bedrock Onboarding" namePage="onboarding_setup" />
      <SubItem title=" GDPR" namePage="gdpr_setup" />
      <SubItem title=" Roles" namePage="roles_setup" />
      <SubItem title="Settings" namePage="setting_setup" />
      <SubItem title="Features Enable" namePage="features_enable_setup" />
      <SubItem title="Help" namePage="" />
    </MenuItem>
  );
}


const RequestHelpItem = () => (
  <MenuItem title="Request Help">
    <SubItem title="Request Help" namePage="request_help" />
  </MenuItem>
);
