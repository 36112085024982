import React from 'react'
import {  CardsSummary, EmptyTableTD, LayoutAdmin, TableAdmin } from '../../core';
import { useSelector } from 'react-redux';
import { TDCheck } from '../cleanse/pages-cleanse/data-cleans';


const RequsetHelp = () => {
    const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
      <LayoutAdmin
        title="Requset Help"
        pages={["Requset Help"]}
       
      >
        <CardsSummary filters={dataCleans["record_status"]} />
        <TableAdmin
          isExport={true}
         
          titles={["Supplier ID", "Company Name", "Request Help" ,'Subject','Created Date','Department','Attachments','Priority','List Reply']}
        >
          <TableBody records={dataCleans.records ?? []} />
        </TableAdmin>
      </LayoutAdmin>
    );
}

export default RequsetHelp


const TableBody = (props) => {
    const recordes = props.records;
    let recordList;
  
    if (recordes && recordes.length >= 1) {
      recordList = recordes.map((record, key) => {
        return <Record key={key} record={record} />;
      });
  
      return <>{recordList}</>;
    } else {
      return <EmptyTableTD colSpan={9} />;
    }
  };
  
  const Record = (props) => {
    const record = props.record;
    return (
      <tr key={record.id}>
        <TDCheck />
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className="text-end"></td>
      </tr>
    );
  };
  