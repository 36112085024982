
import { MarginBottomFiled, MySelect, MyLabel ,BodyContentFormWithCard,TitleSmall} from "../../../../index";

const InviteApproval = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-invite-approval">
      <TitleSmall title="Invite Approval " className="pb-5 pb-lg-10" />
      <Approvel title="select which needs approval before vendor invitation" />
      <Approvel title="select which needs two approval after vendor complete onboarding" />
      <Approvel title="select which needs one approval after vendor complete onboarding" />
    </BodyContentFormWithCard>
  );
};

export default InviteApproval;

const Approvel = (props) => (
  <>
    <MyLabel label={props.title} className="mb-5" />
    <MarginBottomFiled>
      <MySelect
        label="Vendor Type"
        name="settings[vendor_master_auto_archive_vendor_type][]"
      />
    </MarginBottomFiled>
    <MarginBottomFiled>
      <MySelect
        label="Vendor Type"
        name="settings[vendor_master_auto_archive_vendor_type][]"
      />
    </MarginBottomFiled>
  </>
);
