import { useState } from "react";
import { MyLabel } from "./lable";

//props 
///options =[Strings]
//indexSelect int
const GroupRadioBtn = (props) => {
  return (
    <>
      {/*begin::Label*/}
      <MyLabel label={props.label} />
      {/*end::Label*/}
      <div
        className="row g-9"
        data-kt-buttons="true"
        data-kt-buttons-target="[data-kt-button='true']"
      >
        {/*begin::Col*/}
        <div className="col-md-6 col-lg-12 col-xxl-6">
          {/*begin::Option*/}
          <RadioBtn
            title="one test"
            name="test 1"
            defaultValue={1}
            defaultChecked={true}
          />
          {/*end::Option*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-md-6 col-lg-12 col-xxl-6">
          {/*begin::Option*/}
          <RadioBtn
            title="tow test"
            name="test 1"
            defaultValue={2}
            defaultChecked={false}
          />

          {/*end::Option*/}
        </div>
        {/*end::Col*/}
      </div>
    </>
  );
};

export default GroupRadioBtn;

///props
///title String
////name String
///defaultValue int
///defaultChecked bool option
const RadioBtn = (props) => {
  const [active, setActive] = useState(props.defaultChecked ?? false);


  return (
    <label
      onClick={() => setActive(!active)}
      className={`btn btn-outline btn-outline-dashed btn-active-light-primary ${
        active ? "active" : ""
      } d-flex text-start p-6`}
      data-kt-button="true"
    >
      {/*begin::Radio*/}
      <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
        <input
          className="form-check-input"
          type="radio"
          name={props.name}
          defaultValue={props.defaultValue}
          defaultChecked={props.defaultChecked ? "checked" : ""}
        />
      </span>
      {/*end::Radio*/}
      {/*begin::Info*/}
      <span className="ms-5">
        <span className="fs-4 fw-bold text-gray-800 mb-2 d-block">
          {props.title}
        </span>
      </span>
      {/*end::Info*/}
    </label>
  );
};
