//props
// icon Component (icon)(option)
//title String
//isPerant bool (option)
const NavitemSideBarMneu = (props) => {

  const attribute = props.onClick ? {'onClick':props.onClick}: {
    href: "#",
    "data-bs-toggle": "tab",
    "data-bs-target": `#${props.id}`,
    role: "tab",
  };

  return (
    <>
      {/*begin::Nav item*/}
      <div className="menu-item mt-3">
        <a
          className={`menu-link  text-muted text-active-primary ms-0 py-0  border-0 text-captalize ${
            props.isActive ? "active" : ""
          }`}
    
            {...attribute}
          
        >
          {/*begin:Menu link*/}
          <span className="menu-link">
            <span className="menu-bullet">
              {props.icon ?? (
                <>
                  <span className="bullet bullet-dot"></span>
                </>
              )}
            </span>
            <span className="menu-title"> {props.title}</span>
            {props.isPerant ? <span className="menu-arrow" /> : ""}
          </span>
          {/*end:Menu link*/}
        </a>
      </div>
      {/*end::Nav item*/}
    </>
  );
};

export default NavitemSideBarMneu;
