

import "../style.css";


const HeaderSecondary = (props) => {
  return (
    <>
      {/*begin::Header secondary*/}
      <div className="app-header-secondary">
        {/*begin::Header secondary container*/}
        <div className="app-container container-xxl d-flex align-items-stretch">
          {/*begin::Secondary*/}
          <div
            className="d-flex flex-stack flex-row-fluid"
            id="kt_app_header_wrapper"
          >
            <div className="">
              {/*begin::Menu wrapper*/}
              <div
                className="app-header-menu app-header-mobile-drawer align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="app-header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="250px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                {/*begin::Menu*/}
                <div
                  className="menu menu-rounded menu-active-bg menu-state-primary menu-column menu-lg-row menu-title-gray-700 menu-icon-gray-500 menu-arrow-gray-500 menu-bullet-gray-500 my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
                  id="kt_app_header_menu"
                  data-kt-menu="true"
                >
              {props.children}
                </div>
                {/*end::Menu*/}
              </div>
              {/*end::Menu wrapper*/}
            </div>
            {/*begin::Action*/}
            <div
              className="px-4 px-lg-0 pt-5 pt-lg-0 text-end"
              data-kt-swapper="true"
              data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
              data-kt-swapper-parent="{default: '#kt_app_header_menu', lg: '#kt_app_header_wrapper'}"
            ></div>
            {/*end::Action*/}
          </div>
          {/*end::Secondary*/}
        </div>
        {/*end::Header secondary container*/}
      </div>
      {/*end::Header secondary*/}
    </>
  );
};

export default HeaderSecondary;
