import React from 'react'
///props
////name     String ex 'name';
////isRequired bool option by default false;
////type bool option by default text;
///value Option
const TextFiled = (props) => {
  return (
    
      <input
        type={props.type ?? "text"}
        className="form-control form-control-solid"
        // placeholder={`Enter your ticket ${props.name}`}
        name={props.name}
        defaultValue={props.value}
      />  

  )
}

export default TextFiled
