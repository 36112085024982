

///props
///id 
  //classname (option)
///attributes add for btn
///icon (option)
////text string 
const BtnGray = (props) => {
  return (
    <>
    {/*begin::Primary button*/}
    <a
     // href="#"
      className={"btn btn-sm btn-flex btn-color-white bg-gray-600 btn-active-dark h-35 px-3 "+props.className}
      {...props.attributes}
    >
  
    {props.icon}
      {/*end::Svg Icon*/}  {props.text}
    </a>
    {/*end::Primary button*/}
  </>
  
  )
}

export default BtnGray
