import React from 'react'

function Japan() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path fill="#F5F5F5" d="M0 0H512V512H0z"></path>
            <circle cx="256" cy="256" r="97.1" fill="#FF4B55"></circle>
        </svg>
    )
}

export default Japan