import React from 'react'
import MyLabel from './MyLabel'

const ColInputWithLable = (props) => {
  return (
    <>
    <MyLabel {...props}/>
      {props.children}
    </>
  )
}

export default ColInputWithLable;
