import { useSelector } from "react-redux";
import {
  CardsSummary,
  LayoutAdmin,
  TableAdmin,

  EmptyTableTD,
  CreateFullFormGlobalVerificationDialog,
  CreateGlobalVerificationFullFormBTN,
  LayoutCleanse,
} from "../../../../index";



const GlobalVerifiction = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <LayoutCleanse
      title="Global Verifiction"
      pages={["Bedrock Verifiction", "Global Verifiction"]}
      extraAction={<CreateGlobalVerificationFullFormBTN />}
    >
      <CardsSummary filters={dataCleans["record_status"]} />
      <TableAdmin
        isExport={true}
        isSelest={true}
        isAction={true}
        titles={[
          "Bedrock ID",
          "Supplier Number",
          "Company Name",
          "Status",
          "Created Date",
        ]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
      <CreateFullFormGlobalVerificationDialog />
    </LayoutCleanse>
  );
};

export default GlobalVerifiction;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record}/>
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={7} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

      <td className="text-end">View</td>
    </tr>
  );
};
