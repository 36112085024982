import React from "react";

import { Tabs, CompanyInfo, VendorAuditor, SubCompany } from "../../../../../index";

const ProfileContent = () => {
  return (
    <>
      <Tabs
        tabs={[
          {
            idContent: "contact_info",
            title: " Vendor Details ",
            isActive: true,
          },
          {
            idContent: "customer_admins",
            title: "Vendor Auditor",
          },
          {
            idContent: "sub_vendors",
            title: "Sub Company  ",
          },
        ]}
      />

      <div className="tab-content mt-5">
     
      <CompanyInfo />
      <VendorAuditor />
      <SubCompany />
      </div>
    </>
  );
};

export default ProfileContent;
