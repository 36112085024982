

const TDFaxNumber = (props) => {
    const faxNumber=props.fax_number;
  return (
    <td>{faxNumber}</td>
  )
}

export default TDFaxNumber
