import React from 'react';
import LayoutAudit from '../../LayoutAudit';

const OverviewClaims = () => {
    return ( 
     <LayoutAudit     title={'Overview'}
  pages={['Claims']}

 >


  <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
    {/*begin::Content wrapper*/}
    <div className="d-flex flex-column flex-column-fluid">
      {/*begin::Content*/}
      <div id="kt_app_content" className="app-content px-0">
        {/*begin:: hala Add */}
        {/*begin::Row1*/}
        <div className="row g-5 g-xl-10 mb-xl-10">
          {/*begin::Col*/}
          <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
            {/*begin::Chart widget 3*/}
            <div className="card card-flush overflow-hidden h-md-100">
              {/*begin::Header*/}
              <div className="card-header py-5">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">
                    Sales This Months
                  </span>
                </h3>
                {/*end::Title*/}
                {/*begin::Toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Menu*/}
                  <button
                    className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-overflow="true"
                  >
                    {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                    <span className="svg-icon svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={4}
                          fill="currentColor"
                        />
                        <rect
                          x={11}
                          y={11}
                          width="2.6"
                          height="2.6"
                          rx="1.3"
                          fill="currentColor"
                        />
                        <rect
                          x={15}
                          y={11}
                          width="2.6"
                          height="2.6"
                          rx="1.3"
                          fill="currentColor"
                        />
                        <rect
                          x={7}
                          y={11}
                          width="2.6"
                          height="2.6"
                          rx="1.3"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </button>
                  {/*begin::Menu 2*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                    data-kt-menu="true"
                  >
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                        Quick Actions
                      </div>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu separator*/}
                    <div className="separator mb-3 opacity-75" />
                    {/*end::Menu separator*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a
                        href="https://ews-jo.com/proto/page/home.html"
                        className="menu-link px-3"
                      >
                        New Ticket
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a
                        href="https://ews-jo.com/proto/page/home.html"
                        className="menu-link px-3"
                      >
                        New Customer
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div
                      className="menu-item px-3"
                      data-kt-menu-trigger="hover"
                      data-kt-menu-placement="right-start"
                    >
                      {/*begin::Menu item*/}
                      <a
                        href="https://ews-jo.com/proto/page/home.html"
                        className="menu-link px-3"
                      >
                        <span className="menu-title">New Group</span>
                        <span className="menu-arrow" />
                      </a>
                      {/*end::Menu item*/}
                      {/*begin::Menu sub*/}
                      <div className="menu-sub menu-sub-dropdown w-175px py-4">
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link px-3"
                          >
                            Admin Group
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link px-3"
                          >
                            Staff Group
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="https://ews-jo.com/proto/page/home.html"
                            className="menu-link px-3"
                          >
                            Member Group
                          </a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu sub*/}
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a
                        href="https://ews-jo.com/proto/page/home.html"
                        className="menu-link px-3"
                      >
                        New Contact
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu separator*/}
                    <div className="separator mt-3 opacity-75" />
                    {/*end::Menu separator*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <div className="menu-content px-3 py-3">
                        <a
                          className="btn btn-primary btn-sm px-4"
                          href="https://ews-jo.com/proto/page/home.html"
                        >
                          Generate Reports
                        </a>
                      </div>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu 2*/}
                  {/*end::Menu*/}
                </div>
                {/*end::Toolbar*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                {/*begin::Chart*/}
                <div
                  id="kt_charts_widget_16_chart"
                  className="min-h-auto ps-4 pe-6"
                  style={{ height: 350 }}
                />
                {/*end::Chart*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Chart widget 3*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 4*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      1,204
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-primary fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Statements Collected
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-2 pb-4 d-flex align-items-center">
                {/*begin::Chart*/}
                <div className="d-flex flex-center me-5 pt-2">
                  <div
                    id="kt_card_widget_4_chart"
                    style={{ minWidth: 70, minHeight: 70 }}
                    data-kt-size={70}
                    data-kt-line={11}
                  />
                </div>
                {/*end::Chart*/}
                {/*begin::Labels*/}
                <div className="d-flex flex-column content-justify-center w-100">
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">
                      Uploaded Today
                    </div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $7,660
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center my-3">
                    {/*begin::Bullet*/}
                    <div className="bullet w-8px h-6px rounded-2 bg-primary me-3" />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">
                      Outstanding
                    </div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $2,820
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Label*/}
                  <div className="d-flex fs-6 fw-semibold align-items-center">
                    {/*begin::Bullet*/}
                    <div
                      className="bullet w-8px h-6px rounded-2 me-3"
                      style={{ backgroundColor: "#e4e6ef" }}
                    />
                    {/*end::Bullet*/}
                    {/*begin::Label*/}
                    <div className="text-gray-500 flex-grow-1 me-4">Others</div>
                    {/*end::Label*/}
                    {/*begin::Stats*/}
                    <div className="fw-bolder text-gray-700 text-xxl-end">
                      $45,257
                    </div>
                    {/*end::Stats*/}
                  </div>
                  {/*end::Label*/}
                </div>
                {/*end::Labels*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 4*/}
            {/*begin::Card widget 5*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                      $
                    </span>
                    {/*end::Currency*/}
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      53
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-primary fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.6%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Audit Completion
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end pt-0">
                {/*begin::Progress*/}
                <div className="d-flex align-items-center flex-column mt-3 w-100">
                  <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-bolder fs-6 text-dark">
                      1,048 to Complete
                    </span>
                    <span className="fw-bold fs-6 text-gray-400">62%</span>
                  </div>
                  <div className="h-8px mx-3 w-100 bg-light-primary rounded">
                    <div
                      className="bg-primary rounded h-8px"
                      role="progressbar"
                      style={{ width: "62%" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                {/*end::Progress*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 5*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 6*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                      1,585
                    </span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light-primary fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-primary ms-n1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}57.08%
                    </span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Under Investigation
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end px-0 pb-0">
                {/*begin::Chart*/}
                <div
                  id="kt_card_widget_6_chart"
                  className="w-100"
                  style={{ height: 80 }}
                />
                {/*end::Chart*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 6*/}
            {/*begin::Card widget 7*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Amount*/}
                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                    30
                  </span>
                  {/*end::Amount*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Open Claims
                  </span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex flex-column justify-content-end pe-0">
                {/*begin::Title*/}
                <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                  Pending Validation
                </span>
                {/*end::Title*/}
                {/*begin::Users group*/}
                <div className="symbol-group symbol-hover flex-nowrap">
                  <div
                    className="symbol symbol-40px symbol-circle"
                    data-bs-toggle="tooltip"
                    title="Pending Supplier Validation"
                  >
                    <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                      25
                    </span>
                  </div>
                  <div
                    className="symbol symbol-40px symbol-circle"
                    data-bs-toggle="tooltip"
                    title="Pending Client Validation"
                  >
                    <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                      0
                    </span>
                  </div>
                  <div
                    className="symbol symbol-40px symbol-circle"
                    data-bs-toggle="tooltip"
                    title="Perry Matthew"
                  >
                    <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                      3
                    </span>
                  </div>
                </div>
                {/*end::Users group*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 7*/}
          </div>
          
          {/*end::Col*/}
        </div>
        {/*end::Row1*/}
        {/*begin::Row2*/}
        
        {/*end::Row2*/}
        {/*begin::Row3*/}
        <div className="row gy-5 g-xl-10 mx-1 mt-1">
          {/*begin::Card*/}
          <div className="card">
            {/*begin::Card header*/}
            <div className="card-header border-0 pt-6">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">
                     
                    Report
                  </span>
                  <span className="text-gray-400 mt-1 fw-semibold fs-6">
                    Total 2,356 Items in the
                  </span>
                </h3>
                {/*end::Title*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Actions*/}
                <div className="card-toolbar">
                  {/*begin::Filters*/}
                  <div className="d-flex flex-stack flex-wrap gap-4">
                    {/*begin::Search*/}
                    <a
                      href="https://ews-jo.com/proto/page/home.html"
                      className="btn btn-light btn-sm"
                    >
                      All
                    </a>
                    {/*end::Search*/}
                  </div>
                  {/*begin::Filters*/}
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-0">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed fs-6 gy-5"
                  id="kt_subscriptions_table"
                >
                  {/*begin::Table head*/}
                  <thead>
                    {/*begin::Table row*/}
                    <tr>
                      <th className="min-w-200px">
                        id
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        description
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        Assigned To
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        Due
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        InQueut
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-150px">
                        Notfication
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-200px">
                        Most Recent
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      <th className="min-w-125px">
                        Notes
                        <button
                          className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-overflow="true"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen023.svg*/}
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={4}
                                fill="currentColor"
                              />
                              <rect
                                x={11}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={15}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                              <rect
                                x={7}
                                y={11}
                                width="2.6"
                                height="2.6"
                                rx="1.3"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                      </th>
                      {/*end::Table row*/}
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody className="text-gray-600 fw-semibold">
                    {/* begin::record1 */}
                    <tr>
                      <td>11112255</td>
                      <td>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </td>
                      {/*end::Status=*/}
                      <td>Lorem ipsum</td>
                      <td>4/15/22</td>
                      <td>od 1h 23m</td>
                      <td />
                      <td>4/15/22 6:01</td>
                      <td />
                    </tr>
                    {/* end::record1 */}
                    {/* begin::record1 */}
                    <tr>
                      <td>11112255</td>
                      <td>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </td>
                      {/*end::Status=*/}
                      <td>Lorem ipsum</td>
                      <td>4/15/22</td>
                      <td>od 1h 23m</td>
                      <td />
                      <td>4/15/22 6:01</td>
                      <td />
                    </tr>
                    {/* end::record1 */}
                    {/* begin::record1 */}
                    <tr>
                      <td>11112255</td>
                      <td>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </td>
                      {/*end::Status=*/}
                      <td>Lorem ipsum</td>
                      <td>4/15/22</td>
                      <td>od 1h 23m</td>
                      <td />
                      <td>4/15/22 6:01</td>
                      <td />
                    </tr>
                    {/* end::record1 */}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Row3*/}
        {/* end::hala Add */}
      </div>
      {/*end::Content*/}
    </div>
    {/*end::Content wrapper*/}

  </div>
  


  </LayoutAudit>
     );
}
 
export default OverviewClaims;