import React from "react";
import {
  BtnPrimary,
  CardWhite,
  Table,
  Badge,
  MarginBottomFiled,

  SettingIcon,
} from "../../../../../../index";

const OverviewStatementTab = () => {
  return (
    <>
      {/*begin::Body*/}
      <CardWhite  >
        {/*begin::Layout*/}
        <div className="d-flex flex-column flex-xl-row">
          {/*begin::Content*/}
          <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0 ">
            {/*begin::Invoice 2 content*/}
            <div className="mt-n1 ">
              {/*begin::Top*/}
              <div className="d-flex flex-stack pb-16 ">
                {/*begin::Label*/}
                <div className="fw-bold fs-2 text-gray-800 mb-2 ">
                  #Statement
                </div>

                {/*end::Label*/}
                {/*begin::Action*/}
                <BtnPrimary text="UploadFile" />
                {/*end::Action*/}
              </div>
              {/*end::Top*/}
              {/*begin::Wrapper*/}
              <div className="m-0">
                {/*begin::Row*/}
                <div className="row g-5 mb-11">
                  <ShortInfo title="Vendor" description="Hala" />
                  <ShortInfo title="Date Created" description="2023-05-17" />
                </div>
                {/*end::Row*/}

                {/*begin::Content*/}
                <div className="flex-grow-1">
                  {/*begin::Table*/}
                  <Table
                    className=" border-bottom mb-9"
                    titles={["FileName", "Options"]}
                  >
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </Table>
                  {/*end::Table*/}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Invoice 2 content*/}
          </div>
          {/*end::Content*/}
          {/*begin::Sidebar*/}
          <div className="m-0">
            {/*begin::Invoice 2 sidebar*/}
            <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9  bg-lighten">
              <MarginBottomFiled>
                <Badge name="In Progress" color="badge-light-primary" />
              </MarginBottomFiled>
              <MarginBottomFiled>
                <ShortInfo
                  title="Description"
                  description="No description for this statement"
                />
              </MarginBottomFiled>
              <MarginBottomFiled>
                <div className="d-flex justify-content-between mb-2">
                  <MyTitle title="AUDITOR" />
                  <SettingIcon />
                </div>
                <ItemAuditor
                  name="Hala Nidal"
                  urlImg="assets/media/avatars/300-6.jpg"
                />
              </MarginBottomFiled>
            </div>
            {/*end::Invoice 2 sidebar*/}
          </div>
        {/*end::Sidebar*/}
        </div>
        {/*end::Layout*/}
      </CardWhite>
      {/*end::Body*/}
    </>
  );
};

export default OverviewStatementTab;

///props
///title String
///description String
function ShortInfo(props) {
  return (
    <div className="col-sm-6">
      {/*end::Label*/}
      <MyTitle {...props} />
      {/*end::Label*/}
      {/*end::Col*/}
      <div className="fw-bold fs-6 text-gray-800">{props.description}</div>
      {/*end::Col*/}
    </div>
  );
}

function MyTitle(props) {
  return (
    <div className="fw-semibold fs-7 text-gray-600 mb-1">{props.title}:</div>
  );
}

//props
//urlImg String link img
//name String
const ItemAuditor = (props) => {
  return (
    <>
      {/*begin::Item*/}
      <div className="d-flex align-items-center mb-7">
        {/*begin::Avatar*/}
        <div className="symbol symbol-50px me-5">
          <img src={props.urlImg} className="" alt="" />
        </div>
        {/*end::Avatar*/}
        {/*begin::Text*/}
        <div className="flex-grow-1">
          <a href="#" className="text-dark fw-bold text-hover-primary fs-6">
            {props.name}
          </a>
          <span className="text-danger d-block fw-bold">remove</span>
        </div>
        {/*end::Text*/}
      </div>
      {/*end::Item*/}
    </>
  );
};
