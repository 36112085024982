
const PermissionForm = () => {
  return (
    <>
        {/*begin::Form*/}
        <form id="kt_modal_update_role_form" className="form" action="#">
          {/*begin::Scroll*/}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7"
            id="kt_modal_update_role_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_update_role_header"
            data-kt-scroll-wrappers="#kt_modal_update_role_scroll"
            data-kt-scroll-offset="300px"
          >
            {/*begin::Input group*/}
            <div className="fv-row mb-10">
              {/*begin::Label*/}
              <label className="fs-5 fw-bold form-label mb-2">
                <span className="required">Role name</span>
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <input
                className="form-control form-control-solid"
                placeholder="Enter a role name"
                name="role_name"
                defaultValue="Developer"
              />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Permissions*/}
            <div className="fv-row">
              {/*begin::Label*/}
              <label className="fs-5 fw-bold form-label mb-2">
                Role Permissions
              </label>
              {/*end::Label*/}
              {/*begin::Table wrapper*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed fs-6 gy-5">
                  {/*begin::Table body*/}
                  <tbody className="text-gray-600 fw-semibold">
                    {/*begin::Table row*/}
                    <tr>
                      <td className="text-gray-800">
                        Administrator Access
                        <i
                          className="fas fa-exclamation-circle ms-1 fs-7"
                          data-bs-toggle="tooltip"
                          title="Allows a full access to the system"
                        />
                      </td>
                      <td>
                        {/*begin::Checkbox*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="kt_roles_select_all"
                          />
                          <span
                            className="form-check-label"
                            htmlFor="kt_roles_select_all"
                          >
                            Select all
                          </span>
                        </label>
                        {/*end::Checkbox*/}
                      </td>
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">User Management</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="user_management_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="user_management_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="user_management_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">Content Management</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="content_management_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="content_management_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="content_management_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">Financial Management</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="financial_management_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="financial_management_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="financial_management_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">Reporting</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="reporting_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="reporting_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="reporting_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">Payroll</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="payroll_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="payroll_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="payroll_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">Disputes Management</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="disputes_management_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="disputes_management_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="disputes_management_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">API Controls</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="api_controls_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="api_controls_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="api_controls_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">Database Management</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="database_management_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="database_management_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="database_management_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                    {/*begin::Table row*/}
                    <tr>
                      {/*begin::Label*/}
                      <td className="text-gray-800">Repository Management</td>
                      {/*end::Label*/}
                      {/*begin::Input group*/}
                      <td>
                        {/*begin::Wrapper*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="repository_management_read"
                            />
                            <span className="form-check-label">Read</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-5 me-lg-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="repository_management_write"
                            />
                            <span className="form-check-label">Write</span>
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              name="repository_management_create"
                            />
                            <span className="form-check-label">Create</span>
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Wrapper*/}
                      </td>
                      {/*end::Input group*/}
                    </tr>
                    {/*end::Table row*/}
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table wrapper*/}
            </div>
            {/*end::Permissions*/}
          </div>
          {/*end::Scroll*/}
          {/*begin::Actions*/}
          <div className="text-center pt-15">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-roles-modal-action="cancel"
            >
              Discard
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-roles-modal-action="submit"
            >
              <span className="indicator-label">Submit</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
          {/*end::Actions*/}
        </form>
        {/*end::Form*/}
    </>
  )
}

export default PermissionForm;
