
import { Outlet, useNavigate } from "react-router-dom";
import { BtnWhite, LayoutAdmin, SideBarMneuVendor } from "../../../index";


const DetailsVendor = () => {
  const navigate = useNavigate();
  return (
    <LayoutAdmin
      title="Details Supplier"
      pages={["Apps", "Details Supplier"]}
      sidebar={<SideBarMneuVendor isnav={true} />}
      extraAction={<BtnWhite text='Onboarding'  attributes={{
        //"onClick": ()=>{navigate(`/edit_onborading/${props.id}`)},
        "onClick": ()=>{navigate(`/edit_onborading`)},
      }} />}
    >
      <div id="kt_app_content" className="app-content px-0">
       <Outlet/>
      </div>
    </LayoutAdmin>
  );
};

export default DetailsVendor;
