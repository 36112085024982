import { BtnPrimary, BtnSecundary, Dialog, GroupCheckBox } from "../../index";


export const  AddAnnouncementBTN =()=>{
    return (
        <><BtnPrimary
        text="New Announcements"
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_announcements_add",
        }}
      /><AddAnnouncementDialog/></>);
}
const AddAnnouncementDialog = () => {
  return (
    <>
      <Dialog id="kt_modal_announcements_add" title="Add new announcement">
       
        <div className="d-block rounded border \">


  
  {/*begin::Subject*/}
  <div className="border-bottom">
    <input
      className="form-control border-0 px-8 min-h-45px"
      name="compose_subject"
      placeholder="Subject"
    />
  </div>
  {/*end::Subject*/}
  {/*begin::Message*/}
  <div className="ql-toolbar ql-snow px-5 border-top-0 border-start-0 border-end-0">
    <span className="ql-formats">
      <span className="ql-header ql-picker">
        <span
          className="ql-picker-label"
          tabIndex={0}
          role="button"
          aria-expanded="false"
          aria-controls="ql-picker-options-0"
        >
          <svg viewBox="0 0 18 18">
             
            <polygon className="ql-stroke" points="7 11 9 13 11 11 7 11" /> 
            <polygon className="ql-stroke" points="7 7 9 5 11 7 7 7" /> 
          </svg>
        </span>
        <span
          className="ql-picker-options"
          aria-hidden="true"
          tabIndex={-1}
          id="ql-picker-options-0"
        >
          <span
            tabIndex={0}
            role="button"
            className="ql-picker-item"
            data-value={1}
          />
          <span
            tabIndex={0}
            role="button"
            className="ql-picker-item"
            data-value={2}
          />
          <span
            tabIndex={0}
            role="button"
            className="ql-picker-item ql-selected"
          />
        </span>
      </span>
      <select className="ql-header" style={{ display: "none" }}>
        <option value={1} />
        <option value={2} />
        <option selected="selected" />
      </select>
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-bold">
        <svg viewBox="0 0 18 18">
           
          <path
            className="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          /> 
          <path
            className="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          /> 
        </svg>
      </button>
      <button type="button" className="ql-italic">
        <svg viewBox="0 0 18 18">
           
          <line className="ql-stroke" x1={7} x2={13} y1={4} y2={4} /> 
          <line className="ql-stroke" x1={5} x2={11} y1={14} y2={14} /> 
          <line className="ql-stroke" x1={8} x2={10} y1={14} y2={4} /> 
        </svg>
      </button>
      <button type="button" className="ql-underline">
        <svg viewBox="0 0 18 18">
           
          <path
            className="ql-stroke"
            d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"
          /> 
          <rect
            className="ql-fill"
            height={1}
            rx="0.5"
            ry="0.5"
            width={12}
            x={3}
            y={15}
          /> 
        </svg>
      </button>
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-image">
        <svg viewBox="0 0 18 18">
           
          <rect className="ql-stroke" height={10} width={12} x={3} y={4} /> 
          <circle className="ql-fill" cx={6} cy={7} r={1} /> 
          <polyline
            className="ql-even ql-fill"
            points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
          /> 
        </svg>
      </button>
      <button type="button" className="ql-code-block">
        <svg viewBox="0 0 18 18">
           
          <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11" /> 
          <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11" /> 
          <line className="ql-stroke" x1={10} x2={8} y1={5} y2={13} /> 
        </svg>
      </button>
    </span>
  </div>
  <div
    id="kt_inbox_form_editor"
    className="border-0 h-250px px-3 ql-container ql-snow"
  >
    <div
      className="ql-editor ql-blank"
      data-gramm="false"
      contentEditable="true"
      data-placeholder="Type your text here..."
    >
      <p>
        <br />
      </p>
    </div>
    <div className="ql-clipboard" contentEditable="true" tabIndex={-1} />
    <div className="ql-tooltip ql-hidden">
      <a
        className="ql-preview"
        rel="noopener noreferrer"
        target="_blank"
        href="about:blank"
      />
      <input
        type="text"
        data-formula="e=mc^2"
        data-link="https://quilljs.com"
        data-video="Embed URL"
      />
      <a className="ql-action" />
      <a className="ql-remove" />
    </div>
  </div>
  {/*end::Message*/}
  {/*begin::Attachments*/}
  <div
    className="dropzone dropzone-queue px-8 py-4"
    id="kt_inbox_reply_attachments"
    data-kt-inbox-form="dropzone"
  >
    <div className="dropzone-items"></div>
    <div className="dz-default dz-message">
      <button className="dz-button" type="button">
        Drop files here to upload
      </button>
    </div>
  </div>
  {/*end::Attachments*/}
</div>

        <GroupCheckBox
              name="showname"
              isRow={true}
              options={[
                 { name:"Show to staff",checked:'checked'},
                 { name:"Show to clients",checked:'checked'},
                 { name:"Show my name",checked:'checked'},
                
              ]}
            />
        <div className="modal-footer flex-center">
          <BtnSecundary text="close" />
          <BtnPrimary text="save" />
        </div>
      </Dialog>
      
    </>
  );
};

export default AddAnnouncementDialog;
