import {
  LayoutAdmin,
  BadroukVerification,
  CompanyInformation,
  CronJob,
  DataCleansOnboarding,
  Email,
  General,
  Invite,
  InviteApproval,
  Localization,
  Onboarding,
  PdfSetting,
  Pirority,
  SupplierMaster,
} from "../../../index";
import SideBarMneuSettingSetup from "./SideBarMneuSettingSetup";

const SetupSetting = () => {
  return (
    <LayoutAdmin
      title="Setting"
      pages={["Setup", "Setting"]}
      sidebar={<SideBarMneuSettingSetup isnav={true} />}
    >
      <div id="kt_app_content" className="app-content px-0">
        {/*begin::Navbar*/}
        <div className="tab-content ">
          <General />
          <CompanyInformation />
          <Localization />
          <Email />
          <BadroukVerification />
          <InviteApproval />
          <Invite />
          <Pirority />
          <Onboarding />
          <DataCleansOnboarding />
          <PdfSetting />
          <SupplierMaster />
          <CronJob />
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default SetupSetting;
