const PopupMenu = (props) => {
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-700 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
      data-kt-menu="true"
    >
      {props.children}
    </div>
  );
};

export default PopupMenu;

/////props
//text String
///attributes add for btn
//classname (option)
export const PopupMenuItem = (props) => {
  // console.log(props.attributes);
  return (
    <div className="menu-item px-3">
      <a
        className={"menu-link px-3" + props.className ?? ""}
        {...props.attributes}
      >
        {props.text}
      </a>
    </div>
  );
};
