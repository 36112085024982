import React, { useState } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const TextEditor = () => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
    ],
  };
  const { quill, quillRef } = useQuill();
  const [value,setValue]=useState() 

  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {

        setValue(quillRef.current.firstChild.innerHTML)
      });
    }
  }, [quill]);
  console.log("value",value)
  
  return (
   <div style={{height:"250px",marginBottom:"30px"}} >
      <div ref={quillRef} />
    </div>
  
  )
}

export default TextEditor
