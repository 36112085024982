import React from 'react'

function Input({ id,type, val, setVal, placeHolder }) {
  return (
    <input
    id={id}
      type={type}
      placeholder={placeHolder}
      name={id}
      onChange={(e) => setVal(e.target.value)}
      autoComplete="off"
      className="form-control bg-transparent"
    />
  )
}

export default Input
