
import {
  BtnPrimary, Dialog ,
  InputGroupRow,
  ColTextFiledWithLabel,
  MySelect,
  Notice,
  SingleSelect,
} from "../../../../index";

const AddCurrency = () => {
  return (
    <>
      <BtnPrimary
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_add_currency",
        }}
        text="Add New Currency "
        className="me-3"
      />

      <AddCurrencyDialog id="kt_add_currency" title="Add Currency" />
    </>
  );
};

export default AddCurrency;

export const AddCurrencyDialog = (props) => {
  return (
    <Dialog id={props.id} title={props.title} classNameBody="ps-lg-10 pt-0">
      <form
        className="form mt-10"
        action="#"
        id="kt_modal_new_kt_add_currency_form"
      >
        {/*begin::Scroll*/}
        <div
          className="scroll-y me-n7 pe-7"
          id="kt_modal_new_kt_add_currency_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_new_kt_add_currency_header"
          data-kt-scroll-wrappers="#kt_modal_new_kt_add_currency_scroll"
          data-kt-scroll-offset="300px"
        >
          <Notice desc="Make sure to enter valid currency ISO code" />

          <Margin>
            <ColTextFiledWithLabel label="Currency Code" name="currency-code" />
          </Margin>
          <Margin>
            <ColTextFiledWithLabel label="Symbol" name="symbol" />
          </Margin>

          <Margin>
            <InputGroupRow
              col1={
                <MySelect
                  label="Decimal Separator"
                  name="decimal-separator"
                  options={[
                    { value: "A", country: "A" },
                    { value: "B", country: "B" },
                    { value: "C", country: "C" },
                  ]}
                />
              }
              col2={
                <MySelect
                  label="Thousand Separator"
                  name="thousand-separator"
                  options={[
                    { value: "A", country: "A" },
                    { value: "B", country: "B" },
                    { value: "C", country: "C" },
                  ]}
                />
              }
            />
          </Margin>
          <Margin>
            <SingleSelect label='Currency Placement' />
          </Margin>
        </div>
      </form>
    </Dialog>
  );
};

function Margin(props) {
  return <div className="mt-6">{props.children}</div>;
}
