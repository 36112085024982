

import TestScript from '../../pages/signin/SignIn/TestScript';
import {PopupDialog,BtnSecundary,FilterIcon} from '../index';
const FilterButton = () => {
  return (
    <div>
       {/*begin::Filter*/}
       <BtnSecundary
              attributes={{
                "data-kt-menu-trigger":"click",
                "data-kt-menu-placement":"bottom-end"
              }}
              text="Filter"
              icon={  <FilterIcon />}
            />
     <PopupDialog title='Filter Options'>

        {/*begin::Input group*/}
        <div className="mb-10">
            <label className="form-label fs-6 fw-semibold">Role:</label>
            <TestScript/>
            <select
              className="form-select form-select-solid fw-bold"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="role"
              data-hide-search="true"
            >
              <option />
              <option value="Administrator">Administrator</option>
              <option value="Analyst">Analyst</option>
              <option value="Developer">Developer</option>
              <option value="Support">Support</option>
              <option value="Trial">Trial</option>
            </select>
          </div>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <div className="mb-10">
            <label className="form-label fs-6 fw-semibold">
              Two Step Verification:
            </label>
            <select
              className="form-select form-select-solid fw-bold"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="two-step"
              data-hide-search="true"
            >
              <option />
              <option value="Enabled">Enabled</option>
            </select>
          </div>
          {/*end::Input group*/}
          {/*begin::Actions*/}
          <div className="d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset"
            >
              Reset
            </button>
            <button
              type="submit"
              className="btn btn-primary fw-semibold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
            >
              Apply
            </button>
          </div>
          {/*end::Actions*/}
     </PopupDialog>
          
    </div>
  )
}

export default FilterButton;
