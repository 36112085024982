import "./style.css";
///props
///id
//className (optional)
//classNameSizeBtn  (optional)
///children
//onClick Function
const BtnIcon = (props) => {
  return (
    <>
      {/*begin::Menu- wrapper*/}
      <div
        id={props.id}
        className={`btn btn-icon custom-btn ${
          props.classNameSizeBtn ?? "w-35px h-35px w-lg-45px h-lg-45px"
        } ${props.className}`}
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom"
        onClick={props.onClick}
      >
        {props.children}
      </div>
      {/*begin::Menu*/}
    </>
  );
};

export default BtnIcon;
