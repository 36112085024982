
import {
  BodyContentFormWithCard,
  TitleSmall,
  MarginBottomFiled,
  ColTextFiledWithLabel,
  MySelect,
  MyLabel,
  EnablesRadiosBtn,
} from "../../../../index";

const SupplierMaster = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-supplier-master" >
      <TitleSmall title="Supplier Master" className=" pb-5 pb-lg-10" />
      {/* <EnablesRadiosBtn typeForms='tin_check'/> */}
      <MarginBottomFiled>
       <MyLabel label='vendor master auto archive'/>
       <EnablesRadiosBtn/>
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Annual Spend Auto Archive"
          type="number"
          name="settings[vendor_master_auto_archive_annual_spend]"
        />
     
      </MarginBottomFiled>

      <MarginBottomFiled>
        <MySelect label='Vendor Type' name='settings[vendor_master_auto_archive_vendor_type][]'/>
      </MarginBottomFiled>

      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Contact Log"
          type="number"
          name="settings[vendor_master_contact_log_input]"
        />
     
      </MarginBottomFiled>
      <TitleSmall title='Contact Log List '/>
    </BodyContentFormWithCard>
  );
};

export default SupplierMaster;

