
import { LayoutAdmin, LayoutCleanse } from "../../../../../index";
import SideBarMneuSettingDataCleans from "./SideBarMneuSettingDataCleans";
import {
  AVGCount,
  AVGSpend,
  BedrockVerificationForm,
  DataCleansOnboarding,
  Invite,
  Onborading,
  Pirority,
  VendorMaster,
} from "./forms";

const Setting = () => {
  return (
    <LayoutCleanse
      title="Setting"
      pages={["Setting"]}
      sidebar={<SideBarMneuSettingDataCleans isnav={true} />}
    >
      <div id="kt_app_content" className="app-content px-0">
        {/*begin::Navbar*/}
        <div className="tab-content ">
          <BedrockVerificationForm />
          <Invite />
          <Pirority />
          <Onborading />
          <DataCleansOnboarding />
          <AVGCount />
          <AVGSpend />
          <VendorMaster />
        </div>
      </div>
    </LayoutCleanse>
  );
};

export default Setting;
