import React from "react";
import { CardWhite, FormEmailNotificationVendor } from "../../../../index";

const InviteFormEmailNotification = (props) => {
  return (
    <CardWhite  margenBottom>
   <FormEmailNotificationVendor/>
    </CardWhite>
  );
};

export default InviteFormEmailNotification;
