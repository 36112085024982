import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LayoutAdmin,
  TableAdmin,
  EmptyTableTD,
  ActionOnboradingField,
  CardWhite,
  CreateTabOnborading,
  LayoutCleanse,
} from "../../../../../index";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchOnborading } from "../../../../../../rtk/Slices/Onborading-Data-Cleanse-Slices";

const EditTabOnborading = () => {
  const { tabId, cleanse } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOnborading({ cleanse: cleanse }));
  }, []);
  const tabs = useSelector((state) => state.onbording.result);

  const tab = tabs.find((element) => {
    return element.id == tabId;
  });

  if (tab)
    return (
      <LayoutCleanse
        title={tab.form_name}
        pages={[ "OnBorading", tab.form_name]}
      >
        <CardWhite margenTop >
          <CreateTabOnborading {...tab} />
        </CardWhite>

        <TableAdmin
          isExport={true}
          isSelest={true}
          isAction={true}
          titles={["ID", "Name", "Belogs to", "Type", "Slug", "Active"]}
        >
          <TableBody records={tab?.with ?? []} />
        </TableAdmin>
      </LayoutCleanse>
    );

  return <div>some thing woring </div>;
};

export default EditTabOnborading;

const TableBody = (props) => {
  const recordes = props.records;
  console.log("recordes:", recordes);

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={key} {...record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={5} />;
  }
};

const Record = (props) => {
  return (
    <tr key={props.id}>
      <td></td>
      <td>{props.id}</td>
      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column justify-content-center">
            <a href="" className="fs-6 text-gray-800 text-hover-primary">
              {props.name}
            </a>
          </div>
        </div>
      </td>
      <td>{props.fieldto}</td>
      <td>{props.type}</td>
      <td>{props.slug}</td>

      <td>{props.active}</td>
      <td className="text-end">
        <ActionOnboradingField record={props} />
      </td>
    </tr>
  );
};
