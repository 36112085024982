import React from "react";
import {
  CardWhite,
  ColTextAreaWithLabel,
  ColTextFiledWithLabel,
  InputGroupRow,
  MarginBottomFiled,
  MySelect,
} from "../../../../index";

const SupplierInviteForm = () => {
  return (
    <CardWhite margenBottom margenTop>
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Company"
            name="company"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Provided Reason For Using Supplier"
            name="provided_reason_for_using_vendor"
            isRequired={true}
          />
        }
      />
      <InputGroupRow
        col1={
          <MySelect
            label="Supplier Type "
            name="vendor_type"
            isRequired={true}
          />
        }
        col2={
          <MySelect
            label="Payment Term  "
            name="payment_term"
            isRequired={true}
          />
        }
      />
      <MarginBottomFiled>
        <MySelect
          label="Estimated Annual Spend "
          name="total_spend"
          isRequired={true}
        />
      </MarginBottomFiled>
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Requested On Behalf Of Name"
            name="requested_on_behalf_of_name"
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Requested On Behalf Of Email"
            name="requested_on_behalf_of_email"
          />
        }
      />
      <MarginBottomFiled>
        <ColTextAreaWithLabel
          label="Description Of Services"
          name="description_of_services"
        />
      </MarginBottomFiled>
    </CardWhite>
  );
};

export default SupplierInviteForm;
