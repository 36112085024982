import {ColInputWithLable, TextArea} from "../index";
///props
////lable    String ex 'lable' (option);
////name     String ex 'name';
////isRequired bool option by default false;
////type bool option by default text;



///tooltip String (option)
const ColTextAreaWithLabel = (props) => {
  return (
    <ColInputWithLable {...props}>
      <TextArea {...props}/>
    </ColInputWithLable>
  );
};

export default ColTextAreaWithLabel;
