
import { useSelector } from "react-redux";

import { LayoutCleanse, TableAdmin} from "../../../../../../index";


const TotalApprovedVendor = () => {
   const dataCleans = useSelector((state) => state.records?.result
  ??[]);
  return (
    <LayoutCleanse title="Total Approved Vendor" pages={['Report','Total Approved Vendor']}>
       
      <TableAdmin isExport={true} isSelest={true}
        titles={[
          "Vendor ID",
          "Company",
          "Approval",
        ]}
      >
         
        <TableBody  records={dataCleans.records??[]}/>
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default TotalApprovedVendor;

const TableBody = (props) => {
  const recordes =props.records;
  let recordList;
       
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record) => {
      return (
        <Record
          id={record.vendor_id}
          name={record.company}
          name2={record.dba_name}
          email={record.email}
          country={record.COUNTRY}
          record={record}
          status={record.status}
        />
      );
    });

    return <>{recordList}</>;
  } else {

    return (
    <div>
      <p className="fs-7 text-muted fw-bold text-capitalize">No entries found</p>
      <img src={`${process.env.PUBLIC_URL}/assets/media/illustrations/sketchy-1/4.png`}/>
    </div>
    );
  }
};

const Record = (props) => {
  return (
    <tr key={props.id}>
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            className="form-check-input widget-13-check"
            type="checkbox"
            value="1"
          />
        </div>
      </td>
      <td>{props.id}</td>

      <td></td>
      <td></td>
    

   
    </tr>
  );
};
