import { useState } from "react";

function EditButton() {
  const [show, setShow] = useState(false);
  const handelShow = () => {
    setShow(true);
  };
  return (
    <button
      className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-overflow="true"
      style={{ marginTop: "-10px" }}
    >
      <span
        className=" border border-dashed border-gray-300 rounded py-2 px-3"
        style={{ width: "28px", height: "28px" }}
      >
        <i
          className="bi bi-pencil-fill fs-7 fa-2xs"
          style={{ marginTop: "-10px", marginLeft: "-3px" }}
        />
      </span>
    </button>
  );
}

export default EditButton;
