import React from 'react'

function MenuItem({ alt, src }) {
  return (
    <div className="menu-item px-3">
      <a
        href="#"
        className="menu-link d-flex px-5"
        data-kt-lang="English"
      >
        <span className="symbol symbol-20px me-4">
          {/* <img
                                    data-kt-element="lang-flag"
                                    className="rounded-1"
                                    src= {flag.src}
                                    alt=""
                                    /> */}
          {
            src
          }
        </span>
        <span data-kt-element="lang-name">{alt}</span>
      </a>
    </div>
  )
}

export default MenuItem
