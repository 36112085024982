import {
  ColTextFiledWithLabel,
  InputGroupRow,
  MySelect,
  UploadFile,
  TabForDialog,
  BodyTabs,
 Dialog, 
 FormViewTitle,
 CountrySelect,
 VerticalBTN} from "../../../../index";
import { Fragment, useState } from "react";

const SupplierInformationForm = (props) => {
  var record = props.record;

  var companyInformation = `company_information_${record.vendor_id}`;
  var primaryContact = `primary_contact_${record.vendor_id}`;
  var countrySpecificTaxInformation = `country_specific_tax_information_${record.vendor_id}`;
  var additionalAddress = `additional_address_${record.vendor_id}`;
  const tabsBody = [
    {
      idContent: `${companyInformation}`,
      title: "Company Information",
      isActive: true,
      component: <BodyCompanyInformation idContent={companyInformation} {...record} />,
    },
    {
      idContent: `${primaryContact}`,
      title: "Primary Contact",
      component: <BodyPrimaryContact idContent={primaryContact} {...record} />,
    },
    {
      idContent: `${countrySpecificTaxInformation}`,
      title: "Country Specific Tax In formation",
      component: (
        <BodyCountrySpecificTaxInformation
          idContent={countrySpecificTaxInformation}
          {...record}
        />
      ),
    },
    {
      idContent: `${additionalAddress}`,
      title: "AdditionalAddress",
      component: <BodyAdditionalAddress idContent={additionalAddress} {...record} />,
    },
  ];
  const [ver, setVer] = useState(false);
  return (
    <>
      {/*begin::Modal - Supplier Information*/}
      <Dialog
        id={props.idDialog}
        title="Supplier Information"
        extraAction={
          <VerticalBTN
            actionVertical={() => setVer(true)}
            actionHorizantal={() => setVer(false)}
          />
        }
        classNameBody="pt-lg-5 ps-lg-5"
      >
        <form className="form " action="#" id="kt_modal_new_address_form">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_new_address_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_new_address_header"
            data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
            data-kt-scroll-offset="300px"
          >
            {/* <div class="tab-content"> */}
            {/* <div id="kt_vertical_pane" class="tab-pane fade p-2 show active "> */}
            {ver ? (
              <>
                {tabsBody.map((element, key) => (
                  <Fragment key={element.idContent + "_vertical"}>
                    <FormViewTitle title={element.title} isFirst={key === 0} />
                   <div className="mx-5">{element.component}</div> 
                  </Fragment>
                ))}
          
              </>
            ) : (
              <>
                {/* <div id="kt_horizontal_pane" class="tab-pane fade "> */}
                <TabForDialog tabs={tabsBody}>
                  {tabsBody.map((element) => (
                    <Fragment key={element.idContent + "_Horizantal"}>
                      {element.component}
                    </Fragment>
                  ))}
                </TabForDialog>
             
              </>
            )} 
          </div>
          {/* <BodyCompanyInformation /> */}
          {/* </div> */}
          {/*end::Scroll*/}

          {/*begin::Modal footer*/}
          <div className="modal-footer flex-center">
            {/*begin::Button*/}
            <button
              type="reset"
              id="kt_modal_new_address_cancel"
              className="btn btn-light me-3"
            >
              Discard
            </button>
            {/*end::Button*/}
            {/*begin::Button*/}
            <button
              type="reset"
              id="kt_modal_new_address_submit"
              className="btn btn-primary"
            >
              <span className="indicator-label">Submit</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
            {props.extraAction}
            {/*end::Button*/}
          </div>
          {/*end::Modal footer*/}
        </form>
        {/*end::Form*/}
      </Dialog>
      {/*end::Modal - Supplier Information*/}
    </>
  );
};

export default SupplierInformationForm;

const BodyCompanyInformation = (props) => {
  const record = props;

  return (
    <BodyTabs isActive={true} id={props.idContent}>
      <InputGroupRow
      
        col1={
          <ColTextFiledWithLabel
            label="Supplier ID"
            name="vendor_id"
            isRequired={true}
            value={record.vendor_id}
          />
        }
        col2={
          <CountrySelect
       
            label="Country"
            name="country"
            value={record.country}
         
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            value={record.company_phone_number}
            label="Company Number"
            name="company_phone_number"
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Company"
            name="name_1"
            value={record.company}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="DBA Name"
            name="name_2"
            value={record.dba_name}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Company Email"
            name="company_email"
            value={record.email}
          />
        }
      />
      <InputGroupRow
        col1={
          <MySelect
            value={record.vendor_type}
            label="Supplier  Type"
            name="vendor_type"
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Address 1"
            name="street1"
            value={record.address_1}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Address 2"
            name="street2"
            value={record.address_2}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Address 3"
            name="street3"
            value={record.address_3}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel label="City" name="city" value={record.city} />
        }
        col2={
          <ColTextFiledWithLabel
            label="county"
            name="county"
            value={record.country}
          />
        }
      />
      <InputGroupRow
        col1={
          <MySelect
            label="state"
            name="state"
            value={record.state}
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
        col2={
          <MySelect
       value={record.payment_method}
            label="Payment Method"
            name="payment_method"
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="NAICS codes"
            name="NAICS_codes"
            value={record.NAICS_codes}
          />
        }
        col2={<ColTextFiledWithLabel label="avg count"name="avg_count" value={record.avg_count} />}
      />
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="Avg spend" name="avg_spend" value={record.avg_spend}/>}
        col2={
          <ColTextFiledWithLabel
            label="Po Box City"
            name="po_box_city"
            value={record.po_box_city}
          />
        }
      />
      <ColTextFiledWithLabel
        label="Po Box Zip Code"
        name="po_box_zip_code"
        value={record.po_box_zip_code}
      />
    </BodyTabs>
  );
};

const BodyPrimaryContact = (props) => {
  const record = props;
  return (
    <BodyTabs id={props.idContent}>
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="First Name"
            name="first_name"
            value={record.first_name}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Last Name"
            name="last_name"
            value={record.last_name}
          />
        }
      />
      <InputGroupRow
        col1={
          <MySelect
          value={record.business_contact_type}
            label="Business Contact Type"
            name="business_contact_type"
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
        col2={
          <ColTextFiledWithLabel
           label="Email"
            name="email"
            value={record.email}
          />
        }
      />
      <InputGroupRow
        col1={
           <CountrySelect
         
            label="Country Code"
            name="country_code"
          
            value={record.country_code}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Phone Number"
            name="phone_number"
            value={record.phone_number}
          />
        }
      />
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="extension" name="extension" value={record.extension} />}
        col2={
          <ColTextFiledWithLabel
            label="fax number"
            name="fax_number"
            value={record.fax_number}
          />
        }
      />
    </BodyTabs>
  );
};

const BodyCountrySpecificTaxInformation = (props) => {
  const record = props;
  return (
    <BodyTabs id={props.idContent}>
      <MySelect
        label="Tax Country"
        name="tax_country"
        value={record.tax_country}
        options={[
          { value: "A", country: "A" },
          { value: "B", country: "B" },
          { value: "C", country: "C" },
        ]}
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label=" tax id 1"
            name="tax_id"
            value={record.tax_id}
          />
        }
        col2={
          <MySelect
            label="TAX 1 type"
            name="tax_type"
            value={record.tax_1_type}
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label=" tax id 2"
            name="tax_id_2"
            value={record.tax_id_2}
          />
        }
        col2={
          <MySelect
            label="TAX 2 type"
            name="tax2_type"
            value={record.tax_2_type}
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label=" tax id 3"
            name="tax_id_3"
            value={record.tax_id_3}
          />
        }
        col2={
          <MySelect
            label="TAX 3 type"
            name="ta3_type"
            value={record.tax_3_type}
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
      />
      <UploadFile />
    </BodyTabs>
  );
};

const BodyAdditionalAddress = (props) => {
  const record = props;
  return (
    <BodyTabs id={props.idContent}>
      <InputGroupRow
        col1={
          <MySelect
            label="Address Type"
            name="address_type"
            value={record.additional_address_type}
            options={[
              { value: "A", country: "A" },
              { value: "B", country: "B" },
              { value: "C", country: "C" },
            ]}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Address 1"
            name="additional_address_1"
            value={record.additional_address_1}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Address 2/PO Box"
            name="additional_address_"
            value={record.additional_address_2}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Address 3"
            name="additional_address_3"
            value={record.additional_address_3}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Address 4"
            name="additional_address_4"
            value={record.additional_address_4}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="County"
            name="additional_address_county"
            value={record.additional_address_country}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="remittance email"
            value={record.additional_address_remittance_email}
            name="additional_address_remittance_email"
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="State"
            name="additional_address_state"
            value={record.additional_address_state}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="City"
            name="additional_address_city"
            value={record.additional_address_city}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Zip Code"
            name="additional_address_zip_code"
            value={record.additional_address_zip_code}
          />
        }
      />
    </BodyTabs>
  );
};



