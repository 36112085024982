import React from "react";
import { NavitemSideBarMneu, SideBarMneu } from "../../../core";
import { useLocation, useNavigate } from "react-router-dom";

const SideBarMneuCreateVendor = () => {
  const navigate = useNavigate();
  let location = useLocation();
  console.log("location:");

  function handleClick(namePage) {
    navigate(`/vendor_${namePage}_page`);
  }
  function checkActive(namePage) {
    return `/vendor_${namePage}_page` == location.pathname;
  }

  const pages = [
    {
      id: "profile_vendor",
      title: "Profile Vendor",
      nampePage: "view_admin_profile",
    },
    {
      id: "contacts_vendor",
      title: "Contacts vendor",
      nampePage: "contacts",
    },

    {
      id: "nots_vendor",
      title: "Nots Vendor",
      nampePage: "nots",
    },
    {
      id: "statements_vendor",
      title: "Statements Vendor",
      nampePage: "statements",
    },

    {
      id: "claims_vendor",
      title: "Claims Vendor",
      nampePage: "claims",
    },

    {
      id: "files_vendor",
      title: "Files Vendor",
      nampePage: "files",
    },
    {
      id: "maps_vendor",
      title: "Maps Vendor",
      nampePage: "maps",
    },
  ];
  return (
    <SideBarMneu>
      {pages.map((element) => (
        <NavitemSideBarMneu
          id={element.id}
          title={element.title}
          onClick={() => handleClick(element.nampePage)}
          isActive={checkActive(element.nampePage)}
        />
      ))}
    </SideBarMneu>
  );
};

export default SideBarMneuCreateVendor;
