import { useSelector } from "react-redux";
import {
  BtnGray,
  BtnSecundary,
  CardsSummary,
  EmptyTableTD,

  LayoutAdmin,

  SwitchInput,
  TableAdmin,
  VendorAction,
} from "../../index";
import { useNavigate } from "react-router-dom";
import  { AddCreateVendorBTN as CreateVendorBTN } from "./CreateVendor";

const VendorPage = () => {
 const dataCleans = useSelector((state) => state.records?.result
  ??[]);

  const navgation = useNavigate();
  return (
    <LayoutAdmin
      title="Vendor"
      pages={["Vendor", "Vendor"]}
      extraAction={
        <BtnGray
          text="CONTACTS"
         attributes={{ 'onClick':() => navgation("/vendor_contact_page")}}
        />
      }
    >
      <CardsSummary filters={dataCleans['record_status']} />
      <TableAdmin
        isExport={true}
        isSelest={true}
        bulks={{
          name: "vendor_bulk",
        
          list: [
            {
              defaultValue: 1,
              id: "kt_modal_vendor_bulk_option_1",

              title: "Mass Delete",
              desc: "Best for developers or people primarily using the API",
            },
          ],
        }}
        ActionExtra={
          <>
           <CreateVendorBTN/>
            <BtnSecundary text="Import Vendors"    attributes={{ 'onClick':() => navgation("/import_supplier")}}/>
          </>
        }
        titles={[
          "Bedrock ID",
          "Vendor ID",
          "Company",
          "Primary Contact",
          "Primary Email",
          "Phone",
          "Active",
          "Date Created",
        ]}
      >
        <TableBody records={dataCleans.records??['x']} />
      
      </TableAdmin>
    </LayoutAdmin>
  );
};

export default VendorPage;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;
   
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={key} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={9} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <td></td>

      <td></td>
      <td>
    
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td><SwitchInput/></td>

      <td className="text-end"><VendorAction/></td>
    </tr>
  );
};
