import { ContentForm } from "../../../../../index";
import EmailQueue from "./EmailQueue";
import SMTPSettings from "./SMTPSettings";

const Email = () => {
  return (
    <ContentForm id="setting-setup-email">
      <SMTPSettings />
      <EmailQueue />
    </ContentForm>
  );
};

export default Email;
