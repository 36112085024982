import {
  MarginBottomFiled,
  ColTextFiledWithLabel,
  EnablesRadiosBtn,
  BodyContentFormWithCard,
  TitleSmall,
} from "../../../../../../index";

const Onborading = () => {
  return (
    <BodyContentFormWithCard id="setting-onborading">
      <TitleSmall title="Onboarding Information" className=" pb-5 pb-lg-10" />
      <EnablesRadiosBtn typeForms="onboarding_cleans_information" />
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Support Email"
          name="settings[onboarding_cleans_information_support_email]"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Support Phone"
          name="settings[onboarding_cleans_information_support_phone]"
        />
      </MarginBottomFiled>
    </BodyContentFormWithCard>
  );
};

export default Onborading;
