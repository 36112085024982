import React from "react";
//Components
import CardList from "./CardList";
//Style
import "./card.css";
//props
///WithoutMT
const CardsSummary = (props) => {
  return (
    <div className={props.WithoutMT?"":" mt-10 "}>
      {/*begin::Mixed Widget 3*/}
      <div className="card card-xl-stretch mb-xl-8  ">
        {/*begin::Beader*/}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Vendors Summary
            </span>
            <span className="text-muted fw-semibold fs-7">
              Total Imported Vendors 
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(props.total_vendors)}
            </span>
          </h3>
          <div className="card-toolbar">
            {/*begin::Menu*/}
            <button
              type="button"
              className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect
                      x={5}
                      y={5}
                      width={5}
                      height={5}
                      rx={1}
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={5}
                      height={5}
                      rx={1}
                      fill="currentColor"
                      opacity="0.3"
                    />
                    <rect
                      x={5}
                      y={14}
                      width={5}
                      height={5}
                      rx={1}
                      fill="currentColor"
                      opacity="0.3"
                    />
                    <rect
                      x={14}
                      y={14}
                      width={5}
                      height={5}
                      rx={1}
                      fill="currentColor"
                      opacity="0.3"
                    />
                  </g>
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </button>
            {/*begin::Menu 1*/}
            <div
              className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
              data-kt-menu="true"
              id="kt_menu_63d79135ab785"
            >
              {/*begin::Header*/}
              <div className="px-7 py-5">
                <div className="fs-5 text-dark fw-bold">Filter Options</div>
              </div>
              {/*end::Header*/}
              {/*begin::Menu separator*/}
              <div className="separator border-gray-200" />
              {/*end::Menu separator*/}
              {/*begin::Form*/}
              <div className="px-7 py-5">
                {/*begin::Input group*/}
                <div className="mb-10">
                  {/*begin::Label*/}
                  <label className="form-label fw-semibold">Status:</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <div>
                    <select
                      className="form-select form-select-solid"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-dropdown-parent="#kt_menu_63d79135ab785"
                      data-allow-clear="true"
                    >
                      <option />
                      <option value={1}>Approved</option>
                      <option value={2}>Pending</option>
                      <option value={2}>In Process</option>
                      <option value={2}>Rejected</option>
                    </select>
                  </div>
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="mb-10">
                  {/*begin::Label*/}
                  <label className="form-label fw-semibold">Member Type:</label>
                  {/*end::Label*/}
                  {/*begin::Options*/}
                  <div className="d-flex">
                    {/*begin::Options*/}
                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                      />
                      <span className="form-check-label">Author</span>
                    </label>
                    {/*end::Options*/}
                    {/*begin::Options*/}
                    <label className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={2}
                        defaultChecked="checked"
                      />
                      <span className="form-check-label">Customer</span>
                    </label>
                    {/*end::Options*/}
                  </div>
                  {/*end::Options*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="mb-10">
                  {/*begin::Label*/}
                  <label className="form-label fw-semibold">
                    Notifications:
                  </label>
                  {/*end::Label*/}
                  {/*begin::Switch*/}
                  <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      name="notifications"
                      defaultChecked="checked"
                    />
                    <label className="form-check-label">Enabled</label>
                  </div>
                  {/*end::Switch*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Actions*/}
                <div className="d-flex justify-content-end">
                  <button
                    type="reset"
                    className="btn btn-sm btn-light btn-active-light-primary me-2"
                    data-kt-menu-dismiss="true"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    data-kt-menu-dismiss="true"
                  >
                    Apply
                  </button>
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Form*/}
            </div>
            {/*end::Menu 1*/}
            {/*end::Menu*/}
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body p-0 d-flex flex-column">
          {/*begin::Stats*/}
          <div className="card-p pt-5 bg-body flex-grow-1 card-rounded-bottom">
            {/*begin::Row*/}
            <div className="row g-0">
              <div className="grid-filter">
                <CardList filters={props.filters} total={props.total_vendors} />
              </div>

              {/*end::Col*/}
            </div>
          </div>
          {/*end::Stats*/}
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Mixed Widget 3*/}
    </div>
  );
};

export default CardsSummary;
