import React from "react";
import { CardWhite, GroupCheckBox, TagsSelect, TitleSmall } from "../.././../../index";

const StatementSettings = () => {
  return (
    <CardWhite  margenBottom>
      <TitleSmall title="Statement Settings" className="pb-5" />
      <TagsSelect label="Visible Tabs" />
      <div className="separator mb-5"></div>
      <GroupCheckBox
        options={[
          { name: "Allow Vendor to upload files", checked: "checked" },
          { name: "Allow Vendor to open discussions", checked: "checked" },
          { name: "Allow Vendor to view activity log", checked: "checked" },
          { name: "Allow Vendor to view auditor", checked: "checked" },
        ]}
      />
    </CardWhite>
  );
};

export default StatementSettings;
