

const PageTitle = (props) => {
    return (
      <>
        {/*begin::Item*/}
        <div className="page-title d-flex align-items-center me-3">
                {/*begin::Title*/}
                <h1 className="page-heading d-flex text-dark fw-bolder fs-1 flex-column justify-content-center my-0">
                  {props.title}
                </h1>
                {/*end::Title*/}
              </div>
              {/*end::Page title*/}
        {/*end::Item*/}
      </>
    );
  };
  

export default PageTitle;
