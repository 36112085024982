  import { useSelector } from "react-redux";
import { LayoutAdmin, TableAdmin } from "../../../index";


  const SetupRoles = () => {
    const dataCleans = useSelector((state) => state.records?.result
  ??[]);
    return (
      <LayoutAdmin
        title="Roles"
        pages={["Setup", "Roles"]}

      >
        <TableAdmin
          isExport={true}
          titles={[ "Role Name ", "Option"]}
        >
          <TableBody records={dataCleans.records??[]} />
        </TableAdmin>
        {/* <Pagenation
        total={100}
        limit={20}
        updateCurrentPage={updateCurrentPage}
      /> */}
      </LayoutAdmin>
    );
  };

  export default SetupRoles;

const TableBody = (props) => {
  const recordes = props.records;

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return (
        <Record
          key={key}
          record={record}
          id={record.vendor_id}
          name={record.company}
          email={record.email}
          tax={record.tax_id}
          address1={record.address_1}
          country={record.country}
          status={record.status}
        />
      );
    });

    return <>{recordList}</>;
  } else {
    return (
      <tr>
        <td colSpan={9}>
          <center>Empty data</center>
        </td>
      </tr>
    );
  }
};

const Record = (props) => {
  const record = props.record;

  return (
    <tr key={props.id}>
      <td>{props.id}</td>
      <td></td>
      <td></td>
      <td></td>
      <td className="text-end"></td>
    </tr>
  );
};
