
import { BodyContentFormWithCard ,TitleSmall} from "../../../../index";


const DataCleansOnboarding = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-data-cleans-onboarding" >
      <TitleSmall title="Data Cleans Onboarding" className=" pb-5 pb-lg-10" />
 
  
    </BodyContentFormWithCard>
  );
};

export default DataCleansOnboarding;


