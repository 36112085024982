import   './style.css';

const Footer = () => {
  return (
    <>
           {/*begin::Footer*/}
           <div
          id="kt_app_footer"
          className="app-footer d-flex flex-column flex-md-row align-items-center flex-center flex-md-stack pb-2 pb-lg-4"
        >
          {/*begin::Copyright*/}
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-semibold me-1">2023©</span>
            <a
              href="https://www.mybedrock.com"
              target="_blank"
              className="text-gray-800 text-hover-primary"
            >
              Bedrock
            </a>
          </div>
          {/*end::Copyright*/}
          {/*begin::Menu*/}
          <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <a
                href="https://www.mybedrock.com"
                target="_blank"
                className="menu-link px-2"
              >
                About
              </a>
            </li>
            <li className="menu-item">
              <a
                href="https://www.mybedrock.com"
                target="_blank"
                className="menu-link px-2"
              >
                Support
              </a>
            </li>
            <li className="menu-item">
              <a
                href="https://www.mybedrock.com/"
                target="_blank"
                className="menu-link px-2"
              >
                Purchase
              </a>
            </li>
          </ul>
          {/*end::Menu*/}
        </div>
        {/*end::Footer*/}
    </>
  )
}

export default Footer;
