import {
  InputGroupRow,
  ColTextFiledWithLabel,
  BtnSecundary,
  BtnPrimary,
  Dialog,
  BtnGray,
  GlobalVerificationForm,
} from "../../../../../index";

const CreateGlobalVerificationDialog = () => {
  return (
    <>
      <Dialog id="kt_modal_global_verification_add" title="Global Verification">
        <InputGroupRow
          col1={
            <ColTextFiledWithLabel
              label="Supplier Number"
              name="vendor_number"
            />
          }
          col2={
            <ColTextFiledWithLabel
              label="Company Name "
              name="company"
              isRequired={true}
            />
          }
        />
        <GlobalVerificationForm />
        <div className="modal-footer flex-center">
          <BtnSecundary text="close" />
          <BtnPrimary text="save" />
        </div>
      </Dialog>
    </>
  );
};

export default CreateGlobalVerificationDialog;

export const CreateGlobalVerificationBTN = () => {
  return (
    <>
      <BtnGray
        text="Global Compliance Verification"
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_global_verification_add",
        }}
      />
    </>
  );
};
