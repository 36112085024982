import { useSelector } from "react-redux";
import {
  BtnGray,
 
  CardsSummary,
  CreateBankVerificationBTN,
  CreateBankVerificationDialog,
  CreateGlobalVerificationBTN,
  CreateGlobalVerificationDialog,
  EmptyTableTD,
  LayoutAdmin,
  LayoutCleanse,
  TableAdmin,
} from "../../../../index";

const BankVerifiction = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <LayoutCleanse  

      title="Bank Verifiction"
      pages={["Bedrock Verifiction", "Bank Verifiction"]}
      extraAction={
        <>
          <BtnGray text="Import Bank Verifiction" />
          <CreateGlobalVerificationBTN />
          <CreateBankVerificationBTN />
        </>
      }
    >
      <CardsSummary filters={dataCleans.filters} />
      <TableAdmin
        isExport={true}
        isSelest={true}
        titles={[
          "vendor_number",
          "Company",
          "Status",
          "ABA Routing Number",
          "Account Number",
          "Date Created",
        ]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
      <CreateBankVerificationDialog />
      <CreateGlobalVerificationDialog />
    </LayoutCleanse>
  );
};

export default BankVerifiction;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record}/>
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={6} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <td></td>

      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

      <td className="text-end"></td>
    </tr>
  );
};
