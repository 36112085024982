import {  BoxEmailTemplates, LayoutAdmin} from "../../../index";


const SetupEmailTemplates = () => {
  return (
    <LayoutAdmin
      title="Email Templates"
      pages={["Setup", "Email Templates"]}

    >
      <BoxEmailTemplates/>
    
    </LayoutAdmin>
  );
}

export default SetupEmailTemplates;
