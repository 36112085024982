import {Pagenation,Table, CardHeader } from "../../index";


///props
//title String
//isAction bool (option)
///isTableTabs bool (option)
///isExport bool (option)
///isFilter bool (option)
///isBulkActions bool (option)
//ActionExtra widget (option)
///pagenation map {total,limit,updateCurrentPage}
const TableAdmin = (props) => {
  const pagenation = props.pagenation;


  return (
    <>
      {/*begin::Table*/}
      <div
        className={`card card-flush ${props.className}  ${
          props.isTableTabs ? "" : "mt-6 mt-xl-9"
        }`}
        id={props.id}
      >
        <CardHeader {...props} />
        {/*begin::Card body*/}
        <div className="card-body pt-2">
          <Table {...props} />
          {pagenation ? (
            <Pagenation
              total={pagenation.total }
              currentPage={pagenation.currentPage }
              updateCurrentPage={pagenation.updateCurrentPage }
            />
          ) : (
            ""
          )}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}
      {/*end::Content*/}
      {/*end::Content wrapper*/}

      {/*end::Table*/}
    </>
  );
};

export default TableAdmin;
