import React from 'react'

const TDContact = (props) => {
    const contact=props.first_name+''+props.last_name;
  return (
    <td>{contact}</td>
  )
}

export default TDContact;
