
import { PageTitle, Breadcrumb, StepperVertical } from "../../index";
/////extraAction option widget (btn)
const Toolbar = (props) => {
  return (
    <>
      {/*begin::Toolbar*/}
      <div id="kt_app_toolbar" className="app-toolbar align-items-center">
        {/*begin::Toolbar container*/}
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl"
        >
          {/*begin::Toolbar container*/}
          <div className="d-flex flex-stack flex-row-fluid">
            {/*begin::Toolbar container*/}
            <div className="d-flex flex-column flex-row-fluid">
              {/*begin::Toolbar wrapper*/}
              <Breadcrumb pages={props.pages} />
              {/*begin::Page title*/}
              <PageTitle title={props.title} />
            </div>
            {/*end::Toolbar container*/}
            {/*begin::Actions*/}
      
            <div className="d-flex align-items-center gap-3">
              {/*begin::Secondary button*/}
             
              <div className="m-0  ">
                {/*begin::Menu*/}
                <a  
                  className="btn btn-flex btn-sm h-40px bg-body fw-bold  "
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_app"
                >
                  Wizard
                </a>
               < StepperVertical/>
                {/*begin::Menu 1*/}
                <div
                  className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_63d790c008368"
                >
                  {/*begin::Header*/}
                  <div className="px-7 py-5">
                    <div className="fs-5 text-dark fw-bold">Filter Options</div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Menu separator*/}
                  <div className="separator border-gray-200" />
                  {/*end::Menu separator*/}
                  {/*begin::Form*/}
                  <div className="px-7 py-5">
                    {/*begin::Input group*/}
                    <div className="mb-10">
                      {/*begin::Label*/}
                      <label className="form-label fw-semibold">Status:</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div>
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_63d790c008368"
                          data-allow-clear="true"
                        >
                          <option />
                          <option value={1}>Approved</option>
                          <option value={2}>Pending</option>
                          <option value={2}>In Process</option>     
                          <option value={2}>Rejected</option>
                        </select>
                      </div>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-10">
                      {/*begin::Label*/}
                      <label className="form-label fw-semibold">
                        Member Type:
                      </label>
                      {/*end::Label*/}
                      {/*begin::Options*/}
                      <div className="d-flex">
                        {/*begin::Options*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                          />
                          <span className="form-check-label">Author</span>
                        </label>
                        {/*end::Options*/}
                        {/*begin::Options*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={2}
                            defaultChecked="checked"
                          />
                          <span className="form-check-label">Customer</span>
                        </label>
                        {/*end::Options*/}
                      </div>
                      {/*end::Options*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-10">
                      {/*begin::Label*/}
                      <label className="form-label fw-semibold">
                        Notifications:
                      </label>
                      {/*end::Label*/}
                      {/*begin::Switch*/}
                      <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          name="notifications"
                          defaultChecked="checked"
                        />
                        <label className="form-check-label">Enabled</label>
                      </div>
                      {/*end::Switch*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex justify-content-end">
                      <button
                        type="reset"
                        className="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Form*/}
                </div>
                {/*end::Menu 1*/}
                {/*end::Menu*/}
              </div>
              {props.extraAction}
              {/*end::Secondary button*/}
            
            </div>
           
            {/*end::Actions*/}
          </div>
          {/*end::Toolbar container*/}
        </div>
        {/*end::Toolbar container*/}
      </div>
      {/*end::Toolbar*/}
    </>
  );
};

export default Toolbar;
