import React from 'react'

function FooterLinks() {
  return (
    <div className="d-flex fw-semibold text-primary fs-base gap-5">
                <a href="pages/team.html" target="_blank">
                  Terms
                </a>
                <a href=''  target="_blank">
                {/* href="pages/pricing/column.html" */}
                  Plans
                </a>
                <a href='' target="_blank">
                {/* href="pages/contact.html" */}
                  Contact Us
                </a>
              </div>
  )
}

export default FooterLinks
