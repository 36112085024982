

const TDId = (props) => {
    const id=props.vendor_id;
  return (
    <td>{id}</td>
  )
}

export default TDId
