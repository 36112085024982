import { useState } from "react";
import {
  CountRecourdOnPage,
  CreatePagenationNumbers,
  PagenationBtnDecrementCustom,
  PagenationBtnIncrementCustom,
  SingleItemPagenation,
} from "../../index";
import { useEffect } from "react";

function Pagenation({ total, updateCurrentPage, currentPage }) {
  const [startNum, setStartNum] = useState(1);

  const pageCountTotal = total - 1;

  const basic = 10;
  let stop = pageCountTotal > basic ? basic : pageCountTotal;

  const [pagesStop, setPagesStop] = useState(stop);

  const increment = () => {
    if (pagesStop <= pageCountTotal&& pageCountTotal>1) {
      const tempTotal = pageCountTotal - startNum;

      if (tempTotal == startNum ) {
        stop = tempTotal;
        setPagesStop(stop);
      } else {
        stop = pagesStop + basic;
        setPagesStop(stop);
        setStartNum(startNum + basic);
      }
    }
  };
  const decrement = () => {
    if (startNum > 1 ) {
      const tempTotal = pageCountTotal - pagesStop;// without - :error when show numbeer 430

      if (tempTotal < pagesStop) {
        startNum = tempTotal;
        setPagesStop(startNum);

      } else {
        stop = pagesStop - basic;
        setPagesStop(stop);
        setStartNum(startNum - basic);
      }
    }

  };

  const viewDec = () => {
    if(pageCountTotal>1){
    if (currentPage < startNum) {
      return (
        <>
          <SingleItemPagenation
            page={currentPage}
            key={currentPage}
            onPageChange={updateCurrentPage}
            currentPage={currentPage}
          />
          <button className="page-link">....</button></>)
    }
  }
else return ;
  }

  const viewInc = () => {
    if(pageCountTotal>1) 
    if (currentPage > pagesStop) {

      return (
        <>
          <button className="page-link">....</button>

          <SingleItemPagenation
            page={currentPage}
            key={currentPage}
            onPageChange={updateCurrentPage}
            currentPage={currentPage}
          />
        </>
      )
    }
    else 
    return ;

  }
  useEffect(() => {
    setPagesStop(stop);
  }, [stop]);

  return (
    <div className="d-flex flex-stack flex-wrap pt-10">

      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <CountRecourdOnPage
          updateCurrentPage={updateCurrentPage}
          page={currentPage}
        />
      </div>

      {/*begin::Pages*/}
      <ul className="pagination">
        <PagenationBtnDecrementCustom decrement={decrement} />
        {/* here put the condition  */}

        {viewDec()}




        <CreatePagenationNumbers
          start={startNum}
          stop={pagesStop}
          updateCurrentPage={updateCurrentPage}
          currentPage={currentPage}
        />
        {viewInc()}
        <PagenationBtnIncrementCustom increment={increment} />

      </ul>


      {/*end::Pages*/}
    </div>
  );
}

export default Pagenation;


