
import { useSelector } from "react-redux";
import { BtnPrimary, LayoutAdmin, TableAdmin } from "../../index";

const ActivityLog = () => {
  const dataCleans = useSelector((state) => state.records?.result
  ??[]);
  return (
    <LayoutAdmin
      title="Activity Log"
      pages={["Utilities", "Activity Log"]}
      extraAction={<BtnPrimary text="Clear Log" className="" />}
    >
      <TableAdmin
        isExport={true}
        isSelest={true}
        isFilter={true}
        titles={["Description", "Date", "Staff"]}
      >
        <TableBody records={dataCleans.records??[]} />
      </TableAdmin>
    </LayoutAdmin>
  );
};

export default ActivityLog;
const TableBody = (props) => {
  const recordes = props.records;
  let recordList;
   
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} />;
    });

    return <>{recordList}</>;
  } else {
    return (
      <div>
        <p className="fs-7 text-muted fw-bold text-capitalize">
          No entries found
        </p>
        <img
          src={`${process.env.PUBLIC_URL}/assets/media/illustrations/sketchy-1/4.png`}
        />
      </div>
    );
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <td></td>
      <td></td>

      <td className="text-end"></td>
    </tr>
  );
};
