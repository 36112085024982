
import { NavitemSideBarMneu, SideBarMneu , MneuHalfFillIcon,
  SettingIcon,
  CharterIcon,
  PersonIcon,} from "../../../../../index";


const SideBarMneuSettingDataCleans = (props) => {
  return (
    <>
      {/*begin::Sidebar*/}
     <SideBarMneu>
                <NavitemSideBarMneu
                  id="setting-bedrock-verification"
                  title="BEDROCK Verification"
                  icon={<MneuHalfFillIcon />}
                  isActive={true}
                />
                <NavitemSideBarMneu
                  id="setting-onborading"
                  title="Onborading"
                  icon={<CharterIcon />}
                />
                <NavitemSideBarMneu
                  id="setting-invite"
                  title="Invite"
                  icon={<PersonIcon />}
                />
                <NavitemSideBarMneu
                  id="setting-avg-spend"
                  title="AVG spend"
                  icon={<SettingIcon />}
                />
                <NavitemSideBarMneu
                  id="setting-avg-count"
                  title="AVG count"
                  icon={<CharterIcon />}
                />
                <NavitemSideBarMneu
                  id="setting-vendor-master"
                  title="Vendor Master"
                  icon={<MneuHalfFillIcon />}
                />
                <NavitemSideBarMneu
                  id="setting-data-cleans-onboarding"
                  title="Data Cleans Onboarding"
                  icon={<SettingIcon />}
                />
                <NavitemSideBarMneu
                  id="setting-pirority"
                  title="pirority"
                  icon={<PersonIcon />}
                />
              </SideBarMneu>
      {/*end::Sidebar*/}
    </>
  );
};

export default SideBarMneuSettingDataCleans;
