
import { BoxEmail } from "../../../index";

//props
//title String
//emails Array
const BoxEmailTemplates = () => {
  return (
    <div className="row g-5 g-xl-8 mt-5">
      <div className="col-xl-6">
        <BoxEmail title="Claims" istrue={true} />
        <BoxEmail title="Request Help" />
        <BoxEmail title="Vendor Invitation" />
        <BoxEmail title="Vendor cleans invitaions" />
      </div>
      <div className="col-xl-6">
        <BoxEmail title="Vendor " />
        <BoxEmail title="Statements " />
        <BoxEmail title="Staff Members " />
        <BoxEmail title="General Data Protection Regulation (GDPR) " />
      </div>
    </div>
  );
};

export default BoxEmailTemplates;



