import React from 'react'

const TDPriority = (props) => {
    
  const priority = props.priority;
  return (
 <td>{priority}</td>
  )
}

export default TDPriority;
