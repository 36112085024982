import React from "react";
import { Badge } from "../../../../../../index";

const TDStatus = (props) => {
  const status = props.status;
  return (
    <td><Badge color={status?.color ?? ""} name={status?.status ?? ""} />
    </td>
  );
};

export default TDStatus;
