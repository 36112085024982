//props
//title String

import { useNavigate } from "react-router-dom";
import ItemEmail from "./ItemEmail";

//emails Array
const BoxEmail = (props) => {
    const navigation=useNavigate();
  return (
    <div className="card  mb-5 mb-xl-8">
      {/*begin::Header*/}
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">{props.title}</h3>
        <div className="card-toolbar">
          {/*begin::Menu*/}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
            <span className="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect
                    x={5}
                    y={5}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                  />
                  <rect
                    x={14}
                    y={5}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                    opacity="0.3"
                  />
                  <rect
                    x={5}
                    y={14}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                    opacity="0.3"
                  />
                  <rect
                    x={14}
                    y={14}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </button>
          {/*begin::Menu 3*/}
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
            data-kt-menu="true"
          >
            {/*begin::Heading*/}
            <div className="menu-item px-3">
              <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
          Action
              </div>
            </div>
            {/*end::Heading*/}
            {/*begin::Menu item*/}
            <div>
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-3">
                 Disable All
                </a>
              </div>
            </div>
            {/*end::Menu item*/}
      
              {/*begin::Menu item*/}
              <div>
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-3">
                Enable All
                </a>
              </div>
            </div>
            {/*end::Menu item*/}
          </div>
          {/*end::Menu 3*/}
          {/*end::Menu*/}
        </div>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body pt-2">
        <ItemEmail
            onClick={()=>{navigation('/send_email_templates_setup')}}
          isDisable={true}
          title="New Claim Opened (Opened by Staff, Sent to Vendor)"
          desc="new-ticket-opened-admin"
   
        />
        <ItemEmail
            onClick={()=>{navigation('/send_email_templates_setup')}}
          isDisable={false}
          title="Claim Reply (Sent to Vendor)"
          desc="ticket-reply"
        />

        {props.istrue ? (
          <>
            <ItemEmail
                onClick={()=>{navigation('/send_email_templates_setup')}}
              isDisable={false}
              title="New Claim Opened (Opened by Staff, Sent to Vendor)"
              desc="new-ticket-opened-admin"
            />
            <ItemEmail
                onClick={()=>{navigation('/send_email_templates_setup')}}
              isDisable={false}
              title="Claim Reply (Sent to Vendor)"
              desc="ticket-reply"
            />
          </>
        ) : (
          ""
        )}
        <ItemEmail
            onClick={()=>{navigation('/send_email_templates_setup')}}
          isDisable={true}
          title="New Claim Opened (Opened by Staff, Sent to Vendor)"
          desc="new-ticket-opened-admin"
        />
        <ItemEmail
            onClick={()=>{navigation('/send_email_templates_setup')}}
          isDisable={false}
          title="Claim Reply (Sent to Vendor)"
          desc="ticket-reply"
        />
        <ItemEmail
            onClick={()=>{navigation('/send_email_templates_setup')}}
          isDisable={true}
          title="New Claim Opened (Opened by Staff, Sent to Vendor)"
          desc="new-ticket-opened-admin"
        />
        <ItemEmail
            onClick={()=>{navigation('/send_email_templates_setup')}}
          isDisable={true}
          title="Claim Reply (Sent to Vendor)"
          desc="ticket-reply"
        />
      </div>
      {/*end::Body*/}
    </div>
  );
};

export default BoxEmail;
