import React from 'react'

const PagenationBtnIncrementCustom = ({increment}) => {
   
  return (
    <li className="page-item next">
          <button className="page-link"
            onClick={increment}>
            <i className="next" />
          </button>
        </li>
  )
}

export default PagenationBtnIncrementCustom