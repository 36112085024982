import { Notice, TitleSmall,GroupCheckBox } from "../../../index";

const FormPermissionVendor = (props) => {
  return (
    <>

      <TitleSmall title="Permissions" className="pt-0 mb-xl-15 mb-5" />
      <Notice desc="this contact pending approval" />
      <GroupCheckBox
        options={[
          { name: "Statement", checked: props.statement ? "checked" : "" },
          { name: "Claims", checked: props.claims ? "checked" : "" },
        ]}
      />
    </>
  );
};

export default FormPermissionVendor;
