import React from "react";
import {
  CheckBox,
  GroupCheckBox,
  ImgPicker,

  RowInputWithLable,
  RowTextFiledWithLabel,
  TextFiled,
} from "../../../index";
import RowMySelectWithLabel from "../../../core/input/select/RowMySelectWithLabel";
//using in staff and edit mey profile

const FormStaff = (props) => {
  const isViewVendor = props.isViewVendor;
  return (
    <>
      {/*begin::Form*/}
      <form id="kt_account_profile_details_form" className="form">
        {/*begin::Card body*/}
        <div className={`card-body p-9 ${props.className}`}>
          <RowInputWithLable label="Avatar" isRequired={true}>
            <ImgPicker img="assets/media/avatars/300-1.jpg" />
          </RowInputWithLable>
          {/*begin::Input group*/}
          <RowInputWithLable label="Full Name" isRequired={true}>
            {/*begin::Row*/}
            <div className="row">
              {/*begin::Col*/}
              <div className="col-lg-6 fv-row">
                <TextFiled isRequired={true} value="Max" name="fname" />
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-6 fv-row">
                <TextFiled isRequired={true} value="Smith" name="lname" />
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </RowInputWithLable>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <RowTextFiledWithLabel
            label="Contact Phone"
            isRequired={true}
            type="tel"
            value="044 3276 454 935"
            name="phone"
          />

          {/*begin::Input group*/}
          <RowTextFiledWithLabel
            label="Email"
            isRequired={true}
            value="www.mybedrock.com"
            name="email"
          />
          {isViewVendor ? <CompleteFormVendorPortal /> : <CompleteFormAdmin />}
          {props.isAdd ? (
            <>
               
              {/*begin::Input group*/}
              <RowInputWithLable className="border-top">
                <CheckBox
                  id="send_welcome_email"
                  title="send_welcome_email"
                  defaultValue={"checked"}
                />
              </RowInputWithLable>
              {/*begin::Input group*/}
              <RowTextFiledWithLabel
                label="Password"
                isRequired={true}
                name="password"
                type="password"
              />
            </>
          ) : (
            ""
          )}
        </div>
        {/*end::Card body*/}
        {/*begin::Actions*/}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="reset"
            className="btn btn-light btn-active-light-primary me-2"
          >
            Discard
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            id="kt_account_profile_details_submit"
          >
            Save Changes
          </button>
        </div>
        {/*end::Actions*/}
      </form>
      {/*end::Form*/}
    </>
  );
};

export default FormStaff;

function CompleteFormAdmin() {
  return (
    <>
      {/*begin::Input group*/}
      <RowTextFiledWithLabel
        label="Hourly Rate"
        isRequired={true}
        type="number"
        value="0.00"
        name="hourly_rate"
      />
      {/*end::Input group*/}
      {/*begin::Input group*/}
      <RowTextFiledWithLabel label="Facebook" name="facebook" />
      {/*begin::Input group*/}
      {/*begin::Input group*/}
      <RowTextFiledWithLabel label="LinkedIn" name="linkedIn" />
      {/*begin::Input group*/}
      {/*begin::Input group*/}
      <RowTextFiledWithLabel label="Skype" name="Skype" />
      {/*begin::Input group*/}
      {/*begin::Input group*/}
      <RowTextFiledWithLabel label="Email Signature" name="email_signature" />
      {/*begin::Input group*/}
      {/*begin::Input group*/}
      <RowInputWithLable label="Member departments">
        <GroupCheckBox
          name="communication[]"
          options={[
            { name: "Auditor Claims", checked: "checked" },
            { name: "Delete Statement", checked: "checked" },
            { name: "IT support", checked: "checked" },
            { name: "Client", checked: "checked" },
            { name: "Administrator", checked: "checked" },
          ]}
        />
      </RowInputWithLable>
      {/*begin::Input group*/}
    </>
  );
}

function CompleteFormVendorPortal() {
  return (
    <>
      <RowTextFiledWithLabel label="Position" name="position" />
      <RowMySelectWithLabel
        label="Direction"
        options={[
          { title: "Syetm Default", value: 1 },
          { title: "rtl", value: 2 },
          { title: "ltr", value: 3 },
        ]}
      />
      <RowInputWithLable
        label="Email Notifications"
      >
        <GroupCheckBox
          name="email_notification[]"
          options={[
            { name: "Claims", checked: "checked" },
            { name: " Statement", checked: "checked" },
          ]}
        />
      </RowInputWithLable>
      {/*begin::Input group*/}
    </>
  );
}
