import React from "react";
import { BtnSecundary, CardWhite } from "../../../../index";
import PortalChat from "./PortalChat";

const ClaimViewVendor = () => {
  return (
    <CardWhite margenBottom margenTop>
      {/*begin::Body*/}
      <div className="card-body p-lg-20">
        {/*begin::Layout*/}
        <div className="d-flex flex-column flex-xl-row">
          {/*begin::Content*/}
          <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
            {/*begin::Invoice 2 content*/}
            <div className="mt-n1">
              {/*begin::Top*/}
              <div className="d-flex flex-stack pb-10">
                {/*begin::Logo*/}
                <a href="#">
                  {/*begin::Label*/}
                  <div className="fw-bold fs-3 text-gray-800 mb-8">
                    Claim #61 - 15002-2023-05-cl-45
                  </div>
                  {/*end::Label*/}
                </a>
                {/*end::Logo*/}
                {/*begin::Action*/}
                <BtnSecundary text="Upload File" />
                {/*end::Action*/}
              </div>
              {/*end::Top*/}
              {/*begin::Wrapper*/}
              <div className="m-0">
                {/*begin::Row*/}
                <div className="row g-5 mb-11">
                  <ItemClaimBasicInformation
                    name="Department"
                    content="Client"
                  />
                  <ItemClaimBasicInformation
                    name="Vendor Name"
                    content="invite invite"
                  />
                </div>
                {/*end::Row*/}

                {/*begin::Content*/}

                <div className="flex-grow-1">
                  <PortalChat />

                  {/* <RowTextAreaWithLabel label="Add reply to this claim" />
                  <RowInputWithLable label="Attachments">
                    <UploadFile />
                  </RowInputWithLable>
                  <div className="d-flex  align-items-end">
                    <BtnPrimary text="Add Reply" />
                  </div>  */}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Invoice 2 content*/}
          </div>
          {/*end::Content*/}
          {/*begin::Sidebar*/}
          <div className="m-0">
            {/*begin::Invoice 2 sidebar*/}
            <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
              {/*begin::Labels*/}
              <div className="mb-8">
                <span className="badge badge-light-info me-2">
                  Auditor Responded On Behalf Of Vendor - waiting client
                  response
                </span>
                <span className="badge badge-light-warning">High</span>
              </div>
              {/*end::Labels*/}
              {/*begin::Title*/}
              <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">
                Claim Information
              </h6>
              {/*end::Title*/}
              <ItemClaimInformation name=" Claim Type" content="Duplicates" />
              <ItemClaimInformation name=" Claim Reason" content="19" />
              <ItemClaimInformation
                name="Original Amount"
                content="$500,040.00"
              />
              <ItemClaimInformation
                name="other amount is"
                content="$500,040.00"
              />
              {/*begin::Item*/}
              <ItemClaimInformation
                name="Submitted"
                content={<>02 May 2023 7:37 AM</>}
              />
              {/*end::Item*/}
              {/*begin::Title*/}
              <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">
                Add a Response
              </h6>
              {/*end::Title*/}
              <ItemClaimInformation
                name="Approved Amount"
                content="$500,040.00"
              />
            </div>
            {/*end::Invoice 2 sidebar*/}
          </div>
          {/*end::Sidebar*/}
        </div>
        {/*end::Layout*/}
      </div>
      {/*end::Body*/}
    </CardWhite>
  );
};

export default ClaimViewVendor;

function ItemClaimInformation(props) {
  return (
    <>
      {/*begin::Item*/}
      <div className="mb-6">
        <div className="fw-semibold text-gray-600 fs-7">{props.name}:</div>
        <div className="fw-bold text-gray-800 fs-6">{props.content}</div>
      </div>
      {/*end::Item*/}
    </>
  );
}

function ItemClaimBasicInformation(props) {
  return (
    <>
      {/*end::Col*/}
      <div className="col-sm-6">
        {/*end::Label*/}
        <div className="fw-semibold fs-7 text-gray-600 mb-1">{props.name}:</div>
        {/*end::Label*/}
        {/*end::Col*/}
        <div className="fw-bold fs-6 text-gray-800">{props.content}</div>
        {/*end::Col*/}
      </div>
      {/*end::Col*/}
    </>
  );
}
