
///props
///id 
//classname (option)
///attributes add for btn
///icon (option)
////text string 
const BtnSecundary = (props) => {
  return (
    <>
      <button
        type="button"
        className={`btn btn-light-primary me-3 ${props.className??''}`}
        {...props.attributes}
      >
        {props.icon}
        {props.text}
      </button>
    </>
  );
};

export default BtnSecundary;
