const CountRecourdOnPage = (props) => {
  
  return (
    <div className="dataTables_length" id="kt_profile_overview_table_length">
      <label>
        <select
          name="kt_profile_overview_table_length"
          aria-controls="kt_profile_overview_table"
          className="form-select form-select-sm form-select-solid"
          onChange={(value) =>
            props.updateCurrentPage({ count: value.target.value ,page:props.page})
          }
        >
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={50}>75</option>
          <option value={100}>100</option>
          <option value={125}>125</option>
          <option value={150}>150</option>
          <option value={150}>175</option>
          <option value={200}>200</option>
        </select>
      </label>
    </div>
  );
};

export default CountRecourdOnPage;
