//props
//props.isActive bool  (option) first elemant view
//props.id (mandatory)  Select an body for any tab id
const ContentForm = (props) => {
  return (
    <div
      className={`tab-pane fade ${props.isActive ? "show active" : ""} ${
        props.className ?? ""
      }`}
      role="tab-panel"
      id={props.id}
    >
      {props.children}
    </div>
  );
};

export default ContentForm;

export const BodyContentFormWithCard = (props) => {
  return (
    <ContentForm {...props}>
      <CardWhite margenBottom>
        <form className="form">{props.children}</form>
      </CardWhite>
    </ContentForm>
  );
};

///props
//classNameBody
//margenTop bool(optional)
//margenBottom bool(optional)
export const CardWhite = (props) => (
  <div
    className={`card ${props.className ?? ""} 
    ${props.margenBottom ? "mb-5 mb-xl-10 " : ""} 
    ${props.margenTop ? "mt-5 mt-xl-10 " : ""} `}
  >
    <div className={`card-body ${props.classNameBody} py-9 `}>
      {props.children}
    </div>
  </div>
);
