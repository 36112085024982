import React from 'react'
import { FormClaim, FormClaimBody, LayoutAudit} from '../.././../../index';



const CreateClaims = (props) => {
    const claim = props.claim;
    return (
   
      <LayoutAudit
        title="Claims"
        pages={["Claims", claim ? claim.name : "Create Claim"]}
      >
        <FormClaim {...claim} />
        <FormClaimBody {...claim} />
      </LayoutAudit>
  )
}

export default CreateClaims
