import React from "react";
import LineBetweenSteps from "./LineBetweenSteps";
//props
/// isFirst bool  select first element
/// isCompleted bool  select end element
function Step({ num, title, desc, isFirst, isCompleted }) {
  return (
    <>
       
      {/*begin::Step */}
      <div
        className={`stepper-item 
        ${isFirst ? "current" : ""} 
         ${isCompleted ? "mark-completed" : ""}`}
        data-kt-stepper-element="nav"
      >
        <div className="stepper-wrapper">
          {/*begin::Icon*/}
          <div className="stepper-icon w-40px h-50px">
            <i className="stepper-check fas fa-check" />
            <span className="stepper-number">{num}</span>
          </div>
          {/*end::Icon*/}
          {/*begin::Label*/}
          <div className="stepper-label">
            <h3 className="stepper-title">{title}</h3>
            <div className="stepper-desc">{desc}</div>
          </div>
          {/*end::Label*/}
        </div>

        {!isCompleted ? <LineBetweenSteps /> : ""}
      </div>
      {/*end::Step */}
    </>
  );
}

export default Step;
