
import { PopupMenuItem ,ActionPopup,NestedClaimDialog} from "../.././../../index";


//props 
////isCleanse bool
const ActionNestedClaim = (props) => {

  const idDialog=`nested_claim_${props.id}`
  return (
    <>
      <ActionPopup>
      <PopupMenuItem
          text="Edit"
          attributes={{
  
            "data-bs-toggle": "modal",
            "data-bs-target": `#${idDialog}`,
          }}
        />
        <PopupMenuItem
          text="View"
         
            attributes={{
                
                "data-bs-toggle": "modal",
                "data-bs-target": `#${idDialog}`,
              }}
         
        />
        <PopupMenuItem
          text="Delete"
          attributes={{
            style: { color: "var(--bs-danger)" },
          }}
        />
      </ActionPopup>
     <NestedClaimDialog idDialog={idDialog}/>
    </>
  );
};

export default ActionNestedClaim;


