

import {SignInMethod,FormStaff} from "../../../index";
////props
///isViewVendor bool (optional)
const CardSettingsProfile = (props) => {
  const isViewVendor = props.isViewVendor;
  return (
    <div id="kt_profile_setting_view" className="tab-pane fade">
      {/*begin::Setting View*/}
      <div className=" card mb-5 mb-xl-10 ">
        {/*begin::Card header*/}
        <div className="card-header cursor-pointer">
          {/*begin::Card title*/}
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Profile Setting</h3>
          </div>
          {/*end::Card title*/}
        </div>
        {/*begin::Card header*/}

        {/*begin::Content*/}
        <div id="kt_account_settings_profile_details" className="collapse show">
          <FormStaff className='border-top' isViewVendor />
        </div>
        {/*end::Content*/}
      </div>
   <SignInMethod/>
    </div>
  );
};

export default CardSettingsProfile;
