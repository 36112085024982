import React from 'react'
import { CardWhite, ColTextEditorWithLabel } from '../../../../../../index'

const NoteStetmentTab = () => {
  return (
    <CardWhite  margenBottom >
<ColTextEditorWithLabel label='Note'/>

      
    </CardWhite>
  )
}

export default NoteStetmentTab