
import { useDispatch, useSelector } from "react-redux";
import {  TableAdmin } from "../../../../../index";
import { fetchOnborading } from "../../../../../../rtk/Slices/Onborading-Data-Cleanse-Slices";
import { useEffect } from "react";
import AddTabFiled from "./AddTabFiled";
import ActionOnborading from "./ActionOnborading";
import { Link } from "react-router-dom";

//props 
////isCleanse bool
const TableOnborading = (props) => {
  const tabs = useSelector((state) => state.onbording.result);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOnborading({cleanse:props.isCleanse}));
  }, []);
  return (
    <TableAdmin
    isTableTabs={true}
    titles={["Id", "Name", "Percentage", "Active", "Order",'Action']}
    className={props.classNameTable}
    ActionExtra={<AddTabFiled />}
  >  
    <TableBodyOnborading records={tabs} isCleanse={props.isCleanse} />
  </TableAdmin>
  )
}

export default TableOnborading;

const TableBodyOnborading = (props) => {
  const recordes = props.records;
  console.log("recordes:", recordes);

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return (
        <Record
          key={key}
          id={record.id}
          name={record.form_name}
          percentage={record.percentage}
          active={record.active}
          order={record.order} isCleanse={props.isCleanse} 
        />
      );
    });

    return <>{recordList}</>;
  } else {
    return (
      <tr>
        <td colSpan={5}>
          <center>Empty data</center>
        </td>
      </tr>
    );
  }
};

const Record = (props) => {
  const record = props.record;

  return (
    <tr key={props.id}>
      <td>{props.id}</td>

      <td>
        {/*begin::User*/}
        <div className="d-flex align-items-center">
          {/*begin::Info*/}
          <div className="d-flex flex-column justify-content-center">
            <Link to={`/edit_onborading/${props.id}/${props.isCleanse}`} className="fs-6 text-gray-800 text-hover-primary">
              {props.name}
            </Link>
          </div>
          {/*end::Info*/}
        </div>
        {/*end::User*/}
      </td>
      <td>{props.percentage}</td>

      <td>{props.active}</td>

      <td >{props.order}</td>
      <td ><ActionOnborading {...props}  isCleanse={props.isCleanse} /></td>
    </tr>
  );
};