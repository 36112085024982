
import {  ContentForm, TableOnborading } from "../../../../../../index";


const DataCleansOnboarding = () => {

  return (
    <ContentForm id="setting-data-cleans-onboarding">
    <TableOnborading isCleanse={true}/>
    </ContentForm>
  );
};

export default DataCleansOnboarding;


