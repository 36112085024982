import React from "react";
import {
  ColTextFiledWithLabel,
  GroupCheckBox,
  MarginBottomFiled,
} from "../../../../../index";

const CreateTabOnborading = (props) => {

  return (
    <>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Form Name"
          name="form_name"
          value={props.form_name}
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel label="Order" name="order" value={props.order} />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel
          label="Percentage"
          name="percentage "
          tooltip="Min Percentage is 0 and max is 2"
          value={props.percentage}
        />
      </MarginBottomFiled>

      <MarginBottomFiled>
        <GroupCheckBox
          name="form_active"
          options={[
            { name: "active", checked: props.active == 1 ? "checked" : "" },
            {
              name: "not require",
              checked: props.do_have == 1 ? "checked" : "",
            },
          ]}
        />
      </MarginBottomFiled>
    </>
  );
};

export default CreateTabOnborading;
