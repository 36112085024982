////props
///options array ex[{checked ,name}]
//name String declaration it is a group

//isRow bool
const GroupCheckBox = (props) => {
  
  return (
    <>
      {/*begin::Options*/}
      <div className={`d-flex flex-${props.isRow?"row mt-5":"column"}    align-items-start mt-3`}>
      {props.options?.map((element,key)=><OptionItems text={element.name??''} value={key} key={key} checked={element.checked??false}/>)}
      
      </div>
      {/*end::Options*/}
    </>
  );
};

export default GroupCheckBox;




export const OptionItems = (props) => {
  return (
    <>
       {/*begin::Option*/}
       <label className="form-check form-check-custom form-check-inline form-check-solid mb-4">
          <input
            className="form-check-input"
            name={props.name}
            type="checkbox"
            defaultChecked={props.checked?'checked':''}
            defaultValue={props.value}
          />
          <span className="fw-semibold ps-2 fs-6">{props.text}</span>
        </label>
        {/*end::Option*/}
 </>
  )
}
