//props
//color (class or color)
//size (s,m,l)
//name String
const Badge = (props) => {
  return (
    <>
      <span
        className={`badge ${
          props.color ?? ""
        } fw-bold ${props.size=='s'?'':'px-4 py-3'} text-capitalize`}
        style={props.color ? { color: `${props.color}` } : {}}
      >
        {props.name}
      </span>
    </>
  );
};

export default Badge;
