import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
  result: {"current_page": 1},
  status: "unStatus",
  error: null,
};
const SettingSlice = createSlice({
  initialState: initialState,
  name: "settingSlice",
  reducers: {
    searchRecord: (state, action) => {},
    // filterRecord: (state, action) => {
    //   const typeFilter = action.payload.type;
    //   console.log("typeFilter:", typeFilter);
    //   const elementFilter = action.payload.name;
    //   console.log("elementFilter:", elementFilter);

    //   if (typeFilter == 2) {
    //     ///missing condition
    //     state.data = current(state.allData).filter((element) => {
    //       const item = element[`${elementFilter}`]?.trim();
    //       return item === "" || item == undefined;
    //     });
    //   } else if (typeFilter == 1) {
    //     ///duplicate condition
    //     state.data = filtersDuplicate(state.allData, elementFilter);
    //   } else if (typeFilter == 3) {
    //     ///multi condition
    //     state.data = filtersDuplicateMltiCondition(
    //       state.allData,
    //       elementFilter
    //     );
    //   } else {
    //     state.data = state.allData;
    //   }
    // },
    updateCurrentPage: (state, action) => {
      state.result.current_page = action.payload?? state.result.current_page++;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetting.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSetting.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array

        let result = action.payload;
        let records = result.records;
        for (const key in records) {
          const element = records[key];
          element["status"] = status(element);
        }
        state.result = result;

        return state;
      })
      .addCase(fetchSetting.rejected, (state, action) => {
        state.status = "failed";

        console.log(" action.error.products:", action);
        state.error = action;
      });
  },
});
export const { searchProduct, filterRecord, updateCurrentPage } =
  SettingSlice.actions;
export default SettingSlice.reducer;

export const fetchSetting = createAsyncThunk(
  "SettingSlice/fetchSetting",
  async (props) => {
    const query = queryLink(props);
    const link = `https://bedrock-api.ews-jo.com/api/data_cleans?${query}`;

    console.log("link:", link);
    const client = axios.create({
      baseURL: link,
    });

    let response = await client
      .get()
      .then((result) => {
        return result;
      })
      ////////
      .catch((error) => {
        console.log(error, "error test");
      });

    return response.data;
  }
);
function queryLink(props) {
  const page = `page=${props.page ?? 1}`;
  const status = `${props.status ? "&&status=" + props.status : ""}`;
  const category = `${props.category ? "&&category=" + props.category : ""}`;
  const count = `${props.count ? "&&count=" + props.count : ""}`;
  const query = page + status + category+count;
  return query;
}

const filtersDuplicate = (arr, key) => {
  var filterArray = [];
  //// 1. Delete empty items from the search list
  filterArray = arr.filter((element) => {
    const item = element[`${key}`]?.trim();
    return notEmptyItem(item);
  });

  //// 2. Search the filtered list of empty values to reduce the percentage of possibilities
  filterArray = filterArray.filter((element, index) => {
    const item = element[`${key}`];
    const find = filterArray.find((element, index1) => {
      if (index === index1) return false;
      const item2 = element[`${key}`];
      return item2 === item;
    });

    const check = notEmptyItem(find);

    return check;
  });

  return filterArray;
};
const filtersDuplicateMltiCondition = (arr, keys) => {
  var filterArray = arr;
  //// 1. Delete empty items from the search list

  filterArray = arr.filter((element) => {
    let bool = true;
    for (let key in keys) {
      let item = element[keys[key].key_filter];
      console.log("key:", keys[key].key_filter);
      bool = notEmptyItem(item) && bool;
    }
    return bool;
  });
  console.log("filterArray:", filterArray);
  //// 2. Search the filtered list of empty values to reduce the percentage of possibilities
  filterArray = filterArray.filter((element, index) => {
    const item = element;
    const find = filterArray.find((element1, index1) => {
      if (index === index1) return false;
      const item2 = element1;

      return multiCondition(keys, item2, item);
    });

    const check = notEmptyItem(find);

    return check;
  });

  console.log("filterArray:", filterArray);
  return filterArray;
};

const notEmptyItem = (item) => item !== "" && item != undefined && item != null;

const multiCondition = (arr, item, itemB) => {
  var bool = true;

  for (const key in arr) {
    const element = arr[key];

    if (element.type == 1) {
      const check =
        itemB[`${element.key_filter}`] === item[`${element.key_filter}`];

      bool = check && bool;
      // console.log(
      //   itemB[`${element.key_filter}`],
      //   "===",
      //   item[`${element.key_filter}`],
      //   "->",
      //   bool,
      //   "check",
      //   check
      // );
    } else {
      const check =
        itemB[`${element.key_filter}`] !== item[`${element.key_filter}`];

      bool = check && bool;

      // console.log(
      //   itemB[`${element.key_filter}`],
      //   "!==",
      //   item[`${element.key_filter}`],
      //   "->",
      //   bool,
      //   "check",
      //   check
      // );
    }
  }

  //console.log("bool:", bool);
  return bool;
};

const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

function emptyValues(element) {
  let listEmptyElement = [];
  if (element) {
    //   const keys = Object.keys(element);
    const keys = [
      "vendor_id",
      "tax_id",
      "address_1",
      "phone_number",
      "first_name",
      "last_name",
      "state",
      "zip_code",
      "email",
    ];
    if (keys && keys?.length >= 1) {
      for (const key in keys) {
        const keyAttribut = keys[key];
        const item = element[keyAttribut];
        if (!notEmptyItem(item)) {
          listEmptyElement.push(keyAttribut);
        }
      }
    } else {
      console.log(`keys is ${keys}`);
    }
  } else {
    console.log(`empty values is ${element}`);
  }
  return listEmptyElement;
}

export const status = (element) => {
  const emptyList = emptyValues(element);
  let msg = "";

  if (emptyList.length >= 1) {
    for (const key in emptyList) {
      const item = emptyList[key];
      msg = TextCapitalize(
        `${msg} Missing ${item.replace("_", " ")} ${
          key == emptyList.length - 1 ? "" : ","
        }`
      );
    }
    if (emptyList.length == 1) {
      return statusObject(msg, "", "badge-light-primary");
    } else if (emptyList.length > 1) {
      return statusObject("Mixed", msg.captali, "badge-light-warning");
    }
  } else {
    return statusObject("complete", "", "badge-light-success");
  }
};

function statusObject(status1, msg, color) {
  return { message: msg, status: status1, color: color };
}

function TextCapitalize(txt) {
  return txt.toLowerCase().replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}
