import { useSelector } from "react-redux";
import {
  CardsSummary,
  CreateBankVerificationBTN,
  CreateBankVerificationDialog,
  CreateGlobalVerificationBTN,
  CreateGlobalVerificationDialog,
  EmptyTableTD,
  LayoutAdmin,
  LayoutCleanse,
  TableAdmin,
} from "../../../../index";

const BedrockVerifiction = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <LayoutCleanse
      title="Bedrock Verifiction"
      pages={["Bedrock Verifiction", "Bedrock Verifiction"]}
      extraAction={
        <>
          <CreateGlobalVerificationBTN />
          <CreateBankVerificationBTN />
        </>
      }
    >
      <CardsSummary filters={dataCleans["record_status"]} />
      <TableAdmin
        isExport={true}
        isSelest={true}
        titles={[
          "#",
          "Company",
          "tin",
          "address",
          "city",
          "state",
          "zip",
          "Date Created",
          "status",
        ]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
      <CreateBankVerificationDialog />
      <CreateGlobalVerificationDialog />
    </LayoutCleanse>
  );
};

export default BedrockVerifiction;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={9} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <td></td>

      <td></td>
      <td>
        <a data-bs-toggle="modal" data-bs-target="#kt_modal_tin_check_add btn">
           
          view
        </a>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

      <td className="text-end"></td>
    </tr>
  );
};
