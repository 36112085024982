import React, { useState } from 'react'


//Components
import Menu from './Menu'
import FooterLinks from './FooterLinks'
//Style 
import "../signin.css"
function Footer() {
  const [open, setOpen] = useState(false)

  return (
    <div className="d-flex flex-stack">
      {/*begin::Languages*/}
      <div className="me-10">
        {/*begin::Toggle*/}
        <button
          className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-offset="0px, 0px"
        >
          <img
            data-kt-element="current-lang-flag"
            className="w-20px h-20px rounded me-3"
            src={process.env.PUBLIC_URL+'/assets/media/flags/united-states.svg'}
            alt=""
          />
          <span data-kt-element="current-lang-name" className="me-1">
            English
          </span>

          <span className="svg-icon svg-icon-5 svg-icon-muted rotate-180 m-0">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={(e) => { setOpen(e.target.valu) }}
            >
              <path
                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                fill="currentColor"
              />
            </svg>
          </span>

        </button>
        <div className={`menu-item px-3 ${open ? 'active' : 'inactive'}`}>
          <Menu />
        </div>
      </div>
      <FooterLinks />

    </div>
  )
}

export default Footer
