import {
  Dialog,
  AddPrimaryUser,
  BtnSecundary,
  BtnPrimary,

  ColTextAreaWithLabel,
  MarginBottomFiled,
} from "../../index";

///props
///record (optional) Object  use in edit
export const AddCreateVendorNoteBTN = (props) => {
  return (
    <>
      <BtnSecundary
        text={`${props.id ? "" : "Create"} Note`}
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": `#kt_modal_create_vendor_note_${props.id ?? ""}`,
        }}
      />
      <AddCreateVendorContactDialog />
    </>
  );
};
const AddCreateVendorContactDialog = (props) => {
  return (
    <>
      <Dialog
        id={`kt_modal_create_vendor_note_${props.id ?? ""}`}
        title={props.id ? props.name : "Create Note"}
        classNameBody="pt-lg-5 "
      >
        <MarginBottomFiled>
           
          <ColTextAreaWithLabel label="Note Description" />
        </MarginBottomFiled>

        <BtnPrimary text="Save" />
      </Dialog>
    </>
  );
};

export default AddCreateVendorContactDialog;
