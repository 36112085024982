import { PopupMenuItem, ActionPopup } from "../../../../../index";
import SupplierInformationForm from "../SupplierInformationForm";

const ReadyToDataCleansAction = (props) => {
  const record = props.record;
  const idDialog = `kt_modal_new_address_${record?.vendor_id ?? ""}`;
  return (
    <>
      <ActionPopup>
        <PopupMenuItem
          text="View"
          attributes={{
            "id-dialog": props.vendor_id,
            "data-bs-toggle": "modal",
            "data-bs-target": `#${idDialog}`,
          }}
        />
      </ActionPopup>
      <SupplierInformationForm
        record={record}
        idDialog={idDialog}
        extraAction={props.extraAction}
      />
    </>
  );
};

export default ReadyToDataCleansAction;
