import React from 'react'

const LineBetweenSteps = () => {
  return (
    <>
         {/*begin::Line*/}
         <div className="stepper-line h-35px" />
            {/*end::Line*/}
    </>
  )
}

export default LineBetweenSteps
