import React from "react";
import { LayoutAdmin, ProfileContentPage } from "../../index";


const Profile = () => {
  return (
    <>
      <LayoutAdmin title="My Profile" pages={["Profile"]}>
        <ProfileContentPage/>
      </LayoutAdmin>
    </>
  );
};

export default Profile;
