///props
///tooltip String (option)
//label String (option)

import Label from "./lable/MyLabel";

///name String
const SingleSelect = (props) => {
    const items=[{isSelect:false,value:1, text:'After Amount'},{isSelect:true,value:2, text:'Before Amount '}];
  return (
    <div className="mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
        <Label {...props}/>
      {/*begin::Row*/}
      <div className="row mb-2" data-kt-buttons="true" data-kt-initialized={1}>
     {items?.map((element)=>      < Item  {...element} name={props.name}/>)}
      </div>
      {/*end::Row*/}
      {/*begin::Hint*/}
      <div className="form-text">
        Customers will see this shortened version of your statement descriptor
      </div>
      {/*end::Hint*/}
      <div className="fv-plugins-message-container invalid-feedback" />
    </div>
  );
};

export default SingleSelect;
function Item(props) {
  return (
    <div className="col">
      {/*begin::Option*/}
      <label
        className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
          props.isSelect ? "active" : ""
        }`}
      >
        <input
          type="radio"
          className="btn-check"
          name={props.name}
          defaultValue={props.value}
        />
        <span className="fw-bold fs-5">{props.text}</span>
      </label>
      {/*end::Option*/}
    </div>
  );
}
