import React from "react";
///props
////name     String ex 'name';
////isRequired bool option by default false;
////type bool option by default text;
///placeholder String option
//row int option
const TextFiled = (props) => {
  return (
    <textarea
      className="form-control form-control-solid"
      name={props.name}
      placeholder={props.placeholder}
      rows={props.row ?? 1}
    >
      {props.value}
    </textarea>
  );
};

export default TextFiled;
