import React from 'react'
function Content() {
  return (
            <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
          {/*begin::Image*/}
          <img
            className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
            src={process.env.PUBLIC_URL+'/assets/media/logos/official-logosSVGbadge-bedrock-blue-black.svg'}
            alt=""
          />
          <img
            className="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
            src={process.env.PUBLIC_URL+'/assets/media/logos/official-logosSVGbadge-bedrock-white-black.svg'}
            alt=""
          />
          {/*end::Image*/}
          {/*begin::Title*/}
          <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">
            Fast, Efficient and Productive
          </h1>
          {/*end::Title*/}
          {/*begin::Text*/}
          <div className="text-gray-600 fs-base text-center fw-semibold">
      
          For trouble logging in, please call
            <a href="#" className="opacity-75-hover text-primary mx-1">
            +1 (305) 311-1122
            </a>
        
            <br />
            For customer service, please email
            <a href="#" className="opacity-75-hover text-primary mx-1">
            demo.support@gmail.com
            </a>
           
            <br />
            Looking to become a supplier for Demo Corp?
          </div>
          {/*end::Text*/}
        </div>
   
  )
}

export default Content
