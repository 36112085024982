import {
    BodyContentFormWithCard,

    ColTextAreaWithLabel,
    ColTextFiledWithLabel,
    CountrySelect,
    InputGroupRow,
    MarginBottomFiled,
    MySelect,

  } from "../../../../../index";


 const CompanyInfo = () => {
    return (
      <BodyContentFormWithCard id="contact_info" isActive={true}>
        <InputGroupRow
          col2={
            <ColTextFiledWithLabel label="vat Number" name="vat_phone_number" />
          }
          col1={<ColTextFiledWithLabel label="Company" name="name_1" />}
        />
        <InputGroupRow
          col1={<ColTextFiledWithLabel label="Supplier ID" name="vendor_id" />}
          col2={<CountrySelect label="Country" name="country" />}
        />
  
        <InputGroupRow
          col1={<MySelect label="Parent Company" />}
          col2={<ColTextFiledWithLabel label="Website" name="website" />}
        />
        <MarginBottomFiled>
          <ColTextAreaWithLabel label="Address" name="Address" />
        </MarginBottomFiled>
        <InputGroupRow
          col1={
            <MySelect
              label="state"
              name="state"
              options={[
                { value: "A", country: "A" },
                { value: "B", country: "B" },
                { value: "C", country: "C" },
              ]}
            />
          }
          col2={<ColTextFiledWithLabel label="Street 1" name="street1" />}
        />
        <InputGroupRow
          col1={<ColTextFiledWithLabel label="Street 2" name="street2" />}
          col2={<ColTextFiledWithLabel label="Street 3" name="street3" />}
        />
        <InputGroupRow
          col1={<ColTextFiledWithLabel label="City" name="city" />}
          col2={<ColTextFiledWithLabel label="county" name="county" />}
        />
  
     
        <ColTextFiledWithLabel label="Zip Code" name="zip_code" />
      </BodyContentFormWithCard>
    );
  };
  
export default CompanyInfo