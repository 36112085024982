
import {
  CheckBox,
  InputGroupRow,
  MarginBottomFiled,
  BodyContentFormWithCard,TitleSmall,
  MySelect,
} from "../../../../index";

const Localization = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-localization">
      <TitleSmall title="Localization" className=" pb-5 pb-lg-10" />
      {/* <EnablesRadiosBtn typeForms='tin_check'/> */}
      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <MySelect
              label="Date Format"
         
              name="dateformat"
            />
          }
          col2={
            <MySelect
              label="Time Format"
        
              name="timeformat"
            />
          }
        />
      </MarginBottomFiled>

      <MarginBottomFiled>
        <InputGroupRow
          col1={
            <MySelect
              label="Default Timezone"
         
              name="timezones"
            />
          }
          col2={
            <MySelect
              label="Default Language"
        
              name="active_language"
            />
          }
        />
      </MarginBottomFiled>

      <MarginBottomFiled>
        <CheckBox title="Disable Languages" name='disable_language' />
      </MarginBottomFiled> <MarginBottomFiled>
        <CheckBox title="Output client PDF documents from admin area in client language" name='disable_language'/>
      </MarginBottomFiled>
    </BodyContentFormWithCard>
  );
};

export default Localization;


