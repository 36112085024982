import React from "react";
import {
  BtnIcon,
  BtnWhite,
  CardsSummary,
  EmptyTableTD,
  LayoutAdmin,
  LayoutAudit,
  MinusIcon,
  PlusIcon,
  TableAdmin,
  TDCheck,
} from "../.././../../index";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ActionNestedClaim from "./ActionNestedClaim";

const Claims = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  const navigate = useNavigate();
  return (
    <LayoutAudit
      title="All Claim"
      pages={["Claims", "All Claim"]}
      extraAction={
        <BtnWhite
          text="Create Claim"
          attributes={{ onClick: () => navigate("/new_claims") }}
        />
      }
    >
      <CardsSummary filters={dataCleans["record_status"]} />
      <TableAdmin
        isExport={true}
 
        titles={["Supplier ID",'', "Company Name",'','', "Contact",'', "Attachment",'',""]}
      >
        <TableBody records={dataCleans.records ?? ['x']} />
      </TableAdmin>
    </LayoutAudit>
  );
};

export default Claims;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={4} />;
  }
};


const Record = (props) => {
  const record = props.record;
  const [isOpen, setIsOpen] = useState(false);
 
  return (
    <>
      <tr key={record.id} className={isOpen?``:'border-bottom-0'}>
    
        <td colSpan={2} > </td>
        <td colSpan={3} ></td>
        <td colSpan={2} > </td>
        <td colSpan={3}  ></td>

        <td className="text-end">
          <BtnIcon
            className={`btn-active-light-primary toggle  ${
              isOpen ?  '':"active"
            }`}
            classNameSizeBtn="h-30px w-30px "
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <PlusIcon className="m-0 toggle-on" />
            <MinusIcon className="m-0 toggle-off" />
          </BtnIcon>
        </td>
      </tr>
      <NestedTableBody records={['x', 'v']}  className={isOpen?'':"d-none"}/>
  
    </>
  );
};


const NestedTableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <NestedRecord key={key+'_nested'} record={record} className={props.className} />;
    });

    return <>{recordList}</>;
  } 
};
const NestedRecord = (props) => {
  const record = props.record;

  return (
   
      <tr key={record.id} className={props.className}>
        <TDCheck />
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
         <td></td>
        <td></td>
        <td></td>
        <td className="text-end">
        <ActionNestedClaim/>
        </td>
      </tr>
     
  
  );
};
