import React from "react";
import LayoutPrevent from "../LayoutPrevent";
import { Badge, EmptyTableTD, TableAdmin } from "../../../core";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchPrevent } from "../../../../rtk/Slices/Prevent-Inview-Slice";
import { useDispatch, useSelector } from "react-redux";
import { CheckBox } from "@mui/icons-material";
import { TDCheck } from "../../cleanse";

const UploadPrevent = () => {
  const data = useSelector(
    (state) => state.prevent.result["Prevention - Fields to Present"]
  );

  const { status } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPrevent());
  }, []);

  return (
    <LayoutPrevent
      //   extraAction={
      //    <BtnUpload/>
      //   }
      title={status}
      pages={["Data Prevention", "Upload Payment"]}
    >
      <TableAdmin
        isExport={true}
        titles={[
          "id",
          "Status", ///name and email
          "Reason",
          "Description",
          "	Incoract Invoice",
          "Scource",
          "Supplier Number",
          "Supplier Name",
          "Invoice Number",
          "Invoice Date",
          "Gross Amount",
          "Currency",
          "PO",
          "Due Date",
          "	Payment Status",
          " Entery Date",
          "Payment Type",
          "Staff",
        ]}
        pagenation={{
          total: 1,
          currentPage: 1,
          updateCurrentPage: ({ page, count }) => {},
        }}
      >
        <TableBody records={data} />
      </TableAdmin>
    </LayoutPrevent>
  );
};

export default UploadPrevent;
const TableBody = (props) => {
  const recordes = props.records;

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return (
        <Record
          key={key}
          record={record}
          colorSupplier={key < 2 ? "badge-light-warning" : ""}
          colorGross={key < 8 ? "badge-light-warning" : "badge-light-danger"}
        />
      );
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={9} />;
  }
};

const Record = (props) => {
  const record = props.record;
  const { status } = useParams();
  console.log("recordes:", typeof record?.status);
  return (
    <tr key={record?.id}>
      <td>{record?.id ?? ""}</td>
      <td>
        {record?.Status
          ? status == "Upload Payment"
            ? record?.Status
            : status
          : ""}
      </td>
      <td>{record?.Reason ?? ""}</td>
      <td>{record?.Description ?? ""}</td>
      <TDCheck />
      <td>{record?.Source ?? ""}</td>
      <td>{record?.Supplier_Number ?? ""}</td>

      <td>
        <Badge
          name={record?.Supplier_Name ?? ""}
          color={props.colorSupplier}
        ></Badge>
      </td>

      <td>
        <Badge
          name={record?.Invoice_Number ?? ""}
          color="badge-light-warning"
        ></Badge>
      </td>

      <td>{record?.Invoice_Date ?? ""}</td>

      <td>
        <Badge
          name={record?.Gross_Amount ?? ""}
          color={props.colorGross}
        ></Badge>
      </td>
      <td>{record?.Currency ?? ""}</td>
      <td>{record?.PO ?? ""}</td>
      <td>{record?.Due_Date ?? ""}</td>
      <td>{record?.Payment_Status ?? ""}</td>
      <td>{record?.Entery_Date ?? ""}</td>
      <td>{record?.Payment_Type ?? ""}</td>
      <td className="text-end">{record?.Staff ?? ""}</td>
    </tr>
  );
};
