import React from "react";

const Breadcrumb = (props) => {
  const pages = props.pages;
  const length = pages?.length;

  return (
    <>
      {/*begin::Breadcrumb*/}
      <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-3">
        <IconHome />
        <ArrowItem />
        {length >= 1 && pages
          ? pages.map((element, key) => (
              <React.Fragment key={key} >
                <Item    item={element} />

                {length - 1 !== key ? <ArrowItem  /> : ""}
              </React.Fragment>
            ))
          : ""}
      </ul>
      {/*end::Breadcrumb*/}
    </>
  );
};

export default Breadcrumb;

const Item = (props) => {
  return (
    <>
      {/*begin::Item*/}
      <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
        {props.item}
      </li>
      {/*end::Item*/}
    </>
  );
};

const ArrowItem = () => {
  return (
    <>
      {/*begin::Item*/}
      <li className="breadcrumb-item">
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/}
        <span className="svg-icon svg-icon-5 svg-icon-gray-700 mx-n2">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
              fill="currentColor"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
      </li>
      {/*end::Item*/}
    </>
  );
};
const IconHome = () => {
  return (
    <>
      {/*begin::Item*/}
      <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
        <a href="/index.html" className="text-white">
          <i className="fonticon-home text-gray-700 fs-3" />
        </a>
      </li>
      {/*end::Item*/}
    </>
  );
};
