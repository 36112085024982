import React from "react";
import {
  BtnPrimary,
  ContentForm,
  EmptyTableTD,
  MySelect,
  PopupDialog,
  TableAdmin,
  TDCheck,
} from "../../../../../index";
import { useSelector } from "react-redux";

const SubCompany = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <ContentForm id="sub_vendors">
      <TableAdmin
        isExport={true}
        isSelest={true}
        ActionExtra={
          <div>
            <BtnPrimary
              text="ASSIGN SUB COMPANY"
              attributes={{
                "data-kt-menu-trigger": "click",
                "data-kt-menu-placement": "bottom-end",
              }}
            />
            <PopupDialog title="ASSIGN SUB COMPANY"><MySelect/></PopupDialog>
          </div>
        }
        titles={["Staff Member", "Date Assigned", "Options"]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </ContentForm>
  );
};

export default SubCompany;
const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={3} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <td></td>
      <td></td>
      <td className="text-end"></td>
    </tr>
  );
};
