import React from "react";
// Components
import Card from "./Card";
function CardList(props) {


  let cardList = props.filters?.map((card) => {
    return <Card key={card.id} {...card} />;
  });

  return <><Card key={-1} name='total' total_record_status={props.total} is_edit='1' />
  {cardList}</>;
}

export default CardList;

