import React from "react";
import {  LayoutAudit } from "../.././../../index";
import StatementForm from "./StatementForm";
import StatementSettings from "./StatementSettings";
///props
///statement Object  (option)
const UploadStatement = (props) => {
  const statement = props.statement;
  return (
    <LayoutAudit
      title=" Upload Statement"
      pages={["Statement", statement ? statement.name : "Create Statement"]}
    >
      <StatementForm {...statement} />
      <StatementSettings {...statement} />
    </LayoutAudit>
  );
};

export default UploadStatement;
