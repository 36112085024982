
import { useSelector } from "react-redux";
import {  TableAdmin ,LayoutAdmin, EmptyTableTD, LayoutCleanse} from "../../../../../index";
import { useNavigate } from "react-router-dom";

const Report = () => {
   const report = useSelector((state) => state.records?.result
  ??[]);
  return (
    <LayoutCleanse title="Report" pages={["Data Cleans", "Report"]}>
     
      <TableAdmin
        isExport={true}
        isSelest={true}
        titles={[
          "Staff",
          "Total Assignee Vendor",
          "Total Finished Vendor",
          "Total Approved Vendor",
          "Total Not Approved Vendor",
          "Last Day Working",
        ]}
      >
        <TableBody records={report.records??[]} />
      </TableAdmin>
    </LayoutCleanse>
  );
};

export default Report;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;
   
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record) => {
      return (
        <Record
         key={record.id}
          record={record}
       
        />
      );
    });

    return <>{recordList}</>;
  } else {
    return (
      < EmptyTableTD colSpan={7}/>
    );
  }
};

const Record = (props) => {
  const record=props.record;
  return (
    <tr key={props.id}>
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            className="form-check-input widget-13-check"
            type="checkbox"
            value="1"
          />
        </div>
      </td>
      <td>{props.name}</td>
      <NavItem data={0} page="/total_approved_vendor" />
      <td>0</td>
      <NavItem data={0} page="/total_assignee_vendor" />
      <NavItem data={0} page="/total_Not_approved_vendor" />
      <td></td>
    </tr>
  );
};

const NavItem = (props) => {
  const navgation = useNavigate();

  return (
    <td>
      <a
        onClick={() => navgation(props.page)}
        class="btn btn-flush"
      >
        0
      </a>
    </td>
  );
};
