
import { BodyContentFormWithCard,TitleSmall } from "../../../../index";


const CronJob = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-data-cron-job" >
      <TitleSmall title="Cron Job" className=" pb-5 pb-lg-10" />
    
    </BodyContentFormWithCard>
  );
};

export default CronJob;

const Margin = (props) => <div className="mb-10">{props.children}</div>;
