import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const Loading = () => {
    const open= useSelector((state)=>state.records.status);


    return (  <>
    
   {open=='loading'? <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open
  >
    <CircularProgress color="inherit" />
  </Backdrop>:""} 
    </>);
}
 
export default Loading;