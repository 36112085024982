import { useDispatch } from "react-redux";
import { fetchRecord } from "../../../rtk/Slices/Records-Slices";
import { Badge } from "../index";
import "./card.css";
import EditButton from "./EditButton";
function Card(props) {
  const dispatch = useDispatch();
  const handelClick = () => {
    dispatch(fetchRecord({ status: props.id }));
  };
  const color = getRandomItem();

  return (
    <div className="col cursor-pointer me-8 mb-2 " onClick={handelClick}>
      <div className="d-flex justify-content-between  ">
        <div>
          <div className="fs-7 text-muted fw-bold text-capitalize clo text-hover-primary">
            {props.name}
          </div>

          <div className="d-flex align-items-center">
            <div className="fs-4  text-gray-800 pe-1 fw-bold">{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(props.total_record_status)}</div>
            <Badge name={props.percentage} color={color}  size='s'/>
          </div>
        </div>
        {props.is_edit == 1 ? <EditButton /> : ""}
      </div>
    </div>
  );
}

export default Card;

function getRandomItem() {
  const array = [
    "badge-light-primary",
    "badge-light-success",
    // "badge-light-info",
    "badge-light-warning",
    "badge-light-danger",
  ];
  // get random index value
  const randomIndex = Math.floor(Math.random() * array.length);

  // get random item
  const item = array[randomIndex];

  return item;
}
