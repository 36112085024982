

const TDSearchTrem = (props) => {
    
    const searchTrem = props.search_trem;
    return (
      <td>{searchTrem}</td>
    )
  }
  
  export default TDSearchTrem
  