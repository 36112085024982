import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

// npm i @react-oauth/google
//npm i jwt-decode
const TestLogIn = () => {
    return ( <div className=' btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>
   
    <GoogleLogin
    
  onSuccess={credentialResponse => {
    console.log(credentialResponse.credential);
    var decoded = jwt_decode(credentialResponse.credential);
    console.log(decoded)

    
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>
    

    
    </div> )
}
 
export default TestLogIn;