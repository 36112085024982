import React from "react";
import { ColTextFiledWithLabel, InputGroupRow, MySelect, TitleSmall } from "../../../../../index";

const BankVerificationForm = () => {
  return (
    <>
   <InputGroupRow
    col1={<ColTextFiledWithLabel label="Supplier Number" name="vendor_number" />}
    col2={
      <ColTextFiledWithLabel
        label="Company Name "
        name="company"
        isRequired={true}
      />
    }
  />
      <TitleSmall title="Account Holder Information" 
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Company Name / Full Name"
            name="company"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Company Name / Last Name"
            name="company"
            isRequired={true}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Bank Name"
            name="bank_name"
            isRequired={true}
          />
        }
        col2={
          <MySelect
            label=" Bank Country"
            name="bank_country"
            isRequired={true}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label=" ABA Routing No"
            name="aba_routing_no"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label=" Account Number"
            name="account_number"
            isRequired={true}
          />
        }
      />
      <InputGroupRow
        col1={
          <MySelect
            label="Bank Account Type"
            name="bank_account_type"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel label="Bank Address 1" name="bank_address_1" />
        }
      /> 
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel label="Bank Address 2" name="bank_address_2" />
        }
        col2={
          <ColTextFiledWithLabel label="Bank Address 3" name="bank_address_3" />
        }
      /> 
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="Bank City" name="bank_city" />}
        col2={<ColTextFiledWithLabel label="Bank State" name="bank_state" />}
      />
      <ColTextFiledWithLabel label="Bank Postal Code" name="bank_postal_code" />
    </>
  );
};

export default BankVerificationForm;
