
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import { MasterInit } from "./_doublesupport/layout/MasterInit";
import  Loading  from "./component/core/main/Loading";
import { useEffect, version } from "react";
import { MenuComponent } from "./_doublesupport/assets/ts/components";
import { GoogleOAuthProvider } from "@react-oauth/google";

//   const script = document.createElement("script");
  //   script.src = "./assets/js/widgets.bundle.js";
  //   script.async = false;
  //   script.defer = false;
  //   document.body.append(script);
    
  //    }, [location.key]);

  var a = 1;
      function increase(){
            var textBox = document.getElementsByTagName("script");
            console.log("text",textBox)
            textBox.value = a;
            a++;
            return a
      }  


  
function App() {
  
  var version=1;
  const location = useLocation();
  useEffect(() => {
   
    const script = document.createElement('script');
  
    script.src = `./assets/js/widgets.bundle.js?v=${(increase())}`;
    script.async = false;
  
    document.head.appendChild(script);
  
    return () => {
      document.head.removeChild(script);
    }
  }, [location.key]);
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);
  return (
    <>
    <GoogleOAuthProvider clientId="412515060101-qae1d3g5nup40a8ka6fa677sp10ij666.apps.googleusercontent.com">
     <Loading/> 
      <Outlet />
      <MasterInit />
      </GoogleOAuthProvider>
    </>
  );
  
}

export default App;


