import React from 'react'

function France() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path fill="#41479B" d="M0 0H170.67V512H0z"></path>
            <path fill="#F5F5F5" d="M170.67 0H341.34V512H170.67z"></path>
            <path fill="#FF4B55" d="M341.33 0H512V512H341.33z"></path>
        </svg>
    )
}

export default France