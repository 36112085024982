import React from "react";
import {  LayoutAdmin, LayoutCleanse } from "../../../../index";
import AddAPrimaryUserByInvite from "./AddAPrimaryUserByInvite";
import SupplierInviteForm from "./SupplierInviteForm";
import InviteFormPermissions from "./InviteFormPermissions";
import InviteFormEmailNotification from "./InviteFormEmailNotification";

///props
///invite Object  (option)
const SupplierInvite = (props) => {
  const invite = props.invite;
  return (
    <LayoutCleanse
      title=" Supplier Invite"
      pages={["Supplier Invition ", invite ? invite.name : "Create invite"]}
    >
      <SupplierInviteForm {...invite} />
      <AddAPrimaryUserByInvite {...invite} />
      <InviteFormPermissions {...invite} />
      <InviteFormEmailNotification {...invite} />
    </LayoutCleanse>
  );
};

export default SupplierInvite;
