import React from 'react'

const TDBy = (props) => {
    const by=props.by;
  return (
 
      <td>by</td>

  )
}

export default TDBy
