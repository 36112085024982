import { useNavigate } from "react-router-dom";
import {
  TabForDialog,
  BodyTabs,
  Dialog,
  FormViewTitle,
  VerticalBTN,
  InputGroupRow,
  MySelect,
  MarginBottomFiled,
  ColTextAreaWithLabel,
  ColTextFiledWithLabel,
  CheckBox,
  UploadFile,
  DateComp,
  Badge,
  GroupRadioBtn,
  MyLabel,
  ColTextEditorWithLabel,
  BtnPrimary,
} from "../../../../index";
import { Fragment, useState } from "react";

const NestedClaimDialog = (props) => {
  var record = props.record;
  const navgation = useNavigate();
  var reply = `clame_reply_${record?.vendor_id ?? ""}`;
  var note = `clame_note_${record?.vendor_id ?? ""}`;
  var setting = `clame_setting_${record?.vendor_id ?? ""}`;
  var response = `clame_response_${record?.vendor_id ?? ""}`;
  const tabsBody = [
    {
      idContent: `${reply}`,
      title: "Reply",
      isActive: true,
      component: <BodyReplyClaim idContent={reply} {...record} />,
    },
    {
      idContent: `${note}`,
      title: "Note",
      component: <BodyNoteClaim idContent={note} {...record} />,
    },

    {
      idContent: `${setting}`,
      title: "Settings",
      component: <BodySettingsClaim idContent={setting} {...record} />,
    },
    {
      idContent: `${response}`,
      title: "response",
      component: <BodyResponseClaim idContent={response} {...record} />,
    },
  ];
  const [ver, setVer] = useState(false);
  return (
    <>
      {/*begin::Modal - Supplier Information*/}
      <Dialog
        id={`${props.idDialog}`}
        title="#61 - 15002-2023-05-cl-45"
        extraAction={
          <VerticalBTN
            actionVertical={() => setVer(true)}
            actionHorizantal={() => setVer(false)}
          />
        }
        classNameBody="pt-lg-5 ps-lg-5"
      >
        <div className="d-flex justify-content-between m-5">
          <div className="text-gray-500 fw-normal  fs-5 mb-5">
            This claim is linked to statement: 15002-2023-05-st-0
          </div>
          <div className="d-flex ">
   
            <Badge
              name="Waiting Vendor Validation"
              color=" badge-light-primary me-3"
            />
            <BtnPrimary
              text="View Public Form"
              attributes={{
                onClick: () => {
                  
                  navgation("/vendor_claim_portal_page");
                },
                'data-bs-dismiss':"modal"
              }}
            />
          </div>
        </div>
        <form className="form " action="#" id="kt_modal_nested_claim_form">
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_new_address_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_new_address_header"
            data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
            data-kt-scroll-offset="300px"
          >
            {/* <div className="tab-content"> */}
            {/* <div id="kt_vertical_pane" className="tab-pane fade p-2 show active "> */}
            {ver ? (
              <>
                {tabsBody.map((element, key) => (
                  <Fragment key={element.idContent + "_vertical"}>
                    <FormViewTitle title={element.title} isFirst={key === 0} />
                    <div className="mx-5">{element.component}</div>
                  </Fragment>
                ))}
              </>
            ) : (
              <>
                {/* <div id="kt_horizontal_pane" className="tab-pane fade "> */}
                <TabForDialog tabs={tabsBody}>
                  {tabsBody.map((element) => (
                    <Fragment key={element.idContent + "_Horizantal"}>
                      {element.component}
                    </Fragment>
                  ))}
                </TabForDialog>
              </>
            )}
          </div>
          {/* <BodyCompanyInformation /> */}
          {/* </div> */}
          {/*end::Scroll*/}
          <ColTextEditorWithLabel label=" System Generated  (Staff)" />

          {/*begin::Modal footer*/}
          <div className="modal-footer flex-center">
            {/*begin::Button*/}
            <button
              type="reset"
              id="kt_modal_new_address_cancel"
              className="btn btn-light me-3"
            >
              Discard
            </button>
            {/*end::Button*/}
            {/*begin::Button*/}
            <button
              type="reset"
              id="kt_modal_new_address_submit"
              className="btn btn-primary"
            >
              <span className="indicator-label">Submit</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
            {props.extraAction}
            {/*end::Button*/}
          </div>
          {/*end::Modal footer*/}
        </form>
        <span className="fs-7 text-primary d-flex align-items-center fw-semibold">
          <span className="bullet bullet-dot  bg-primary me-2"></span>Posted: 17
          MAR,2023 7:37 AM
        </span>
        {/*end::Form*/}
      </Dialog>
      {/*end::Modal - Supplier Information*/}
    </>
  );
};

export default NestedClaimDialog;

const BodyResponseClaim = (props) => {
  const record = props;

  return (
    <BodyTabs id={props.idContent}>
      <MyLabel label="No Response Yet" />
      <InputGroupRow
        col1={
          <MySelect
            label="Add Response"
            options={[
              { value: 3, title: "CLOSE CLAIM" },
              { value: 2, title: "APPROVE CLAIM ON BEHALF OF SUPPLIER" },
              { value: 1, title: "DENY CLAIM ON BEHALF OF SUPPLIER" },
            ]}
          />
        }
        col2={<ColTextFiledWithLabel label="Supplier Approved Amount" />}
      />
      <MarginBottomFiled>
        <MyLabel label="Select Approval Amount" />
        <GroupRadioBtn options={["Original ", "Other"]} />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <MySelect
          label="How You Take The Approval"
          options={[
            { value: 3, title: "Phone Call" },
            { value: 2, title: "Email" },
            { value: 1, title: "Other" },
          ]}
        />
      </MarginBottomFiled>
    </BodyTabs>
  );
};

const BodyNoteClaim = (props) => {
  const record = props;

  return (
    <BodyTabs id={props.idContent}>
      <MarginBottomFiled>
        <ColTextAreaWithLabel label="Note" />
      </MarginBottomFiled>
    </BodyTabs>
  );
};
const BodySettingsClaim = (props) => {
  const record = props;

  return (
    <BodyTabs id={props.idContent}>
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="Subject" />}
        col2={<ColTextFiledWithLabel label="Amount" />}
      />
      <MySelect label="Assign claim (default is current user)" />
      <InputGroupRow
        col1={<MySelect label="Contact" />}
        col2={<MySelect label="Priority" />}
      />
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="Name" />}
        col2={<ColTextFiledWithLabel label="Email address" />}
      /> 
      <InputGroupRow
        col1={<MySelect label="Select Type" />}
        col2={<MySelect label="Select Reason" />}
      />
      <InputGroupRow
        col1={<MySelect label="statement" />}
        col2={<DateComp label="Newest Credit Date" />}
      />
    </BodyTabs>
  );
};
const BodyReplyClaim = (props) => {
  const record = props;

  return (
    <BodyTabs isActive={true} id={props.idContent}>
      <InputGroupRow
        col1={<MySelect label=" predefined reply" />}
        col2={<MySelect label="knowledge base link" />}
      />
      <MarginBottomFiled>
        <ColTextAreaWithLabel />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <ColTextFiledWithLabel label="cc" />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <CheckBox title="Assign this claim to me automatically" />
      </MarginBottomFiled>
      <UploadFile />
    </BodyTabs>
  );
};
