import {
  InputGroupRow,
  ColTextFiledWithLabel,
  MySelect,
  Radio,
  BodyContentFormWithCard,
} from "../../../../../../index";

const Pirority = () => {
  return (
    <BodyContentFormWithCard id="setting-pirority">
      <div className="mb-10">
         
        <ColTextFiledWithLabel label="Spend" type="number" />
      </div>
      <ORAnd typeForm="TEST1" />
      <div className="mb-10">
         
        <ColTextFiledWithLabel label="Count" type="number" /> 
        <ORAnd typeForm="TEST2" />
      </div>

      <div className="mb-10">
        <MySelect
          label="Vendor Type"
          name="settings[vendor_master_auto_archive_vendor_type][]"
        />
      </div>
    </BodyContentFormWithCard>
  );
};

export default Pirority;

const ORAnd = (props) => {
  return (
    <div className="mb-10">
      <InputGroupRow
        col1={
          <Radio
            id={`y_opt_1_settings_${props.typeForm}`}
            name={`settings[onoffswitch_${props.typeForm}]`}
            title="OR"
          />
        }
        col2={
          <Radio
            id={`y_opt_2_settings_${props.typeForm}`}
            name={`settings[onoffswitch_${props.typeForm}]`}
            title="AND"
          />
        }
      />
    </div>
  );
};
