import React from 'react'
//props 
//className
const MinusIcon = (props) => {
  return (
<span className={`svg-icon svg-icon-3 ${props.className}`}>
																			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" />
																			</svg>
																		</span>
  )
}

export default MinusIcon