

const CharterIcon = () => {
  return (
    <>
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr070.svg*/}
        <span className="svg-icon svg-icon-3 svg-icon-muted me-2">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x={8}
                          y={9}
                          width={3}
                          height={10}
                          rx="1.5"
                          fill="currentColor"
                        />
                        <rect
                          opacity="0.5"
                          x={13}
                          y={5}
                          width={3}
                          height={14}
                          rx="1.5"
                          fill="currentColor"
                        />
                        <rect
                          x={18}
                          y={11}
                          width={3}
                          height={8}
                          rx="1.5"
                          fill="currentColor"
                        />
                        <rect
                          x={3}
                          y={13}
                          width={3}
                          height={6}
                          rx="1.5"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
    </>
  )
}

export default CharterIcon;
