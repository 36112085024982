///props
//titles array String
//isAction bool (option)

const TableHead = (props) => {
  let listItems;
  const titles = props.titles;
  const checkIsNotEmpty = () => titles && titles.length >= 1;
  if (checkIsNotEmpty()) {
    listItems = props.titles.map((title) => (
      // Correct! Key should be specified inside the array.
      <th
        className={`min-w-${
          title.toString().length > 10
            ? "150"
            : title.toString().length > 5
            ? "125"
            : "80"
        }px`}
        key={title.toString()}
      >
        {title}
      </th>
    ));
  }
  return (
    <>
      {/*begin::Head*/}
      <thead className="fs-7 text-gray-400 text-uppercase mb-2">
        <tr>
          {props.isSelest ? <CheckForAll /> : ""}
          {checkIsNotEmpty() ? listItems : ""}
          {props.isAction ? (
            <th className="min-w-80px text-end">Details</th>
          ) : (
            ""
          )}
        </tr>
      </thead>
      {/*end::Head*/}
    </>
  );
};

export default TableHead;
const CheckForAll = () => (
  <th className="w-25px">
    <div className="form-check form-check-sm form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="checkbox"
        value="1"
        data-kt-check="true"
        data-kt-check-target=".widget-13-check"
      />
    </div>
  </th>
);
