
import { LayoutAdmin, TableAdmin } from '../../index';
import  { AddAnnouncementBTN } from './AddAnnouncementBTN';


const Announcements = () => {
    const dataCleans=[];
  return (
 
    <LayoutAdmin
      title="Announcements"
      pages={[ "Utilities", "Announcements"]}
  extraAction={<AddAnnouncementBTN />}
    >

      <TableAdmin
        isExport={true}
        isSelest={true}
        titles={[
          "Name",
          "Date",
          
       
        ]}
      >
        <TableBody records={dataCleans.records??[]} />
      </TableAdmin>
   
    </LayoutAdmin>


  )
}

export default Announcements
const TableBody = (props) => {
    const recordes = props.records;
    let recordList;
     
    if (recordes && recordes.length >= 1) {
      recordList = recordes.map((record, key) => {
        return <Record id={key} />;
      });
  
      return <>{recordList}</>;
    } else {
      return (
        <div>
          <p className="fs-7 text-muted fw-bold text-capitalize">
            No entries found
          </p>
          <img
            src={`${process.env.PUBLIC_URL}/assets/media/illustrations/sketchy-1/4.png`}
          />
        </div>
      );
    }
  };
  
  const Record = (props) => {
    const record = props.record;
    return (
      <tr key={props.id}>

   
        <td></td>
      
 
        <td className="text-end"></td>
      </tr>
    );
  };
  