import React from 'react'

const CloseIcon = () => {
  return (
    <>
       {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
       <span className="svg-icon svg-icon-1">
                    <svg
                      width={24}
                      height={24}     
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
    </>
  )
}

export default CloseIcon
