import "./style.css";
import { HeaderSecondary, HeaderPrimary, ItemMenuUserLink } from "../index";
const Header = (props) => {
  return (
    <>
      {/*begin::Header*/}
      <div id="kt_app_header" className="app-header mb-6 mb-lg-10">
        <HeaderPrimary
          menuUser={
         props.menuUser }
        />
        <HeaderSecondary>{props.menuNavigation}</HeaderSecondary>
      </div>
      {/*end::Header*/}
    </>
  );
};

export default Header;
