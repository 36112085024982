import { useSelector } from "react-redux";
import {
  
  TableAdmin,
  EmptyTableTD,
  TDCheck,
  
  BtnPrimary,
  ActionStatement,
  LayoutAudit,
} from "../.././../../index";

const OutstandingStatement = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  return (
    <LayoutAudit title=" Outstanding" pages={["Statement", "Outstanding"]}>
      <TableAdmin
        isExport={true}
        isSelest={true}
        isAction={true}
        titles={[
          "Bedrock ID",
          "Supplier ID",
          "Company Name",
          "Primary Contact",
          "Primary Email",
          "Phone",
          "Active",
          "Created Date",
        ]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </LayoutAudit>
  );
};

export default OutstandingStatement;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={3} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <TDCheck />
      <td>
        <BtnPrimary text="view" />
      </td>
      <td></td>
      <td className="text-end">
        <ActionStatement />
      </td>
    </tr>
  );
};
