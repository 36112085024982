function TDNameAndEmail(props) {
    const name = props.company;
    const email = props.email; 

    return (
      <td>
        {/*begin::company*/}
        <div className="d-flex align-items-center">
          {/*begin::Info*/}
          <div className="d-flex flex-column justify-content-center">
            <a href="" className="fs-6 text-gray-800 text-hover-primary">
              {name}
            </a>
          {props.isHideEmail?  '':<div className="fw-semibold text-gray-400">{email}</div>}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::company*/}
      </td>
    );
  }
  

  export default TDNameAndEmail;