

import { BtnPrimary, PopupMenuItem,Dialog, CreateTabFieldOnborading } from "../../../../../index";
import { ActionPopup } from "../Action";



const ActionOnboradingField = (props) => {
const idDialog=`kt_edit_fields_onbording_${props.id}`;
  const dataTatget = `#${idDialog}`;
    return (
      <>
        <ActionPopup>

          <PopupMenuItem
            text="Edit"
            attributes={
            {  "id-dialog": props.id,
              "data-bs-toggle": "modal",
              "data-bs-target": dataTatget,
           } }
          />
          <PopupMenuItem
            text="Delete"
            attributes={{
              style: { color: "var(--bs-danger)" },
            }}
          />
        </ActionPopup>
        <EditTabFiledDialog idDialog={idDialog} title="Edit Tab-Field" record={props.record} />
      </>
    );
  };
  

  export default ActionOnboradingField;

  export const EditTabFiledDialog = (props) => {
  
    return (
      <Dialog id={props.idDialog} title={props.title} classNameBody="ps-lg-10 pt-0">
        <form
          className="form mt-10"
          action="#"
          id="kt_modal_new_kt_edit_fields_form"
        >
    
          {/*begin::Scroll*/}
          <div
            className="scroll-y me-n7 pe-7"
            id="kt_modal_new_kt_edit_fields_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_new_kt_edit_fields_header"
            data-kt-scroll-wrappers="#kt_modal_new_kt_edit_fields_scroll"
            data-kt-scroll-offset="300px"
          >
            <CreateTabFieldOnborading {...props.record} />
           
            <BtnPrimary
              text="Edit Fields"
              attributes={{ onClick: () => {}}}
            />
          </div>
        </form>
      </Dialog>
    );
  };
  
  