import React from 'react'

const PagenationBtnDecrementCustom = ({decrement}) => {
   
    
  return (
    <li className="page-item previous">
    <button
      className="page-link"
    //   disabled={pagesStop <= pageCountTotal ? true : false}
      onClick={decrement}
      
    >
      <i className="previous" />
    </button>
  </li>
  )
}

export default PagenationBtnDecrementCustom
