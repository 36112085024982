////props
///isDisable bool
///title String 
///desc String

import { Badge } from "../../../index";

const ItemEmail =( props) => {
  return (<>
   {/*begin::Item*/}
   <div className="d-flex align-items-center mb-8" >
   {/*begin::Bullet*/}
   <span className={`bullet bullet-vertical h-40px ${props.isDisable?' bg-danger':'bg-primary'}` } />
   {/*end::Bullet*/}
   {/*begin::Checkbox*/}
   <div className="form-check form-check-custom form-check-solid mx-5">
     <input className="form-check-input" type="checkbox" defaultValue="" />
   </div>
   {/*end::Checkbox*/}
   {/*begin::Description*/}
   <div className="flex-grow-1">
     <a className="text-gray-800 text-hover-primary fw-bold fs-6" onClick={props.onClick}>
    { props.title}
     </a>
     <span className="text-muted fw-semibold d-block"> { props.desc}</span>
   </div>
   {/*end::Description*/}
{props.isDisable?<Badge name='Disable' color='badge-light-danger'/>:<Badge name='Enable' color='badge-light-primary'/>}  
 </div>
 {/*end:Item*/}
</>
  )
}

export default ItemEmail


