import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  result: [],
  status: "unStatus",
  error: null,
};
const onbordingSlice = createSlice({
  initialState: initialState,
  name: "onbordingSlice",
  reducers: {
    searchRecord: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnborading.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOnborading.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array

        let result = action.payload;
        let onbording = result.onboarding_tab;
        // onbording.sort((a,b) => a.order - b.order);
        // for (let index = 0; index < onbording.length; index++) {
        //   const element = onbording[index];
        //   element?.with?.sort((a,b) => a.field_order - b.field_order);
        // }
        state.result = onbording;

        return state;
      })
      .addCase(fetchOnborading.rejected, (state, action) => {
        console.log('action:', action)
        state.status = "failed";

        state.error = action;
      });
  },
});
export const { searchProduct, filterRecord, updateCurrentPage } =
  onbordingSlice.actions;
export default onbordingSlice.reducer;

export const fetchOnborading = createAsyncThunk(
  "onbordingSlice/fetchTabsAndField",
  async (props) => {
   const cleanse=props.cleanse?'_cleans':'';
    const query = queryLink(props);

    const link = `https://bedrock-api.ews-jo.com/api/onboarding${cleanse}?${query}`;

    console.log("link:", link);
    const client = axios.create({
      baseURL:link
      // 'json/onborading.json',
    });

    let response = await client
      .get()
      .then((result) => {
        return result;
      })
      ////////
      .catch((error) => {
        console.log(error, "error test");
      });

    return response.data;
  }
);
function queryLink(props) {
  const vendor = props?.vendor ? `vendor=${props.vendor}` : "";
  const query = vendor;
  console.log('query:', query)
  return query;
}
