import TagsSelect from "../../input/TagsSelect";

import { PopupDialog, Radio, BtnSecundary, ExportIcon } from "../../index";
import { useState } from "react";

const ExportMenu = () => {
  const [isFilter, setIsFilter] = useState(false);
  return (
    <PopupDialog title="Export Options">
      {/*begin::Input group*/}
      <div className="fv-row mb-10">
        <label className="required fs-6 fw-semibold form-label mb-2">
          Select Export:
        </label>
        <a onClick={() => setIsFilter(false)}>
          <Radio
            title="Export "
            name="export"
            id="export-1"
            className="mb-2"
            defaultValue={1}
          />
        </a>
        <a onClick={() => setIsFilter(false)}>
          <Radio
            title="Export All"
            name="export"
            id="export-2"
            className="mb-2"
          />
        </a>
        <a onClick={() => setIsFilter(true)}>
          <Radio
            title="Export Filter"
            name="export"
            id="export-3"
            className="mb-2"
          />
        </a>
      </div>
      {/*end::Input group*/}
      {isFilter ? <TagsSelect title="Filters" /> : ""}
      {/*begin::Input group*/}
      <div className="fv-row mb-10">
        {/*begin::Label*/}
        <label className="required fs-6 fw-semibold form-label mb-2">
          Select Export Format:
        </label>
        {/*end::Label*/}
        {/*begin::Input*/}
        <select
          name="format"
          data-control="select2"
          data-placeholder="Select a format"
          data-hide-search="true"
          className="form-select form-select-solid fw-bold"
        >
          <option />
          <option value="excel">Excel</option>
          <option value="pdf">PDF</option>
          <option value="cvs">CVS</option>
          <option value="zip">ZIP</option>
        </select>

        {/*end::Input*/}
        {/* <MySelect2 label='Select Export Format:' name="format" options={[{value:'excel',title:'Excel'},
      {value:'pdf',title:'PDF'},
      {value:'cvs',title:'CVS'},
      {value:'zip',title:'ZIP'}]}/> */}
      </div>
      {/*end::Input group*/}

      {/*begin::Actions*/}
      <div className="text-center">
        <button
          type="reset"
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          data-kt-users-modal-action="submit"
        >
          <span className="indicator-label">Export</span>
          <span className="indicator-progress">
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        </button>
      </div>
      {/*end::Actions*/}
    </PopupDialog>
  );
};

const ExportBtn = () => (
  <div>
    {/*begin::Export*/}
    <BtnSecundary
      attributes={{
        "data-kt-menu-trigger": "click",
        "data-kt-menu-placement": "bottom-end",
      }}
      text="Export"
      icon={<ExportIcon />}
    />
    {/*end::Export*/}
    <ExportMenu />
  </div>
);

export default ExportBtn;
