import React from 'react'

const TDPaymentMethod = (props) => {
    const paymentMethod=props.payment_method;
  return (
 
      <td>{paymentMethod}</td>

  )
}

export default TDPaymentMethod
