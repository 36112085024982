

const  TDHouseNumber= (props) => {
    
  const houseNumber = props.house_number;
  return (
    <td>{houseNumber}</td>
  )
}

export default TDHouseNumber
