////props
///isViewVendor bool (optional)

const CardSatementsProfile = (props) => {
  return (
    <>
      {/*begin::Setting View*/}
      <div className="tab-pane fade card mb-5 mb-xl-10 " id="kt_profile_statements_view">
        {/*begin::Card header*/}
        <div className="card-header cursor-pointer">
          {/*begin::Card title*/}
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Profile Satements</h3>
          </div>
          {/*end::Card title*/}
          {/*begin::Action*/}
          <a
            href="/account/settings.html"
            className="btn btn-sm btn-primary align-self-center"
          >
            Edit Profile
          </a>
          {/*end::Action*/}
        </div>
        {/*begin::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body p-9">
          <ItemSettingProfile title="Full Name" desc="Ayman Jakhbir" />
          <ItemSettingProfile title="Company" desc=" Bedrock" />
          <ItemSettingProfile
            title=" Contact Phone"
            desc="044 3276 454 935"
            isVerified={true}
            tooltip="Phone number must be active"
          />
          <ItemSettingProfile
            title=" Company Site"
            desc={
              <a
                href="#"
                className="fw-semibold fs-6 text-gray-800 text-hover-primary"
              >
                www.mybedrock.com
              </a>
            }
          />
              <ItemSettingProfile
            title="Country"
            desc="Germany"
         
            tooltip="Country of origination"
          />
          {/*begin::Input group*/}

          <ItemSettingProfile title="Communication" desc="Email, Phone" />
       
         
          {/*begin::Notice*/}
          <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
            {/*begin::Icon*/}
            {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
            <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  rx={10}
                  fill="currentColor"
                />
                <rect
                  x={11}
                  y={14}
                  width={7}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 14)"
                  fill="currentColor"
                />
                <rect
                  x={11}
                  y={17}
                  width={2}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11 17)"
                  fill="currentColor"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            {/*end::Icon*/}
            {/*begin::Wrapper*/}
            <div className="d-flex flex-stack flex-grow-1">
              {/*begin::Content*/}
              <div className="fw-semibold">
                <h4 className="text-gray-900 fw-bold">
                  We need your attention!
                </h4>
                <div className="fs-6 text-gray-700">
                  Your payment was declined. To start using tools, please
                  <a className="fw-bold" href="/account/billing.html">
                    Add Payment Method
                  </a>
                  .
                </div>
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Notice*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  );
};

export default CardSatementsProfile;

const ItemSettingProfile = (props) => {
  return (
    <>
      {/*begin::Row*/}
      <div className="row mb-7">
        {/*begin::Label*/}
        <label className="col-lg-4 fw-semibold text-muted">
          {props.title}
          {props.tooltip ? (
            <i
              className="fas fa-exclamation-circle ms-1 fs-7"
              data-bs-toggle="tooltip"
              title={props.tooltip}
            />
          ) : (
            ""
          )}
        </label>
        {/*end::Label*/}
        {/*begin::Col*/}
        <div className="col-lg-8">
          <span className="fw-bold fs-6 text-gray-800">{props.desc}</span>
          {props.isVerified ? (
            <span className="badge badge-success">Verified</span>
          ) : (
            ""
          )}
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Row*/}
    </>
  );
};
