import React from 'react'

function Germany() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path fill="#464655" d="M0 0H512V170.67H0z"></path>
            <path fill="#FFE15A" d="M0 341.33H512V512H0z"></path>
            <path fill="#FF4B55" d="M0 170.67H512V341.34H0z"></path>
        </svg>
    )
}

export default Germany