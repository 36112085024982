import {
  PlusIcon,
  BtnPrimary,
  PopupDialog,
  SeparatorDashed,
  Radio,
} from "../../index";
///props
// widget commponent (optional)
// name String
///bulks array ex[{
//   defaultValue: num,
//   id: String,
//   title: String,
//   desc: String,
// }]
const BulkActionBtn = (props) => {
  return (
    <div>
      <BtnPrimary
        attributes={{
          "data-kt-menu-trigger": "click",
          "data-kt-menu-placement": "bottom-end",
        }}
        text="Bulk Actions"
        icon={<PlusIcon />}
      />
      <BulkActionMenu bulks={props.bulks} />
    </div>
  );
};

export default BulkActionBtn;

const BulkActionMenu = (props) => (
  <PopupDialog title="bulk">
    {/*begin::Scroll*/}
    <div
      className="d-flex flex-column scroll-y me-n7 pe-7"
      id="kt_modal_add_user_scroll"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-max-height="auto"
      data-kt-scroll-dependencies="#kt_modal_add_user_header"
      data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
      data-kt-scroll-offset="300px"
    >
      {/*begin::Input group*/}
      <div className="mb-7">
        {/*begin::Label*/}
        <label className="fw-semibold fs-6 ">Select :</label>
        {/*end::Label*/}
        {/*begin::Roles*/}
        {props.bulks
          ? props.bulks.list.map((element, key) => (
              <ItemBulk
                {...element}
                name={props.bulks.name}
                lastItem={key == props.bulks.length - 1}
              />
            ))
          : "Empty Element Bulk "}
        {props.bulks.widget
          }
        {/*begin::Input row*/}

        {/*end::Input row*/}

        {/*end::Roles*/}
      </div>
      {/*end::Input group*/}
    </div>
    {/*end::Scroll*/}
    {/*begin::Actions*/}
    <div className="text-center pt-5">
      <button
        type="reset"
        className="btn btn-light me-3"
        data-kt-users-modal-action="cancel"
      >
        Discard
      </button>
      <button
        type="submit"
        className="btn btn-primary"
        data-kt-users-modal-action="submit"
      >
        <span className="indicator-label">Submit</span>
        <span className="indicator-progress">
          Please wait...
          <span className="spinner-border spinner-border-sm align-middle ms-2" />
        </span>
      </button>
    </div>
    {/*end::Actions*/}
  </PopupDialog>
);

///props
//id String
//name String
//title String
//desc String (option)
///defaultValue
///lastItem  bool
const ItemBulk = (props) => {
  return (
    <>
      {/*begin::Input row*/}
      <div className="d-flex fv-row">
        <Radio {...props} />
      </div>
      {/*end::Input row*/}
      {props.lastItem ? "" : <SeparatorDashed />}
    </>
  );
};
