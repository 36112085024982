import React from 'react'

const TDAssignee = (props) => {
    const assignee=props.assignee;
  return (
 
      <td>assignee</td>

  )
}

export default TDAssignee
