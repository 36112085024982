import React from 'react'

const TDZipCode = (props) => {
    const zipCode=props.zip_code;
  return (
 
      <td>{zipCode}</td>

  )
}

export default TDZipCode
