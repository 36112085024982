import React from "react";
import {
  CardWhite,
  ColTextFiledWithLabel,
  DateComp,
  InputGroupRow,
  MarginBottomFiled,
  MySelect,
} from "../.././../../index";

const FormClaim = (props) => {
  const claim = { ...props };
  return (
    <CardWhite margenTop margenBottom>
     <MarginBottomFiled> <ColTextFiledWithLabel
        label="Subject"
        name="subject"
        isRequired={true}
        value={claim.subject}
      /></MarginBottomFiled>
      <InputGroupRow
        col1={<MySelect lable="Vendor" option={[]} />}
        col2={
          <MySelect
            lable="Priority"
            option={[
              { value: "Low", title: "Low" },
              { value: "Medium", title: "Medium" },
              { value: "High", title: "High" },
            ]}
          />
        }
      />
      <InputGroupRow
        col1={<MySelect lable="Assign Claim" option={[]} />}
        col2={<MySelect lable="Select Type" option={[]} />}
      />
      <InputGroupRow
        col1={<DateComp lable="Newest Creadit Date" />}
        col2={<MySelect lable="Select Reason" option={[]} />}
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Amount"
            name="Amount"
            isRequired={true}
            value={claim.amount}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="Name"
            name="name"
            isRequired={true}
            value={claim.name}
          />
        }
      />
      <ColTextFiledWithLabel
        label="Email Address"
        name="Email Address"
        isRequired={true}
        value={claim.email_address}
      />
    </CardWhite>
  );
};

export default FormClaim;
