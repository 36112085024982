

import {
  CheckBox,
  MarginBottomFiled,
  EnablesRadiosBtn,
  MyLabel,BodyContentFormWithCard,TitleSmall
} from "../../../../index";

const Onboarding = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-onboarding">
      <TitleSmall title="Onboarding" className=" pb-5 pb-lg-10" />
      {/* <EnablesRadiosBtn typeForms='tin_check'/> */}
      <MarginBottomFiled>
        <MyLabel label="Onboarding Information " />
        <EnablesRadiosBtn />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <CheckBox title="Force Vendors To Fill Onboarding Form" />
      </MarginBottomFiled>
    </BodyContentFormWithCard>
  );
};

export default Onboarding;
