
import { PlusIcon } from '../../../index';
//props 
//fieldNamed String
//fieldKey String
const FiledNamed = (props) => {
  return (
    <div className="d-flex flex-stack mt-5 ">

    {/*begin::Section*/}
    <div className="d-flex align-items-center flex-row-fluid flex-wrap ">
      {/*begin:Author*/}
      <div className="flex-grow-1 me-2">
        <a
          href="pages/user-profile/overview.html"
          className="text-gray-800 text-hover-primary fs-6 fw-bold"
        >
          {props.fieldNamed}
        </a>
        <span className="text-muted fw-semibold d-block fs-7"> {props.fieldKey}</span>
      </div>
      {/*end:Author*/}
      {/*begin::Actions*/}
      <a
        href="#"
        className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
      >
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
   <PlusIcon/>
        {/*end::Svg Icon*/}
      </a>
      {/*begin::Actions*/}
    </div>
    {/*end::Section*/}
  </div>
  
  )
}

export default FiledNamed
