

const  TDCity= (props) => {
    
  const city = props.city;
  return (
    <td>{city}</td>
  )
}

export default TDCity
