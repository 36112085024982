
///props
////lable    String ex 'lable' (option);
////name     String ex 'name';
////isRequired bool option by default false;
////type bool option by default text;

import { RowInputWithLable } from "../lable";
import TextFiled from "./TextFiled";



///tooltip String (option)
const RowTextFiledWithLabel = (props) => {
  return (
    <RowInputWithLable {...props}>
   <TextFiled {...props}/>
    </RowInputWithLable>
  );
};

export default RowTextFiledWithLabel;
