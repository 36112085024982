import React from "react";

const TDTax = (props) => {
  const tax1 = props.tax_id;
  const tax2 = props.tax_id_2;
  const tax3 = props.tax_id_3;
  const tax4 = props.tax_id_4;
  const arr = [tax1, tax2, tax3, tax4];
  let str = "";
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element) {
      const slash = str.length > 0 ? "/" : "";
      str = str + slash + element;
    }
  }
  
  return <td>{props.singleTax ? tax1 : str}</td>;
};

export default TDTax;
