import { useDispatch, useSelector } from "react-redux";
import {
  TableAdmin,
  TDCheck,
  TDNameAndEmail,
  TDTax,
  TDAvgSpend,
  TDAvgCount,
  TDPriority,
  TDAddress1,
  TDStatus,
  TDContact,
  TDPhon,
  TDCountry,
  TDVerificationStatus,
  TDVerificationCount,
  TDId,
  CardsSummary,
  BtnPrimary,
  Dialog,
  // BtnView,
  BtnSecundary,
  UploadFile,
  LayoutAdmin,
  MySelect,
  PersonIcon,
  EmptyTableTD,
  TDAssignee,
  LayoutCleanse,
} from "../../../../../index";

import ReadyToDataCleansAction from "../Action/ReadyToDataCleansAction";
import {
  avgCountTxt,
  avgSpendTxt,
  contactTxt,
  emailTxt,
  priorityTxt,
  taxTxt,
  vendorIdTxt,
  address1Txt,
  statusTxt,
  countryTxt,
  verificationCountTxt,
  verificationStatusTxt,
  phonTxt,
  assigneeTxt,
} from "./constTitleFiled";
import { useEffect } from "react";
import {
  fetchRecord,
  updateCurrentPage,
} from "../../../../../../rtk/Slices/Records-Slices";

const ReadyToDataCleans = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCurrentPage(1));
    dispatch(fetchRecord({ category: "ready_to_data_cleans" }));
  }, []);
  return (
    <>
      <LayoutCleanse
        title="Ready To Data Cleans"
        pages={[ "Ready To Data Cleans"]}
      >
        <CardsSummary
          filters={dataCleans["record_status"]}
          total_vendors={dataCleans.total_vendors}
        />
        <TableAdmin
          isFilter={true}
          isSelest={true}
          isExport={true}
          isAction={true}
          ActionExtra={
            <>
              <BtnSecundary
                attributes={{
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#import_vendor_ids_cleans",
                }}
                text="assign employee & priority"
                icon={<PersonIcon />}
              />
              <Dialog
                id="import_vendor_ids_cleans"
                title="Import Vendor Ids"
                extraAction={<BtnSecundary text="Download Sample" />}
              >
                <ol>
                  <li className="fw-bold text-gray-800 mb-3">
                    Your CSV data should be in the format below. The first line
                    of your CSV file should be the column headers as in the
                    table example. Also make sure that your file is <b>UTF-8</b> 
                    to avoid unnecessary <b>encoding problems</b>.
                  </li>
                  <li className="fw-bold text-gray-800 my-3">
                    If the column&nbsp;
                    <b>
                      you are trying to import is date make sure that is
                      formatted in format Y-m-d (2023-04-11).
                    </b>
                  </li>
                  <li className="fw-bold text-gray-800 my-3"> test.</li>
                  <li className="text-danger"> Upload </li>
                </ol>
                <div className="my-4">
                  <MySelect title="Priority" key="priority" />
                </div>
                <div className="my-4">
                  <MySelect title="Assign To Staff" key="assign_to_staff" />
                </div>
                <UploadFile />
              </Dialog>
            </>
          }
          bulks={{
            name: "ready_to_data_cleans_bulk",
            list: [
              {
                defaultValue: 1,
                id: "kt_modal_update_role_option_1",

                title: "Send Invitation",
                desc: "Best for developers or people primarily using the API",
              },
              {
                id: "kt_modal_update_role_option_0",

                title: "To Verify",
                desc: " Best for business owners and company administrators",
              },
              {
                defaultValue: 2,
                id: "kt_modal_update_role_option_2",

                title: "To Final",
                desc: "Best for people who need full access to analytics data, but don't need to update business settings",
              },
              {
                defaultValue: 3,
                id: "kt_modal_update_role_option_3",

                title: "Archive",
                desc: "Best for employees who regularly refund payments and respond to disputes",
              },
              {
                defaultValue: 3,
                id: "kt_modal_update_role_option_3",

                title: "To Bank Verify",
                desc: "Best for employees who regularly refund payments and respond to disputes",
              },
            ],
          }}
          titles={[
            vendorIdTxt,
            emailTxt, ///name and email
            taxTxt,
            avgSpendTxt,
            avgCountTxt,
            priorityTxt,
            assigneeTxt,
            address1Txt,
            statusTxt,
            contactTxt,
            phonTxt,
            countryTxt,
            verificationStatusTxt,
            verificationCountTxt,
          ]}
          pagenation={{
            total: dataCleans["num_page"],
            currentPage: dataCleans.current_page,
            updateCurrentPage: ({ page, count }) => {
              dispatch(
                fetchRecord({
                  category: "ready_to_data_cleans",
                  page: page,
                  count: count,
                })
              );
            },
          }}
        >
          <TableBody records={dataCleans.records ?? []} />
        </TableAdmin>
      </LayoutCleanse>
    </>
  );
};

export default ReadyToDataCleans;
const TableBody = (props) => {
  const recordes = props.records;

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={9} />;
  }
};

const Record = (props) => {
  const record = props.record;

  return (
    <tr key={props.id}>
      <TDCheck />
      <TDId {...record} />
      <TDNameAndEmail {...record} />
      <TDTax {...record} />
      <TDAvgSpend {...record} />
      <TDAvgCount {...record} />
      <TDPriority {...record} />
      <TDAssignee {...record} />
      <TDAddress1 {...record} />
      <TDStatus {...record} />
      <TDContact {...record} />
      <TDPhon {...record} />
      <TDCountry {...record} />
      <TDVerificationStatus {...record} />
      <TDVerificationCount {...record} />
      <td className="text-end">
        <ReadyToDataCleansAction
          record={record}
          extraAction={<BtnPrimary text="Save Data To Final" />}
        />
      </td>
    </tr>
  );
};
