
import CardComplete from './CardComplete';
import "./complete.css";

function Completed() {
  
  const steps = [
    { title: "Details", desc: "Name your App", isFirst: true },
    { title: "Frameworks", desc: "Define your app framework" },
    { title: "Database", desc: "Select the app database type" },
    { title: "Billing", desc: "Provide payment details" },
  ];
 const completList = steps.map((item ,id)=>{
    return<CardComplete title={item.title} key={id}/>
 })
  return (
    // <div className="row g-5 g-xl-10">
    //    <div className="col-xxl-8">
       <div className="w-100">
    <div className="card-body">
    {/*begin::Tab Content*/}
    <div className="tab-content">
        {/*begin::Tap pane*/}
        <div className="tab-pane fade show active" id="kt_timeline_widget_2_tab_1">
        {/*begin::Table container*/}
        <div className="table-responsive">
            {/*begin::Table*/}
            {/* <table className="table align-middle gs-0 gy-4"> */}
             
            
          
            {/* <tbody > */}
                 {completList}
                 
                
            {/* </tbody> */}
           
            {/* </table> */}
        </div>
        
        </div>
         
    </div>
   
    </div>
</div> 
    // </div> 
    // </div>
    

        


  )
}

export default Completed
