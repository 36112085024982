import React from 'react'

const TDTax2 = (props) => {
    const tax2=props.tax_id_2;
  return (
    <td>{tax2}</td>
  )
}

export default TDTax2
