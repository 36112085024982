import { NavitemSideBarMneu, SideBarMneu } from "../../../index";

const SideBarMneuSettingSetup = (props) => {
  return (
    <>
      {/*begin::Sidebar*/}
      <SideBarMneu>
        <NavitemSideBarMneu
          id="setting-setup-general"
          title="General"
          isActive={true}
        />
        <NavitemSideBarMneu
          id="setting-setup-company-information"
          title="Company Information"
        />
        <NavitemSideBarMneu
          id="setting-setup-localization"
          title="Localization"
        />

        <NavitemSideBarMneu id="setting-setup-email" title="Email" />

        <NavitemSideBarMneu
          id="setting-setup-badrouk-verification"
          title="BEDROCK Verification"
        />
        <NavitemSideBarMneu id="setting-setup-pdf" title="PDF" />
        <NavitemSideBarMneu id="setting-setup-data-cron-job" title="Cron Job" />
        <NavitemSideBarMneu id="setting-setup-onboarding" title="Onboarding" />
        <NavitemSideBarMneu id="setting-setup-invite" title="Invite" />
        <NavitemSideBarMneu
          id="setting-setup-invite-approval"
          title="Invite Approval"
        />
        <NavitemSideBarMneu
          id="setting-setup-supplier-master"
          title="Supplier Master"
        />
        <NavitemSideBarMneu
          id="setting-setup-data-cleans-onboarding"
          title="Data Cleans Onboarding"
        />
        <NavitemSideBarMneu id="setting-setup-pirority" title="pirority" />
      </SideBarMneu>
      {/*end::Sidebar*/}
    </>
  );
};

export default SideBarMneuSettingSetup;
