
import { BtnPrimary, Table, UploadFile,BtnSecundary } from "../../../../../index";


const CardUploadExcelSupplier = () => {
  return (
    <>
      <div className="card  mt-15 ">
        <div id="kt_account_settings_signin_method" className="collapse show">
          {/* <!--begin::Card body--> */}
          <div class="card-body border-top p-9">
            <ol>
              <li className="mb-2">
                Your CSV data should be in th e format below. The first line of
                your CSV file should be the column headers as in the table
                example. Also make sure that your file is <b>UTF-8</b> to avoid
                unnecessary <b>encoding problems</b>.
              </li>
              <li className="mb-2">
                If the column
                <b>
                  you are trying to import is date make sure that is formatted
                  in format Y-m-d (2023-04-13).
                </b>
              </li>
            </ol>
            <div className='card card-flush  px-5 mt-0  '>
            <Table
          
              titles={[
                "Vendor number",
                "Country",
                "Vendor name 1",
                "Vendor name 2",
                "Vendor name 3",
                "Vendor name 4",
                "Address 1",
                "Address 2",
                "Address 3",
                "Address 4",
                "NAICS code",
                "House number",
                "City",
                "County",
                "District",
                "Region",
                "Zip Code",
                "Address book",
                "Po box city",
                "Po box zip code",
                "Search term",
                "Company main telephone number",
                "Clerk at vendor",
                "Account memo",
                "Extension",
                "Ar contact phone number",
                "Clrk's internet",
                "Ar contact fax number",
                "Tax id",
                "Tax id 2",
                "Tax id 3",
                "Tax id 3 type",
                "Tax id 4",
                "Tax id 4 type",
                "Additional address type",
                "Additional Address 1",
                "Additional Address 2/PO BOX",
                "Additional Address 3",
                "Additional Address 4",
                "Additional Address country",
                "Additional Address state",
                "Additional Address city",
                "Additional Address county",
                "Additional Address zip code",
                "Additional Address remittance email",
                "Payment method",
                "Vendor type",
                "Spend",
                "Purchasing Organization",
                "Withholding Tax Code",
                "Vendor Recipient Type",
                "Responsible Salesperson at Vendor's Office",
                "FY2022 spend",
                "FY2022 count",
                "FY2021 spend",
                "FY2021 count",
                "FY2020 spend",
                "FY2020 count",
                "FY2019 spend",
                "FY2019 count",
              ]}
            >
              <Record />
            </Table>
            </div>
            <UploadFile />
          <div className="d-flex justify-content-end">  <BtnSecundary text='DownLoad Sample'/> <BtnPrimary text='Import'/></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardUploadExcelSupplier;

const Record = () => {
  return (
    <tr>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td> <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td> <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td> <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td> <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td> <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td> <td>Sample Data</td>
      <td>Sample Data</td>
      <td>Sample Data</td>
      <td className="text-end">Sample Data</td>
    </tr>
  );
};
