import { useSelector } from "react-redux";
import {
  CardsSummary,
  TableAdmin,
  EmptyTableTD,
  BtnWhite,
  TDCheck,
  MySelect,
  ActionStatement,
  LayoutAudit,
} from "../.././../../index";
import { useNavigate } from "react-router-dom";

const CollectedStatement = () => {
  const dataCleans = useSelector((state) => state.records?.result ?? []);
  const navigate = useNavigate();
  return (
    <LayoutAudit
      title=" Collected"
      pages={["Statement", "Collected"]}
      extraAction={
        <BtnWhite
          text="Create Statement"
          attributes={{ onClick: () => navigate("/upload_statements") }}
        />
      }
    >
      <CardsSummary filters={dataCleans["record_status"]} />
      <TableAdmin
        isExport={true}
        isSelest={true}
        isAction={true}
        bulks={{
          name: "collected_statement_bulk",
          widget: (
            <MySelect
              label="Change status"
              options={[
                { value: false, title: "Not A Claim" },
                { value: false, title: "Resend" },
              ]}
            />
          ),
          list: [
            {
              defaultValue: 1,
              id: "kt_modal_collected_statement_bulk_option_1",

              title: "Mass Delete",
              desc: "Best for developers or people primarily using the API",
            },
          ],
        }}
        titles={["Supplier ID", "Company Name", "File"]}
      >
        <TableBody records={dataCleans.records ?? []} />
      </TableAdmin>
    </LayoutAudit>
  );
};

export default CollectedStatement;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;

  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record key={key} record={record} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={3} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={record.id}>
      <TDCheck />
      <td></td>
      <td></td>
      <td className="text-end"><ActionStatement/></td>
    </tr>
  );
};
