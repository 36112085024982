import { useNavigate } from "react-router-dom";
import { PopupMenuItem ,ActionPopup} from "../../../../../index";


//props 
////isCleanse bool
const ActionOnborading = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <ActionPopup>

        <PopupMenuItem
          text="Edit"
          attributes={{
            "onClick": ()=>{navigate(`/edit_onborading/${props.id}/${props.isCleanse}`)},
          }}
        />
        <PopupMenuItem
          text="Delete"
          attributes={{
            style: { color: "var(--bs-danger)" },
          }}
        />
      </ActionPopup>
     
    </>
  );
};

export default ActionOnborading;


