import { useSelector } from "react-redux";
import { LayoutAdmin, TableAdmin } from "../../../../index";
import AddCurrency from "./AddCurrency";


const SetupCurrencies = () => {
  const dataCleans = useSelector((state) => state.records?.result
  ??[]);
  return (
    <>
      <LayoutAdmin
        title="Currencies"
        pages={["Setup", "Finance", "Currencies"]}
        extraAction={<AddCurrency />}
      >
        <TableAdmin isExport={true} titles={["Name", "Symbol", "Option"]}>
          <TableBody records={dataCleans.records??[]} />
        </TableAdmin>
        {/* <Pagenation
          total={100}
          limit={20}
          updateCurrentPage={updateCurrentPage}
        /> */}
      </LayoutAdmin>
    </>
  );
};

export default SetupCurrencies;
const TableBody = (props) => {
  const recordes = props.records;

  let recordList;
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return (
        <Record
          key={key}
          record={record}
          id={record.vendor_id}
          name={record.company}
          email={record.email}
          tax={record.tax_id}
          address1={record.address_1}
          country={record.country}
          status={record.status}
        />
      );
    });

    return <>{recordList}</>;
  } else {
    return (
      <tr>
        <td colSpan={9}>
          <center>Empty data</center>
        </td>
      </tr>
    );
  }
};

const Record = (props) => {
  const record = props.record;

  return (
    <tr key={props.id}>
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            className="form-check-input widget-13-check"
            type="checkbox"
            value="1"
          />
        </div>
      </td>
      <td>{props.id}</td>
     <td className="text-end"></td>
    </tr>
  );
};
