

const InputGroupRow = (props) => {
  return (
    <>
       
      {/*begin::Input group*/}
      <div className="row mb-5">
        {/*begin::Col*/}
        <div className="col-md-6 fv-row">{props.col1}</div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-md-6 fv-row">{props.col2}</div>  
        {/*end::Col*/}
      </div>
      {/*end::Input group*/}
    </>
  );
};

export default InputGroupRow;
