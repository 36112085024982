import { useNavigate } from "react-router-dom";
import { PopupMenuItem ,ActionPopup} from "../.././../../index";


//props 
////isCleanse bool
const ActionStatement= (props) => {
  const navigate = useNavigate();
  return (
    <>
      <ActionPopup>

        <PopupMenuItem
          text="View Statement"
          attributes={{
            "onClick": ()=>{navigate(`/statement_overview_page`)},
          }}
        />
    
      </ActionPopup>
     
    </>
  );
};

export default ActionStatement;


