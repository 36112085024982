

const MenuLink = (props) => {
  return (
    <>
       {/*begin:Menu link*/}
       <span className="menu-link " >
                          <span className="menu-title ">{props.title??""}</span>
                          <span className="menu-arrow d-lg-none" />
                        </span>
                        {/*end:Menu link*/}
    </>
  )
}

export default MenuLink;
