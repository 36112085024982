///props
////defaultValue    String ex 'defaultValue';
////id     String ex 'id';
////title     String ex 'title';
////desc bool (option);

const Radio = (props) => {
  return (
    <>
      {/*begin::Radio*/}
      <div className={`form-check form-check-custom form-check-solid  ${props.className} my-5`}>
        {/*begin::Input*/}
        <input
          className="form-check-input me-3"
          name={props.name}
          type="radio"
          defaultValue={props.defaultValue ?? 0}
          id={props.id}
          defaultChecked="checked"
        />
        {/*end::Input*/}
        {/*begin::Label*/}
        <label className="form-check-label" htmlFor={props.id}>
          <div className="fw-bold text-gray-800">{props.title}</div>
          {props.desc ? <div className="text-gray-600">{props.desc}</div> : ""}
        </label>
        {/*end::Label*/}
      </div>
      {/*end::Radio*/}
    </>
  );
};

export default Radio;
