// /tabs not work
// /props
// /tabs Array ex [{idContent,title}]

import { Tabs } from "./index";

const TabForDialog = (props) => {
  return (
    <div>
      {/*begin:Pages menu*/}
      <div className="menu-active-bg px-4 px-lg-0">
      <div className="w-100 overflow-auto">
        <Tabs {...props} />  </div>
        {/*begin:Tab content*/}
        <div className="tab-content py-4 py-lg-8 px-lg-7">{props.children}</div>
        {/*end:Tab content*/}
      </div>
      {/*end:Pages menu*/}
    </div>
  );
};

export default TabForDialog;

/////props
////isActive bool by defualt false using in start tab opening
////id  A string to associate the tab with the  content(data-bs-target)
export const BodyTabs = (props) => {
  return (
    <div className={`tab-pane ${props.isActive ? "active" : ""}`} id={props.id}>
      {props.children}
    </div>
  );
};
