import React from "react";
import { NavitemSideBarMneu, SideBarMneu } from "../../../../../index";
import { useLocation, useNavigate } from "react-router-dom";

const SideBarMneuStatement = () => {
  const navigate = useNavigate();
  let location = useLocation();
  console.log("location:");

  function handleClick(namePage) {
    navigate(`/statement_${namePage}_page`);
  }
  function checkActive(namePage) {
    return `/statement_${namePage}_page` == location.pathname;
  }

  const pages = [
    {
      id: "profile_statement",
      title: "Overview",
      nampePage: "overview",
    },
    {
      id: "files_statement",
      title: "Files ",
      nampePage: "files",
    },

    {
      id: "claims_statement",
      title: "Claims ",
      nampePage: "claims",
    },
    {
      id: "discussions_statement",
      title: "Discussions ",
      nampePage: "discussions",
    },

    {
      id: "nots_statement",
      title: "Nots ",
      nampePage: "nots",
    },

    {
      id: "activity_statement",
      title: "Activity ",
      nampePage: "activity",
    },
  ];
  return (
    <SideBarMneu>
      {pages.map((element) => (
        <NavitemSideBarMneu
          id={element.id}
          title={element.title}
          onClick={() => handleClick(element.nampePage)}
          isActive={checkActive(element.nampePage)}
        />
      ))}
    </SideBarMneu>
  );
};

export default SideBarMneuStatement;
