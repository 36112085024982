import React from 'react'

const TDCountry = (props) => {
    const country=props.country;
  return (
    <td>{country}</td>
  )
}

export default TDCountry
