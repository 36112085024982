import React from "react";
import { CardWhite, FormPermissionVendor} from "../../../../index";

const InviteFormPermissions = () => {
  return (
    <CardWhite  margenBottom>
 <FormPermissionVendor/>
    </CardWhite>
  );
};

export default InviteFormPermissions;
