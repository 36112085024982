import {
  InputGroupRow,
  MarginBottomFiled,
  ColTextFiledWithLabel,
  MySelect,
  Radio,BodyContentFormWithCard
} from "../../../../index";


const Pirority = () => {
  return (
    <BodyContentFormWithCard id="setting-setup-pirority">
      <MarginBottomFiled>
        
        <ColTextFiledWithLabel label="Spend" type="number" />
      </MarginBottomFiled>
      <ORAnd typeForm="TEST1" />
      <MarginBottomFiled>
        
        <ColTextFiledWithLabel label="Count" type="number" />
        </MarginBottomFiled>
      <ORAnd typeForm="TEST" />
      <MarginBottomFiled>
      </MarginBottomFiled>

      <MarginBottomFiled>
        <MySelect
          label="Vendor Type"
          name="settings[vendor_master_auto_archive_vendor_type][]"
        />
      </MarginBottomFiled>
    </BodyContentFormWithCard>
  );
};

export default Pirority;

const ORAnd = (props) => {
  return (
    <MarginBottomFiled>
      <InputGroupRow
        col1={
          <Radio
            id={`y_opt_1_settings_${props.typeForm}`}
            name={`settings[onoffswitch_${props.typeForm}]`}
            title="OR"
          />
        }
        col2={
          <Radio
            id={`y_opt_2_settings_${props.typeForm}`}
            name={`settings[onoffswitch_${props.typeForm}]`}
            title="AND"
          />
        }
      />
    </MarginBottomFiled>
  );
};
