

import { BtnPrimary, Dialog ,Tabs,FormStaff, PermissionForm } from "../../../index";



const AddStaff = () => {
  return (
    <>
      <BtnPrimary
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_add_staff",
        }}
        text="New Staff Member"
        className="me-3"
      />

      <AddStaffDialog id="kt_add_staff" title="Add Staff" />
    </>
  );
};

export default AddStaff;

export const AddStaffDialog = (props) => {
  return (
    <Dialog id={props.id} title={props.title} classNameBody='ps-lg-10 pt-0'>
      <Tabs
        tabs={[
          { idContent: "kt_profile_form", title: "Profile", isActive: true },
          { idContent: "kt_permission_form", title: "Permission" },
        ]}
      />
      <div className="tab-content">
        <div id="kt_profile_form" className="tab-pane fade show active ">   
          <FormStaff isAdd={true} />
        </div>
        <div id="kt_permission_form" className="tab-pane fade mt-10">
          <PermissionForm />
        </div>
      </div>
    </Dialog>
  );
};
