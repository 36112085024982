///props
///id
//classname (option)
///attributes add for btn
///icon (option)
////text string
//link String for roo

const BtnWhite = (props) => {
  return (
    <a
     
      className={
        "btn btn-flex btn-sm h-35px bg-body fw-bold " + props.className
      }
      {...props.attributes}

    >
    
      {props.icon}
      {props.text}
    </a>
  );
};

export default BtnWhite;
