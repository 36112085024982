import { useSelector } from "react-redux";
import {

  AddCreateVendorContactBTN,
  EmptyTableTD,
  LayoutAdmin,
  SwitchInput,
  TableAdmin,
} from "../../index";


const ContactsVendorPage = () => {
 const dataCleans = useSelector((state) => state.records?.result
  ??[]);
  return (
    <LayoutAdmin
      title="Vendor Contacts"
      pages={["Vendor", "Vendor","Contacts"]}
   
    >
    
      <TableAdmin
        isExport={true}
   
        titles={[
          "First Name",
          "Last Name",
          "Email",
          "Company",
          "Primary Email",
          "Phone",
          "Position",
          "Active",
          "Last Login",
        ]}
      >
        <TableBody records={dataCleans.records??['x']} />
      </TableAdmin>

    </LayoutAdmin>
  );
};

export default ContactsVendorPage;

const TableBody = (props) => {
  const recordes = props.records;
  let recordList;
   
  if (recordes && recordes.length >= 1) {
    recordList = recordes.map((record, key) => {
      return <Record id={key} />;
    });

    return <>{recordList}</>;
  } else {
    return <EmptyTableTD colSpan={9} />;
  }
};

const Record = (props) => {
  const record = props.record;
  return (
    <tr key={props.id}>
      <td></td>

      <td></td>
      <td>
     < AddCreateVendorContactBTN/>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td><SwitchInput/></td>

      <td className="text-end"></td>
    </tr>
  );
};

