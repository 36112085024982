import { useState } from "react";

import {

  BtnPrimary, Dialog ,BtnSecundary, CreateTabFieldOnborading, CreateTabOnborading
} from "../../../../../index";

const AddTabFiled = (props) => {

  return (
    <>
      <BtnPrimary
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_add_fields",
        }}
        text="New Onboarding Tab-Field"
        className="me-3"
      />

      <AddTabFiledDialog id="kt_add_fields" title="Add Tab-Field" />
    </>
  );
};

export default AddTabFiled;

export const AddTabFiledDialog = (props) => {
  const [next, setNext] = useState(false);
  return (
    <Dialog id={props.id} title={props.title} classNameBody="ps-lg-10 pt-0">
      <form
        className="form mt-10"
        action="#"
        id="kt_modal_new_kt_add_fields_form"
      >
  
        {/*begin::Scroll*/}
        <div
          className="scroll-y me-n7 pe-7"
          id="kt_modal_new_kt_add_fields_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_new_kt_add_fields_header"
          data-kt-scroll-wrappers="#kt_modal_new_kt_add_fields_scroll"
          data-kt-scroll-offset="300px"
        >
          {next ? <CreateTabFieldOnborading /> : <CreateTabOnborading />}
          {next ? (
            <BtnSecundary
              text="Back"
              attributes={{ onClick: () => setNext(false) }}
            />
          ) : (
            ""
          )}
          <BtnPrimary
            text="Add Fields"
            attributes={{ onClick: () => (!next ? setNext(!next) : "") }}
          />
        </div>
      </form>
    </Dialog>
  );
};





