import React, { useState } from 'react'
//Images
import UnitedStates from '../../images/UnitedStates'
import Spain from '../../images/Spain'
import France from '../../images/France'
import Germany from '../../images/Germany'
import Japan from '../../images/Japan'
//Components
import MenuItem from './MenuItem'
function Menu() {
    // const [open , setOpen] = useState(false)
    // const menuList = flag.map((men) => {
    //     <MenuItem flag={men} key={men.src} />
    // })
    return (
        // <div className="menu-item px-3">
        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7"
            data-kt-menu="true"
            id="kt_auth_lang_menu"
        >

            <MenuItem alt={"English"} src={<UnitedStates className="rounded-1" />} />

            <MenuItem alt={"Spain"} src={<Spain className="rounded-1" />} />

            <MenuItem alt={"France"} src={<France className="rounded-1" />} />

            <MenuItem alt={"Japan"} src={<Japan className="rounded-1" />} />

            <MenuItem alt={"Germany"} src={<Germany className="rounded-1" />} />
            {/* {menuList} */}

        </div>
        // </div>
    )
}

export default Menu
