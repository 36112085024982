import React from 'react'
import { BtnPrimary, CardWhite, ColTextFiledWithLabel, MarginBottomFiled } from '../../../../index'

const MapsVendorTabs = () => {
  return (
    <CardWhite>
    <MarginBottomFiled><ColTextFiledWithLabel label='Latitude (Google Maps)'/></MarginBottomFiled>
    <MarginBottomFiled><ColTextFiledWithLabel label='Longitude (Google Maps)'/></MarginBottomFiled>
    <BtnPrimary text='Save'/>
    </CardWhite>
  )
}

export default MapsVendorTabs