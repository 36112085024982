import { LayoutAdmin, TableOnborading } from "../../../index";


const BedrockOnboarding = () => {
  return (
    <LayoutAdmin
      title="Bedrock Onboarding"
      pages={["Setup", "Bedrock Onboarding"]}
  >
      <TableOnborading isCleanse={false} classNameTable='mt-15' />
    </LayoutAdmin>
  );
};

export default BedrockOnboarding;
