import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import React, { useState } from "react";
import { format } from "date-fns";
import './datecomp.css';
import { MyLabel } from "../../../index";
//props
//isRequired bool (option)
//tooltip string (option)
//label String 
//isRangeDate bool 
const DateComp = (props) => {
  const [openDate, setOpenDate] = useState(false);

  const [date, setDate] = useState([
    {
      // end date: remove null value and put new Date when enter this from calender
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  return (
    <>
  <MyLabel label={props.label }  tooltip={props.tooltip}  isRequired={props.isRequired}/>
  
      <span onClick={() => setOpenDate(!openDate)} className="headerSearchText form-control form-control-solid">
        {` ${format(date[0].startDate, "dd MMM, yyyy")}  
                         ${props.isRangeDate==true?`to ${format(date[0].endDate, "dd MMM, yyyy")}`:''} `}
      </span>

      {/*   يلي كانت مكتوبة setstate جبت الكلام هاد من الموقع تبع الكاليندر يلي فيه المكتبة تبعتها بس انا غيرت ال*/}
      {openDate && (
        <DateRange
      
          editableDateInputs={true}
          onChange={(item) => setDate([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={date}
          //to handle the calender
          className="date"
          //minimun date: to be anew date so in this case we are not able to choose any previous date
          minDate={new Date()}
        />
      )}
    </>
  );
};

export default DateComp;
