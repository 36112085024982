
import {
  MarginBottomFiled,
  ColTextFiledWithLabel,
  MySelect,EnablesRadiosBtn,BodyContentFormWithCard,TitleSmall
} from "../../../../../../index";



const VendorMaster = () => {
  return (
    <BodyContentFormWithCard id="setting-vendor-master">
      <TitleSmall
        title="Vendor Master Auto Archive"
        className=" pb-5 pb-lg-10"
      />
      <EnablesRadiosBtn typeForms="vendor_master_auto_archive" />
      <MarginBottomFiled>
 
        <ColTextFiledWithLabel
          label="Annual Spend Auto Archive"
          name="settings[vendor_master_auto_archive_annual_spend]"
          type="number"
        />
      </MarginBottomFiled>
      <MarginBottomFiled>
        <MySelect
          label="Vendor Type"
          name="settings[vendor_master_auto_archive_vendor_type][]"
        />
      </MarginBottomFiled>
    </BodyContentFormWithCard>
  );
};

export default VendorMaster;
