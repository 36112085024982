import React from 'react'
import TableHead from './TableHead'
///props
//title String
//isAction bool (option)
//className(optional)
const Table = (props) => {
  return (
    <>   {/*begin::Table container*/}
    <div className={`table-responsive  ${props.className}`}>
      {/*begin::Table*/}
      <table
        id="kt_profile_overview_table"
        className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold"
      >
        <TableHead
         {...props}
        />
        {/*begin::Body*/}
        <tbody className="fs-6">{props.children}</tbody>
        {/*end::Body*/}
      </table>
      {/*end::Table*/}
    </div>
    {/*end::Table container*/}</>
  )
}

export default Table
