import React, { useState } from 'react';
import './complete.css';
import Badge from '../../Badge';
function CardComplete({ title }) {
  const [activeClass, setActiveClass] = useState(false);
  const checkHandler = () => {
    setActiveClass(!activeClass);
  };

  return (
    // <tr >
    // <td>
    // <span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center h-40px bg-${activeClass=== true? "success":"primary"}`}></span>
    // <a
    //     href="#"
    //     className="text-gray-800 text-hover-primary fw-bold fs-6"
    //     >
    //     {title}
    //     </a>
    // </td>
    //      {/* <td className="ps-0">
    //         <div className={`form-check form-check-custom ${activeClass === true?"form-check-success":""} form-check-solid`}>
    //             <input className="form-check-input" type="checkbox" value="" data-kt-element="checkbox" checked={activeClass} onChange={checkHandler}/>
    //      </div>
    //    </td> */}
    // {/* <td>
    //     <a
    //     href="#"
    //     className="text-gray-800 text-hover-primary fw-bold fs-6"
    //     >
    //     {title}
    //     </a>
    // </td> */}
    // <td className="text-end">
    // <div className={`form-check form-check-custom ${activeClass === true?"form-check-success":""} form-check-solid`}>
    //             <input className="form-check-input" type="checkbox" value="" data-kt-element="checkbox" checked={activeClass} onChange={checkHandler}/>
    //      </div>
    //         <span data-kt-element="status" className ={` badge badge-light-${activeClass=== true? "success":"primary"} `}>{ activeClass ? "Done" : " In Process" } </span>
    //     </td>

    // </tr>
    <div className='test'>
      <div className='sec1'>
        <span
          data-kt-element='bullet'
          className={`bullet bullet-vertical d-flex align-items-center h-40px bg-${
            activeClass === true ? 'success' : 'primary'
          }`}></span>
        <a
          href='#'
          className='text-gray-800 text-hover-primary fw-bold fs-6'
          style={{ padding: '10px 0px' }}>
          {title}
        </a>
      </div>
      <div className='sec'>
        <div
          className={`form-check form-check-custom ${
            activeClass === true ? 'form-check-success' : ''
          } form-check-solid`}>
          <input
            className='form-check-input   m-5'
            type='checkbox'
            value=''
            data-kt-element='checkbox'
            checked={activeClass}
            onChange={checkHandler}
          
          />
        </div>
        <Badge
          color={`badge-light-${activeClass === true ? 'success' : 'primary'}`}
          name={activeClass ? 'Done' : ' In Process'}
        />
      </div>
    </div>
  );
}
// badge ${props.color} fw-bold px-4 py-3
export default CardComplete;
