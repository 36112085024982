
////props
//tabs array [ {idContent: String, title: String,isActive: bool }]
const TabsProfile = (props) => {
    const tabs = props.tabs;
  return (
    <>
           {/*begin:Tabs nav*/}
     
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            {tabs?.map((element,key) => (
              <NavitemTabDialog key={key}  title={element.title} id={element.idContent} isActive={element.isActive}/>
            )) ?? ""}
          </ul>
     
        {/*end:Tabs nav*/}  
    </>
  )
}

export default TabsProfile;

function NavitemTabDialog(props) {
    const isActive=props?.isActive??false;
    return (
      <>
         
        {/*begin:Nav item*/}
        <li className="nav-item mt-2">
          <a
            className={`nav-link text-active-primary ms-0 me-10 py-5 text-capitalize  ${isActive ? "active" : ""}`}
            href="#"
            data-bs-toggle="tab"
            data-bs-target={`#${props.id}`}
          >
            {props.title}
          </a>
        </li>
        {/*end:Nav item*/}
      </>
    );
  }
