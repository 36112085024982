import React from 'react'
import {SingleItemPagenation} from '../../../index';

function CreatePagenationNumbers({
    start,
    stop,
    updateCurrentPage,
    currentPage,
  }) {
    console.log("start, stop, currentPage:", start, stop, currentPage);
  
    const onPageChange = (page) => {
      if (updateCurrentPage) {
        updateCurrentPage({ page: page });
      }
    };
  
    if (stop <= 1) {
      return (
        <SingleItemPagenation
          page={1}
          key={1}
          onPageChange={onPageChange}
          currentPage={currentPage}
        />
      );
    }
  
    return Array.from({ length: (stop - start) / 1 + 1 }, (e, i) => {
      return (
        <SingleItemPagenation
          page={i+start }
          key={i + 1}
          onPageChange={onPageChange}
          currentPage={currentPage}
        />
      );
    });
  }

export default CreatePagenationNumbers