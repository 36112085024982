import React from "react";
import { useNavigate } from "react-router-dom";
import { Dot } from "../../icon/index";
////PROPS
///tabs Array to create Menu sub (Optional) ex [{title, page}]
///title String Name For Item
///namePage String  (Optional) use navigation
///isNotSubMenu bool  (Optional)
const SubItem = (props) => {
  // console.log(props.isNotSubMenu);
  const tabs = props?.tabs;
  const IsNotEmptySubItems = () => tabs && tabs.length > 0;
  if (!IsNotEmptySubItems()) {
    return (
      <ItemNav
        name={props.title}
        namePage={props.namePage}
        isNotSubMenu={props.isNotSubMenu ?? false}
      />
    );
  } else {
    return (
      <>
        {/*begin:Menu item*/}
        <div
          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
          data-kt-menu-placement="right-start"
          className="menu-item menu-lg-down-accordion"
        >
          {/*begin:Menu link*/}
          <span className="menu-link">
            <Dot />
            <span className="menu-title">{props.title}</span>
            <span className="menu-arrow" />
          </span>
          {/*end:Menu link*/}
          {/*begin:Menu sub*/}
          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
            {props.tabs.map((element, key) => (
              <ItemNav
                key={key}
                name={element.title}
                namePage={element.namePage}
              />
            ))}
          </div>
          {/*end:Menu sub*/}
        </div>
        {/*end:Menu item*/}
      </>
    );
  }
};

export default SubItem;
///props

const ItemNav = (props) => {
  const navigate = useNavigate();

  function handleClick() {
    console.log("props.namePage:", props.namePage);
    navigate(`/${props.namePage}`);
  }
  return (
    <>
      {/*begin:Menu item*/}
      <div
        className="menu-item"
     
      >
        {/*begin:Menu link*/}
        <a className="menu-link" onClick={handleClick}>
          {props.isNotSubMenu ? (
            ""
          ) : (
            <span className="menu-bullet">
              <span className="bullet bullet-dot" />
            </span>
          )}
          <span className="menu-title"  style={props.isNotSubMenu ? { color: `var(--bs-white)`} : {}}>{props.name}</span>
        </a>
        {/*end:Menu link*/}
      </div>
      {/*end:Menu item*/}
    </>
  );
};
