import {
  Dialog,
  AddPrimaryUser,
  BtnSecundary,
  BtnPrimary,
  FormPermissionVendor,
  FormEmailNotificationVendor,
  ColTextFiledWithLabel,
  MarginBottomFiled,
} from "../../index";

///props
///record (optional) Object  use in edit
export const AddCreateVendorContactBTN = (props) => {
  return (
    <>
      <BtnSecundary
        text={`${props.id ? "" : "Create"} Contact`}
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": `#kt_modal_create_vendor_contact_${props.id ?? ""}`,
        }}
      />
      <AddCreateVendorContactDialog {...props} />
    </>
  );
};
const AddCreateVendorContactDialog = (props) => {
  const isViewVendor = props.isViewVendor;
  return (
    <>
      <Dialog
        id={`kt_modal_create_vendor_contact_${props.id ?? ""}`}
        title={props.id ? props.name : "Create Contact"}
        classNameBody="pt-lg-5 "
      >
        <MarginBottomFiled>
          <AddPrimaryUser isViewVendor />
        </MarginBottomFiled>
        <MarginBottomFiled>
          {props.isViewVendor == true ? (
            <ColTextFiledWithLabel name="password" label="password" />
          ) : (
            <FormPermissionVendor />
          )}
        </MarginBottomFiled>
        <FormEmailNotificationVendor />
        <div className="modal-footer flex-center">
          <BtnSecundary text="close" />
          <BtnPrimary text="save" />
        </div>
      </Dialog>
    </>
  );
};

export default AddCreateVendorContactDialog;
