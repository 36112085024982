

const  TDCompanyPhoneMumber= (props) => {
    
  const companyPhoneMumber = props.company_phone_number;
  return (
    <td>{companyPhoneMumber}</td>
  )
}

export default TDCompanyPhoneMumber;
