import {
  BtnPrimary,
  Dialog,
  InputGroupRow,
  ColTextFiledWithLabel,
  MySelect,
  BtnSecundary,
  UploadFile,

  FormViewTitle,
  AddPrimaryUser,
} from "../../index";

export const AddCreateVendorBTN = () => {
  return (
    <>
      <BtnSecundary
        text="Create Vendor"
        attributes={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_create_vendor_",
        }}
      />
      <AddCreateVendorDialog />
    </>
  );
};
const AddCreateVendorDialog = (props) => {
  return (
    <>
      <Dialog id={`kt_modal_create_vendor_${props.id??''}`} title="Create Vendor"     classNameBody="pt-lg-5 ">
        <FormFrist />
        <FormViewTitle title='Add User Primary'/>
        <AddPrimaryUser />
        <div className="modal-footer flex-center">
          <BtnSecundary text="close" />
          <BtnPrimary text="save" />
        </div>
      </Dialog>
    </>
  );
};

export default AddCreateVendorDialog;

const FormFrist = () => {
  return (
    <>
<FormViewTitle title='Information' isFirst={true}/>
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="Vendor ID" name="id" />}
        col2={
          <ColTextFiledWithLabel
            label="Company Name "
            name="company"
            isRequired={true}
          />
        }
      />
      <MySelect
        label="Parent Company"
        name="parent_company"
        isRequired={true}
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="WebSite"
            name="webSite"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel
            label="zip Code"
            name="zip"
            isRequired={true}
          />
        }
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel label="City" name="city" isRequired={true} />
        }
        col2={<MySelect label="state" name="state" isRequired={true} />}
      />
      <InputGroupRow
        col1={
          <ColTextFiledWithLabel
            label="Street 1"
            name="address1"
            isRequired={true}
          />
        }
        col2={
          <ColTextFiledWithLabel label="Phone" name="phone" isRequired={true} />
        }
      />
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="Street 2" name="address3" />}
        col2={<ColTextFiledWithLabel label="Vat Nmber" name="vat_number" />}
      />
      <InputGroupRow
        col1={<ColTextFiledWithLabel label="Street 3" name="address3" />}
        col2={<MySelect label="country" name="country" isRequired={true} />}
      />
      <UploadFile />
    </>
  );
};

