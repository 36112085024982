import React from "react";

import {
  CardDetailsProfile,
  CardHeaderProfile,
  CardSatementsProfile,
  CardSettingsProfile,
} from "./component/index";
////props
///isViewVendor bool (optional)

const ProfileContentPage = (props) => {
  return (
    <>
  
        <div id="kt_app_content" className="app-content px-0">
          <CardHeaderProfile />
          <div className="tab-content">
            <CardDetailsProfile isViewVendor={props.isViewVendor}/>
            <CardSettingsProfile isViewVendor={props.isViewVendor}/>
            <CardSatementsProfile isViewVendor={props.isViewVendor} />
          </div>
        </div>
      
    </>
  );
};

export default ProfileContentPage;
